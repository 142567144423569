import {useSelector, } from "react-redux";
import { useLocation } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";

function AuthLogin() {
    const isLoggedIn = useSelector((state) => { return state.loginState.isLoggedIn});
    let location = useLocation();

    if (isLoggedIn) {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        let navigation = user.defaultScreen;

        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.

        return <Navigate to={navigation} state={{ from: location }} />;
    }

    return <Outlet />;
}

export default AuthLogin;