import { createSlice } from '@reduxjs/toolkit'

export const headerReducer = createSlice({
    name: "header",
    initialState: {
        "showSideNav": true
    },
    reducers: {
        updateVisibility: (state) => {
            state.showSideNav = !state.showSideNav;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateVisibility } = headerReducer.actions;

export default headerReducer.reducer;