import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {updateDate, updateRangeOfDate} from "../../reducer/DateRangeReducer";
import {updateDataFlag} from "../../reducer/DateAppliedReducer";
import {
    currentWeekRange,
    getMonthEndDate,
    getMonthStartDate,
    lastQuarterRange,
    lastWeekRange
} from "../../commom/DateFormat";

const DateRange = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedDateRange, setSelectedDateRange] = useState("Select");

    useEffect(() => {
        let localStorageStartDate = window.localStorage.getItem('startDate');
        let localStorageEndDate = window.localStorage.getItem('endDate');
        let localStorageDateRange = window.localStorage.getItem('dateRange');
        
        if (localStorageStartDate) {
            dispatch(updateDate({name: "startDate", value: localStorageStartDate}));
            setStartDate(localStorageStartDate);
        } else {
            dispatch(updateDate({name: "startDate", value: getMonthStartDate()}));
        }

        if (localStorageEndDate) {
            dispatch(updateDate({name: "endDate", value: localStorageEndDate}));
            setEndDate(localStorageEndDate);
        } else {
            dispatch(updateDate({name: "endDate", value: getMonthEndDate()}));
        }

        if(localStorageDateRange && localStorageDateRange != "Select") {
            setSelectedDateRange(localStorageDateRange);
        }
    }, []);

    const handleChange = (event) => {
        let { name, value} = event.currentTarget;

        if(name === "startDate") {
            window.localStorage.setItem('startDate',value);
            setStartDate(value);

            dispatch(updateDate({name, value}));
            dispatch(updateDataFlag(false));

            setSelectedDateRange("Select");
            window.localStorage.setItem('dateRange',"");
        } else if(name === "endDate"){
            window.localStorage.setItem('endDate', value);
            setEndDate(value);

            dispatch(updateDate({name, value}));
            dispatch(updateDataFlag(false));

            setSelectedDateRange("Select");
            window.localStorage.setItem('dateRange',"");
        } else if(name === "selectedDate") {
            setSelectedDateRange(value);
            if(value === "Select") {
                window.localStorage.setItem('dateRange', "");
                return false;
            }

            let startValue, endValue;
            if(value === "Current Week") {
                let dates = currentWeekRange();
                startValue = dates.start;
                endValue = dates.end;
                console.log(dates);
            } else if(value === "Last Week") {
                let dates = lastWeekRange();
                startValue = dates.start;
                endValue = dates.end;

            } else if(value === "Last Quarter") {
                let dates = lastQuarterRange();
                startValue = dates.start;
                endValue = dates.end;
            }
            window.localStorage.setItem('dateRange',value);
            window.localStorage.setItem('startDate',startValue);
            setStartDate(startValue);
            window.localStorage.setItem('endDate', endValue);
            setEndDate(endValue);
            dispatch(updateRangeOfDate({"startDate" : startValue, "endDate" : endValue}));
            dispatch(updateDataFlag(false));
        }
    };

    const applyDateChange = () => {
        let startValue = new Date(startDate);
        let endValue = new Date(endDate);

        if(startValue && endValue && endValue > startValue) {
            dispatch(updateDataFlag(true));
        }
    };

    return (
        <div className="input-group">
            <select name="selectedDate" onChange={handleChange} onBlur={applyDateChange}
                    className="rangeSelection form-select" value={selectedDateRange}>
                <option value="Select">Select</option>
                <option value="Last Quarter">Last Quarter</option>
                <option value="Last Week">Last Week</option>
                <option value="Current Week">Current Week</option>
            </select>
            <span className="input-group-text">Start</span>
            <input className="form-control" onChange={handleChange} id="sessionDate"
                   name="startDate" type="date" value={startDate} onBlur={applyDateChange}/>
            <span className="input-group-text">End</span>
            <input className="form-control" onChange={handleChange} id="sessionDate"
                   name="endDate" type="date" value={endDate} onBlur={applyDateChange}/>
        </div>
    )
};

export default DateRange;