import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import Form from 'react-bootstrap/Form';
import MultiSiteForm from "./MultiSiteForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TreeCheckbox from "../../commom/CustomCheckbox";
import {formValueSetup, handleInputChange} from "../../commom/FormHandler";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import Loader from "../../commom/LoaderComponent";
import {
    sortArrayData,
    getPlantMasterData,
    getUserAccessToUrl,
    searchArray,
    getSelectedPlantConfigArray
} from "../../commom/CommonFunction";
import {Link} from "react-router-dom";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";

const MultiSite = () => {
    const [site, setSite] = useState(null);
    const sitesData = useSelector((state) => state.siteReducer);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [siteId, setSiteId] = useState();
    const [plantId, setPlantId] = useState();
    const [houseCode, setHouseCode] = useState();
    const [dvpList, setDvpList] = useState();
    const [rdoList, setRdoList] = useState();
    const [gmList, setGmList] = useState();
    const [samList, setSamList] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);

    const [frequencyData, setFrequencyData] = useState();
    const [plantData, setPlantData] = useState();
    const [formValue, setFormValue] = useState({
        dvp: "",
        rdo: "",
        gm: "",
        sam: ""
    });
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [showListMessage, setShowListMessage] = useState(true);
    const [siteValidationMessage, setsiteValidationMessage] = useState("Please select atleast one facility or plant.");

    useEffect(() => {
        dispatch(showDateInHeader(false));
        setSite(updateData(sitesData));
        dispatch(showMasterDateOption(false));

        getUserDetailWithMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            setSite(updateData(response.sites, response.plants));
            setPlantData(response.plants);
            setFrequencyData(response.masterData);
            setSamList(getSelectedPlantConfigArray("Sam", "type", response.masterData, "value"));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
        })
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithMasterData',
            requestConfig: {
                "data" : {
                    "id": user.userId,
                    "isSam": true
                }
            }
        });
        return await response.data;
    };

    const updateData = (fetchedValue, activePlantList) => {
        let plants = {};
        let plantIds = [];

        fetchedValue.forEach((value) => {
            if (value.plantHc && plantIds.indexOf(value.plantHc) === -1) {
                let isPlantActive = searchArray(value.plantHc, activePlantList, "houseCode");
                if(isPlantActive) {
                    plants[value.plantHc] = {
                        id: value.plantHc,
                        name: value.plant,
                        isActive: false,
                        open: false,
                        subModule: [
                            {
                                id: value.siteId,
                                name: value.site,
                                isActive: false
                            }
                        ]
                    };

                    plantIds.push(value.plantHc)
                }
            } else if (value.plantHc && plantIds.indexOf(value.plantHc)) {
                plants[value.plantHc].subModule.push({
                    id: value.siteId,
                    name: value.site,
                    isActive: false
                })
            }
        });

        let data =  Object.keys(plants).map(key => {
            return plants[key];
        });
        return sortArrayData(data, "name");
    };

    const toggleOpen = (event) => {
        let toggleValue = event.currentTarget.attributes['name'].value;
        const newData = [];
        site.forEach(obj => {
            let roleValue = Object.assign({}, obj);

            if (roleValue.id === toggleValue) {
                roleValue.open = !roleValue.open;
            }

            newData.push(roleValue);
        });
        let sortData = sortArrayData(newData, "name");
        setSite(sortData);
    };

    const toggleExpand = (selectValue) => {
        let flag = selectValue;
        const newData = [];
        site.forEach(obj => {
            let roleValue = Object.assign({}, obj);
            roleValue.open = flag;
            newData.push(roleValue);
        });
        let sortData = sortArrayData(newData, "name");
        setSite(sortData);
    };

    const onCheckAllCheckbox = (name) => {
        setShowListMessage(true);
        let checkBoxName = name.split(',');
        let parent = (checkBoxName.length === 1);
        let child = (checkBoxName.length === 2);
        let siteIds = [];

        const newData = site.map(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});

            if (roleValue.id === checkBoxName[0]) {
                if (parent) {
                    roleValue.isActive = !roleValue.isActive;
                }

                let isParentActive = false;
                (obj.subModule).forEach((subVal) => {
                    let subModule;

                    subModule = Object.assign({}, subVal);

                    if (parent) {
                        subModule.isActive = roleValue.isActive;
                    } else if (subModule.id === checkBoxName[1]) {
                        subModule.isActive = !subModule.isActive;
                    }

                    if (subModule.isActive === true) {
                        isParentActive = true;
                    }

                    if (child && !isParentActive) {
                        roleValue.isActive = false;
                    } else if (child && isParentActive) {
                        roleValue.isActive = true;
                    }

                    if(subModule.isActive) {
                        siteIds.push(subModule.id);
                    }

                    roleValue.subModule.push(subModule);
                });

                if(roleValue.isActive) {
                    selectedPlant(roleValue.id);
                }

                return roleValue
            } else {
                roleValue.isActive = false;
                roleValue.open = false;

                (obj.subModule).forEach((subVal) => {
                    let subModule = Object.assign({}, subVal);
                    subModule.isActive = false;
                    roleValue.subModule.push(subModule);
                });
                return roleValue;
            }
        });
        let sortData = sortArrayData(newData, "name");
        setSite(sortData);
        setSiteId(siteIds);
    };

    const handleChange = (event) => {
        setShowListMessage(true);
        setDisableSubmit(false);
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const cancelHandler = () => {
        setShowListMessage(true);
        setDisableSubmit(false);
        setShowMessage(false);
        setPlantId("");
        setHouseCode("");
        formInputValueSetup(false);
        setDvpList("");
        setRdoList("");
        setGmList("");
        setSamList("");

        setSite(updateDataValueToInactive());
    };

    const updateDataValueToInactive = () => {
        let plants = [];

        site.map(obj => {
            let plant = Object.assign({}, obj, {subModule: []});
            plant.isActive = false;
            plants.push(plant);

            (obj.subModule).forEach((subValue) => {
                let site = Object.assign({}, subValue);
                site.isActive = false;
                plant.subModule.push(site);
            })
        });

        return sortArrayData(plants, "name");
    };

    const selectedPlant = (plantId) => {
        setShowMessage(false);
        let selectedPlant = plantData.find((obj) => {
            if (obj.houseCode === plantId) {
                return obj;
            }
        });

        setPlantId(selectedPlant._id);
        setHouseCode(selectedPlant.houseCode);

        setDvpList(getPlantMasterData(frequencyData,"DVP", selectedPlant._id));
        setRdoList(getPlantMasterData(frequencyData,"RDO", selectedPlant._id));
        setGmList(getPlantMasterData(frequencyData,"GM", selectedPlant._id));
    };

    const submitHandler = async(event) => {
        event.preventDefault();
        setDisableSubmit(true);
        if(siteId && siteId.length === 0) {
            setShowListMessage(false);
            return false;
        }
        let newEntry = {
            siteIds: siteId,
            dvp: formValue.dvp,
            rdo: formValue.rdo,
            gm: formValue.gm,
            sam: formValue.sam
        };

        await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/updateMultiSite',
            requestConfig: {
                data : newEntry
            }
        });

        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Message showMessage={showMessage}
                     response={response} error={error} loading={loading}
                     formValidation={showListMessage} formValidationMessage={siteValidationMessage}/>
            <Row>
                <Col sm={4}>
                    <Form>
                        <ul id="myUL">
                            <TreeCheckbox data={site} onCheckAllCheckbox={onCheckAllCheckbox}
                                          toggleOpen={toggleOpen}
                                          toggleExpand={toggleExpand} hideSelectAll={true}/>
                        </ul>
                    </Form>
                </Col>
                <Col sm={8}>
                    <div className="mb-sm-1 text-end">
                        <Link to="/plant"><Button className="mx-sm-1 buttonCss">Plant Master Data</Button></Link>
                        <Link to="/plantconfig"><Button className="mx-sm-1 buttonCss">Form Attributes</Button></Link>
                        <Link to="/site"><Button className="mx-sm-1 buttonCss">Facility Master Data</Button></Link>
                    </div>
                    <MultiSiteForm submitHandler={submitHandler} handleChange={handleChange} cancelHandler={cancelHandler}
                                   dvpList={dvpList} rdoList={rdoList} gmList={gmList}
                                   plantId={plantId} houseCode={houseCode}
                                   formValue={formValue} response={response} error={error}
                                   loading={loading} showMessage={showMessage} disableSubmit={disableSubmit}
                                   samList={samList}/></Col>
            </Row>
        </Container>
    )
};

export default MultiSite;