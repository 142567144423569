import React from 'react';
import MultiSelect from "../../commom/MultiSelect";
import Button from "react-bootstrap/Button";
import Message from "../../commom/Message";

const ReportExportModal = (props) => {
    return (
        <form>
            <div className="form-group row mb-sm-1">
                <label className="col-form-label col-sm-3" htmlFor="plant"><span className="text-danger starCss">*</span>Plant</label>
                <div className="col" ref={props.innerRef}>
                    <input type="text"
                           className={`form-control ${!props.inputValidation.plantCount ? "errorDiv" : ""}`}
                           onClick={props.handleClick} readOnly={true}
                           value={`${props.formValue.plantCount} selected`} name="plant"/>
                    {
                        props.formValue.showPlant &&
                        <div className="selectDiv text-start px-sm-1">
                            <MultiSelect show={props.formValue.showPlant} data={props.plantList}
                                         handleCheckboxChange={props.handleCheckboxChange}
                                         allSelected={props.formValue.selectAllPlant} label="plant"
                                         handleSearch={props.handleSearch}/>
                        </div>
                    }
                </div>
            </div>
            <div className="form-group row">
                <label className="col-form-label col-sm-3" htmlFor="site">Site</label>
                <div className="col" ref={props.innerRefSite}>
                    <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                           value={`${props.formValue.siteCount} selected`} name="site"/>
                    {
                        props.formValue.showSite &&
                        <div className="selectDiv text-start px-sm-1">
                            <MultiSelect show={props.formValue.showSite} data={props.siteList}
                                         handleCheckboxChange={props.handleCheckboxChange}
                                         allSelected={props.formValue.selectAllSite} label="site"
                                         handleSearch={props.handleSearch}/>
                        </div>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button type="button" className="formButton mx-sm-1 buttonCss" onClick={props.submitHandler} disabled={props.disableExport}>Show Table</Button>
                <Button type="button" className="formButton buttonCss" onClick={props.exportReportFromTable} disabled={props.disableExport}>Export</Button>
            </div>
        </form>
    )
};

export default ReportExportModal;