import React from "react";
import {
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    YAxis,
    Line,
    Legend,
    LineChart,
    ReferenceLine
} from 'recharts';
import {getDateByMonth} from "../../commom/DateFormat";

const renderColorfulLegendText = (value, entry) => {
    const {color, payload} = entry;

    if (value && value.indexOf('-') !== -1) {
        value = getDateByMonth(value);
    }

    return (
        <span style={{color}}>{value} : {payload.value}</span>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const CustomizedDot = (props) => {
    const {cx, cy, value, payload} = props;
    let referenceValue = payload.referenceValue;

    if (value > referenceValue) {
        return (
            <svg x={cx - 14} y={cy - 14} width={20} height={20} fill="green" viewBox="0 0 500 500">
                <path
                    d="M373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088z"/>
            </svg>
        );
    } else if(value === referenceValue) {
        return (
            <svg x={cx - 14} y={cy - 14} width={20} height={20} fill="yellow" viewBox="0 0 500 500">
                <path
                    d="M373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088z"/>
            </svg>
        );
    }

    return (
        <svg x={cx - 14} y={cy - 14} width={20} height={20} fill="red" viewBox="0 0 500 500">
            <path
                d="M373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088z"/>
        </svg>
    );
};

const AreaChartD = (props) => {
    return (
            <ResponsiveContainer width="99%" height={100}>
                <LineChart data={props.data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <XAxis dataKey="date" stroke="black"/>
                    <YAxis/>
                    <Tooltip/>
                    <ReferenceLine y={props.referenceValue} label={props.referenceValue} stroke="black"/>
                    <Line type="monotone" dataKey={props.label} stroke="grey" dot={<CustomizedDot />}/>
                </LineChart>
            </ResponsiveContainer>
    );
};

export default AreaChartD