import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlantList from "./PlantList";
import PlantForm from "./PlantForm";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {setPlant, updatePlant} from "../../reducer/PlantReducer";
import './Plant.css';
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {getUserAccessToUrl, trimObjectValues} from "../../commom/CommonFunction";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "../../commom/ConfirmationModal";

const Plant = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [isReadonly, setIsReadonly] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: "",
        houseCode: "",
        region: "",
        division: "",
        hasKPI: false,
        hasPulse: false,
        hasPlantQA: false,
        hasLinenMaster: false,
        hasCLII: false,
        president: "",
        presidentEmail: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        mondayFrom: "",
        mondayTo: "",
        tuesdayFrom: "",
        tuesdayTo: "",
        wednesdayFrom: "",
        wednesdayTo: "",
        thrusdayFrom: "",
        thrusdayTo: "",
        fridayFrom: "",
        fridayTo: "",
        saturdayFrom: "",
        saturdayTo: "",
        sundayFrom: "",
        sundayTo: "",
        hasMasterData: false,
        isActive: false,
    });
    const [inputValidation, setInputValidation] = useState({
        _id: true,
        houseCode: true
    });
    const fieldValidation = {
        _id: "string",
        houseCode: "number"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);

    useEffect(() => {
        dispatch(showDateInHeader(false));
        dispatch(showMasterDateOption(false));

        getUserDetailWithAllPlant().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(setPlant(response.plants));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
        })
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithAllPlant = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithAllPlant',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        formInputValueSetup(true, value);
        setIsReadonly(true);
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setIsReadonly(false);
    };

    const submitHandler = async () => {
        setShowConfirmationBox(false);
        let newData = trimObjectValues(formValue);

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdatePlant',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(updatePlant(response.data));
            setButtonTitle("Update");
            setIsReadonly(true);
        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowConfirmationBox(false);
        setDisableSubmit(false);
    };

    const checkPlantExist = async () => {
        return await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantByTitle',
            requestConfig: {
                data: {
                    "houseCode": formValue.houseCode
                }
            }
        });
    };

    const handleOpen = () => {
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        checkPlantExist().then((fetchedValue) => {
            let response = fetchedValue.data;
            console.log("ss")
            if(response) {
                if (!formValue.isActive && response.isActive === formValue.isActive) {
                    submitHandler();
                } else if (formValue.isActive) {
                    submitHandler();
                } else {
                    setShowConfirmationBox(true);
                }
            } else {
                submitHandler();
            }
        });
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {showConfirmationBox && <ConfirmationModal handleClose={handleClose} showModal={showConfirmationBox}
                                                       submitForm={submitHandler}/>}
            <Row>
                <Col sm={4}><PlantList selectedRow={selectedRow}/></Col>
                <Col sm={8}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                    <PlantForm handleOpen={handleOpen} handleChange={handleChange} cancelHandler={cancelHandler}
                               buttonTitle={buttonTitle} isReadonly={isReadonly}
                               formValue={formValue} formValidation={formValidation} inputValidation={inputValidation}
                               response={response} error={error} loading={loading} showMessage={showMessage}
                               disableSubmit={disableSubmit}/></Col>
            </Row>
        </Container>
    )
};

export default Plant;