import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import './SurveyGroup.css';
import SurveyGroupForm from "./SurveyGroupForm";
import SurveyGroupList from "./SurveyGroupList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {setSurveyGroupData, updateSurveyGroup} from "../../reducer/SurveyGroupReducer";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import Form from "react-bootstrap/Form";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {getUserAccessToUrl, trimObjectValues} from "../../commom/CommonFunction";
import {logIn, logOut} from "../../reducer/LoginReducer";

const SurveyGroup = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [formValue, setFormValue] = useState({
        _id: "",
        title: "",
        surveys: "",
        active: false

    });
    const [inputValidation, setInputValidation] = useState({
        title: true,
    });
    const fieldValidation = {
        title: "string",
    };
    const [formValidation, setFormValidation] = useState(true);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [surveyList, setSurveyList] = useState();
    const [selectAllSurvey, setSelectAllSurvey] = useState(false);
    const [surveyCount, setSurveyCount] = useState(0);
    const [showSurvey, setShowSurvey] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));
        getUserDetailWithSurvey().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setSurveyList(updateMultiSelectOption(response.surveys));
        });
        getSurveyGroupList().then((fetchedValue) => {
            dispatch(setSurveyGroupData(fetchedValue));
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getSurveyGroupList = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getSurveyGroupList'
        });
        return await response.data;
    };

    const getUserDetailWithSurvey = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSurvey',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const updateMultiSelectOption = (fetchedValue) => {
        return fetchedValue.map((obj) => {
            return {
                label: obj.title,
                value: false
            };
        });
    };

    const handleCheckboxChange = (event) => {
        setDisableSubmit(false);
        let value = event.currentTarget.name;
        let checked = event.currentTarget.checked;
        let allChecked = false;
        let count = 0;
        let selectData = [];
        let arrayList;
        arrayList = surveyList;

        if (value === "selectAll") {
            setSelectAllSurvey(checked);
            allChecked = true;
        }

        const newData = arrayList.map(obj => {
            let data = Object.assign({}, obj);

            if (allChecked) {
                data.value = checked;

                if (checked) {
                    count = arrayList.length;
                    selectData.push(data);
                } else {
                    count = 0;
                }
                return data
            } else {
                if (data.label === value) {
                    data.value = checked;
                }

                if (data.value) {
                    count += 1;
                    selectData.push(data);
                }

                return data;
            }
        });

        setSurveyList(newData);
        setSurveyCount(count);
        setFormValue({
            ...formValue,
            surveys: selectData
        })
    };

    const selectedValue = (selectedData) => {
        let count = 0;
        let selectData = [];
        let arrayList;
        let flag = true;
        arrayList = surveyList;

        const newData = arrayList.map(obj => {
            let data = Object.assign({}, obj);

            let matchedValue = selectedData.find(dataObj => {
                let subData = Object.assign({}, dataObj);

                if (subData.label === obj.label) {
                    return subData
                }
            });

            if (matchedValue) {
                data.value = true;
                selectData.push(data);
                count += 1;
                return data;
            } else {
                data.value = false;
                flag = false;
            }

            return data;
        });

        setSurveyList(newData);
        setSurveyCount(count);
        setFormValue({
            ...formValue,
            surveys: selectData
        });
        if (flag) {
            setSelectAllSurvey(true);
        }
    };

    const resetValue = () => {
        let count = 0;
        let selectData = [];
        let arrayList;
        arrayList = surveyList;

        const newData = arrayList.map(obj => {
            let data = Object.assign({}, obj);
            data.value = false;
            return data;
        });

        setSurveyList(newData);
        setSurveyCount(count);
        setFormValue({
            ...formValue,
            surveys: selectData
        });
        setSelectAllSurvey(false);
    };

    const handleClick = () => {
        setShowSurvey(!showSurvey);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        selectedValue(value.surveys);
        formInputValueSetup(true, value);

        async function getUsersByFieldName() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getUsersByFieldName',
                requestConfig: {
                    data: {"fieldName": "surveysGroup", "fieldValue": value.title}
                }
            });

            return await response;
        }

        getUsersByFieldName().then((response) => {
            if(response.data) {
                setReadOnly(true);
            } else {
                setReadOnly(false);
            }
        });
    };

    const getSurveyData = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getSurvey'
        });
        return await response.data;
    };

    const cancelHandler = () => {
        setShowMessage(false);
        setDisableSubmit(false);
        setButtonTitle("ADD");
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        resetValue();
        formInputValueSetup(false);
        setReadOnly(false);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if(!checkValidate) {
            return false;
        }

        let newData = trimObjectValues({...formValue});

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSurveyGroup',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(updateSurveyGroup(response.data));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
        }
        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={4}><SurveyGroupList selectedRow={selectedRow}/></Col>
                <Col sm={4}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                             <SurveyGroupForm submitHandler={submitHandler} cancelHandler={cancelHandler}
                                             inputChangeHandler={inputChangeHandler} buttonTitle={buttonTitle}
                                             handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                             selectAllSurvey={selectAllSurvey} surveyCount={surveyCount}
                                             showSurvey={showSurvey} surveyList={surveyList} formValue={formValue}
                                             formValidation={formValidation} inputValidation={inputValidation}
                                             response={response} error={error} loading={loading}
                                             showMessage={showMessage} readOnly={readOnly} disableSubmit={disableSubmit}/></Col>
                <Col sm={4}>
                    <Form>
                        <ul id="ulCheckbox">
                            <label className="d-flex">
                                <input
                                    name="selectAll"
                                    id="selectAll"
                                    type="checkbox"
                                    checked={selectAllSurvey}
                                    onChange={handleCheckboxChange}
                                    data-name="surveys"
                                    className="fw-bold mx-sm-1"
                                />
                                <b htmlFor="selectAll">{("Select All").toUpperCase()}</b>
                            </label>
                            {surveyList && surveyList.length
                            && surveyList.map((obj, index) => {
                            return <label key={index + obj} htmlFor={obj.label} className="d-flex align-items-center">
                            <input
                            data-name={obj.label}
                            name={obj.label}
                            id={obj.label}
                            type="checkbox"
                            checked={obj.value}
                            className="mx-sm-1"
                            onChange={handleCheckboxChange}
                            />
                            {obj.label}
                            </label>

                        })
                            }
                        </ul>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
};

export default SurveyGroup;