import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {faMultiply} from "@fortawesome/free-solid-svg-icons/faMultiply";

const SearchInputWithIcon = ({filterFunction, placeHolder}) => {
    const [searchText, setSearchText] = useState('');

    const handleChange = (e) => {
        setSearchText(e.target.value);
        filterFunction(e.target.value);
    };

    const handleClear = () => {
        setSearchText('');
        filterFunction("");
    };

    return (
        <InputGroup className="mt-1">
            <Form.Control
                placeholder={placeHolder ? placeHolder : "Search..."}
                value={searchText}
                onChange={handleChange}
            />
            <InputGroup.Text onClick={handleClear} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={searchText ? faMultiply : faSearch}/>
            </InputGroup.Text>
        </InputGroup>
    );
};

export default SearchInputWithIcon;