import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './Survey.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import SurveyForm from "./SurveyForm";
import SurveyList from "./SurveyList";
import {setSurveyData, updateSurvey} from "../../reducer/SurveyReducer";
import {setSurveyQuestionData} from "../../reducer/SurveyQuestionReducer";
import {setSurveyComputedData} from "../../reducer/SurveyComputedReducer";
import SurveyQuestion from "./SurveyQuestion";
import SurveyCompute from "./SurveyComputed";
import {selectedSurvey} from "../../reducer/SurveySelectionReducer";
import {Button} from "react-bootstrap";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {
    getArrayValue, getExistingSurveyGroup, getSelectedPlantConfigArray, getUserAccessToUrl, trimObjectValues,
    updateDropdownSelectionData,
    updateMultiSelectOptionForDropdown
} from "../../commom/CommonFunction";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";
import ConfirmationModal from "../../commom/ConfirmationModal";
import {updateList} from "../../reducer/FrequencyReducer";

const Survey = () => {
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const userState = useSelector((state) => state.userState.user._id);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [survey, setSurvey] = useState();
    const [isEdit, setEdit] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: "",
        title: "",
        frequency: "",
        category: "",
        questions: [],
        computedFields: [],
        siteRequire: false,
        active: false,
        sendEmail: false,
        showOnReport: false
    });
    const [inputValidation, setInputValidation] = useState({
        title: true,
        frequency: true,
        category: true,
    });
    const fieldValidation = {
        title: "string",
        frequency: "select",
        category: "select",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [frequencyList, setFrequencyList] = useState();
    const [categoryList, setCategoryList] = useState();
    const [isReadOnly, setIsReadOnly] = useState();
    const [show, setShow] = useState(false);
    const [showQuestion, setShowQuestion] = useState(true);
    const [showComputation, setShowComputation] = useState(true);
    const [surveyGroupList, setSurveyGroupList] = useState();
    const [surveyGroupData, setSurveyGroupData] = useState();
    const [frequencyData, setFrequencyData] = useState();
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        getSurveyGroupList().then((fetchedValue) => {
            setSurveyGroupList(fetchedValue);
            setSurveyGroupData(updateMultiSelectOptionForDropdown(fetchedValue, "title"));
        });

        getUserDetailWithAllSurveyAndMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            setFrequencyData(response.masterData);
            setFrequencyList(getSelectedPlantConfigArray("Frequency", "type",response.masterData, "value"));
            setCategoryList(getSelectedPlantConfigArray("Category", "type",response.masterData, "value"));
            dispatch(setUserRole(response.securityRoles));
            setSurvey(response.surveys);
            dispatch(setSurveyData(response.surveys));
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithAllSurveyAndMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithAllSurveyAndMasterData',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getSurveyGroupList = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getSurveyGroupList'
        });
        return await response.data;
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const handleCheckboxChange = (event) => {
        setDisableSubmit(false);
        let value = event.currentTarget.attributes['data-label'].value;
        let checked = event.currentTarget.checked;

        const newData = surveyGroupData.map(obj => {
            let data = Object.assign({}, obj);

            if (data.label === value) {
                data.value = checked;
            }

            return data;
        });

        setSurveyGroupData(newData);
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setEdit(true);
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(true, value);

        dispatch(selectedSurvey(value));
        setIsReadOnly(true);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        if (value.questions) {
            dispatch(setSurveyQuestionData(value.questions));
        }
        if (value.computedFields) {
            dispatch(setSurveyComputedData(value.computedFields));
        }
        if(value.surveyGroup && value.surveyGroup.length > 0) {
            let dataForSurveyGroupList = updateDropdownSelectionData(value.surveyGroup, surveyGroupData, "label");
            setSurveyGroupData(dataForSurveyGroupList);
        } else {
            let existingSurveyGroup = getExistingSurveyGroup(surveyGroupList, value.title);
            let dataForSurveyGroupList = updateDropdownSelectionData(existingSurveyGroup, surveyGroupData, "label");
            setSurveyGroupData(dataForSurveyGroupList);
        }
    };

    const cancelHandler = () => {
        setEdit(false);
        setDisableSubmit(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShow(false);
        setIsReadOnly(false);
        setShowMessage(false);
        setButtonTitle("Add");
        let dataForSurveyGroupList = updateDropdownSelectionData([], surveyGroupData, "label");
        setSurveyGroupData(dataForSurveyGroupList);
    };

    const showQuestionScreen = () => {
        setShowQuestion(true);
        setShowComputation(false);
        setShow(true);
    };

    const showComputationScreen = () => {
        setShowComputation(true);
        setShowQuestion(false);
        setShow(true);
    };

    const showSurveyScreen = () => {
        setShow(false);
        setShowComputation(false);
        setShowQuestion(false);
    };

    const submitSurveyGroupHandler = async (data) => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSurveyGroup',
            requestConfig: {
                data: data
            }
        });

        return response.data;
    };

    const checkValueOfReportOption = async () => {
        let newData = {
            value: formValue.title,
        };

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getMasterDataByReportTitle',
            requestConfig: {
                data: newData
            }
        });

        return response.data;
    };

    const submitHandlerForReportOption = async () => {
        let reportOptionObject = await checkValueOfReportOption();

        if(reportOptionObject && reportOptionObject.length && reportOptionObject.length > 0) {
            let newData = trimObjectValues({...reportOptionObject[0]});
            newData["isActive"] = formValue.showOnReport;
            newData["_id"] = newData._id;
            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/updateActiveValueForReport',
                requestConfig: {
                    data: newData
                }
            });

            return response;
        } else {
            let newEntry = {
                _id: "",
                email: "",
                plant: "",
                value: formValue.title + " Data",
                isActive: formValue.showOnReport,
            };

            let newData = trimObjectValues({...newEntry});
            newData["modAt"] = new Date();
            newData["modBy"] = userState;
            newData["type"] = "Report Option";
            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdateMasterData',
                requestConfig: {
                    data: newData
                }
            });

            return response;
        }
    };

    const checkDuplicateSurveyValue = async (data) => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/checkDuplicateSurveyValue',
            requestConfig: {
                data: data
            }
        });

        return response.data;
    };

    const submitHandler = async () => {
        handleClose();
        let newEntry = trimObjectValues({...formValue});

        if(newEntry.questions === "") {
            newEntry["questions"] = [];
        }

        if(newEntry.computedFields === "") {
            newEntry["computedFields"] = [];
        }
        let selectionOfSurveyGroup = getArrayValue(surveyGroupData);

        newEntry["surveyGroup"] = selectionOfSurveyGroup;
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSurvey',
            requestConfig: {
                data: newEntry
            }
        });

        if (response.data) {
            dispatch(updateSurvey(response.data));
            setButtonTitle("Update");
            setIsReadOnly(true);
            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }

            submitHandlerForReportOption().then((response) => {
                if (response.data) {
                    dispatch(updateList(response.data));
                }
            });

            surveyGroupList.forEach((surveyGroupObj) => {
                let obj = Object.assign({}, surveyGroupObj);
                let flag = false;
                let existingIndex = null;

                if(obj.surveys && obj.surveys.length && selectionOfSurveyGroup.indexOf(obj.title) !== -1) {
                    obj.surveys.forEach((surveyObj) => {
                        if(surveyObj.label ===  newEntry.title) {
                            flag = true;
                        }
                    });

                    if(!flag) {
                        obj.surveys.push({
                            "label": newEntry.title,
                            "value": true
                        });
                        submitSurveyGroupHandler(obj).then(res => res);
                    }
                } else if(obj.surveys && obj.surveys.length && surveyGroupData.indexOf(obj.title) === -1) {
                    obj.surveys.forEach((surveyObj, surveyObjIndex) => {
                        if(surveyObj.label ===  newEntry.title) {
                            flag = true;
                            existingIndex = surveyObjIndex;
                        }
                    });

                    if(flag) {
                        obj.surveys.splice(existingIndex, 1);
                        submitSurveyGroupHandler(obj).then(res => res);
                    }
                }


            })
        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowConfirmationBox(false);
        setDisableSubmit(false);
    };

    const handleOpen = () => {
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);
        if (!checkValidate) {
            return false;
        }

        if(formValue._id === "") {
            submitHandler();
        } else {
            if(formValue.active) {
                submitHandler();
            } else {
                checkDuplicateSurveyValue({
                    "title": formValue.title,
                    "key": "title",
                    id: formValue._id
                }).then((response) => {
                    if(response) {
                        if (response.active === formValue.active) {
                            submitHandler();
                        } else {
                            setShowConfirmationBox(true);
                        }
                    } else {
                        submitHandler();
                    }
                });
            }
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {showConfirmationBox && <ConfirmationModal handleClose={handleClose} showModal={showConfirmationBox}
                                             submitForm={submitHandler}/>}
            {!show && <Row>
                <Col sm={6}><SurveyList selectedRow={selectedRow} screen={screen}/></Col>
                <Col sm={6}><div>
                    {isEdit &&
                    <Button onClick={showQuestionScreen}
                            className={`formButton m-sm-1 btn ${!showQuestion ? "buttonCss" : "buttonDisableCss"}`}>Show
                        Question</Button>

                    }
                    {isEdit &&
                    <Button onClick={showComputationScreen}
                            className={`formButton m-sm-1 btn ${!showComputation ?  "buttonCss" : "buttonDisableCss"}`}>Show
                        Computation</Button>
                    }
                    {show &&
                    < Button onClick={showSurveyScreen}
                             className={`formButton m-sm-1 btn ${show ?  "buttonCss" : "buttonDisableCss"}`}>Back To
                        Survey</Button>
                    }
                </div>

                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                <SurveyForm handleOpen={handleOpen} cancelHandler={cancelHandler}
                                        buttonTitle={buttonTitle} handleChange={handleChange}
                                        frequencyList={frequencyList} categoryList={categoryList}
                                        isReadOnly={isReadOnly} formValue={formValue} formValidation={formValidation}
                                        inputValidation={inputValidation} response={response} error={error}
                                        loading={loading} showMessage={showMessage} disableSubmit={disableSubmit}
                            surveyGroupData={surveyGroupData} handleCheckboxChange={handleCheckboxChange}/>
                </Col>
            </Row>}
            {isEdit && (show) &&
            <Button onClick={showQuestionScreen}
                    className={`formButton m-sm-1 btn ${!showQuestion ? "buttonCss" : "buttonDisableCss"}`}>Show
                Question</Button>

            }
            {isEdit && (show) &&
            <Button onClick={showComputationScreen}
                    className={`formButton m-sm-1 btn ${!showComputation ? "buttonCss" : "buttonDisableCss"}`}>Show
                Computation</Button>
            }
            {show &&
            < Button onClick={showSurveyScreen}
                     className={`formButton m-sm-1 btn ${show ? "buttonCss" : "buttonDisableCss"}`}>Back To
                Survey</Button>
            }
            {show && showQuestion && <Row><SurveyQuestion/></Row>}
            {show && showComputation && <Row><SurveyCompute/></Row>}

        </Container>
    )
};

export default Survey;