import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './QualityAuditDashboard.css';
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import AreaChartD from "./AreaChartD";
import QualityDashboardDetailTotal from "./QualityDashboardDetailTotal";
import {updateItemCategoryData} from "../../reducer/ItemCategoryReducer";
import {setPlantQualityDashboardData} from "../../reducer/PlantQualityDashboardDataReducer";
import {Link} from "react-router-dom";

const QualityDashboardDetail = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [plantQualityAudit, setPlantQualityAudit] = useState("");
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const plantData = useSelector((state) => state.plantNameReducer);

    useEffect(() => {
        getPlantQuality();
    }, []);

    const getPlantQuality = () => {
        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantQualityAuditByTitle',
                requestConfig: {
                    data : {"houseCode": plantData.houseCode}
                }
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            let newArray = [];
            fetchedValue.forEach(obj => {
                let objData = Object.assign({}, obj);
                newArray.push(objData)
            });
            dispatch(setPlantQualityDashboardData(fetchedValue));

            let data = plantSpecificRange(newArray);
            setPlantQualityAudit(data);
        });
    };

    const getDate = (dateObj) => {
        let date = new Date(dateObj);

        let month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
        let day = ("0" + date.getDate()).slice(-2);
        let year = date.getFullYear();

        return (month + "/" + day + "/" + year);
    };

    const dateRange = (startDate, endDate, steps = 1) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            let newDate = getDate(new Date(currentDate));
            dateArray.push(newDate);
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }

        return dateArray;
    };

    const finder = (cmp, arr, attr) => {
        let val = arr[0][attr];
        for (let i = 1; i < arr.length; i++) {
            val = cmp(val, arr[i][attr])
        }
        return parseInt(val);
    };

    const searchByDate = (nameKey, myArray, key) => {
        for (let i = 0; i < myArray.length; i++) {
            let date = getDate(nameKey);
            if (date === myArray[i][key]) {
                return myArray[i];
            }
        }
    };

    const plantDateRange = (fetchedValue) => {
        let plantArr = []; // add unique housecode present in the fetched data
        let plantArray = [];
        let dateArray = [];
        let newArray = [];
        let dateRangeArray = dateRange(startDate, endDate);
        // create date range for the selected date
        dateRangeArray.map((dateObject, index) => {
            let data = {
                "date": getDate(dateObject),
                "inspected": 0,
                "imperfections": 0,
                "data": index
            };
            dateArray.push(data)
        });

        // Add the missing date with objects on "chart" key for each plant to plot chart
        fetchedValue.map((plantObject) => {
            let plant = {...plantObject};
            let chartObject = [...plant["chart"]];
            let chartArray = [];
            let maxInspected = finder(Math.max, chartObject, "inspected");
            let minInspected = finder(Math.min, chartObject, "inspected");
            let maxImperfections = finder(Math.max, chartObject, "imperfections");
            let minImperfections = finder(Math.min, chartObject, "imperfections");

            // Add objects on "chart" key for each plant for selected date range
            dateArray.map((dateObject) => {
                let dateObj = {...dateObject};
                let resultObject = search(dateObj.date, chartObject, "date");
                let point = {
                    "date": dateObj["date"],
                    "data": dateObj["index"]
                };

                if (resultObject !== undefined) {
                    point["inspected"] = ((resultObject.inspected + minInspected) / (maxInspected + minInspected)) * minInspected;
                    point["imperfections"] = ((resultObject.imperfections + minImperfections) / (maxImperfections + minImperfections)) * minImperfections;
                    point["inspectedValue"] = resultObject.inspected;
                    point["imperfectionsValue"] = resultObject.imperfections;
                }

                chartArray.push(point);
            });

            // Update the date range array of object to each plant
            let endDateObject = search(getDate(endDate), chartObject, "date");
            plant.chart = chartArray;
            plant["imperfectionsAvg"] = Math.ceil((plant.imperfections / plant.inspected) * 100) + "%";
            plant["inspectedAvg"] = Math.ceil(plant.inspected / chartObject.length);

            if (endDateObject) {
                plant["endDateimperfections"] = Math.ceil((endDateObject.imperfections / endDateObject.inspected) * 100) + "%";
                plant["endDateinspected"] = Math.ceil(endDateObject.inspected);
            }
            plantArray.push(plant);
        });

        return plantArray;
    };

    const search = (nameKey, myArray, key) => {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i][key] === nameKey) {
                return myArray[i];
            }
        }
    };

    const plantSpecificRange = (fetchedValue) => {
        let itemCatArr = []; // add unique housecode present in the fetched data
        let plantArray = []; // create new array for table insertion
        let newArray = [];

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            newArray.push(objData)
        });
        // fetched data array
        fetchedValue.forEach((plantObject) => {
            // Add unique object in plantArray with new key as "chart"
            if (itemCatArr.indexOf(plantObject.itemCategory) < 0) {
                itemCatArr.push(plantObject.itemCategory);
                let data = plantObject;
                let chart = {
                    "date": getDate(plantObject.date),
                    "inspected": parseInt(plantObject.inspected),
                    "imperfections": parseInt(plantObject.imperfections)
                };
                data["chart"] = [];
                data["chart"].push(chart);
                data.inspected = parseInt(plantObject.inspected);
                data.imperfections = parseInt(plantObject.imperfections);
                plantArray.push(data);
            } else {
                const resultObject = search(plantObject.itemCategory, plantArray, "itemCategory");
                let date = getDate(plantObject.date);
                const chartObject = search(date, resultObject["chart"], "date");

                // Duplicate (plant and  date) object should sum value
                if (chartObject) {
                    chartObject.inspected = chartObject.inspected + parseInt(plantObject.inspected);
                    chartObject.imperfections = chartObject.imperfections + parseInt(plantObject.imperfections);
                } else {
                    // Duplicate plant and  unique date object should append in "chart" key

                    let chart = {
                        "date": getDate(plantObject.date),
                        "inspected": parseInt(plantObject.inspected),
                        "imperfections": parseInt(plantObject.imperfections)
                    };
                    resultObject["chart"].push(chart);
                }

                // Duplicate plant object should sum value
                resultObject.inspected = parseInt(plantObject.inspected) + resultObject.inspected;
                resultObject.imperfections = parseInt(plantObject.imperfections) + resultObject.imperfections;
            }
        });

        let totalArray = {
            "imperfectionsAvg" : 0,
            "inspectedAvg" : 0,
            "inspected" : 0,
            "imperfections" : 0,
            "itemCategory" : "Total",
            "imperfectionType" : "Total"
        };
        totalArray["chart"] = [];

        let dateArr = []; // add unique housecode present in the fetched data

        // fetched data array
        newArray.forEach((plantData) => {
            let plantObject = {...plantData};
            let date = getDate(plantObject.date);
            // Add unique object in plantArray with new key as "chart"
            if (dateArr.indexOf(date) < 0) {
                dateArr.push(date);
                let chart = {
                    "date": getDate(plantObject.date),
                    "inspected": parseInt(plantObject.inspected),
                    "imperfections": parseInt(plantObject.imperfections)
                };
                totalArray["chart"].push(chart);
            } else {
                const chartObject = search(date, totalArray["chart"], "date");

                // Duplicate (plant and  date) object should sum value
                if (chartObject) {
                    chartObject.inspected = chartObject.inspected + parseInt(plantObject.inspected);
                    chartObject.imperfections = chartObject.imperfections + parseInt(plantObject.imperfections);
                }
            }

            totalArray.inspected = parseInt(plantObject.inspected) + totalArray.inspected;
            totalArray.imperfections = parseInt(plantObject.imperfections) + totalArray.imperfections;
        });

        totalArray["imperfectionsAvg"] = Math.ceil((totalArray.imperfections / totalArray.inspected) * 100) + "%";
        totalArray["inspectedAvg"] = Math.ceil(totalArray.inspected / totalArray.chart.length);
        plantArray.unshift(totalArray);
        let plantData = plantDateRange(plantArray);

        return plantData;
    };

    const viewClicked = (data) => {
        dispatch(updateItemCategoryData({"itemCategory": data.itemCategory, "imperfectionType": data.imperfectionType}));
    };

    return (
        <Container fluid="md">
            <Link to="/qualityTodayDetail">TODAY</Link>
            <Row>
                <Col sm={6}>
                    <table className="fixedHeaderTable">
                        <thead>
                        <tr>
                            <th className="shrink">ITEM</th>
                            <th># Inspected</th>
                            <th>Imperfection %</th>
                            <th className="shrink">Detail</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            plantQualityAudit &&
                            (plantQualityAudit).map((value, index) => {
                                return (
                                    <tr key={value + index} className="gmneha">
                                        <td className="shrink px-sm-1">{value.itemCategory}</td>
                                        <td className="chartRow">
                                            <AreaChartD data={value.chart} label="inspected"
                                                        showLabel="inspectedValue" avg={value.inspectedAvg}
                                                        total={value.inspected} lineColor="#008000"/>
                                        </td>
                                        <td className="chartRow">
                                            <AreaChartD data={value.chart} label="imperfections"
                                                        showLabel="imperfectionsValue" avg={value.imperfectionsAvg}
                                                        total={value.imperfections} lineColor="#ff0000"/>
                                        </td>
                                        <td className="text-center" onClick={() => viewClicked(value)}>View</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </Col>
                <Col sm={6}>
                    <QualityDashboardDetailTotal />
                </Col>
            </Row>
        </Container>
    )
};

export default QualityDashboardDetail;