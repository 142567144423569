import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import './Idn.css';
import IdnForm from "./IdnForm";
import IdnList from "./IdnList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {setIdnData, updateIdn} from "../../reducer/IdnReducer";
import TreeCheckbox from "../../commom/CustomCheckbox";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import {setAllSite, updateAllSite} from "../../reducer/SiteAllReducer";
import {setSite, updateSite} from "../../reducer/SiteReducer";
import {getUserAccessToUrl, sortArrayData, specificArrayData, trimObjectValues} from "../../commom/CommonFunction";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {setPlant} from "../../reducer/PlantReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare} from "@fortawesome/free-solid-svg-icons";

const Idn = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [idn, setIdn] = useState();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [readOnly, setReadonly] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: "",
        idnAffiliation: "",
        active: false,
        isAdmin: false,
        displayIdn: true
    });
    const [inputValidation, setInputValidation] = useState({
        idnAffiliation: true,
    });
    const fieldValidation = {
        idnAffiliation: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [addIdnToSite, setAddIdnToSite] = useState([]);
    const [showIdnMessage, setShowIdnMessage] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [messageData, setMessageData] = useState("");
    const [plantsData, setPlantsData] = useState();
    const [unAssignedPlant, setUnAssignedPlant] = useState();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        getUserDetail().then(response => {
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            setPlantsData(response.plants);
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
            setIdn(updateData(response.sites, response.plants));
        });

        getIdnData().then((fetchedValue) => {
            dispatch(setIdnData(fetchedValue));
        });

    }, []);

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetail = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetail',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getIdnData = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getIdn'
        });
        return await response.data;
    };

    const updateData = (fetchedValue, plantList) => {
        let plants = {};
        let plantIds = [];
        let plantData = specificArrayData(plantList, "_id");

        fetchedValue.forEach((value) => {
            if (value.plantHc && (plantIds.indexOf(value.plantHc) === -1) && (plantData.indexOf(value.plant)) !== -1) {
                plants[value.plantHc] = {
                    id: value.plantHc,
                    name: value.plant,
                    isActive: false,
                    open: false,
                    subModule: [
                        {
                            id: value.siteId,
                            name: value.site,
                            isActive: false
                        }
                    ]
                };

                plantIds.push(value.plantHc)
            } else if (value.plantHc && plantIds.indexOf(value.plantHc) !== -1) {
                plants[value.plantHc].subModule.push({
                    id: value.siteId,
                    name: value.site,
                    isActive: false
                })
            }
        });
        plantList.forEach((value) => {
            if (plantIds.indexOf(value.houseCode) === -1 && value.isActive) {
                plants[value.houseCode] = {
                    id: value.houseCode,
                    name: value._id,
                    isActive: false,
                    open: false,
                    subModule: []
                };
            }
        });
        let plantIdnData = Object.keys(plants).map(key => {
            return plants[key];
        });

        let unAssignPlantData = [];
        console.log(plantIdnData);
        console.log(plantList);
        plantList.forEach((obj) => {
            console.log(obj + "----");
            if (plantIds.indexOf(obj.houseCode) === -1 && obj.isActive) {
                unAssignPlantData.push(obj);
            }
        });

        setUnAssignedPlant(unAssignPlantData);

        return sortArrayData(plantIdnData, "name");
    };

    const toggleOpen = (event) => {
        let toggleValue = event.currentTarget.attributes['name'].value;
        const newData = [];

        idn.forEach(obj => {
            let roleValue = Object.assign({}, obj);

            if (roleValue.id === toggleValue) {
                roleValue.open = !roleValue.open;
            }

            newData.push(roleValue);
        });

        setIdn(newData);
    };

    const toggleExpand = (selectValue) => {
        let flag = selectValue;
        const newData = [];

        idn.forEach(obj => {
            let roleValue = Object.assign({}, obj);
            roleValue.open = flag;
            newData.push(roleValue);
        });

        setIdn(newData);
    };

    const onCheckAllCheckbox = (name, flag = false) => {
        setDisableSubmit(false);
        let checkBoxName = name.split(',');
        let parent = (checkBoxName.length === 1);
        let child = (checkBoxName.length === 2);
        if (formValue.idnAffiliation === "SuperAdmin IDN" && !flag) {
            return false;
        }

        const newData = idn.map(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});

            if (roleValue.id === checkBoxName[0]) {
                if (parent) {
                    roleValue.isActive = !roleValue.isActive;
                }
                let isParentActive = false;
                (obj.subModule).forEach((subVal) => {
                    let subModule;

                    subModule = Object.assign({}, subVal);

                    if (parent) {
                        subModule.isActive = roleValue.isActive;
                    } else if (subModule.id === checkBoxName[1]) {
                        subModule.isActive = !subModule.isActive;
                    }

                    if (subModule.isActive === true) {
                        isParentActive = true;
                    }

                    if (child && !isParentActive) {
                        roleValue.isActive = false;
                    } else if (child && isParentActive) {
                        roleValue.isActive = true;
                    }

                    roleValue.subModule.push(subModule);
                });

                return roleValue
            } else {
                return obj;
            }
        });
        setIdn(newData)
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        if (formValue.idnAffiliation === "SuperAdmin IDN") {
            return false;
        }
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        formInputValueSetup(true, value);


        async function getSelectedIdn() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getIdnPlantMappingByTitle',
                requestConfig: {
                    idn: {"idnAffiliation": value.idnAffiliation}
                }
            });

            return await response;
        }

        getSelectedIdn().then((response) => {
            if (response.flag) {
                setReadonly(true);
            } else {
                setReadonly(false);
            }
            let fetchedValue = response.data;
            let data;
            if (fetchedValue.idnAffiliation === "SuperAdmin IDN") {
                data = getInactiveDataForSuperAdmin(fetchedValue.plants);
                setIdn(data.plants);
                if (data.flag) {
                    saveSuperIDN(fetchedValue, data.plants).then();
                }
            } else {
                data = updateListDataValue(fetchedValue.plants);
                setIdn(data);
            }
        });

    };

    const cancelHandler = () => {
        setReadonly(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setIdn(updateDataValueToInactive(false));
        setAddIdnToSite([]);
    };

    const getInactiveDataForSuperAdmin = (fetchedValue) => {
        let inActivePlant = [];
        let inActiveSites = [];
        let plants = [];
        let plantId = [];
        let siteData = [];

        if (idn && idn.length) {
            idn.forEach((val, index) => {
                let plant = Object.assign({}, val, {subModule: []});

                fetchedValue.forEach((obj) => {
                    if (obj.id === plant.id && obj.isActive) {
                        plant.isActive = true;

                        plants.push(plant);
                        (val.subModule).forEach((subValue) => {
                            let site = Object.assign({}, subValue);
                            site.isActive = false;

                            (obj.subModule).forEach((subVal) => {
                                if (subVal.id === site.id) {
                                    siteData.push(site.id);
                                    site.isActive = true;
                                }
                            });

                            if (!site.isActive) {
                                inActiveSites.push(site.id);
                                site.isActive = true;
                            }
                            plants[index].subModule.push(site);
                        });

                        plantId.push(plant.id);
                    }
                });

                if (plantId.indexOf(plant.id) === -1) {
                    plant.isActive = true;
                    plants.push(plant);
                    inActivePlant.push(plant.id);
                    (val.subModule).forEach((subValue) => {
                        let site = Object.assign({}, subValue);
                        site.isActive = true;
                        plants[index].subModule.push(site);
                    });
                    plantId.push(plant.id);
                }
            });

            setAddIdnToSite(siteData);
            let flag = false;
            if (inActivePlant.length > 0 || inActiveSites.length > 0) {
                flag = true;
            }

            return {plants, flag};
        }
    };

    const updateListDataValue = (fetchedValue) => {
        let plants = [];
        let plantId = [];
        let siteData = [];

        if (idn && idn.length) {
            idn.forEach((val, index) => {
                let plant = Object.assign({}, val, {subModule: []});

                fetchedValue.forEach((obj) => {
                    if (obj.id === plant.id && obj.isActive) {
                        plant.isActive = true;

                        plants.push(plant);
                        (val.subModule).forEach((subValue) => {
                            let site = Object.assign({}, subValue);
                            site.isActive = false;

                            (obj.subModule).forEach((subVal) => {
                                if (subVal.id === site.id) {
                                    siteData.push(site.id);
                                    site.isActive = true;
                                }
                            });
                            plants[index].subModule.push(site);
                        });

                        plantId.push(plant.id);
                    }
                });

                if (plantId.indexOf(plant.id) === -1) {
                    plant.isActive = false;
                    plants.push(plant);

                    (val.subModule).forEach((subValue) => {
                        let site = Object.assign({}, subValue);
                        site.isActive = false;
                        plants[index].subModule.push(site);
                    });
                    plantId.push(plant.id);
                }
            });

            setAddIdnToSite(siteData);
            return plants;
        }
    };

    const updateDataValue = () => {
        let plants = [];
        let addSiteData = [];

        idn.map(obj => {
            let plant = Object.assign({}, obj, {subModule: []});
            if (plant.id && plant.isActive) {
                plants.push(plant);

                (obj.subModule).forEach((subValue) => {
                    let site = Object.assign({}, subValue);

                    if (site.id && site.isActive) {
                        plant.subModule.push(site);
                        addSiteData.push(site.id);
                    }
                })
            }
        });

        setAddIdnToSite(addSiteData);
        return plants;
    };

    const selectedAllCheckboxHandler = (selectValue) => {
        setIdn(updateDataValueToInactive(selectValue));
    };

    const updateDataValueToInactive = (activeValue) => {
        let plants = [];

        idn.map(obj => {
            let plant = Object.assign({}, obj, {subModule: []});
            plant.isActive = activeValue;
            plants.push(plant);

            (obj.subModule).forEach((subValue) => {
                let site = Object.assign({}, subValue);
                site.isActive = activeValue;
                plant.subModule.push(site);
            })
        });

        return plants;
    };

    const updateIdnDataToSiteValue = async (newData) => {
        let addSiteData = [];
        let removeSiteData = [];
        let addNewIdnSiteData = [];


        if (newData.plants) {
            (newData.plants).map(obj => {
                let plant = Object.assign({}, obj, {subModule: []});
                if (plant.id && plant.isActive) {
                    (obj.subModule).forEach((subValue) => {
                        let site = Object.assign({}, subValue);
                        if (site.id && site.isActive) {
                            addSiteData.push(site.id);
                        }
                    })
                }
            });
        }

        if (addIdnToSite && addIdnToSite.length > 0) {
            addIdnToSite.forEach((siteValue) => {
                let index = addSiteData.indexOf(siteValue);
                if (index === -1) {
                    removeSiteData.push(siteValue);
                }
            });


            addSiteData.forEach((siteValue) => {
                let index = addIdnToSite.indexOf(siteValue);
                if (index === -1) {
                    addNewIdnSiteData.push(siteValue);
                }
            });

            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getAllSite'
            });

            let siteData = await response.data;

            if (addNewIdnSiteData && addNewIdnSiteData.length > 0) {
                siteData.forEach((addData) => {
                    let indexToAdd = addNewIdnSiteData.indexOf(addData.siteId);
                    if (indexToAdd !== -1) {
                        let addIdnIndex = addData.idnAffiliation.indexOf(newData.idnAffiliation);

                        if (addIdnIndex === -1) {

                            let idnToData = [...addData.idnAffiliation];
                            idnToData.push(newData.idnAffiliation);
                            updateIdnValueToSite(addData.siteId, idnToData).then((updatedSite) => {
                                dispatch(updateSite(updatedSite));
                                dispatch(updateAllSite(updatedSite));
                            });
                        }
                    }
                });
            }

            if (removeSiteData && removeSiteData.length > 0) {
                siteData.forEach((removeData) => {
                    let indexToRemove = removeSiteData.indexOf(removeData.siteId);

                    if (indexToRemove !== -1) {
                        let removeIdnIndex = removeData.idnAffiliation.indexOf(newData.idnAffiliation);

                        if (removeIdnIndex !== -1) {
                            let filteredIdn = [];
                            for (let i = 0; i < removeData.idnAffiliation.length; i++) {
                                if (newData.idnAffiliation !== removeData.idnAffiliation[i]) {
                                    filteredIdn.push(removeData.idnAffiliation[i]);
                                }
                            }

                            updateIdnValueToSite(removeData.siteId, filteredIdn).then((updatedSite) => {
                                dispatch(updateSite(updatedSite));
                                dispatch(updateAllSite(updatedSite));
                            });
                        }
                    }
                });
            }
        } else {
            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getAllSite'
            });

            let siteData = await response.data;
            if (addSiteData && addSiteData.length > 0) {
                siteData.forEach((addData) => {
                    let indexToAdd = addSiteData.indexOf(addData.siteId);
                    if (indexToAdd !== -1) {

                        let addIdnIndex = addData.idnAffiliation.indexOf(newData.idnAffiliation);

                        if (addIdnIndex === -1) {

                            let idnOnlyData = [...addData.idnAffiliation];
                            idnOnlyData.push(newData.idnAffiliation);
                            updateIdnValueToSite(addData.siteId, idnOnlyData).then((updatedSite) => {
                                dispatch(updateSite(updatedSite));
                                dispatch(updateAllSite(updatedSite));
                            });
                        }
                    }
                });
            }
        }
    };

    const saveSuperIDN = async (fetchValue, data) => {
        let newData = {...fetchValue};
        newData['plants'] = data;

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateIdn',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(updateIdn(response.data));
            updateIdnDataToSiteValue(newData).then();
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }
        let newData = trimObjectValues({...formValue});
        newData['plants'] = updateDataValue(idn);

        if (newData['plants'] && newData['plants'].length > 0) {
            setShowIdnMessage(false);
        } else {
            setShowIdnMessage(true);
            return false;
        }

        console.log(newData)
        let response;
        if (formValue.active) {
            response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdateIdn',
                requestConfig: {
                    data: newData
                }
            });
        } else {
            let userResponse = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getAllUserIDN',
                requestConfig: {
                    data: {
                        idnAffiliation: formValue.idnAffiliation
                    }
                }
            });

            if (userResponse.code === 400) {
                setShowMessage(true);
                return false;
            } else {
                response = await axiosFetch({
                    axiosInstance: axios,
                    method: 'post',
                    url: '/addUpdateIdn',
                    requestConfig: {
                        data: newData
                    }
                });
            }
        }

        if (response.data) {
            dispatch(updateIdn(response.data));
            setButtonTitle("Update");
            updateIdnDataToSiteValue(newData).then();

            if (formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
        }
        setShowMessage(true);
        setMessageData(response.message);
    };

    const updateIdnValueToSite = async (siteId, idnValue) => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/updateSitesIdn',
            requestConfig: {
                data: {
                    siteId: siteId,
                    idnValue: idnValue
                }
            }
        });

        return await response.data;
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={4}><IdnList selectedRow={selectedRow}/></Col>
                <Col sm={4}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message={messageData}/>
                    <IdnForm submitHandler={submitHandler} cancelHandler={cancelHandler} readOnly={readOnly}
                             inputChangeHandler={inputChangeHandler} buttonTitle={buttonTitle}
                             formValue={formValue} formValidation={formValidation}
                             inputValidation={inputValidation}
                             response={response} error={error} loading={loading} showMessage={showMessage}
                             disableSubmit={disableSubmit}/></Col>
                <Col sm={4}>
                    <Form>
                        {
                            showIdnMessage &&
                            <div className="formErrorDiv">Please select atleast one site.</div>
                        }
                        <ul id="ulCheckbox">
                            <TreeCheckbox data={idn} onCheckAllCheckbox={onCheckAllCheckbox} toggleOpen={toggleOpen}
                                          toggleExpand={toggleExpand}
                                          selectedAllCheckboxHandler={selectedAllCheckboxHandler}
                                          hideSelectAll={false}/>
                        </ul>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
};

export default Idn;