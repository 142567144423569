import { createSlice } from "@reduxjs/toolkit";

export const UserRoleReducer = createSlice({
    name: "UserRoleReducer",
    initialState: {
        sidebarData: {}
    },
    reducers: {
        setUserRole: (state, action) => {
            state.sidebarData = action.payload.modules;
        },
    }
});
export const {setUserRole} = UserRoleReducer.actions;

export default UserRoleReducer.reducer;