import React from "react";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faLink} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import MultiSelect from "../../commom/MultiSelect";

const UserForm = (props) => {

    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="uniqueId" className="col-form-label">
                        <span className="text-danger starCss">*</span>Id</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.uniqueId ? "errorDiv" : ""}`}
                               value={props.formValue.uniqueId} disabled={props.readOnly}
                               name="uniqueId"
                               onChange={props.inputChangeHandler}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="firstName" className="col-form-label">
                        <span className="text-danger starCss">*</span>First Name</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.firstName ? "errorDiv" : ""}`}
                               value={props.formValue.firstName} name="firstName"
                               onChange={props.inputChangeHandler} readOnly={props.isReadonly}/>
                    </div>
                </div>

            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="lastName" className="col-form-label">
                        <span className="text-danger starCss">*</span>Last Name</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.lastName ? "errorDiv" : ""}`}
                               value={props.formValue.lastName} name="lastName"
                               onChange={props.inputChangeHandler}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="email" className="col-form-label">
                        <span className="text-danger starCss">*</span>Email</label>
                    <div>
                        <input type="email" className={`form-control ${!props.inputValidation.email ? "errorDiv" : ""}`}
                               value={props.formValue.email} name="email"
                               onChange={props.inputChangeHandler}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="password" className="col-form-label">
                        <span className="text-danger starCss">*</span>Password</label>
                    <span className="bg-#0f0fec mx-sm-1 addCursorPointer"
                          onClick={() => props.sendPasswordUpdateEmail(false)}>
                        <FontAwesomeIcon icon={faLink} className="px-1"/></span>
                    <div>
                        <input type="password"
                               className={`form-control ${!props.inputValidation.password ? "errorDiv" : ""}`}
                               value={props.formValue.password} name="password"
                               onChange={props.inputChangeHandler}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="phoneNo" className="col-form-label">Phone No.</label>
                    <div>
                        <input type="text" className="form-control" value={props.formValue.phoneNo}
                               name="phoneNo" onChange={props.inputChangeHandler}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="securityRole" className="col-form-label"><span
                        className="text-danger starCss">*</span>Security Role</label>
                    <Link to="/role"><FontAwesomeIcon icon={faCog} className="px-1"/></Link>
                    <div>
                        <select className={`form-select ${!props.inputValidation.securityRole ? "errorDiv" : ""}`}
                                value={props.formValue.securityRole} onChange={props.inputChangeHandler}
                                name="securityRole">
                            <option value="select">Select</option>
                            {
                                props.roleList && props.roleList.length
                                && props.roleList.map((obj, index) => {
                                    return <option key={obj.title + index} value={obj.title}>{obj.title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="idnAffiliation" className="col-form-label"><span
                        className="text-danger starCss">*</span>Security Group</label>
                    <Link to="/idn"><FontAwesomeIcon icon={faCog} className="px-1"/></Link>
                    <div ref={props.innerIdnRef} className="multiSelectField">
                        <input className={`form-select ${!props.inputValidation.idnCount ? "errorDiv" : ""}`}
                               type="text" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.idnCount} selected`} name="idn"/>
                        {
                            props.formValue.showIdn &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showIdn} data={props.idnNewList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllIdn}
                                             label="idn" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            {props.isManagement && <Row>
                <div className="form-group col-1">
                    <label htmlFor="dvp" className="col-form-label">DVP</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="dvp"
                               onChange={props.inputChangeHandler} checked={props.formValue.dvp}/>
                    </div>
                </div>
                <div className="form-group col-1">
                    <label htmlFor="gm" className="col-form-label">GM</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="gm"
                               onChange={props.inputChangeHandler} checked={props.formValue.gm}/>
                    </div>
                </div>
                <div className="form-group col-1">
                    <label htmlFor="rdo" className="col-form-label">RDO</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="rdo"
                               onChange={props.inputChangeHandler} checked={props.formValue.rdo}/>
                    </div>
                </div>
                <div className="form-group col-1">
                    <label htmlFor="sam" className="col-form-label">SAM</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="sam"
                               onChange={props.inputChangeHandler} checked={props.formValue.sam}/>
                    </div>
                </div>
            </Row>
            }
            <Row>
                <div className="form-group col">
                    <label htmlFor="surveys" className="col-form-label">
                        <span className="text-danger starCss">*</span>Survey
                        Group</label>
                    <Link to="/surveyGroup"><FontAwesomeIcon icon={faCog} className="px-1"/></Link>
                    <select className={`form-select ${!props.inputValidation.surveysGroup ? "errorDiv" : ""}`}
                            value={props.formValue.surveysGroup}
                            onChange={props.inputChangeHandler} name="surveysGroup">
                        <option value="select">Select</option>
                        {
                            props.surveyList && props.surveyList.length
                            && props.surveyList.map((obj, index) => {
                                return <option key={obj.title + index} value={obj.title}>{obj.title}</option>
                            })
                        }
                    </select>
                </div>

                <div className="form-group col">
                    <label htmlFor="externalUser" className=" col-form-label">External User</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="externalUser" id="externalUser"
                               onChange={props.inputChangeHandler} checked={props.formValue.externalUser}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="defaultScreen" className="col-form-label">Default Screen</label>
                    <select className="form-select" value={props.formValue.defaultScreen}
                            onChange={props.inputChangeHandler} name="defaultScreen">
                        <option value="select">Select</option>
                        {(props.defaultScreenArray && props.defaultScreenArray.length) &&
                        props.defaultScreenArray.map((obj, index) => {
                            return <option key={obj + index} value={obj}>{obj}</option>
                        })
                        }
                    </select>
                </div>
                <div className="form-group col">
                    <label htmlFor="active" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="active" id="active"
                               onChange={props.inputChangeHandler} checked={props.formValue.active}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="comments" className="col-form-label">Comment</label>
                    <div>
                        <input type="text"
                               className="form-control" value={props.formValue.comments} name="comments"
                               onChange={props.inputChangeHandler}/>
                    </div>
                </div>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss"
                            disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default UserForm;