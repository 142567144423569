import { createSlice } from '@reduxjs/toolkit'

export const ReportOptionReducer = createSlice({
    name: "ReportOptionReducer",
    initialState: {
        "option": ""
    },
    reducers: {
        updateReportOption: (state, action) => {
            state.option = action.payload
        }
    }
});

export const { updateReportOption } = ReportOptionReducer.actions;

export default ReportOptionReducer.reducer;