import React from "react";
import {useSelector} from "react-redux";
import CustomTable from "../../commom/CustomTable";

function PlantList(props) {
    const tableData = useSelector((state) => state.plantReducer);

    let rowHeader = [
        {
            name: "houseCode",
            header: "House Code",
            alignment: "center"
        },
        {
            name: "_id",
            header: "Plant",
            alignment: "left"
        },
        {
            name: "isActive",
            header: "Active"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow}/>
    );
}

export default PlantList;