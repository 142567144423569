import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const PlantQAConfigForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer p-3 mx-auto">
            <div className="form-group row mb-1">
                <label htmlFor="value" className="col-sm-4 col-form-label">
                    <span className="text-danger starCss">*</span>Threshold Value
                </label>
                <div className="col-sm-8">
                    <input type="number" className={`form-control ${!props.inputValidation.value ? "errorDiv" : ""}`}
                           name="value" onChange={props.handleChange} value={props.formValue.value}/>
                </div>
            </div>
            <div className="row mx-auto form">
                <div className="row mx-auto form">
                    <Col>
                        <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>Update</Button>
                    </Col>
                    <Col>
                        <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                    </Col>
                </div>
            </div>
        </form>
    )
};

export default PlantQAConfigForm;