import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

const LeadershipReportModal = (props) => {
    return (
        <>
            <Modal show={props.showReportTable}
                   aria-labelledby="contained-modal-title-vcenter" centered className="reportModal">
                <Modal.Header className="reportModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">Leadership Report</Modal.Title>
                </Modal.Header>
                <Modal.Body className="reportModalBody">
                    <div className="reportModalTableContainer">
                        <div className="sectionContent">
                            <table className="sam-dashboard">
                                <thead>
                                <tr className="heading">
                                    <th>Sam</th>
                                    <th colSpan="3">Facility</th>
                                    <th colSpan="3">Client Sat</th>
                                    <th colSpan="3">Linen Quality & Availability</th>
                                    <th colSpan="2">Onsite Linen Inspection</th>
                                    <th colSpan="2">Linen Losss reviews</th>
                                    <th colSpan="2">PAR Reviews</th>
                                    <th colSpan="2">Other Conducted</th>
                                </tr>
                                <tr>
                                    <th className="left-border">Sam</th>
                                    <th className="left-border">Facilities Assigned</th>
                                    <th>Visits Rqd</th>
                                    <th>SAM Visit Entered</th>
                                    <th className="left-border">Rqd</th>
                                    <th>Complt</th>
                                    <th>Declined</th>
                                    <th className="left-border">Rqd</th>
                                    <th>Complt</th>
                                    <th>% Realtime</th>
                                    <th className="left-border">Rqd</th>
                                    <th>Complt</th>
                                    <th className="left-border">Rqd</th>
                                    <th>Complt</th>
                                    <th className="left-border">Rqd</th>
                                    <th>Complt</th>
                                    <th className="left-border">QBR</th>
                                    <th className="right-border">Linen Aware</th>
                                </tr>
                                </thead>
                                <tbody>
                    {(props.tableData && props.tableData.length) ?
                        props.tableData.map(function (data, index) {
                            return (

                                            <tr>
                                                <td className="left-border">{data[0]}</td>
                                                <td className="left-border">{data[1]}</td>
                                                <td>{data[2]}</td>
                                                <td>{data[3]}</td>
                                                <td className="left-border">{data[4]}</td>
                                                <td>{data[5]}</td>
                                                <td>{data[6]}</td>
                                                <td className="left-border">{data[6]}</td>
                                                <td>{data[7]}</td>
                                                <td>{data[8]}</td>
                                                <td className="left-border">{data[9]}</td>
                                                <td>{data[10]}</td>
                                                <td className="left-border">{data[11]}</td>
                                                <td>{data[12]}</td>
                                                <td className="left-border">{data[13]}</td>
                                                <td>{data[14]}</td>
                                                <td className="left-border">{data[15]}</td>
                                                <td className="right-border">{data[16]}</td>
                                            </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={18} className="left-border right-border">NO DATA AVAILABLE</td>
                        </tr>
                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="reportModalFooter">
                    {(props.tableData && props.tableData.length) ?
                        <Button onClick={props.exportAllSam} className="buttonCss mx-sm-1">Export</Button> : null}
                    <Button onClick={props.hideLeaderShipModal} className="buttonDisableCss">Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default LeadershipReportModal;