import { createSlice } from '@reduxjs/toolkit'

export const roleReducer = createSlice({
    name: "role",
    initialState: {
        "role": []
    },
    reducers: {
        updateRole: (state,action) => {
            state.role = action.payload.role;
        }
    }
});

export const { updateRole } = roleReducer.actions;

export default roleReducer.reducer;