import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import '../site/Site.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {setSite, updateSite} from "../../reducer/SiteReducer";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import {setAllSite, updateAllSite} from "../../reducer/SiteAllReducer";
import Loader from "../../commom/LoaderComponent";
import {updateIdn} from "../../reducer/IdnReducer";
import {useOuterClick} from "../../hook/VisibilityHook";
import {
    getArrayData, getFilteredArray, getPlantMasterData, getSelectedAllFlag, getSelectedPlantConfigArray,
    getUserAccessToUrl, updatedArrayByActiveKey, updateMultiSelectOption, updateMultiSelectSearch
} from "../../commom/CommonFunction";
import Message from "../../commom/Message";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import SiteForm from "../site/SiteForm";

const AddNewFacilityRequest = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [dvpList, setDvpList] = useState();
    const [rdoList, setRdoList] = useState();
    const [gmList, setGmList] = useState();
    const [visitFrequencyList, setVisitFrequencyList] = useState();
    const [traumaLevelList, setTraumaLevelList] = useState();
    const [samList, setSamList] = useState();
    const [idnList, setIdnList] = useState();
    const [idnUpdatedList, setIdnUpdatedList] = useState();
    const [formValue, setFormValue] = useState({
        _id: "",
        plant: "",
        plantHc: "",
        site: "",
        siteId: "",
        siteStatus: "",
        dateStarted: "",
        division: "",
        region: "",
        sam: "",
        annualVolume: "",
        idnAffiliation: "",
        visitFrequency: "",
        requiredCV: "",
        requiredCS: "",
        requiredEU: "",
        requiredQA: "",
        requiredPAR: "",
        requiredLL: "",
        LArequiredCommittee: "",
        LCrequiredCommittee: "",
        requiredQBRs: "",
        addOnSales: "",
        dvp: "",
        rm: "",
        gm: "",
        volume: "",
        traumaLevel: "",
        updatedBy: "",
        contactName1: "",
        contactEmail1: "",
        contactPhone1: "",
        contactName2: "",
        contactEmail2: "",
        contactPhone2: "",
        contactName3: "",
        contactEmail3: "",
        contactPhone3: "",
        contactName4: "",
        contactEmail4: "",
        contactPhone4: "",
        contactName5: "",
        contactEmail5: "",
        contactPhone5: "",
        showIdn: false,
        selectAllIdn: false,
        idnCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        plant: true,
        plantHc: true,
        site: true,
        siteId: true,
        siteStatus: true,
        division: true,
        region: true,
        sam: true,
        annualVolume: true,
        visitFrequency: true,
        requiredCV: true,
        requiredCS: true,
        requiredEU: true,
        requiredQA: true,
        requiredPAR: true,
        requiredLL: true,
        LArequiredCommittee: true,
        LCrequiredCommittee: true,
        requiredQBRs: true,
        addOnSales: true,
        dvp: true,
        rm: true,
        gm: true,
    });
    const fieldValidation = {
        plant: "select",
        site: "string",
        siteId: "string",
        siteStatus: "select",
        division: "string",
        region: "string",
        sam: "select",
        annualVolume: "string",
        visitFrequency: "select",
        requiredCV: "number",
        requiredCS: "number",
        requiredEU: "number",
        requiredQA: "number",
        requiredPAR: "number",
        requiredLL: "number",
        LArequiredCommittee: "number",
        LCrequiredCommittee: "number",
        requiredQBRs: "number",
        addOnSales: "number",
        dvp: "select",
        rm: "select",
        gm: "select"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [readOnlyField, setReadOnlyField] = useState(false);
    const [disableSite, setDisableSite] = useState(false);
    const [idnNewList, setIdnNewList] = useState();
    const innerIdnRef = useOuterClick((ree) => {
        setFormValue({...formValue, showIdn: false});
    });
    const [frequencyData, setFrequencyData] = useState();
    const [plantData, setPlantData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        dispatch(showDateInHeader(false));
        dispatch(setDateRange());

        getUserDetailWithAllSite().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(userDetail({"user": response._doc}));
            if(response._doc.securityRole === "Admin") {
                setIsAdmin(true);
            }
            let updatedArray = updatedArrayByActiveKey(response.allSites);
            dispatch(setSite(response.sites));
            dispatch(setAllSite(updatedArray));
            dispatch(setPlant(response.plants));
            setPlantData(response.plants);
            setFrequencyData(response.masterData);
            setSamList(getSelectedPlantConfigArray("Sam", "type", response.masterData, "value"));
            setVisitFrequencyList(getSelectedPlantConfigArray("Visit Frequency", "type", response.masterData, "value"));
            setTraumaLevelList(getSelectedPlantConfigArray("Trauma Level", "type", response.masterData, "value"));
            dispatch(showMasterDateOption(false));
        });
        getActiveIdnHaveDisplay().then((fetchedValue) => {
            let newIdnList = getFilteredArray(["SuperAdmin IDN", "Admin IDN"], fetchedValue, "idnAffiliation");
            setIdnList(fetchedValue);
            setIdnUpdatedList(newIdnList);
            setIdnNewList(updateMultiSelectOption(newIdnList, "idnAffiliation"))
        });
    }, []);

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithAllSite = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithAllSite',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getActiveIdnHaveDisplay = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getActiveIdnHaveDisplay'
        });
        return await response.data;
    };

    const updateMultiSelectOption = (fetchedValue) => {
        return fetchedValue.map((obj) => {
            let data = obj.idnAffiliation;

            return {
                label: data,
                value: false
            };
        });
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);

        if (name === "idn") {
            setIdnNewList(newData);
            setFormValue({...formValue, selectAllIdn: checked, idnCount: count});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "idn") {
            let flag = formValue.showIdn;

            if(!flag) {
                let data = updateMultiSelectSearch(idnUpdatedList, "idnAffiliation", "", idnNewList);
                setIdnNewList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showIdn: !flag, selectAllIdn: selectedAll});
            } else {
                setFormValue({...formValue, showIdn: !flag});

            }
        }
    };

    const handleSearch = (event) => {
        const {value} = event.currentTarget;
        let data = updateMultiSelectSearch(idnUpdatedList, "idnAffiliation", value, idnNewList);
        setIdnNewList(data);
        let selectedAll = getSelectedAllFlag(data);
        setFormValue({...formValue, selectAllIdn: selectedAll});
    };

    const plantSelection = (plant, flag) => {
        if (!plant) {
            setDvpList([]);
            setRdoList([]);
            setGmList([]);
        } else {
            if (plantData && plantData.length) {
                let selectedPlant = plantData.find((obj) => {
                    if (obj._id === plant) {
                        return obj;
                    }
                });

                setDvpList(getPlantMasterData(frequencyData, "DVP", selectedPlant._id));
                setRdoList(getPlantMasterData(frequencyData, "RDO", selectedPlant._id));
                setGmList(getPlantMasterData(frequencyData, "GM", selectedPlant._id));

                if (flag) {
                    setFormValue((preState) => ({
                        ...preState,
                        plantHc: selectedPlant.houseCode,
                        division: selectedPlant.division,
                        region: selectedPlant.region,
                        street: selectedPlant.street,
                        city: selectedPlant.city,
                        state: selectedPlant.state,
                        zip: selectedPlant.zip
                    }));
                } else {
                    setFormValue((preState) => ({
                        ...preState,
                        plantHc: selectedPlant.houseCode
                    }));
                }
            }
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.name === "plant") {
            plantSelection(event.currentTarget.value, true);
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        if (flag) {
            setFormValue(currentFormState);
        } else {
            currentFormState.selectAllIdn = false;
            currentFormState.idnCount = 0;
            currentFormState.showIdn = false;
            setFormValue(currentFormState);
        }
    };

    const unselectedIdnValue = () => {
        let newArray = [];

        idnNewList.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject.label;

            newArray.push({
                "label": data,
                "value": false
            });
        });

        setIdnNewList(newArray);
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setReadOnlyField(false);
        plantSelection("", false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        unselectedIdnValue();
    };

    const handleIdnChange = (event) => {
        setDisableSubmit(false);
        let newIdn = event.currentTarget.value;
        if (formValue.idnAffiliation === newIdn) {
            return false;
        }
        setFormValue({...formValue, "idnAffiliation": newIdn});
    };

    const sendEmailAdminGrantFacility = async () => {
        await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/sendEmailAdminGrantFacility',
            requestConfig: {
                data: {
                    "site": formValue.site,
                    "siteId": formValue.siteId
                }
            }
        });
        setShowMessage(true);
    };

    const submitIdnHandler = async (data) => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateIdn',
            requestConfig: {
                data: data
            }
        });

        if (response.data) {
            dispatch(updateIdn(response.data));
        }
        setShowMessage(true);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        let selectedIdnData = getArrayData(idnNewList);

        console.log(inputValidation);
        console.log(formValidation);

        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let isAdmin = false, isSuperAdmin = false;

        if(selectedIdnData && selectedIdnData.length) {
            selectedIdnData.forEach((selectedIdn) => {
                if (selectedIdn === "Admin IDN") {
                    isAdmin = true;
                }

                if (selectedIdn === "SuperAdmin IDN") {
                    isSuperAdmin = true;
                }
            });
        }

        console.log(selectedIdnData);
        if(!isAdmin) {
            selectedIdnData.push("Admin IDN")
        }
        if(!isSuperAdmin) {
            selectedIdnData.push("SuperAdmin IDN")
        }

        let newData = {...formValue};
        newData["idnAffiliation"] = selectedIdnData;
        let user = JSON.parse(localStorage.getItem('userDetail'));

        newData["updatedBy"] = user.userId;
        newData["siteStatus"] = "Closed";
        setFormValidation(true);
        console.log(newData);
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSite',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            setDisableSite(true);
            setReadOnlyField(true);
            dispatch(updateSite(response.data));
            dispatch(updateAllSite(response.data));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }

            sendEmailAdminGrantFacility().then((emailResponse) => console.log(emailResponse));

            idnList.forEach((idnObj) => {
                let flag = true;
                let newIndex = selectedIdnData.indexOf(idnObj.idnAffiliation);

                if (newIndex !== -1) {
                    idnObj.plants.forEach((plantNewObj) => {
                        if (plantNewObj.id === formValue.plantHc) {
                            flag = false;
                            let subNewModule = plantNewObj.subModule;
                            const findIndex = subNewModule.findIndex(siteObj => siteObj.id === formValue.siteId);
                            if (findIndex === -1) {
                                subNewModule.push({
                                    "id": formValue.siteId,
                                    "name": formValue.site,
                                    "isActive": true
                                })
                            }
                        }
                    });

                    if (flag) {
                        let newPlantObj = {
                            "id": formValue.plantHc,
                            "name": formValue.plant,
                            "isActive": true,
                            "open": false,
                            "subModule": [{
                                "id": formValue.siteId,
                                "name": formValue.site,
                                "isActive": true
                            }]
                        };
                        idnObj.plants.push(newPlantObj);
                    }

                    submitIdnHandler(idnObj).then();
                }
            })
        }
        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col id="addSiteRequest">
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                    <SiteForm submitHandler={submitHandler} handleChange={handleChange}
                              cancelHandler={cancelHandler} isAdmin={isAdmin}
                              buttonTitle={buttonTitle}
                              plantData={plantData}
                              visitFrequencyList={visitFrequencyList}
                              traumaLevelList={traumaLevelList} idnList={idnList}
                              samList={samList} dvpList={dvpList} rdoList={rdoList} gmList={gmList}
                              formValue={formValue} formValidation={formValidation}
                              inputValidation={inputValidation}
                              response={response} error={error} loading={loading} showMessage={showMessage}
                              disableSubmit={disableSubmit} readOnlyField={readOnlyField}
                              handleIdnChange={handleIdnChange} innerIdnRef={innerIdnRef} idnNewList={idnNewList}
                              handleClick={handleClick} disableSite={disableSite}
                              handleSearch={handleSearch} handleCheckboxChange={handleCheckboxChange} flag={false}
                    />
                </Col>
            </Row>
        </Container>
    )
};

export default AddNewFacilityRequest;