import React from "react";
import {Button, Row, Col, ListGroup} from "react-bootstrap";
import MultiSelect from "../../commom/MultiSelect";

const PlantQASurveyForm = (props) => {

    return (
        <Row className="mb-sm-1">
            <div className="form-group col">
                <label htmlFor="plant" className="col-form-label"><span className="text-danger starCss">*</span>Plant</label>
                <div>
                    <ListGroup className={`${props.formValue.survey ? "divSurveyListCSS": "divSurveyCSS" }`}>
                        {
                            props.plantList && props.plantList.length
                            && props.plantList.map((obj, index) => {
                                return <ListGroup.Item name="plant" key={obj._id + index} onClick={props.updateListSelection}
                                                       className={`listClass ${(props.formValue.plant === obj._id) ? "active": "" }`}
                                >{obj._id}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
            <div className="form-group col">
                <label htmlFor="site" className="col-form-label"><span className="text-danger starCss">*</span>Site</label>
                <div>
                    <ListGroup className={`${props.formValue.survey ? "divSurveyListCSS": "divSurveyCSS" }`}>
                        {
                            props.siteList && props.siteList.length
                            && props.siteList.map((obj, index) => {
                                return <ListGroup.Item name="site" key={obj.site + index} onClick={props.updateListSelection}
                                                       className={`listClass ${(props.formValue.site === obj.site) ? "active": "" }`}
                                >{obj.site}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
            <div className="form-group col">
                <label htmlFor="survey" className="col-form-label"><span className="text-danger starCss">*</span>Surveys & Activities</label>
                <div>
                    <ListGroup className={`${props.formValue.survey ? "divSurveyListCSS": "divSurveyCSS" }`}>
                        {
                            props.surveyList && props.surveyList.length
                            && props.surveyList.map((obj, index) => {
                                return <ListGroup.Item name="survey" key={obj.title + index} onClick={props.updateListSelection}
                                                       className={`listClass ${(props.formValue.survey === obj.title) ? "active": "" }`}
                                >{obj.title}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
            {props.showExternalSurvey && <div className="form-group col">
                <label name="externalUser"
                       onClick={props.handleCheckboxLabelClick}
                       className="col-form-label">
                    Email survey link to client for completion
                </label>
                <div>
                    <input type="checkbox" name="externalUser"
                           onChange={props.handleChange}
                           className="form-check"
                           checked={props.formValue.externalUser}/>
                </div>
            </div>}
            {props.showExternalSurvey && props.formValue.externalUser && <Row>
                <div className="form-group col">
                    <label htmlFor="dateStarted" className="col-form-label"><span
                        className="text-danger starCss">*</span>Date</label>
                    <div>
                        <input type="date" name="dateStarted" className="form-control"
                               onChange={props.handleChange} value={props.formValue.dateStarted}/>
                    </div>
                </div>

                <div className="form-group col">
                    <label className="col-form-label" htmlFor="emailList">Site Contact</label>
                    <div className="col" ref={props.innerRef}>
                        <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.siteEmailCount} selected`} name="emailList"/>
                        {
                            props.formValue.showSiteEmail &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showSiteEmail} data={props.siteEmailList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllSiteEmail} label="emailList"
                                             handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>

                <div className="form-group col">
                    <label htmlFor="externalUser" className="col-form-label">Email</label>
                    <div>
                        <input type="email" name="clientEmail"
                               onChange={props.handleChange} className="form-control"
                               value={props.formValue.clientEmail}/>
                    </div>
                </div>


                <div className="form-group col">
                    <label htmlFor="emailContactValue" className="col-form-label">Email Contact</label>
                    <div>
                        <input type="text" name="emailContactValue"
                               onChange={props.handleChange} className="form-control"
                               value={props.formValue.emailContactValue}/>
                    </div>
                </div>
            </Row>
            }
            {props.showExternalSurvey && props.formValue.externalUser &&<div className="row justify-content-center m-1">
                    <Button onClick={props.submitSurvey} className="formButton col-md-3 mx-sm-1 buttonCss" disabled={props.disableSubmit}>Submit</Button>
            </div>}
        </Row>
    )
};

export default PlantQASurveyForm;