import React from "react";
import CustomTable from "../../commom/CustomTable";
import {useSelector} from "react-redux";

function IdnList(props) {
    const tableData = useSelector((state) => state.idnReducer);
    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "idnAffiliation",
            header: "IDN Affiliation"
        },
        {
            name: "active",
            header: "Active"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow}/>
    );
}

export default IdnList;