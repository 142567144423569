import { createSlice } from '@reduxjs/toolkit'

export const loginReducer = createSlice({
    name: "login",
    initialState: {
        isLoggedIn: false,
        token: "",
        isProductionEnv: true
    },
    reducers: {
        logIn: (state, action) => {
            state.isLoggedIn = true;
            state.token = action.payload.token;
            state.isProductionEnv = action.payload.isProductionEnv;
        },
        logOut: state => {
            state.isLoggedIn = false;
            state.token = "";
            state.isProductionEnv = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { logIn, logOut } = loginReducer.actions;

export default loginReducer.reducer;