import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReportOptionContainer from "./ReportOptionContainer";
import DataExport from "./DataExport";
import PlantQAExportModal from "../plantQAExport/PlantQAExportModal";
import {useDispatch, useSelector} from "react-redux";
import ReportExportForm from "../scoreCardAndUtilizationExport/ReportExportModal";
import {exportSurveyData} from "../../commom/ExportToExcel";
import axios from "../../apis/AxiosInstance";
import {
    convertValidStringToNumber,
    getDateFromArray,
    getUserAccessToUrl,
    searchArray, sortArrayData,
    specificArrayData, specificArrayDataWithActiveAndKey
} from "../../commom/CommonFunction";
import {checkDateExistBetweenDaterange, getDateByMonth, getDateInUTC} from "../../commom/DateFormat";
import useAxiosFunction from "../../hook/AxiosHook";
import ReportTableModal from "./ReportTableModal";
import {useNavigate} from "react-router-dom";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {list} from "../../reducer/FrequencyReducer";
import {setSite} from "../../reducer/SiteReducer";
import {setAllSite} from "../../reducer/SiteAllReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {updateReportOption} from "../../reducer/ReportOptionReducer";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";
import ExternalSurveyReport from "../plantQASurvey/ExternalSurveyReport";
import {setSurveyData} from "../../reducer/SurveyReducer";

const ReportContainer = () => {
    const [showPlantQAExport, setShowPlantQAExport] = useState(false);
    const [showReportExport, setShowReportExport] = useState(false);
    const [showDataExport, setShowDataExport] = useState(false);
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [rowHeader, setRowHeader] = useState("");
    const [tableData, setTableData] = useState("");
    const [reportHeader, setReportHeader] = useState(null);
    const [showReportTable, setShowReportTable] = useState(false);
    const [reportData, setReportData] = useState("");
    const dispatch = useDispatch();
    const [title, setTitle] = useState("Select Report");
    const [reportDropdown, setReportDropdown] = useState("");
    const [siteData, setSiteData] = useState("");
    const navigate = useNavigate();
    const auth = useContext(AuthContext);


    const [incompleteReportClicked, setIncompleteReportClicked] = useState(false);
    const [completedReportClicked, setCompletedReportClicked] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState();
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [samEmail, setSamEmail] = useState("");
    const [surveyList, setSurveyList] = useState();
    const [externalSurveyList, setExternalSurveyList] = useState();

    useEffect(() => {
        let reportOptionData = [];
        dispatch(setDateRange());

        getUserDetailWithMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
            dispatch(setAllSite(response.sites));
            setSiteData(response.sites);
            setSurveyList(response.surveys);
            let externalSurveyData = specificArrayDataWithActiveAndKey(response.surveys, "title", "active", "sendEmail");
            setExternalSurveyList(externalSurveyData);
            dispatch(setSurveyData(response.surveys));

            dispatch(list(response.masterData));
            dispatch(showMasterDateOption(true));
            let fetchedValue = response.securityRoles;

            if(fetchedValue.modules && fetchedValue.modules[1]) {
                let module = fetchedValue.modules[1].subModule;
                if( module[3] &&  module[3].subModules) {
                    let subModule = module[3].subModules;

                    subModule.forEach((reportObj) => {
                        if(reportObj.isActive && (reportObj.subModuleTitle != "Fill Rate") && (reportObj.subModuleTitle != "Plant Compare")) {
                            if(reportObj.subModuleTitle == "Benchmark Report") {
                                reportOptionData.push("Data Export by Attribute");
                            } else {
                                reportOptionData.push(reportObj.subModuleTitle)
                            }
                        }
                    });
                }

                console.log(reportOptionData);
                setReportDropdown(reportOptionData.sort());

            }
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithMasterData = async () => {
        console.log("report container");
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithAllData',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    useEffect(() => {

        if (rowHeader.length) {
            setShowReportTable(true);
        }
    }, [tableData, rowHeader]);

    const getReportForConcernedCustomer = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getReportForConcernedCustomer',
            requestConfig: {
                data: {
                    "sites": specificArrayData(siteData, "site"),
                    "survey": ["Client Satisfaction", "Activities", "Client Satisfaction - No distribution", "Subcontractor End User"],
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });

        let concernCustomerObject = {
            scoreSummary: [],
            declinedSurveys: [],
            detailedSurveys: [],
            newSurveys: [],
            latestSurveys: []
        };

        if (response) {
            response.data.forEach(obj => {
                let objectData = Object.assign({}, obj);

                if ((objectData.survey === "Client Satisfaction" && objectData.surveyDetail[16] && objectData.surveyDetail[16].value < 8) ||
                    (objectData.survey === "Subcontractor End User" && objectData.surveyDetail[8] && objectData.surveyDetail[8].value < 8) ||
                    (objectData.survey === "Client Satisfaction - No distribution" && objectData.surveyDetail[8] && objectData.surveyDetail[8].value < 8)) {

                    let searchPlant = searchArray(objectData.plant, concernCustomerObject.scoreSummary, "Location");
                    let count = objectData.survey === "Client Satisfaction" ? parseInt(objectData.surveyDetail[16].value) : parseInt(objectData.surveyDetail[8].value);
                    let obj = searchPlant;
                    if (!searchPlant) {
                        obj = {
                            "Location": objectData.plant,
                            "1": 0,
                            "2": 0,
                            "3": 0,
                            "4": 0,
                            "5": 0,
                            "6": 0,
                            "7": 0,
                            "Total Count": 0,
                        };
                        concernCustomerObject.scoreSummary.push(obj);
                    }

                    obj[count] = obj[count] + count;

                    let total = 0;
                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            if (key !== "Location" && key !== "Total Count") {
                                total = total + obj[key];
                            }
                        }
                    }
                    obj["Total Count"] = total;
                }
            });

            response.data.forEach(obj => {
                let objectData = Object.assign({}, obj);

                if (objectData.survey === "Activities" &&
                    (objectData.surveyDetail[1].value === "Client Sat Declined" ||
                        objectData.surveyDetail[1].value === "Client Sat Survey Refusal")) {
                    let siteObject = searchArray(objectData.site, siteData, "site");

                    let obj = {
                        "Division": siteObject.division,
                        "Plant": siteObject.plant,
                        "Plant HC": siteObject.plantHc,
                        "Site Id": siteObject.siteId,
                        "Site": siteObject.site,
                        "Estimated Pounds": siteObject.annualVolume,
                        "Visit Frequency": siteObject.visitFrequency,
                        "Required CV Visitations": siteObject.requiredCV,
                        "Survey Date": getDateByMonth(objectData.surveyDate),
                        "Activity Comments": objectData.summary ? objectData.summary : ""
                    };
                    concernCustomerObject.declinedSurveys.push(obj);
                }
            });

            response.data.forEach(obj => {
                let siteObject = Object.assign({}, obj);

                if ((siteObject.survey === "Client Satisfaction" && siteObject.surveyDetail[16] && siteObject.surveyDetail[16].value < 8) ||
                    (siteObject.survey === "Subcontractor End User" && siteObject.surveyDetail[8] && siteObject.surveyDetail[8].value < 8) ||
                    (siteObject.survey === "Client Satisfaction - No distribution" && siteObject.surveyDetail[8] && siteObject.surveyDetail[8].value < 8)) {

                    let searchPlant = searchArray(siteObject.plant, concernCustomerObject.detailedSurveys, "Plant");
                    let count = siteObject.survey === "Client Satisfaction" ? parseInt(siteObject.surveyDetail[16].value) : parseInt(siteObject.surveyDetail[8].value);
                    let consecutiveBadScores = siteObject.survey === "Client Satisfaction" ? 1 : 0;
                    let totalBadScores = 1;
                    let line = siteObject.survey === "Client Satisfaction" ? siteObject.surveyDetail[17].value : siteObject.surveyDetail[9].value;
                    let obj = searchPlant;
                    if (!searchPlant) {
                        let objectData = searchArray(siteObject.site, siteData, "site");

                        obj = {
                            "Division": objectData.division,
                            "Plant": objectData.plant,
                            "Plant HC": objectData.plantHc,
                            "Site Id": objectData.siteId,
                            "Site": objectData.site,
                            "Estimated Pounds": objectData.annualVolume,
                            "Visit Frequency": objectData.visitFrequency,
                            "Required CV Visitations": objectData.requiredCV,
                            "Survey Date": getDateByMonth(siteObject.surveyDate),
                            "Activity Comments": "",
                            "Overall Score": count,
                            "Consecutive Surveys with 7 or Below": 0,
                            "Total 7 & Below": 0

                        };
                        concernCustomerObject.detailedSurveys.push(obj);
                    }
                    obj["Consecutive Surveys with 7 or Below"] += consecutiveBadScores;
                    obj["Total 7 & Below"] += totalBadScores;
                    obj["Activity Comments"] += line + "\n";

                }
            });

            response.data.forEach(obj => {
                let objectData = Object.assign({}, obj);
                let date = checkDateExistBetweenDaterange(objectData.surveyDate);

                if (date && ((objectData.survey === "Client Satisfaction" && objectData.surveyDetail[16]) ||
                    (objectData.survey === "Subcontractor End User" && objectData.surveyDetail[8]) ||
                    (objectData.survey === "Client Satisfaction - No distribution" && objectData.surveyDetail[8]))) {
                    let count = objectData.survey === "Client Satisfaction" ? parseInt(objectData.surveyDetail[16].value) : convertValidStringToNumber(objectData.surveyDetail[8].value);
                    let consecutiveBadScores = (objectData.survey === "Client Satisfaction" && objectData.surveyDetail[16] && parseInt(objectData.surveyDetail[16].value) < 8) ? 0 : 1;
                    let line = objectData.survey === "Client Satisfaction" ? objectData.surveyDetail[17].value : objectData.surveyDetail[9].value;
                    let totalBadScores = count < 8 ? 0 : 1;
                    let siteObject = searchArray(objectData.site, siteData, "site");

                    obj = {
                        "Division": siteObject.division,
                        "Plant": siteObject.plant,
                        "Plant HC": siteObject.plantHc,
                        "Site Id": siteObject.siteId,
                        "Site": siteObject.site,
                        "Estimated Pounds": siteObject.annualVolume,
                        "Visit Frequency": siteObject.visitFrequency,
                        "Required CV Visitations": siteObject.requiredCV,
                        "Survey Date": getDateByMonth(objectData.surveyDate),
                        "Performance & Service Comments": line,
                        "Overall Score": count,
                        "Consecutive Surveys with 7 or Below": consecutiveBadScores,
                        "Total 7 & Below": totalBadScores

                    };
                    concernCustomerObject.newSurveys.push(obj);
                }
            });

            response.data.forEach(obj => {
                let objectData = Object.assign({}, obj);

                if ((objectData.survey === "Client Satisfaction" && objectData.surveyDetail[16]) ||
                    (objectData.survey === "Subcontractor End User" && objectData.surveyDetail[8]) ||
                    (objectData.survey === "Client Satisfaction - No distribution" && objectData.surveyDetail[8])) {

                    let count = objectData.survey === "Client Satisfaction" ? parseInt(objectData.surveyDetail[16].value) : convertValidStringToNumber(objectData.surveyDetail[8]);
                    if(isNaN(count)) {
                        count = "";
                    }
                    let siteObject = searchArray(objectData.site, siteData, "site");
                    obj = {
                        "Division": siteObject.division,
                        "Plant": siteObject.plant,
                        "Plant HC": siteObject.plantHc,
                        "Site Id": siteObject.siteId,
                        "Site": siteObject.site,
                        "Estimated Pounds": siteObject.annualVolume,
                        "Visit Frequency": siteObject.visitFrequency,
                        "Required CV Visitations": siteObject.requiredCV,
                        "Survey Date": getDateByMonth(objectData.surveyDate),
                        "Overall Score": count,
                    };
                    concernCustomerObject.latestSurveys.push(obj);
                }
            });
        }

        return concernCustomerObject;
    };

    const getReportForLatestSurvey = async () => {
        let surveys = ["Linen Quality & Availability", "Client Satisfaction", "Linen Loss", "PAR", "Onsite Linen Inspection", "Linen Awareness", "QBR", "Client Satisfaction - No distribution"];

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getReportForLatestSurvey',
            requestConfig: {
                data: {
                    "sites": specificArrayData(siteData, "site"),
                    "survey": surveys,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });

        if (response) {
            const customHeadings = response.data.map(item => ({
                "Survey": item.survey,
                "Plant": item.plant,
                "Site": item.site,
                "SurveyDate": item.surveyDate
            }));

            let latestSurvey = [];

            siteData.map(obj => {
                let siteObject = Object.assign({}, obj);
                let siteDate = getDateFromArray(siteObject.site, customHeadings, "Site", "Survey", "SurveyDate");

                let surveyObj = {
                    "Division": siteObject.division,
                    "Plant": siteObject.plant,
                    "Plant HC": siteObject.plantHc,
                    "Site Id": siteObject.siteId,
                    "Estimated Pounds": siteObject.annualVolume,
                    "Visit Frequency": siteObject.visitFrequency,
                    "Site": siteObject.site,
                    "Sam": siteObject.sam,
                    "Client Sat": siteDate["Client Satisfaction"] || siteDate["Client Satisfaction - No distribution"] || "",
                    "Linen Quality & Availability": siteDate["Linen Quality & Availability"] || "",
                    "Onsite Linen Inspection": siteDate["Onsite Linen Inspection"] || "",
                };

                latestSurvey.push(surveyObj);
            });
            return ({"LatestSurvey": latestSurvey});
        }
    };

    const getReportForOnTimeExceptionSurvey = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getScorecardPlantMapping',
            requestConfig: {
                data: {
                    "site": specificArrayData(siteData, "site"),
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        let onTimeException = [];

        if (response) {
            response.data.map(objData => {
                let siteObject = Object.assign({}, objData);

                let searchPlant = searchArray(siteObject.site, onTimeException, "Site");
                let obj = searchPlant;
                let count = 1;
                let sum = siteObject.onTimeDeliveryPercent;
                let total = sum / count;
                if (!searchPlant) {
                    let objectData = searchArray(siteObject.site, siteData, "site");
                    let scoreEndDate = new Date((siteObject.dateRange).split(" ")[2]);
                    let data = Math.round((new Date(endDate) - scoreEndDate) / 604800000);
                    obj = {
                        "Division": objectData.division,
                        "Plant": objectData.plant,
                        "Plant HC": objectData.plantHc,
                        "Site Id": objectData.siteId,
                        "Site": objectData.site,
                        "Estimated Pounds": objectData.annualVolume,
                        "Visit Frequency": objectData.visitFrequency,
                        "OT %": 0,
                        "count": 0,
                        "Consecutive Weeks < 98%": sum > 98 ? data : 0,
                    };
                    onTimeException.push(obj);
                }
                obj["OT %"] += total;
                obj["count"] += 1;
            });
        }
        let newTimeException = [];

        onTimeException.forEach((timeObj) => {
            let timeObject = Object.assign({}, timeObj);

            newTimeException.push({
                "Division": timeObject.Division,
                "Plant": timeObject.Plant,
                "Plant HC": timeObject["Plant HC"],
                "Site Id": timeObject["Site Id"],
                "Site": timeObject.Site,
                "Estimated Pounds": timeObject["Estimated Pounds"],
                "Visit Frequency": timeObject["Visit Frequency"],
                "OT %": Math.ceil(timeObject["OT %"] / timeObject.count),
                "Consecutive Weeks < 98%": 0
            });
        });
        return ({"onTimeException": newTimeException});
    };

    const handleClose = () => {
        setShowPlantQAExport(false);
        setShowReportExport(false);
        setShowDataExport(false);
        setShowReportTable(false);
    };

    const exportReportData = () => {
        let reportOption = title;

        if (reportOption === "Concerned Customer Report") {
            exportSurveyData(reportData, "ConcernedCustomers.xlsx");
        } else if (reportOption === "Latest Surveys") {
            exportSurveyData(reportData, "LatestSurvey.xlsx");
        } else if (reportOption === "On Time Exception") {
            exportSurveyData(reportData, "OnTimeException.xlsx");
        }
    };

    const updateListSelection = (event) => {
        setTitle(event.currentTarget.innerHTML);
        let reportOption = event.currentTarget.innerHTML;
        dispatch(updateReportOption(event.currentTarget.innerHTML));


        if (reportOption === "Plant QA Overview") {
            setShowPlantQAExport(true);
        } else if (reportOption === "Missing Utilization Report" || reportOption === "Missing Scorecard Report") {
            setShowReportExport(true);
        } else if (reportOption === "Concerned Customer Report") {
            getReportForConcernedCustomer().then((concernCustomerObject) => {
                let rowHeader = [
                    {
                        name: "Division",
                        header: "Division",
                        alignment: "left"
                    },
                    {
                        name: "Plant",
                        header: "Plant",
                        alignment: "left"
                    },
                    {
                        name: "Plant HC",
                        header: "Plant HC",
                        alignment: "left"
                    },
                    {
                        name: "Site Id",
                        header: "Site Id",
                        alignment: "left"
                    },
                    {
                        name: "Estimated Pounds",
                        header: "Estimated Pounds",
                        alignment: "left"
                    },
                    {
                        name: "Visit Frequency",
                        header: "Visit Frequency",
                        alignment: "left"
                    },
                    {
                        name: "Site",
                        header: "Site",
                        alignment: "left"
                    },
                    {
                        name: "Required CV Visitations",
                        header: "Required CV Visitations",
                        alignment: "left"
                    },
                    {
                        name: "Survey Date",
                        header: "Survey Date",
                        alignment: "left"
                    },
                    {
                        name: "Overall Score",
                        header: "Overall Score",
                        alignment: "left"
                    }
                ];
                setRowHeader(rowHeader);
                setTableData(concernCustomerObject["latestSurveys"]);
                setReportHeader("Concern Customer Latest Surveys")
                setReportData(concernCustomerObject);
            });
        } else if (reportOption === "Latest Surveys") {
            getReportForLatestSurvey().then((latestSurvey) => {
                let rowHeader = [
                    {
                        name: "Division",
                        header: "Division",
                        alignment: "left"
                    },
                    {
                        name: "Plant",
                        header: "Plant",
                        alignment: "left"
                    },
                    {
                        name: "Plant HC",
                        header: "Plant HC",
                        alignment: "left"
                    },
                    {
                        name: "Site Id",
                        header: "Site Id",
                        alignment: "left"
                    },
                    {
                        name: "Estimated Pounds",
                        header: "Estimated Pounds",
                        alignment: "left"
                    },
                    {
                        name: "Visit Frequency",
                        header: "Visit Frequency",
                        alignment: "left"
                    },
                    {
                        name: "Site",
                        header: "Site",
                        alignment: "left"
                    },
                    {
                        name: "Sam",
                        header: "Sam",
                        alignment: "left"
                    },
                    {
                        name: "Client Sat",
                        header: "Client Sat",
                        alignment: "left"
                    },
                    {
                        name: "Linen Quality & Availability",
                        header: "Linen Quality & Availability",
                        alignment: "left"
                    },
                    {
                        name: "Onsite Linen Inspection",
                        header: "Onsite Linen Inspection",
                        alignment: "left"
                    },
                ];
                setRowHeader(rowHeader);
                setTableData(latestSurvey["LatestSurvey"]);
                setReportHeader("Latest Survey");
                setReportData(latestSurvey);
            });
        } else if (reportOption === "On Time Exception") {
            getReportForOnTimeExceptionSurvey().then((onTimeException) => {
                let rowHeader = [
                    {
                        name: "Division",
                        header: "Division",
                        alignment: "left"
                    },
                    {
                        name: "Plant",
                        header: "Plant",
                        alignment: "left"
                    },
                    {
                        name: "Plant HC",
                        header: "Plant HC",
                        alignment: "left"
                    },
                    {
                        name: "Site Id",
                        header: "Site Id",
                        alignment: "left"
                    },
                    {
                        name: "Estimated Pounds",
                        header: "Estimated Pounds",
                        alignment: "left"
                    },
                    {
                        name: "Visit Frequency",
                        header: "Visit Frequency",
                        alignment: "left"
                    },
                    {
                        name: "Site",
                        header: "Site",
                        alignment: "left"
                    },
                    {
                        name: "OT %",
                        header: "OT %",
                        alignment: "left"
                    },
                    {
                        name: "Consecutive Weeks < 98%",
                        header: "Consecutive Weeks < 98%",
                        alignment: "left"
                    }
                ];
                setRowHeader(rowHeader);
                setTableData(onTimeException["onTimeException"]);
                setReportHeader("On Time Exception");
                setReportData(onTimeException);
            });
        } else if (reportOption === "Data Export by Attribute") {
            setShowDataExport(true);
        } else if (reportOption === "Survey Report") {
            navigate('/surveyReport')
        } else if (reportOption === "Completed External Surveys With Details") {
            showCompleteSurvey();
        } else if (reportOption === "Incomplete External Surveys") {
            showIncompleteSurvey();
        } else if (reportOption === "End Of The Day Summary") {
            navigate('/summaryOfDay');
        } else if (reportOption === "SAM Daily Summary") {
            navigate('/samSummaryOfDay');
        }
    };

    const showIncompleteSurvey = () => {
        setIncompleteReportClicked(true);
    };

    const showCompleteSurvey = () => {
        setSelectedSurvey(externalSurveyList[0].title);
        setCompletedReportClicked(true);
    };

    const closeExternalReport = () => {
        setCompletedReportClicked(false);
        setIncompleteReportClicked(false);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row className="align-items-center mb-sm-1">
                <ReportOptionContainer title={title} reportDropdown={reportDropdown} updateListSelection={updateListSelection}/>
                <PlantQAExportModal showPlantQAExport={showPlantQAExport} handleClose={handleClose}/>
                <ReportExportForm showReportExport={showReportExport} handleClose={handleClose}/>
                <DataExport showDataExport={showDataExport} handleClose={handleClose} surveysArray={surveyList}/>
                <ReportTableModal rowHeader={rowHeader} tableData={tableData} showReportTable={showReportTable}
                                  handleClose={handleClose} header={reportHeader} exportReportData={exportReportData}/>
                <ExternalSurveyReport incompleteReportClicked={incompleteReportClicked} sitesData={siteData}
                                      completedReportClicked={completedReportClicked} surveyList={externalSurveyList}
                                      surveys={selectedSurvey} closeExternalReport={closeExternalReport}/>
            </Row>
        </Container>
    )
};

export default ReportContainer;