import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomTable from "../../commom/CustomTable";
import "./Report.css";
import Button from "react-bootstrap/Button";

const ReportTableModal = (props) => {
    return (
        <>
            <Modal show={props.showReportTable}
                   aria-labelledby="contained-modal-title-vcenter" centered className="reportModal">
                <Modal.Header className="reportModalHeader">

                    {props.chartValue == 1 && <span className="fw-bold">Count </span>}
                    {props.chartValue == 1 && <span className="fw-bold px-1">{props.chartCount}</span>}
                    {props.chartValue == 2 && <div className="fw-bold">Pieces </div>}
                    {props.chartValue == 2 && <div className="fw-bold px-1">{props.chartImperfection}</div>}
                    {props.chartValue == 2 && <div className="fw-bold">Flaw </div>}
                    {props.chartValue == 2 && <div className="fw-bold px-1">{props.chartFlaw}</div>}
                    <Modal.Title id="contained-modal-title-vcenter">{props.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="reportModalBody">
                    {(props.tableData && props.tableData.length) ?
                        <div className="reportModalTableContainer">
                        <CustomTable rowHeader={props.rowHeader} tableData={props.tableData} hideSearch={true}/>
                        </div>
                        :
                        <div className="text-center">NO DATA AVAILABLE</div>
                    }

                </Modal.Body>
                <Modal.Footer className="reportModalFooter">
                    {(props.tableData && props.tableData.length && props.exportReportData) ?
                    <Button onClick={props.exportReportData} className="buttonCss mx-sm-1">Export</Button> : null}
                    <Button onClick={props.handleClose} className="buttonDisableCss">Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ReportTableModal;