import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import './DefaultPage.css';
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {useDispatch} from "react-redux";

const DefaultPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showDateInHeader(false));
    }, []);

    return (
        <Container fluid="md">
            <p className="defaultDiv">Page Under Construction</p>
        </Container>
    )
};

export default DefaultPage;