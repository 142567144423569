import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "flag" : false,
    "plant": "",
    "key": "site"
};

export const FacilityPlantReducer = createSlice({
    name: "FacilityPlantReducer",
    initialState,
    reducers: {
        setFacilityPlant: (state, action) => {
            state["flag"] = action.payload.flag;
            state["plant"] = action.payload.plant;
            state["key"] = action.payload.key;
        },
    }
});
export const {setFacilityPlant} = FacilityPlantReducer.actions;

export default FacilityPlantReducer.reducer;