import { createSlice } from '@reduxjs/toolkit'

export const DefaultScreenReducer = createSlice({
    name: "DefaultScreenReducer",
    initialState: {
        defaultScreen: "",
    },
    reducers: {
        updateDefaultScreen: (state, action) => {
            state.defaultScreen = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {updateDefaultScreen} = DefaultScreenReducer.actions;

export default DefaultScreenReducer.reducer;