import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const SurveySelectedQuestionReducer = createSlice({
    name: "surveySelectedQuestionReducer",
    initialState,
    reducers: {
        selectedSurveyQuestion: (state, action) => {
            return action.payload;
        }
    }
});
export const {selectedSurveyQuestion} = SurveySelectedQuestionReducer.actions;

export default SurveySelectedQuestionReducer.reducer;