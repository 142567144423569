import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";

function ToggleMicButton({micIsOn, handleMicChange, resetSpeech, isMicrophoneAvailable}) {
    const handleToggle = () => {
        const newState = !micIsOn;
        handleMicChange(newState);
    };

    return (
        <>
            <span className={`px-sm-1 ${!micIsOn ? 'micOff' : 'micOn'}`} onClick={handleToggle}>
                <FontAwesomeIcon icon={!micIsOn ? "fa-solid fa-microphone-slash" : "fa-solid fa-microphone"}/>
            </span>
            <span onClick={resetSpeech} className="micOn">
                <FontAwesomeIcon icon={faRotate} />
            </span>
            {!isMicrophoneAvailable && <span>Mic is not available.</span>}
        </>
    );
}

export default ToggleMicButton;