import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons/faAdd";
import Col from "react-bootstrap/Col";
import {faArrowDownAZ, faArrowUpAZ} from "@fortawesome/free-solid-svg-icons";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons/faGripVertical";
import Row from "react-bootstrap/Row";
import {faCircleMinus} from "@fortawesome/free-solid-svg-icons/faCircleMinus";

const DraggableList = ({items, onDragEnd, handleFormChange, removeFields, addFields, sorted, handleSorting}) => {
    return (
        <>
            <div className="draggableItem mt-2">
                <Row className="draggableItem">
                    <Col className="mx-sm-1">Choices ({items.length})</Col>
                    <Col xs={8} className="noPadding">
                        <Button onClick={handleSorting} className="mx-sm-1 float-end buttonCss">
                            <FontAwesomeIcon icon={sorted ? faArrowUpAZ : faArrowDownAZ}/>
                            <span> Rearrange Alphabetically</span>
                        </Button>
                        <Button onClick={addFields} className="mx-sm-1 float-end buttonCss"><FontAwesomeIcon
                            icon={faAdd}/><span> Add</span></Button>
                    </Col>
                </Row>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided) => (
                                            <div className="draggableItemChild" ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}>
                                                <span>
                                                    <FontAwesomeIcon icon={faGripVertical}/>
                                                </span>
                                                <input
                                                    className="form-control inputFormat"
                                                    type="text"
                                                    name='displayValue'
                                                    placeholder='Dropdown Title'
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={item.displayValue}/>
                                                <span onClick={() => removeFields(index)} className="deleteIcon">
                                                    <FontAwesomeIcon icon={faCircleMinus}/>
                                                </span>
                                            </div>

                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
};

export default DraggableList;
