import { useState, useCallback, useEffect } from 'react';
import useAxiosFunction from "./AxiosHook";
import axios from "../apis/AxiosInstance";

export const useAuth = () => {
    const [token, setToken] = useState(false);
    const [userId, setUserId] = useState(false);
    const [response, error, loading, axiosFetch] = useAxiosFunction();

    const login = useCallback(( token, expirationDate, uid) => {
        setToken(token);
        setUserId(uid);

        let userData = {
            'userId': uid,
            'token' : token
        };

        localStorage.setItem(
            'userData',
            JSON.stringify(userData)
        );
    }, []);

    const logout = useCallback(() => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        if(user && user.userId) {
            callLogout(user.userId);
        }
        setToken(null);
        setUserId(null);
        localStorage.removeItem('userData');
        localStorage.removeItem('userDetail');
        localStorage.removeItem("dateRange");
        localStorage.removeItem("endDate");
        localStorage.removeItem("startDate");
        localStorage.removeItem("idle_time_2");
        localStorage.removeItem("selectedNavigation");
        localStorage.removeItem("chartRef");

    }, []);

    const callLogout = async (userId) => {
        if(userId) {
            await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/logout',
                requestConfig: {
                    "data": {
                        "id": userId
                    }
                }
            });
        }
    };

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (
            storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(storedData.token, new Date(storedData.expiration, storedData.userId));
        }
    }, [login]);

    return { token, login, logout, userId };
};