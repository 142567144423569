import React, {useContext, useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import {
    convertStringToNumber, determineRange, getAverageValue,
    getChartData, getUserAccessToUrl,
    searchArray,
    sortArrayData,
    sortNumberArrayData
} from "../../commom/CommonFunction";
import {
    getCurrentDateOnly, getDate90DaysBack,
    getDateInUTC,
    getDateInUTCTwoMonthBack,
    getWeekRangeForGivenDate,
    getWeekRanges
} from "../../commom/DateFormat";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {useNavigate} from "react-router-dom";
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";


const CardContainer = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const endDate = getCurrentDateOnly();
    const [siteSortByDec, setSiteSortByDec] = useState("");
    const [siteSortByAce, setSiteSortByAce] = useState("");
    const [sitesData, setSitesData] = useState("");
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [surveysData, setSurveysData] = useState();

    const getPlantSurveyQuality = () => {
        let surveys = ["Linen Quality & Availability", "Client Satisfaction"];
        let sites = [];
        sitesData.map((siteObj) => {
            let data = {...siteObj};
            sites.push(data.site);
        });

        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantSurveyByDates',
                requestConfig: {
                    data: {
                        "site": sites,
                        "survey": surveys,
                        "startDate": getDate90DaysBack(endDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            getSurveySpecificValue(fetchedValue);
        });
    };

    const getSurveySpecificValue = (fetchedValue) => {
        let surveyDataArray = [];
        let weekRange = getWeekRanges(getDate90DaysBack(endDate), endDate);

        fetchedValue.forEach((fetchedValueObj) => {
            let surveyObj = Object.assign({}, fetchedValueObj, {surveyDetail: []});

            fetchedValueObj.surveyDetail.map((detailObj) => {
                let detail = {...detailObj};

                if (fetchedValueObj.survey === "Linen Quality & Availability" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Quality") {
                        surveyObj["Quality"] = determineRange(detail.value);
                        if(surveyObj["Quality"]) {
                            surveyObj["QualityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Quantity") {
                        surveyObj["Availability"] = determineRange(detail.value);
                        if(surveyObj["Availability"]) {
                            surveyObj["QuantityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["QualityDate"] = detail.value;
                    }

                    if (detail.fieldName === "Department") {
                        surveyObj["QualityDept"] = detail.value;
                    }

                    if (detail.fieldName === "Visitation Date") {
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        surveyObj["QuantityDate"] = detail.value;
                        if(weekData) {
                            surveyObj["QuantityWeek"] = "w" + weekData["index"];
                            surveyObj["QuantityWeekRange"] = weekData["data"];
                        }
                    }
                } else if (fetchedValueObj.survey === "Client Satisfaction" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Overall Performance") {
                        surveyObj["Overall Performance"] = determineRange(detail.value);
                        if(surveyObj["Overall Performance"]) {
                            surveyObj["ClientSatCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["ClientSatDate"] = detail.value;
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        if(weekData) {
                            surveyObj["ClientSatWeek"] = "w" + weekData["index"];
                            surveyObj["ClientSatWeekRange"] = weekData["data"];
                        }
                    }

                    if (detail.fieldName === "Contact Title/Dept") {
                        surveyObj["ClientSatDept"] = detail.value;
                    }
                }
            });

            if (surveyObj.status === 2) {
                surveyDataArray.push(surveyObj);
            }
        });

        return getSiteData(surveyDataArray);
    };

    const getSiteData = (fetchedValue) => {
        let newArray = [];
        let plantArr = [];
        let plantArray = [];
        let key = "site";

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            newArray.push(objData)
        });

        newArray.forEach((plantObject) => {
            // Add unique object in plantArray with new key as "chart"
            if (plantArr.indexOf(plantObject[key]) < 0) {
                plantArr.push(plantObject[key]);

                plantObject["qualityChart"] = [];
                plantObject["quantityChart"] = [];
                plantObject["clientSatChart"] = [];
                plantObject["QualityCountValue"] = 0;
                plantObject["QuantityCountValue"] = 0;
                plantObject["ClientSatCountValue"] = 0;
                plantObject["QualityAvgValue"] = 0;
                plantObject["QuantityAvgValue"] = 0;
                plantObject["ClientSatAvgValue"] = 0;

                if (plantObject.QualityDate) {
                    let qualityChart = {
                        "date": plantObject.QualityDate,
                        "Quality": plantObject.Quality,
                        "count": 1,
                        "referenceValue": 7
                    };
                    let quantityChart = {
                        "date": plantObject.QualityDate,
                        "Availability": plantObject.Availability,
                        "count": 1,
                        "referenceValue": 7
                    };

                    plantObject["qualityChart"].push(qualityChart);
                    plantObject["quantityChart"].push(quantityChart);
                    plantObject["QualityCountValue"] = 1;
                    plantObject["QuantityCountValue"] = 1;
                }

                if (plantObject.ClientSatDate) {
                    let clientSatChart = {
                        "date": plantObject.ClientSatDate,
                        "Overall Performance": plantObject["Overall Performance"],
                        "count": 1,
                        "referenceValue": 7
                    };
                    plantObject["clientSatChart"].push(clientSatChart);
                    plantObject["ClientSatCountValue"] = 1;
                }

                plantArray.push(plantObject);
            } else {
                const resultObject = searchArray(plantObject[key], plantArray, key);
                let date = "";
                if (plantObject.QualityDate) {
                    date = (plantObject.QualityDate);
                }

                let clientDate = "";
                if (plantObject.ClientSatDate) {
                    clientDate = (plantObject.ClientSatDate);
                }

                if (date) {
                    const qualityChartObject = searchArray(date, resultObject["qualityChart"], "date");
                    const quantityChartObject = searchArray(date, resultObject["quantityChart"], "date");

                    if (qualityChartObject) {
                        qualityChartObject.Quality = (qualityChartObject.Quality) + (plantObject.Quality);
                        qualityChartObject.count += 1;
                    }
                    if (quantityChartObject) {
                        quantityChartObject.Availability = (quantityChartObject.Availability) + (plantObject.Availability);
                        quantityChartObject.count += 1;
                    } else if (!qualityChartObject && !quantityChartObject && plantObject.QualityDate) {
                        let qualityChart = {
                            "date": (plantObject.QualityDate),
                            "Quality": (plantObject.Quality),
                            "count": 1,
                            "referenceValue": 7
                        };
                        let quantityChart = {
                            "date": (plantObject.QualityDate),
                            "Availability": (plantObject.Availability),
                            "count": 1,
                            "referenceValue": 7
                        };

                        resultObject["qualityChart"].push(qualityChart);
                        resultObject["quantityChart"].push(quantityChart);
                    }

                    resultObject.Quality = convertStringToNumber(plantObject.Quality) + convertStringToNumber(resultObject.Quality);
                    resultObject.Availability = convertStringToNumber(plantObject.Availability) + convertStringToNumber(resultObject.Availability);
                    resultObject.QualityCountValue += 1;
                    resultObject.QuantityCountValue += 1;
                }

                if (clientDate) {
                    const clientSatChartObject = searchArray(clientDate, resultObject["clientSatChart"], "date");

                    if (clientSatChartObject) {
                        clientSatChartObject["Overall Performance"] = (clientSatChartObject["Overall Performance"]) + (plantObject["Overall Performance"]);
                        clientSatChartObject.count += 1;
                    } else if (!clientSatChartObject && plantObject.ClientSatDate) {
                        let clientSatChart = {
                            "date": (plantObject.ClientSatDate),
                            "Overall Performance": (plantObject["Overall Performance"]),
                            "count": 1,
                            "referenceValue": 7
                        };

                        resultObject["clientSatChart"].push(clientSatChart);
                    }

                    resultObject["Overall Performance"] = convertStringToNumber(plantObject["Overall Performance"]) + convertStringToNumber(resultObject["Overall Performance"]);
                    resultObject.ClientSatCountValue += 1;
                }
            }
        });

        return addChartDataWithAvgCount(plantArray);
    };

    const addChartDataWithAvgCount = (fetchedValue) => {
        fetchedValue.forEach((plantObject) => {
            if (plantObject.qualityChart && plantObject.qualityChart.length) {
                plantObject.qualityChart = getChartData(plantObject.qualityChart, "Quality");
            }
            if (plantObject.quantityChart && plantObject.quantityChart.length) {
                plantObject.quantityChart = getChartData(plantObject.quantityChart, "Availability");
            }
            if (plantObject.clientSatChart && plantObject.clientSatChart.length) {
                plantObject.clientSatChart = getChartData(plantObject.clientSatChart, "Overall Performance");
            }
        });

        return updateSiteData(fetchedValue);
    };

    const updateSiteData = (fetchedValue, idnTotal) => {
        let plantSortData = sortArrayData(fetchedValue, "site");

        let sortedSiteDataInDecendingOrder = [];
        let sortedSiteDataInAcendingOrder = [];

        plantSortData.forEach((siteSortObj) => {
            let avgQuality = (convertStringToNumber(siteSortObj.Quality)) / siteSortObj.QualityCountValue;
            let avgQuantity = (convertStringToNumber(siteSortObj.Availability)) / siteSortObj.QuantityCountValue;
            let avgClient = (convertStringToNumber(siteSortObj["Overall Performance"])) / siteSortObj.ClientSatCountValue;
            siteSortObj.QualityAvgValue = isNaN(avgQuality) ? "" : avgQuality;
            siteSortObj.QuantityAvgValue = isNaN(avgQuantity) ? "" : avgQuantity;
            siteSortObj.ClientSatAvgValue = isNaN(avgClient) ? "" : avgClient
        });

        let qualityAvgValue = (sortNumberArrayData(plantSortData, "QualityAvgValue"));
        let quantityAvgValue = (sortNumberArrayData(plantSortData, "QuantityAvgValue"));
        let clientSatAvgValue = (sortNumberArrayData(plantSortData, "ClientSatAvgValue"));

        let index = 0;
        while (index < 6) {
            let qualityObj = "";
            let quantityObj = "";
            let clientStatObj = "";

            if (3 > index) {
                qualityObj = getAverageValue(qualityAvgValue[index], "QualityAvgValue", "qualityChart");
                quantityObj = getAverageValue(quantityAvgValue[index], "QuantityAvgValue", "quantityChart");
                clientStatObj = getAverageValue(clientSatAvgValue[index], "ClientSatAvgValue", "clientSatChart");

                if (qualityObj || quantityObj || clientStatObj) {
                    let obj = {
                        "siteQuality": qualityObj.site,
                        "quality": qualityObj.avg,
                        "siteQuantity": quantityObj.site,
                        "quantity": quantityObj.avg,
                        "siteClientStat": clientStatObj.site,
                        "clientStat": clientStatObj.avg,
                        "qualityChart": qualityObj.chartData,
                        "quantityChart": quantityObj.chartData,
                        "clientSatChart": clientStatObj.chartData
                    };

                    sortedSiteDataInDecendingOrder.push(obj);
                }
            } else {
                qualityObj = getAverageValue(qualityAvgValue[qualityAvgValue.length - index + 2], "QualityAvgValue", "qualityChart");
                quantityObj = getAverageValue(quantityAvgValue[quantityAvgValue.length - index + 2], "QuantityAvgValue", "quantityChart");
                clientStatObj = getAverageValue(clientSatAvgValue[clientSatAvgValue.length - index + 2], "ClientSatAvgValue", "clientSatChart");

                if (qualityObj || quantityObj || clientStatObj) {
                    let obj = {
                        "siteQuality": qualityObj.site,
                        "quality": qualityObj.avg,
                        "siteQuantity": quantityObj.site,
                        "quantity": quantityObj.avg,
                        "siteClientStat": clientStatObj.site,
                        "clientStat": clientStatObj.avg,
                        "qualityChart": qualityObj.chartData,
                        "quantityChart": quantityObj.chartData,
                        "clientSatChart": clientStatObj.chartData
                    };

                    sortedSiteDataInAcendingOrder.push(obj);
                }
            }
            index++;
        }
        setSiteSortByDec(sortedSiteDataInDecendingOrder);
        setSiteSortByAce(sortedSiteDataInAcendingOrder);

        if (idnTotal) {
            plantSortData.unshift(idnTotal[0]);
        }
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    useEffect(() => {
        dispatch(showDateInHeader(false));
        dispatch(setDateRange());

        getUserDetailWithMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            setSurveysData(response.surveys);
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setSitesData(response.sites)
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    useEffect(() => {
        if (endDate && sitesData && surveysData && surveysData.length && surveysData.length > 0) {
            getPlantSurveyQuality();
        }
    }, [sitesData, surveysData]);

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <table className="fixedHeaderTable">
                    <thead>
                    <tr>
                        <th colSpan="2" className="columnWidthEqual">Quality Top 3 Sites</th>
                        <th colSpan="2" className="columnWidthEqual">Availability Top 3 Sites</th>
                        <th colSpan="2" className="columnWidthEqual">Client Sat Top 3 Sites</th>
                    </tr>
                    <tr>
                        <th>Site</th>
                        <th>90 days Avg</th>
                        <th>Site</th>
                        <th>90 days Avg</th>
                        <th>Site</th>
                        <th>90 days Avg</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(siteSortByDec && siteSortByDec.length) ?
                        siteSortByDec.map((siteSortByDecData, index) => {
                            return (
                                <>
                                    <tr key={index + 1 + siteSortByDecData.siteQuality} className="subChartHeader">
                                        <td key={index + 2 + "siteQuality"} className="text-center">{siteSortByDecData.siteQuality}</td>
                                        <td key={index + 3 + "siteQualityQuality"} className="text-center">{siteSortByDecData.quality}</td>

                                        <td className="text-center" key={index + 2 + "siteQuantity"}>{siteSortByDecData.siteQuantity}</td>
                                        <td className="text-center" key={index + 2 + "siteQuantityQuantity"}>{siteSortByDecData.quantity}</td>

                                        <td className="text-center" key={index + 2 + "siteClientStat"}>{siteSortByDecData.siteClientStat}</td>
                                        <td className="text-center" key={index + 2 + "siteClientStatClientStat"}>{siteSortByDecData.clientStat}</td>
                                    </tr>
                                    <tr key={index + 7 + siteSortByDecData.siteQuantity}>
                                        <td colSpan="2" className="pieChartClass" >
                                            {(siteSortByDecData.qualityChart && siteSortByDecData.qualityChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByDecData.qualityChart} label="Quality"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                        <td colSpan="2" className="pieChartClass">
                                            {(siteSortByDecData.quantityChart && siteSortByDecData.quantityChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByDecData.quantityChart} label="Availability"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                        <td colSpan="2" className="pieChartClass">
                                            {(siteSortByDecData.clientSatChart && siteSortByDecData.clientSatChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByDecData.clientSatChart} label="Overall Performance"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                        :
                        (<tr>
                            <td colSpan="6" className="text-center">NO DATA AVAILABLE</td>
                        </tr>)
                    }
                    </tbody>
                </table>
                <table className="fixedHeaderTable">
                    <thead>
                    <tr>
                        <th colSpan="2" className="columnWidthEqual">Quality Bottom 3 Sites</th>
                        <th colSpan="2" className="columnWidthEqual">Availability Bottom 3 Sites</th>
                        <th colSpan="2" className="columnWidthEqual">Client Sat Bottom 3 Sites</th>
                    </tr>
                    <tr>
                        <th>Site</th>
                        <th>90 days Avg</th>
                        <th>Site</th>
                        <th>90 days Avg</th>
                        <th>Site</th>
                        <th>90 days Avg</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(siteSortByAce && siteSortByAce.length) ?
                        siteSortByAce.map((siteSortByAceData, index) => {
                            return (
                                <>
                                    <tr key={index + 11 + siteSortByAceData.siteQuality} className="subChartHeader">
                                        <td className="text-center">{siteSortByAceData.siteQuality}</td>
                                        <td className="text-center">{siteSortByAceData.quality}</td>

                                        <td className="text-center">{siteSortByAceData.siteQuantity}</td>
                                        <td className="text-center">{siteSortByAceData.quantity}</td>

                                        <td className="text-center">{siteSortByAceData.siteClientStat}</td>
                                        <td className="text-center">{siteSortByAceData.clientStat}</td>
                                    </tr>
                                    <tr  key={index + 21 +siteSortByAceData.siteQuantity}>
                                        <td colSpan="2" className="pieChartClass">
                                            {(siteSortByAceData.qualityChart && siteSortByAceData.qualityChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByAceData.qualityChart} label="Quality"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                        <td colSpan="2" className="pieChartClass">
                                            {(siteSortByAceData.quantityChart && siteSortByAceData.quantityChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByAceData.quantityChart} label="Availability"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                        <td colSpan="2" className="pieChartClass">
                                            {(siteSortByAceData.clientSatChart && siteSortByAceData.clientSatChart.length > 0) &&
                                            <BarChartWithTopLabel data={siteSortByAceData.clientSatChart} label="Overall Performance"
                                                        referenceValue="7" legendFlag={false}
                                                        lineColor="#008000" name="date"/>
                                            }
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                        :
                        (<tr>
                            <td colSpan="6" className="text-center">NO DATA AVAILABLE</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </Row>
        </Container>
    )
};

export default CardContainer;