import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    checkValue,
    getActivePlantConfigArray, getActiveSelectedArray,
    getUserAccessToUrl,
    searchArrayCaseInsensitive, specificArrayData
} from "../../commom/CommonFunction";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import './CustomerServicePerformance.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverviewContainer from "./OverviewContainer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {lastSelectedValue} from "../../reducer/CustomerStatReducer";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import PlantReportModal from "../facilitySurveyDashboard/PlantReportModal";
import Loader from "../../commom/LoaderComponent";
import {getDateInUTC} from "../../commom/DateFormat";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {setAllSite} from "../../reducer/SiteAllReducer";
import {setSite} from "../../reducer/SiteReducer";
import {list} from "../../reducer/FrequencyReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import SearchInputWithIcon from "../../commom/SearchInputWithIcon";
import {setSurveyData} from "../../reducer/SurveyReducer";
import CheckboxInput from "../../commom/CheckboxInput";
import {exportSurveyData, exportToExcelData} from "../../commom/ExportToExcel";
import {utilizationReportData} from "../../commom/CommonReportFunction";
import DataExport from "../reports/ReportContainer";
import LeadershipReportModal from "./LeadershipReportModal";

const CustomerServicePerformance = () => {
    const [samList, setSamList] = useState("");
    const [siteList, setSiteList] = useState("");
    const [siteData, setSiteData] = useState("");
    const dispatch = useDispatch();
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const customerStatValue = useSelector((state) => state.customerStatReducer);
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const [tableData, setTableData] = useState("");
    const [showPlantModal, setShowPlantModal] = useState(false);
    const [selectedSam, setSelectedSam] = useState("");
    const [filteredValue, setFilteredValue] = useState("");
    const [filterBySite, setFilterBySite] = useState("");
    const [sitesData, setSitesData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [inactiveUser, setInactiveUser] = useState();
    const [activeSam, setActiveSam] = useState();
    const [allActiveSam, setAllActiveSam] = useState();
    const [visitFrequencyList, setVisitFrequencyList] = useState();
    const [masterList, setMasterList] = useState();
    const [userData, setUserData] = useState("");
    const [surveyList, setSurveyList] = useState("");
    const [isSamChecked, setIsSamChecked] = useState(true);
    const [isSiteChecked, setIsSiteChecked] = useState(false);
    const [surveysData, setSurveysData] = useState();

    const [exportReportData, setExportReportData] = useState();
    const [tableReportData, setTableReportData] = useState();
    const [showReportTable, setShowReportTable] = useState(false);

    useEffect(() => {
        dispatch(showDateInHeader(true));
        getInactiveUsers().then((userRes) => {
            let userArray = [];

            (userRes).forEach((userValue) => {
                let name = userValue.firstName + " " + userValue.lastName;
                userArray.push(name);
            });

            setInactiveUser(userArray);
        });

        getUserDetailWithSiteAndMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            setSurveyList(response.surveys);
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            let activeVisitFreq = getActivePlantConfigArray("Visit Frequency", "type", response.masterData, "value");
            setVisitFrequencyList(activeVisitFreq);

            let visitFreqArray = specificArrayData(activeVisitFreq, "value");
            let siteWithActiveFreq = getActiveSelectedArray(visitFreqArray, "visitFrequency", response.sites);

            setSitesData(siteWithActiveFreq);
            setUserData(response._doc);
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
            dispatch(list(response.masterData));
            setMasterList(response.masterData);
            dispatch(setSurveyData(response.surveys));
            setSurveysData(response.surveys);
        });
        dispatch(setDateRange());
    }, []);

    const getAllActiveSam = () => {
        if (userData.securityRole === "Admin") {
            let samFromMasterList = getActivePlantConfigArray("Sam", "type", masterList, "value");
            let samArray = specificArrayData(samFromMasterList, "value");
            let samArrayFromSite = specificArrayData(sitesData, "sam");

            let activeSamList = [];
            samArray.forEach((samName) => {
                if (inactiveUser.indexOf(samName) === -1) {
                    activeSamList.push(samName);
                }
            });

            let unAssignedSam = [];
            activeSamList.forEach((activeSamName) => {
                if (samArrayFromSite.indexOf(activeSamName) === -1) {
                    unAssignedSam.push(activeSamName);
                }
            });

            setAllActiveSam(unAssignedSam);
            return unAssignedSam;
        }

        return false;
    };

    const getInactiveUsers = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getInactiveUsers'
        });
        return await response.data;
    };

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithSiteAndMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    useEffect(() => {
        if (isDateChanged && sitesData && surveyList && surveyList.length) {
            getSiteSurvey();
        }
    }, [isDateChanged, sitesData, surveyList]);

    const getSiteSurvey = () => {
        let surveyArray = ["Linen Quality & Availability", "Client Satisfaction", "Linen Awareness", "Linen Loss", "Onsite Linen Inspection", "QBR", "PAR",
            "Activities", "Client Satisfaction - No distribution"];

        let surveys = [];
        surveyList.forEach((surveyObj) => {
            if(surveyArray.indexOf(surveyObj.title) !== -1 && surveyObj.active) {
                surveys.push(surveyObj.title);
            }
        });

        let sites = [];
        sitesData.map((siteObj) => {
            let data = {...siteObj};
            sites.push(data.site);
        });

        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getCustomerServiceStatsForSite',
                requestConfig: {
                    data: {
                        "sites": sites,
                        "survey": surveys,
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            let activeUser = [];
            fetchedValue.map((samObj) => {
                let samObject = Object.assign({}, samObj);

                if (inactiveUser.indexOf(samObject.sam) === -1 && samObject.siteStatus != "Closed") {
                    activeUser.push(samObj);
                }
            });

            let activeSamData = getAllActiveSam();
            setSamList(activeUser);
            setSiteList(getSamList(activeUser, activeSamData, filteredValue, filterBySite));
        });
    };

    const camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        });
    };

    const getRealTimePercent = (countEUTotalRealtime, countEUDoneRealtime) => {
        let countEURealtime = 0;
        if (countEUTotalRealtime !== 0) {
            countEURealtime = (countEUTotalRealtime / countEUDoneRealtime) * 100;
            countEURealtime = countEURealtime.toFixed(2);
            if (countEURealtime.endsWith(".00")) {
                countEURealtime = parseInt(countEURealtime, 10);
            }
        }
        return countEURealtime
    };

    const getSamList = (fetchedValue, activeSamData, samSearchedData, siteSearchData) => {
        let samData = [];
        let samNameArray = [];

        fetchedValue.forEach((obj) => {
            let siteObj = Object.assign({}, obj, {"open": false, "active": true});

            if (samNameArray.indexOf(siteObj.sam) === -1) {
                samNameArray.push(siteObj.sam);
                // Create new sam with frequencyTypes and add site to it
                let samObject = Object.assign({}, siteObj, {"frequencyTypes": [], "displayMode": "sam"});
                let frequencyTypeObject = {};
                let samObjectFrequencyValue = camelize(siteObj.visitFrequency);

                visitFrequencyList.forEach((visitFrequencyObj) => {
                    let visitFrequencyValue = camelize(visitFrequencyObj.value);

                    if (samObjectFrequencyValue === visitFrequencyValue) {
                        frequencyTypeObject = Object.assign({}, siteObj, {
                            "sites": [],
                            "displayMode": "freq",
                            "visitFrequency": visitFrequencyValue
                        });
                    }
                });

                let siteObject = Object.assign({}, siteObj);
                frequencyTypeObject.sites.push(siteObject);
                samObject.frequencyTypes.push(frequencyTypeObject);
                samData.push(samObject);
            } else {
                const resultObject = searchArrayCaseInsensitive(siteObj.sam, samData, "sam");
                const resultFrequencyObject = searchArrayCaseInsensitive(siteObj.visitFrequency, resultObject.frequencyTypes, "visitFrequency");
                let siteObject = Object.assign({}, siteObj);

                // Add site in the existing frequencyTypes of sam
                if (resultFrequencyObject) {
                    resultFrequencyObject.sites.push(siteObject);
                } else {
                    // Create frequencyTypes in the existing sam and add site
                    let frequencyTypeObject = {};
                    let samObjectFrequencyValue = camelize(siteObj.visitFrequency);

                    visitFrequencyList.forEach((visitFrequencyObj) => {
                        let visitFrequencyValue = camelize(visitFrequencyObj.value);

                        if (samObjectFrequencyValue === visitFrequencyValue) {
                            frequencyTypeObject = Object.assign({}, siteObj, {
                                "sites": [],
                                "displayMode": "freq",
                                "visitFrequency": visitFrequencyValue
                            });
                        }

                    });

                    resultObject.frequencyTypes.push(frequencyTypeObject);
                    frequencyTypeObject.sites.push(siteObject);
                }
            }
        });
        let enterpriseObj = selectedLabel("Enterprise", true, fetchedValue);
        samData.unshift(enterpriseObj);

        if (customerStatValue.displayMode) {
            selectedLabel("", true, fetchedValue);
        }

        if (samSearchedData === "") {
            filterSamName("", samData, false, activeSamData, siteSearchData);
        } else {
            filterSamName(samSearchedData, samData, true, activeSamData, siteSearchData);
        }

        return samData;
    };

    const selectedLabel = (event, flag = false, samData = []) => {
        let data = "Enterprise", displayMode = "all", frequency = "enterprise";
        let nameAttr = "";
        let checkBoxName = [];
        let downloadSiteData = [];
        let facilities = 0, countActSurvey = 0, countCS = 0, countEU = 0, countLL = 0, countLinenAwareness = 0,
            countPAR = 0, countQA = 0, countQBR = 0, requiredCS = 0, requiredCV = 0, requiredEU = 0, requiredLL = 0,
            requiredPAR = 0, requiredQA = 0, countClientStatDecline = 0, countEURealtime = 0, dataForSite = "",
            samVisitEntered = 0, countEUTotalRealtime = 0, countEUDoneRealtime = 0;
        if (!flag) {
            nameAttr = event.currentTarget.getAttribute("name");
            checkBoxName = nameAttr.split(',');
            data = checkBoxName[0];
            displayMode = checkBoxName[1];
            frequency = checkBoxName[2] ? checkBoxName[2] : "";
            dispatch(lastSelectedValue({
                sam: data,
                displayMode: displayMode,
                visitFrequency: frequency
            }));
            dataForSite = samList;
        } else {
            if (!event) {
                data = customerStatValue.sam;
                displayMode = customerStatValue.displayMode ? customerStatValue.displayMode : "all";
                frequency = customerStatValue.visitFrequency;
            }
            dataForSite = samData;
        }

        if (displayMode === "facility") {
            let siteData = searchArrayCaseInsensitive(data, dataForSite, "site");
            siteData.countEURealtime = getRealTimePercent(siteData.countEUTotalRealtime, siteData.countEUDoneRealtime);
            downloadSiteData.push(siteData.site);
            setTableData(siteData);
            setSelectedSam(downloadSiteData);
            return true;
        } else if (displayMode === "all" && flag) {
            dataForSite.forEach(obj => {
                let siteObj = Object.assign({}, obj);
                facilities = facilities + checkValue(siteObj.facilities);
                countActSurvey = countActSurvey + checkValue(siteObj.countActSurvey);
                samVisitEntered = samVisitEntered + checkValue(siteObj.samVisitEntered);
                countCS = countCS + checkValue(siteObj.countCS);
                countEU = countEU + checkValue(siteObj.countEU);
                countLL = countLL + checkValue(siteObj.countLL);
                countLinenAwareness = countLinenAwareness + checkValue(siteObj.countLinenAwareness);
                countPAR = countPAR + checkValue(siteObj.countPAR);
                countQA = countQA + checkValue(siteObj.countQA);
                countQBR = countQBR + checkValue(siteObj.countQBR);
                requiredCS = requiredCS + checkValue(siteObj.requiredCS);
                requiredCV = requiredCV + checkValue(siteObj.requiredCV);
                requiredEU = requiredEU + checkValue(siteObj.requiredEU);
                requiredLL = requiredLL + checkValue(siteObj.requiredLL);
                requiredPAR = requiredPAR + checkValue(siteObj.requiredPAR);
                requiredQA = requiredQA + checkValue(siteObj.requiredQA);
                countClientStatDecline = countClientStatDecline + checkValue(siteObj.countClientStatDecline);
                countEURealtime = countEURealtime + checkValue(siteObj.countEURealtime);
                countEUTotalRealtime = countEUTotalRealtime + checkValue(siteObj.countEUTotalRealtime);
                countEUDoneRealtime = countEUDoneRealtime + checkValue(siteObj.countEUDoneRealtime);
            });
        } else if (displayMode === "all" && !flag) {
            dataForSite.forEach(obj => {
                let siteObj = Object.assign({}, obj);
                facilities = facilities + checkValue(siteObj.facilities);
                countActSurvey = countActSurvey + checkValue(siteObj.countActSurvey);
                samVisitEntered = samVisitEntered + checkValue(siteObj.samVisitEntered);
                countCS = countCS + checkValue(siteObj.countCS);
                countEU = countEU + checkValue(siteObj.countEU);
                countLL = countLL + checkValue(siteObj.countLL);
                countLinenAwareness = countLinenAwareness + checkValue(siteObj.countLinenAwareness);
                countPAR = countPAR + checkValue(siteObj.countPAR);
                countQA = countQA + checkValue(siteObj.countQA);
                countQBR = countQBR + checkValue(siteObj.countQBR);
                requiredCS = requiredCS + checkValue(siteObj.requiredCS);
                requiredCV = requiredCV + checkValue(siteObj.requiredCV);
                requiredEU = requiredEU + checkValue(siteObj.requiredEU);
                requiredLL = requiredLL + checkValue(siteObj.requiredLL);
                requiredPAR = requiredPAR + checkValue(siteObj.requiredPAR);
                requiredQA = requiredQA + checkValue(siteObj.requiredQA);
                countClientStatDecline = countClientStatDecline + checkValue(siteObj.countClientStatDecline);
                countEURealtime = countEURealtime + checkValue(siteObj.countEURealtime);
                countEUTotalRealtime = countEUTotalRealtime + checkValue(siteObj.countEUTotalRealtime);
                countEUDoneRealtime = countEUDoneRealtime + checkValue(siteObj.countEUDoneRealtime);
            });
        } else if (displayMode === "sam") {
            dataForSite.forEach(obj => {
                let siteObj = Object.assign({}, obj);

                if (siteObj.sam === data) {
                    downloadSiteData.push(siteObj.site);
                    facilities = facilities + checkValue(siteObj.facilities);
                    countActSurvey = countActSurvey + checkValue(siteObj.countActSurvey);
                    samVisitEntered = samVisitEntered + checkValue(siteObj.samVisitEntered);
                    countCS = countCS + checkValue(siteObj.countCS);
                    countEU = countEU + checkValue(siteObj.countEU);
                    countLL = countLL + checkValue(siteObj.countLL);
                    countLinenAwareness = countLinenAwareness + checkValue(siteObj.countLinenAwareness);
                    countPAR = countPAR + checkValue(siteObj.countPAR);
                    countQA = countQA + checkValue(siteObj.countQA);
                    countQBR = countQBR + checkValue(siteObj.countQBR);
                    requiredCS = requiredCS + checkValue(siteObj.requiredCS);
                    requiredCV = requiredCV + checkValue(siteObj.requiredCV);
                    requiredEU = requiredEU + checkValue(siteObj.requiredEU);
                    requiredLL = requiredLL + checkValue(siteObj.requiredLL);
                    requiredPAR = requiredPAR + checkValue(siteObj.requiredPAR);
                    requiredQA = requiredQA + checkValue(siteObj.requiredQA);
                    countClientStatDecline = countClientStatDecline + checkValue(siteObj.countClientStatDecline);
                    countEURealtime = countEURealtime + checkValue(siteObj.countEURealtime);
                    countEUTotalRealtime = countEUTotalRealtime + checkValue(siteObj.countEUTotalRealtime);
                    countEUDoneRealtime = countEUDoneRealtime + checkValue(siteObj.countEUDoneRealtime);
                }
            });
            setSelectedSam(downloadSiteData);
        } else if (displayMode === "freq") {
            dataForSite.forEach(obj => {
                let siteObj = Object.assign({}, obj);

                if (siteObj.sam === data && (siteObj.visitFrequency).toLowerCase() === frequency.toLowerCase()) {
                    downloadSiteData.push(siteObj.site);
                    facilities = facilities + checkValue(siteObj.facilities);
                    countActSurvey = countActSurvey + checkValue(siteObj.countActSurvey);
                    samVisitEntered = samVisitEntered + checkValue(siteObj.samVisitEntered);
                    countCS = countCS + checkValue(siteObj.countCS);
                    countEU = countEU + checkValue(siteObj.countEU);
                    countLL = countLL + checkValue(siteObj.countLL);
                    countLinenAwareness = countLinenAwareness + checkValue(siteObj.countLinenAwareness);
                    countPAR = countPAR + checkValue(siteObj.countPAR);
                    countQA = countQA + checkValue(siteObj.countQA);
                    countQBR = countQBR + checkValue(siteObj.countQBR);
                    requiredCS = requiredCS + checkValue(siteObj.requiredCS);
                    requiredCV = requiredCV + checkValue(siteObj.requiredCV);
                    requiredEU = requiredEU + checkValue(siteObj.requiredEU);
                    requiredLL = requiredLL + checkValue(siteObj.requiredLL);
                    requiredPAR = requiredPAR + checkValue(siteObj.requiredPAR);
                    requiredQA = requiredQA + checkValue(siteObj.requiredQA);
                    countClientStatDecline = countClientStatDecline + checkValue(siteObj.countClientStatDecline);
                    countEURealtime = countEURealtime + checkValue(siteObj.countEURealtime);
                    countEUTotalRealtime = countEUTotalRealtime + checkValue(siteObj.countEUTotalRealtime);
                    countEUDoneRealtime = countEUDoneRealtime + checkValue(siteObj.countEUDoneRealtime);
                }
            });
            setSelectedSam(downloadSiteData);
        }

        countEURealtime =  getRealTimePercent(countEUTotalRealtime, countEUDoneRealtime);

        let siteObject = {
            "requiredScorecard": "",
            "sam": data,
            "site": "",
            "siteId": "",
            "siteStatus": "",
            "visitFrequency": frequency,
            "facilities": facilities,
            "displayMode": displayMode,
            "countActSurvey": checkValue(countActSurvey),
            "countCS": checkValue(countCS),
            "countEU": checkValue(countEU),
            "countEURealtime": countEURealtime,
            "countLL": checkValue(countLL),
            "countLinenAwareness": checkValue(countLinenAwareness),
            "countPAR": checkValue(countPAR),
            "countQA": checkValue(countQA),
            "countQBR": checkValue(countQBR),
            "requiredCS": checkValue(requiredCS),
            "requiredCV": checkValue(requiredCV),
            "requiredEU": checkValue(requiredEU),
            "requiredLL": checkValue(requiredLL),
            "requiredPAR": checkValue(requiredPAR),
            "requiredQA": checkValue(requiredQA),
            "countClientStatDecline": checkValue(countClientStatDecline),
            "samVisitEntered" : checkValue(samVisitEntered)
        };

        setTableData(siteObject);
        return siteObject;
    };

    const filterSamName = (event, samData, flag = true, activeSamData, siteSearchData, isSearch = true) => {
        let value = "";
        let newArray = [];
        let newSamArray = [];

        if (flag) {
            value = event;
            newArray = samData;
            if (activeSamData !== "" && allActiveSam && allActiveSam.length) {
                allActiveSam.forEach((activeSamObject) => {
                    if (value && (activeSamObject).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        newSamArray.push(activeSamObject);
                    } else if (value === "") {
                        newSamArray.push(activeSamObject);
                    }
                });
            }
        } else {
            samData.map((obj) => {
                let siteObj = Object.assign({}, obj);
                value = event;
                if (value && (siteObj.sam).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    newArray.push(siteObj);
                } else if (value === "") {
                    newArray.push(siteObj);
                }
            });

            if (activeSamData && activeSamData.length) {
                activeSamData.forEach((activeSamObject) => {
                    value = event;
                    if (value && (activeSamObject).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        newSamArray.push(activeSamObject);
                    } else if (isSearch && value === "") {
                        newSamArray.push(activeSamObject);
                    }
                });
            }
        }

        if (newArray && newArray.length > 0) {
            let sortData = sortArrayData(newArray, "sam");
            setSiteData(sortData);
        } else {
            sortArrayData(newArray);
        }

        if (newSamArray && newSamArray.length > 0) {
            let sortActiveSamArray = newSamArray.sort();
            setActiveSam(sortActiveSamArray);
        } else {
            setActiveSam(newSamArray);
        }
    };

    const handleCheckboxChange = (newChecked, name) => {
        if(name === "Sam") {
            setIsSamChecked(newChecked);
        } else if(name === "Facility") {
            setIsSiteChecked(newChecked)
        }
    };

    useEffect(() => {
        if(samList && samList.length && samList.length > 0) {
            let siteValue = "", samValue = "";

            if (isSiteChecked) {
                siteValue = filterBySite;
            }

            if (isSamChecked) {
                samValue = filteredValue;
            }

            let siteDataArray = commonFilterFunction(siteValue, samValue, samList);

            if(isSiteChecked && siteValue !== "") {
                setSiteList(getSearchedSamList(siteDataArray, allActiveSam, samValue, siteValue));
            } else {
                setSiteList(getSamList(siteDataArray, allActiveSam, samValue, siteValue));
            }
        }
    }, [isSiteChecked, isSamChecked]);

    const filterBySamName = (filterData) => {
        setFilteredValue(filterData);
        setFilterBySite(filterData);
        let siteValue = "", samValue = "";

        if(isSiteChecked) {
            siteValue = filterData;
        }
        if(isSamChecked) {
            samValue = filterData;
        }

        let siteDataArray = commonFilterFunction(siteValue, samValue, samList);

        if(isSiteChecked && siteValue !== "") {
            setSiteList(getSearchedSamList(siteDataArray, allActiveSam, samValue, siteValue));
        } else {
            setSiteList(getSamList(siteDataArray, allActiveSam, samValue, siteValue));
        }
    };

    const getSearchedSamList = (fetchedValue, activeSamData, samSearchedData, siteSearchData) => {
        let samData = [];
        let samNameArray = [];

        fetchedValue.forEach((obj) => {
            let siteObj = Object.assign({}, obj, {"open": true, "active": true});

            if (samNameArray.indexOf(siteObj.sam) === -1) {
                samNameArray.push(siteObj.sam);
                // Create new sam with frequencyTypes and add site to it
                let samObject = Object.assign({}, siteObj, {"frequencyTypes": [], "displayMode": "sam"});
                let frequencyTypeObject = {};
                let samObjectFrequencyValue = camelize(siteObj.visitFrequency);

                visitFrequencyList.forEach((visitFrequencyObj) => {
                    let visitFrequencyValue = camelize(visitFrequencyObj.value);

                    if (samObjectFrequencyValue === visitFrequencyValue) {
                        frequencyTypeObject = Object.assign({}, siteObj, {
                            "sites": [],
                            "displayMode": "freq",
                            "visitFrequency": visitFrequencyValue
                        });
                    }
                });

                let siteObject = Object.assign({}, siteObj);
                frequencyTypeObject.sites.push(siteObject);
                samObject.frequencyTypes.push(frequencyTypeObject);
                samData.push(samObject);
            } else {
                const resultObject = searchArrayCaseInsensitive(siteObj.sam, samData, "sam");
                const resultFrequencyObject = searchArrayCaseInsensitive(siteObj.visitFrequency, resultObject.frequencyTypes, "visitFrequency");
                let siteObject = Object.assign({}, siteObj);

                // Add site in the existing frequencyTypes of sam
                if (resultFrequencyObject) {
                    resultFrequencyObject.sites.push(siteObject);
                } else {
                    // Create frequencyTypes in the existing sam and add site
                    let frequencyTypeObject = {};
                    let samObjectFrequencyValue = camelize(siteObj.visitFrequency);

                    visitFrequencyList.forEach((visitFrequencyObj) => {
                        let visitFrequencyValue = camelize(visitFrequencyObj.value);

                        if (samObjectFrequencyValue === visitFrequencyValue) {
                            frequencyTypeObject = Object.assign({}, siteObj, {
                                "sites": [],
                                "displayMode": "freq",
                                "visitFrequency": visitFrequencyValue
                            });
                        }

                    });

                    resultObject.frequencyTypes.push(frequencyTypeObject);
                    frequencyTypeObject.sites.push(siteObject);
                }
            }
        });
        let enterpriseObj = selectedLabel("Enterprise", true, fetchedValue);
        samData.unshift(enterpriseObj);

        if (customerStatValue.displayMode) {
            selectedLabel("", true, fetchedValue);
        }

        if (samSearchedData === "") {
            filterSamName("", samData, false, activeSamData, siteSearchData, false);
        } else {
            filterSamName(samSearchedData, samData, true, activeSamData, siteSearchData);
        }

        return samData;
    };

    const commonFilterFunction = (filterSite, filterSam, data) => {
        let siteDataArray = [];

        data.forEach((samListObj) => {
            let siteObj = Object.assign({}, samListObj);
            let siteValue = (siteObj.site).toLowerCase();
            let samValue = (siteObj.sam).toLowerCase();

            if (filterSite && filterSam &&
                (siteValue.indexOf(filterSite.toLowerCase()) !== -1 || samValue.indexOf(filterSam.toLowerCase()) !== -1)) {
                siteDataArray.push(siteObj);
            }
            if (filterSite === "" &&
                (filterSam && samValue.indexOf(filterSam.toLowerCase()) !== -1)) {
                siteDataArray.push(siteObj);
            }
            if (filterSite && siteValue.indexOf(filterSite.toLowerCase()) !== -1 &&
                (filterSam === "")) {
                siteDataArray.push(siteObj);
            }
            if (filterSite === "" && filterSam === "") {
                siteDataArray.push(siteObj);
            }
        });

        return siteDataArray;
    };

    const sortArrayData = (newArray, sortValue) => {
        let enterpriseObj = "";
        let arrayWithoutEnterprise = [];
        newArray.forEach((obj) => {
            if (obj.sam === "Enterprise") {
                enterpriseObj = obj;
            } else {
                arrayWithoutEnterprise.push(obj);
            }
        });

        arrayWithoutEnterprise.sort(function (a, b) {
            return (a[sortValue]).localeCompare(b[sortValue])
        });

        arrayWithoutEnterprise.forEach((samObj) => {
            if (samObj.frequencyTypes && samObj.frequencyTypes.length > 0) {
                (samObj.frequencyTypes).sort(function (a, b) {
                    return (a["visitFrequency"]).localeCompare(b["visitFrequency"])
                });
            }
        });

        if (enterpriseObj) {
            arrayWithoutEnterprise.unshift(enterpriseObj);
        }

        return arrayWithoutEnterprise;
    };

    const toggleOpen = (event) => {
        let nameAttr = event.currentTarget.getAttribute("name");
        let toggleValue = nameAttr.split(',');
        let sam = toggleValue[0];
        let displayMode = toggleValue[1];
        let frequency = toggleValue[2] ? toggleValue[2] : "";

        const newData = [];
        siteData.map(obj => {
            let siteObj = Object.assign({}, obj);

            if (siteObj.sam === sam && displayMode === "sam") {
                siteObj.open = !siteObj.open;
            } else if (siteObj.sam === sam && displayMode === "freq") {
                siteObj.frequencyTypes.forEach(freqObj => {
                    if (freqObj.visitFrequency === frequency) {
                        freqObj.open = !freqObj.open;
                    }
                })
            }

            newData.push(siteObj);
        });

        let sortData = sortArrayData(newData, "sam");
        setSiteData(sortData);
    };

    const handleClose = () => {
        setShowPlantModal(false);
    };

    const showModal = () => {
        setShowPlantModal(true);
    };

    const exportAllSam = () => {
        exportToExcelData(exportReportData);
    };

    const showLeaderShipModal = () => {
        let data = [
            {0: 'Sam', 1: 'Facility', 2: '', 3: '', 4: 'Client Sat', 5: '', 6: '', 7: 'Linen Quality & Availability', 8: '', 9: '', 10: 'Onsite Linen Inspection', 11: '', 12: 'Linen Loss Reviews', 13: '', 14: 'PAR Reviews', 15: '', 16: 'Other Conducted', 17: ''},
            {0: 'Sam', 1: 'Facility Assigned', 2: 'Visits Required', 3: 'SAM Visit Entered', 4: 'Required', 5: 'Completed', 6: 'Declined', 7: 'Required', 8: 'Completed', 9: '% Realtime', 10: 'Required', 11: 'Completed', 12: 'Required', 13: 'Completed', 14: 'Required', 15: 'Completed', 16: 'QBR', 17: 'Linen Aware'},
        ];
        const uniqueSamData = {};
        samList.forEach(item => {
            const sam = item.sam;
            if (!uniqueSamData[sam]) {
                uniqueSamData[sam] = { ...item }; // Initialize with the first occurrence
            } else {
                // Accumulate values for each key
                for (const key in item) {
                    if (key !== 'sam' && key !== 'requiredScorecard' && key !== 'site' && key !== 'siteId' && key !== 'siteStatus' && key !== 'visitFrequency' && key !== 'displayMode') {
                        uniqueSamData[sam][key] += item[key];
                    }
                }
            }
        });

        // Convert object to array
        const uniqueSamArray = Object.values(uniqueSamData);

        let samArrayData = [];
        uniqueSamArray.forEach(item => {
            let countEURealtime =  getRealTimePercent(item.countEUTotalRealtime, item.countEUDoneRealtime);
            let objData = {
                0: item.sam,
                1: item.facilities,
                2: item.requiredCV,
                3: item.samVisitEntered,
                4: item.requiredCS,
                5: item.countCS,
                6: item.countClientStatDecline,
                7: item.requiredEU,
                8: item.countEU,
                9: countEURealtime,
                10: item.requiredQA,
                11: item.countQA,
                12: item.requiredLL,
                13: item.countLL,
                14: item.requiredPAR,
                15: item.countPAR,
                16: item.countQBR,
                17: item.countLinenAwareness
            };
            samArrayData.push(objData);
            data.push(objData);
        });
        setTableReportData(samArrayData);
        setExportReportData(data);
        setShowReportTable(true);
    };

    const hideLeaderShipModal = () => {
        setShowReportTable(false);
    };

    return (
        <div>
            <Loader isLoading={loading}/>
            <Row>
                {surveysData && surveysData.length > 0 &&
                <PlantReportModal showPlantModal={showPlantModal} handleClose={handleClose} isFacilityView={false}
                                  samValue={selectedSam} surveysArray={surveysData}/>
                }

                <LeadershipReportModal tableData={tableReportData} showReportTable={showReportTable}
                                       hideLeaderShipModal={hideLeaderShipModal} exportAllSam={exportAllSam}/>
                <Col xs={3} className="leftContainer">
                    <div className="samHeader"><label className="form-check-label">Assigned SAM</label></div>
                    <div className="divCheckbox">
                        Filter By
                        <CheckboxInput
                            label="Sam"
                            isChecked={isSamChecked}
                            onChange={handleCheckboxChange}
                        />
                        <CheckboxInput
                            label="Facility"
                            isChecked={isSiteChecked}
                            onChange={handleCheckboxChange}
                        />
                    </div>

                    <SearchInputWithIcon placeHolder="Search" filterFunction={filterBySamName}/>
                    <div>
                        {
                            (siteData && siteData.length > 0) &&
                            siteData.map(function (samObj, index) {
                                return (
                                    <div className="" key={samObj.sam + "div"}>
                                        <ul className="ulTree">
                                            <li key={index + samObj.sam + "li"}>
                                            <span onClick={toggleOpen} name={samObj.sam + "," + samObj.displayMode}
                                                  className="p-1">
                                                {samObj.open && samObj.displayMode !== "all" &&
                                                <FontAwesomeIcon icon={faMinusSquare}/>}
                                                {!samObj.open && samObj.displayMode !== "all" &&
                                                <FontAwesomeIcon icon={faPlusSquare}/>}
                                                {samObj.displayMode === "all" &&
                                                <span className="enterprisePadding"/>}
                                            </span>
                                                <span onClick={selectedLabel}
                                                      name={samObj.sam + "," + samObj.displayMode}>
                                                <label className="form-check-label" htmlFor={samObj.sam}>
                                                    {samObj.sam}
                                                </label>
                                            </span>
                                            </li>
                                            {
                                                samObj.open &&
                                                <ul className="nested" key={index + "ul"}>
                                                    {samObj.frequencyTypes && samObj.frequencyTypes.length &&
                                                    (samObj.frequencyTypes).map((subModule) => {
                                                        return (
                                                            <li key={subModule.visitFrequency + subModule.sam}>
                                                                <span onClick={toggleOpen}
                                                                      name={subModule.sam + "," + subModule.displayMode + "," + subModule.visitFrequency}
                                                                      className="p-1">
                                                                    {subModule.open &&
                                                                    <FontAwesomeIcon icon={faMinusSquare}/>}
                                                                    {!subModule.open &&
                                                                    <FontAwesomeIcon icon={faPlusSquare}/>}
                                                                </span>
                                                                <span onClick={selectedLabel}
                                                                      name={subModule.sam + "," + subModule.displayMode + "," + subModule.visitFrequency}>
                                                                    <label className="form-check-label"
                                                                           htmlFor={subModule.visitFrequency}>
                                                                        {subModule.visitFrequency}
                                                                    </label>
                                                                </span>
                                                                <ul className="nested">
                                                                    {
                                                                        subModule.open &&
                                                                        subModule.sites && subModule.sites.length &&
                                                                        (subModule.sites).map((subSubModule) => {
                                                                            return (
                                                                                <li key={subSubModule.siteId}>
                                                                                    <span onClick={selectedLabel}
                                                                                          name={subSubModule.site + "," + subSubModule.displayMode}>
                                                                                    <FontAwesomeIcon icon={faArrowRight}
                                                                                                     className=""/>
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor={subSubModule.site}>
                                                                                            {subSubModule.site}
                                                                                        </label>
                                                                                    </span>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                        }

                        {
                            (activeSam && activeSam.length > 0) &&
                            <div className="samHeader"><label className="form-check-label">Unassigned SAM</label></div>
                        }
                        {
                            (activeSam && activeSam.length > 0) &&
                            activeSam.map(function (samObj, index) {
                                return (
                                    <div className="" key={samObj + "div"}>
                                        <ul className="ulTree">
                                            <li key={index + samObj + "li"}>
                                            <span name={samObj + ",sam"} className="p-1">
                                                <FontAwesomeIcon icon={faMinusSquare}/>
                                            </span>
                                                <span onClick={selectedLabel} name={samObj + ",sam"}>
                                                <label className="form-check-label" htmlFor={samObj}>
                                                    {samObj}
                                                </label>
                                            </span>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col xs={9}>
                    <OverviewContainer data={tableData} showModal={showModal} exportAllSam={exportAllSam} showLeaderShipModal={showLeaderShipModal}/>
                </Col>
            </Row>
        </div>
    )
};

export default CustomerServicePerformance;