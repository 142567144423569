import { createSlice } from '@reduxjs/toolkit'

export const DateAppliedReducer = createSlice({
    name: "DateAppliedReducer",
    initialState: {
        isDateChanged: true
    },
    reducers: {
        updateDataFlag: (state, action) => {
            state.isDateChanged = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {updateDataFlag} = DateAppliedReducer.actions;

export default DateAppliedReducer.reducer;