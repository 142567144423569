import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MultiSelect from "../../commom/MultiSelect";

const SurveyReportForm = (props) => {
    return (
        <form className="formContainer w-100 p-3">
            <Row>
            <div className="form-group col-sm-6">
                <label className="col-form-label" htmlFor="plant">Plant</label>
                <div ref={props.innerRef}>
                    <input type="text"
                           className="form-control"
                           onClick={props.handleClick} readOnly={true}
                           value={`${props.formValue.plantCount} selected`} name="plant"/>
                    {
                        props.formValue.showPlant &&
                        <div className="selectDiv text-start px-sm-1">
                            <MultiSelect show={props.formValue.showPlant} data={props.plantList}
                                         handleCheckboxChange={props.handleCheckboxChange}
                                         allSelected={props.formValue.selectAllPlant} label="plant"
                                         handleSearch={props.handleSearch}/>
                        </div>
                    }
                </div>
            </div>
            <div className="form-group col-sm-6">
                <label className="col-form-label" htmlFor="site">Site</label>
                <div ref={props.innerRefSite}>
                    <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                           value={`${props.formValue.siteCount} selected`} name="site"/>
                    {
                        props.formValue.showSite &&
                        <div className="selectDiv text-start px-sm-1">
                            <MultiSelect show={props.formValue.showSite} data={props.siteList}
                                         handleCheckboxChange={props.handleCheckboxChange}
                                         allSelected={props.formValue.selectAllSite} label="site"
                                         handleSearch={props.handleSearch}/>
                        </div>
                    }
                </div>
            </div>
            </Row>
            {/*<div className="row mb-1">
                <Col xs={12}>
                    <label className="col-form-label">Select Fields to view in report :</label>
                </Col>
                <Row>
                    {props.config && props.config.length > 0 && props.config.map((obj, index) => {
                        const {title, value} = obj;

                        return (
                            <Col key={index + title} xs={3}>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={title}
                                        data-label={title}
                                        checked={value}
                                        onChange={props.handleConfigCheckboxChange}
                                    />
                                    <label className="form-check-label"
                                           data-label={title}
                                    onClick={props.handleConfigCheckboxChange}>{title}</label>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>*/}
            <div className="mx-2 mt-2">
                <Button className="formButton mx-1 buttonCss" onClick={props.showSurveyData}>
                    Show Report
                </Button>
                <Button className="formButton buttonCss" onClick={props.exportSurveyDataArray}>
                    Export Report
                </Button>
            </div>
        </form>
    );
};

export default SurveyReportForm;
