import React from "react";
import {useSelector} from "react-redux";
import CustomTable from "../../commom/CustomTable";

function ItemCategoryList(props) {
    const tableData = useSelector((state) => state.frequencyReducer);
    const screen = useSelector((state) => state.plantDropdownReducer.screen);

    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "value",
            header: screen
        },
        {
            name: "isActive",
            header: "Active"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <>
            {
                tableData && tableData.length &&
                <CustomTable rowHeader={rowHeader}
                             tableData={(tableData).filter(value => value.type === screen)}
                             selectedRow={props.selectedRow}/>
            }
        </>
    );
}

export default ItemCategoryList;