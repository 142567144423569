import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

const OverviewContainer = (props) => {
    return(
        <div className="column">
            <div>
                {props.data.displayMode=='sam' &&
                <div className="containerHeader addCursorPointer" onClick={props.showModal}>{props.data.sam}</div>
                }
                {props.data.displayMode=='all' &&
                <div className="containerHeader" onClick={props.showLeaderShipModal}>{props.data.sam}
                    <span className="p-1"><FontAwesomeIcon icon={faFileExport}/></span>
                </div>
                }
                {props.data.displayMode=='freq' &&
                <div className="containerHeader addCursorPointer" onClick={props.showModal}>{props.data.sam} {props.data.visitFrequency}</div>
                }
                {props.data.displayMode=='facility' &&
                <div className="containerHeader addCursorPointer" onClick={props.showModal}>{props.data.site} {props.data.siteId}</div>
                }
                <div className="sectionContent">
                    <table className="sam-dashboard">
                        <thead>
                        <tr className="heading">
                            <th colSpan="3">Facility</th>
                            <th colSpan="3">Client Sat</th>
                            <th colSpan="3">Linen Quality & Availability</th>
                            <th colSpan="2">Onsite Linen Inspection</th>
                            <th colSpan="2">Linen Losss reviews</th>
                            <th colSpan="2">PAR Reviews</th>
                            <th colSpan="2">Other Conducted</th>
                        </tr>
                        <tr>
                            <th className="left-border">Facilities Assigned</th>
                            <th>Visits Rqd</th>
                            <th>SAM Visit Entered</th>
                            <th className="left-border">Rqd</th>
                            <th>Complt</th>
                            <th>Declined</th>
                            <th className="left-border">Rqd</th>
                            <th>Complt</th>
                            <th>% Realtime</th>
                            <th className="left-border">Rqd</th>
                            <th>Complt</th>
                            <th className="left-border">Rqd</th>
                            <th>Complt</th>
                            <th className="left-border">Rqd</th>
                            <th>Complt</th>
                            <th className="left-border">QBR</th>
                            <th className="right-border">Linen Aware</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="left-border">{props.data.facilities}</td>
                            <td>{props.data.requiredCV}</td>
                            <td>{props.data.samVisitEntered}</td>
                            <td className="left-border">{props.data.requiredCS}</td>
                            <td>{props.data.countCS}</td>
                            <td>{props.data.countClientStatDecline}</td>
                            <td className="left-border">{props.data.requiredEU}</td>
                            <td>{props.data.countEU}</td>
                            <td>{props.data.countEURealtime}</td>
                            <td className="left-border">{props.data.requiredQA}</td>
                            <td>{props.data.countQA}</td>
                            <td className="left-border">{props.data.requiredLL}</td>
                            <td>{props.data.countLL}</td>
                            <td className="left-border">{props.data.requiredPAR}</td>
                            <td>{props.data.countPAR}</td>
                            <td className="left-border">{props.data.countQBR}</td>
                            <td className="right-border">{props.data.countLinenAwareness}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default OverviewContainer;