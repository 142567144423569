import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const FacilitySiteReducer = createSlice({
    name: "FacilitySiteReducer",
    initialState,
    reducers: {
        setFacilitySite: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        },
    }
});
export const {setFacilitySite} = FacilitySiteReducer.actions;

export default FacilitySiteReducer.reducer;