import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './Survey.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {updateSurvey} from "../../reducer/SurveyReducer";
import SurveyComputedList from "./SurveyComputedList";
import SurveyComputedForm from "./SurveyComputedForm";
import {selectedSurvey} from "../../reducer/SurveySelectionReducer";
import {setSurveyComputedData} from "../../reducer/SurveyComputedReducer";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {useOuterClick} from "../../hook/VisibilityHook";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import ConfirmationModal from "../../commom/ConfirmationModal";
import {getSelectedAllFlag, searchArray, updateMultiSelectSearch} from "../../commom/CommonFunction";
const { v4: uuidv4 } = require('uuid');

const SurveyCompute = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const questionArray = useSelector((state) => state.surveyQuestionReducer);
    const selectedSurveyData = useSelector((state) => state.surveySelectionReducer);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [questionList, setQuestionList] = useState();
    const [questionAllList, setQuestionAllList] = useState();

    const [showData, setShowData] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [selectAllData, setSelectAllData] = useState(false);
    const [id, setId] = useState();
    const [showCompute, setShowCompute] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [readOnly, setReadonly] = useState(false);

    const [formValue, setFormValue] = useState({
        title: "",
        description: "",
        type: "",
        fieldName: "",
        numerator: "",
        denominator: "",
        computation: "",
        active: false,
        _id: uuidv4(),
        showComputation: false,
        selectAllComputation: false,
        computationCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        title: true,
        type: true,
        fieldName: true
    });
    const fieldValidation = {
        title: "string",
        type: "select",
        fieldName: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const innerRef = useOuterClick(e => {
        setFormValue({...formValue, showComputation: false});
    });
    useEffect(() => {
        cancelHandler();
    },[]);

    useEffect(() => {
        if(questionArray && questionArray.length) {
            updateMultiSelectOption();
        }
    }, [questionArray]);

    const updateMultiSelectOption = () => {
        let updatedArray = [];
        questionArray.map((obj) => {
            let data;
            data = obj.title;

            let option = {
                label: data,
                value: false
            };

            updatedArray.push(option);
        });
console.log(updatedArray)
        setQuestionList(updatedArray);
        setQuestionAllList(updatedArray);
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        setQuestionList(newData);
        setFormValue({...formValue, selectAllComputation: checked, computationCount: count});
    };

    const handleClick = () => {
        let flag = formValue.showIdn;

        if(!flag) {
            let data = updateMultiSelectSearch(questionAllList, "label", "", questionList);
            setQuestionList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, showComputation: !flag, selectAllComputation: selectedAll});
        } else {
            setFormValue({...formValue, showComputation: !flag});

        }
    };

    const handleSearch = (event) => {
        const {value} = event.currentTarget;
        let data = updateMultiSelectSearch(questionAllList, "label", value, questionList);
        setQuestionList(data);
        let selectedAll = getSelectedAllFlag(data);
        setFormValue({...formValue, selectAllComputation: selectedAll});
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleChange = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if(event.currentTarget.name === "type") {
            if(event.currentTarget.value === 'Divide Two Survey Answers') {
                setShowCompute(true)
            } else {
                setShowCompute(false)
            }
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const selectedValue = (selectedData) => {
        let count = 0;
        let selectData = [];
        let arrayList = questionList;
        let flag = true;

        const newData = arrayList.map(obj => {
            let data = Object.assign({}, obj);

            let matchedValue = selectedData.find(dataObj => {
                let subData = Object.assign({}, dataObj);

                if (subData.label === obj.label) {
                    return subData
                }
            });

            if(matchedValue) {
                data.value = true;
                selectData.push(data);
                count += 1;
                return data;
            } else {
                data.value = false;
                flag = false;
            }

            return data;
        });

        setFormValue({...formValue, computation: selectData});
        setQuestionList(newData);
        setDataCount(count);
        if(flag) {
            setSelectAllData(flag);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setReadonly(true);
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);

        setShowMessage(false);
        setButtonTitle("UPDATE");
        setId(value._id);
        resetValue();

        if(value.type == 'Divide Two Survey Answers') {
            setShowCompute(true);
        } else {
            setShowCompute(false);
            updateMultiSelectOption();
            selectedValue(value.computation);

        }
        formInputValueSetup(true, value);
    };

    const resetValue = () => {
        let selectData = [];
        let arrayList;

        arrayList = questionList;

        if(arrayList) {
            const newData = arrayList.map(obj => {
                let data = Object.assign({}, obj);
                data.value = false;
                return data;
            });

            setFormValue({...formValue, computation: selectData});
            setSelectAllData(false);
            setQuestionList(newData);
            setDataCount(0);
        }
    };

    const cancelHandler = () => {
        setReadonly(false);
        setId("");
        setShowMessage(false);
        setButtonTitle("Add");
        resetValue();

        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(false);
        setDisableSubmit(false);
    };

    const stringGen = () => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let x = 7;
        for (let i = 0; i < x; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    const submitHandler = async() => {
        handleClose();
        let flag = false;
        let newArray = {};
        newArray._id = selectedSurveyData._id;
        newArray.active = selectedSurveyData.active;
        newArray.title = selectedSurveyData.title;
        newArray.collectionName = selectedSurveyData.collectionName;
        newArray.questions = [];
        newArray.freq = selectedSurveyData.freq;
        newArray.path = selectedSurveyData.path;
        newArray.computedFields = [];
        newArray.dateChoicesAvailable = selectedSurveyData.dateChoicesAvailable;

        let newEntry = {...formValue};
        newEntry["_id"] = stringGen();

        if(selectedSurveyData.computedFields && selectedSurveyData.computedFields.length) {
            selectedSurveyData.computedFields.forEach((obj) => {
                let data = Object.assign({}, obj);

                if (data._id === id) {
                    flag = true;
                    data.title = formValue.title;
                    data.description = formValue.description;
                    data.type = formValue.type;
                    data.fieldName = formValue.fieldName;
                    data.numerator = formValue.numerator;
                    data.denominator = formValue.denominator;
                    data.computation = formValue.computation;
                    data.active = formValue.active;
                    setId(data._id);
                }
                newArray.computedFields.push(data);
            });
        }

        if(selectedSurveyData.questions && selectedSurveyData.questions.length) {
            selectedSurveyData.questions.forEach((obj) => {
                let data = Object.assign({}, obj);
                newArray.questions.push(data);
            });
        }

        if(!flag) {
            newArray['computedFields'].push(newEntry);
            setId(newEntry._id);
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSurvey',
            requestConfig: {
                data : newArray
            }
        });

        if (response.data) {
            dispatch(updateSurvey(response.data));
            setButtonTitle("Update");
            setReadonly(true);

            if(response.data.computedFields) {
                dispatch(setSurveyComputedData(response.data.computedFields));
                dispatch(selectedSurvey(response.data));

                if(formValue._id === "" && response.data.computedFields && response.data.computedFields.length) {
                    let data = searchArray(formValue.fieldName, response.data.computedFields, "fieldName");
                    setFormValue({...formValue, "_id": data._id});
                }
            }
        }
        setShowMessage(true);
    };

    const existingValueCheck = async () => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/checkDuplicateSurveyParameter',
            requestConfig: {
                data: {
                    _id: selectedSurveyData._id,
                    title: formValue.fieldName,
                    key: "computedFields.fieldName",
                    surveyKey: "computedFields"
                }
            }
        });
        return response;
    };

    const handleClose = () => {
        setShowConfirmationBox(false);
        setDisableSubmit(false);
    };

    const handleOpen = () => {
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if(!checkValidate) {
            return false;
        }

        if(id === "") {
            existingValueCheck().then(response => {
                if(response.code === 400) {
                    setShowMessage(true);
                    return false;
                } else {
                    submitHandler();
                }
            });
        } else {
            if (formValue.active) {
                submitHandler();
            } else {
                setShowConfirmationBox(true);
            }
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {showConfirmationBox && <ConfirmationModal handleClose={handleClose} showModal={showConfirmationBox}
                                                       submitForm={submitHandler}/>}
            <Row>
                <Col sm={6}><SurveyComputedList selectedRow={selectedRow}/></Col>
                <Col sm={6}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                             <SurveyComputedForm handleOpen={handleOpen} disableSubmit={disableSubmit}
                                                cancelHandler={cancelHandler} buttonTitle={buttonTitle}
                                                handleChange={handleChange}  handleCheckboxChange={handleCheckboxChange} questionList={questionList}
                                                showData={showData} selectAllData={selectAllData} handleClick={handleClick}
                                                dataCount={dataCount} showCompute={showCompute}  formValue={formValue} formValidation={formValidation}
                                                inputValidation={inputValidation} response={response} error={error}
                                                loading={loading} showMessage={showMessage} innerRef={innerRef}
                                                 handleSearch={handleSearch} readOnly={readOnly}
                /></Col>
            </Row>
        </Container>
    )
};

export default SurveyCompute;