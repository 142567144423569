import React from 'react';
import Col from "react-bootstrap/Col";
import CustomTable from "../../commom/CustomTable";

const SummaryOfDayTable = ({tableData, tableHeader, headerName}) => {
    return (
        <Col xs={12} className="colBorder chartAreaForPdf">
            <div className="facilityHeader text-center">{headerName}</div>
            {(tableData && tableData.length > 0) ?
                <div className="summaryTable">
                    <CustomTable rowHeader={tableHeader} tableData={tableData} hideSearch={true}/>
                </div>
                :
                <div className="text-center">NO DATA AVAILABLE</div>
            }
        </Col>
    )
};

export default SummaryOfDayTable;