import React from "react";
import {Row, ListGroup} from "react-bootstrap";
import {SpeechToText} from "../../commom/SpeechToText";

const SummaryOfDayForm = (props) => {
    return (
        <>
            <Row className="mb-1">
                <div className="form-group col">
                    <label htmlFor="plant" className="col-form-label"><span className="text-danger starCss">*</span>Plant</label>
                    <div>
                        <ListGroup className="divSurveyListCSS">
                            {
                                props.plantList && props.plantList.length
                                && props.plantList.map((obj, index) => {
                                    return <ListGroup.Item name="plant" key={obj._id + index}
                                                           onClick={props.updateListSelection}
                                                           className={`listClass ${(props.formValue.plant === obj._id) ? "active" : ""}`}
                                    >{obj._id}</ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="site" className="col-form-label"><span className="text-danger starCss">*</span>Site</label>
                    <div>
                        <ListGroup className="divSurveyListCSS">
                            {
                                props.siteList && props.siteList.length
                                && props.siteList.map((obj, index) => {
                                    return <ListGroup.Item name="site" key={obj.site + index}
                                                           onClick={props.updateListSelection}
                                                           className={`listClass ${(props.formValue.site === obj.site) ? "active" : ""}`}
                                    >{obj.site}</ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    </div>
                </div>
            </Row>
            <Row className="mb-1">
                <div className="form-group col divAlignItem">
                    <label htmlFor="defaultDate" className="col-form-label" name="defaultDate"
                           onClick={props.handleCheckboxLabelClick}>Today's Summary</label>
                    <div className="d-inline-block mx-sm-1">
                        <input type="checkbox" className="form-check-input" checked={props.formValue.defaultDate}
                               name="defaultDate" onChange={props.handleChange}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="startDate" className="col-form-label">Start Date</label>
                    <div>
                        <input type="date" name="startDate"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.startDate} disabled={props.formValue.defaultDate}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="endDate" className="col-form-label">End Date</label>
                    <div>
                        <input type="date" name="endDate"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.endDate} disabled={props.formValue.defaultDate}/>
                    </div>
                </div>
            </Row>
            <Row className="mb-1">
                <div className="form-group col">
                    <label className="col-form-label" htmlFor="siteContact">Site Contact</label>
                    <div>
                        <select onChange={props.handleChange} name="siteContact"
                                value={props.formValue.siteContact} className="form-select">
                            <option value="select">Select</option>
                            {
                                props.siteEmailList && props.siteEmailList.length
                                && props.siteEmailList.map((obj, index) => {
                                    return <option key={obj.label + index}
                                                   value={obj.label}>{obj.label}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="emailAddress" className="col-form-label">Email</label>
                    <div>
                        <input type="email" name="emailAddress"
                               className={`form-control ${!props.showEmailMessage ? "errorDiv" : ""}`}
                               onChange={props.handleChange}
                               value={props.formValue.emailAddress}/>
                    </div>
                </div>

                <div className="form-group col">
                    <SpeechToText fieldName={"Contact Name"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="userName"/>
                </div>
            </Row>

            <Row className="mb-1">
                <div className="form-group col">
                    <SpeechToText fieldName={"Subject of Email"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="emailSubject"/>
                </div>

            </Row>
            <Row className="mb-1">
                <div className="form-group col">
                    <SpeechToText fieldName={"Comment"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="comment"/>
                </div>
            </Row>
        </>

    )
};

export default SummaryOfDayForm;