import {useState, useEffect, useRef, useCallback} from "react";
import {useDispatch} from "react-redux";
import {updateTokenModal} from "../reducer/TokenModalReducer";
import {setIdealTime} from "../reducer/IdealTimeOutReducer";

const useAxiosFunction = () => {
    const [response, setResponse] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); //different!
    const [controller, setController] = useState();
    const cache = useRef({});
    const dispatch = useDispatch();

    const axiosFetch = useCallback(async (configObj) => {
        const {
            axiosInstance,
            method,
            url,
            requestConfig = {}
        } = configObj;

        dispatch(setIdealTime());

            try {
                setResponse("");
                setError("");

                let user = JSON.parse(localStorage.getItem('userDetail'));
                let token = "";
                if (user) {
                    token = user.token;
                }

                let Authorization = token ? `Bearer ${token}` : '';
                axiosInstance.defaults.headers.common['Authorization'] = Authorization;

                setLoading(true);

                const ctrl = new AbortController();
                setController(ctrl);

                const res = await axiosInstance[method.toLowerCase()](url, {
                    ...requestConfig,
                    signal: ctrl.signal
                });

                setResponse(res.data);
                cache.current[url] = res.data;
                return res.data;
            } catch (err) {
                setError({"message": err.response.data.message, "code": 400});

                if(err.response.data.message === "Invalid Token" || err.response.data.message === "Invalid Token !!") {
                    dispatch(updateTokenModal(true));
                }
                return {"message": err.message, "code": 400};
            } finally {
                setLoading(false);
            }
        /*}*/
    }, []);

    useEffect(() => {

        // useEffect cleanup function
        return () => controller && controller.abort();

    }, [controller]);

    return [response, error, loading, axiosFetch];
};

export default useAxiosFunction