export const lightTheme = {
    backgroundColor: "#FFFFFF",
    textColor: "#FFD24C",
    primary: "#FFE69A",
    secondary: "#92B4EC"
};

export const darkTheme = {
    backgroundColor: "#E6E6E6",
    textColor: "#5C7AEA",
    primary: "#3D56B2",
    secondary: "#2D31FA"
};

export const partyTheme = {
    backgroundColor: "#D8E9A8",
    textColor: "#000000",
    primary: "#1E5128",
    secondary: "#4E9F3D"
};