import { createSlice } from '@reduxjs/toolkit'

export const PlantNameReducer = createSlice({
    name: "PlantNameReducer",
    initialState: {
        plant: "",
        houseCode: ""
    },
    reducers: {
        updatePlantData: (state, action) => {
            state.plant = action.payload.plant;
            state.houseCode = action.payload.houseCode;
        }
    }
});

// Action creators are generated for each case reducer function
export const {updatePlantData} = PlantNameReducer.actions;

export default PlantNameReducer.reducer;