import React from "react";
import {useDispatch} from "react-redux";
import {updatePlantDropdownScreen} from "../../reducer/PlantDropdownReducer";
import {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import Col from "react-bootstrap/Col";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import Loader from "../../commom/LoaderComponent";
import {sortArrayData} from "../../commom/CommonFunction";

const PlantDropdown = ({isMasterRole}) => {
    const [nav, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [title, setTitle] = useState("Visit Frequency");
    const [masterDropdown, setMasterDropdown] = useState("");
    const [allMasterDropdown, setAllMasterDropdown] = useState("");

    useEffect(() => {
        setTitle("Visit Frequency");
        getMasterDropdownOption().then((fetchedValue) => {
            dispatch(showMasterDateOption(true));
            setAllMasterDropdown(fetchedValue)
        })
    }, []);

    useEffect(() => {
        if(allMasterDropdown && allMasterDropdown.length) {
            if (isMasterRole) {
                let sortData = sortArrayData(allMasterDropdown, "value");
                setMasterDropdown(sortData);
            } else {
                let list = [];
                let masterDropdownOption = ["Imperfection Threshold", "Admin Email", "Master Role"];
                allMasterDropdown.forEach((obj) => {
                    let data = Object.assign({}, obj);
                    if (masterDropdownOption.indexOf(data.value) === -1) {
                        list.push(data);
                    }
                });

                let sortData = sortArrayData(list, "value");
                setMasterDropdown(sortData);
            }
        }
    }, [isMasterRole, allMasterDropdown]);

    const getMasterDropdownOption = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getMasterDropdownOption'
        });
        return await response.data;
    };

    const handleClick = (event) => {
        dispatch(updatePlantDropdownScreen(event.currentTarget.value));
        setTitle(event.currentTarget.value)
    };

    return (
        <Col md={6} className="linkCol">
            <Loader isLoading={loading}/>
            <div className="row">
                <label className="col-sm-5 col-form-label">Plant/Site Attribute Value : </label>
                <div className="col">
                    <select onChange={handleClick} name="plant"
                            value={title}  className="form-select">
                        {
                            masterDropdown && masterDropdown.length
                            && masterDropdown.map((obj, index) => {
                                return <option key={index} value={obj.value}>{obj.value}</option>
                            })
                        }

                    </select>
                </div>
            </div>
        </Col>
    );
};

export default PlantDropdown;