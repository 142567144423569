export const updateMonthDigit = (value) => {
    let month = value;
    if(typeof value === "string") {
        month = parseInt(value);
    } else {
        month = value + 1;
    }
    return month < 10 ? '0' + month: '' + month;
};

export const updateDateDigit = (date) => {
    if(typeof date === "string") {
        date = parseInt(date);
    }

    return date < 10 ? '0' + date: '' + date;
};

export const getCurrentDateOnly = () => {
    let date = new Date();

    let month =  updateMonthDigit(date.getMonth());
    let day = updateDateDigit(date.getDate());
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getPreDateOnly = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);

    let month =  updateMonthDigit(tomorrow.getMonth());
    let day = updateDateDigit(tomorrow.getDate());
    let year = tomorrow.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getNextDateOnly = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let month =  updateMonthDigit(tomorrow.getMonth());
    let day = updateDateDigit(tomorrow.getDate());
    let year = tomorrow.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getDateOnly = (dateValue) => {
    if(dateValue) {
        let date = new Date(dateValue);

        let month = updateMonthDigit(date.getMonth());
        let day = updateDateDigit(date.getDate());
        let year = date.getFullYear();

        return (!isNaN(month)) ? (year + "-" + month + "-" + day) : dateValue;
    } else {
        return dateValue;
    }
};

export const getNextDate = (dateValue) => {
    let tomorrow = new Date(dateValue);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let month =  updateMonthDigit(tomorrow.getMonth());
    let day = updateDateDigit(tomorrow.getDate());
    let year = tomorrow.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getDateByMonth = (dateValue) => {
    let date = new Date(dateValue);

    let month =  updateMonthDigit(date.getMonth());
    let day = updateDateDigit(date.getDate());
    let year = date.getFullYear();

    return (!isNaN(month)) ? (month + "-" + day + "-" + year) : dateValue;
};

// get week date range for given date
export const getWeekDateRange = (dateObj) => {
    let [year, month, day] = dateObj.split('-').map(Number);
    let currentDate = new Date(year, month - 1, day);

    let firstDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    let lastDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()+6));

    return (getDateByMonth(firstDay) + " to " +  getDateByMonth(lastDay));
};

// get week date range for given date
export const getWeekDateRangeFromStartDate = (dateObj) => {
    let [month, day, year] = dateObj.split('-').map(Number);
    let currentDate = new Date(year, month - 1, day);

    let firstDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    let lastDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()+6));

    return (getDateByMonth(firstDay) + " to " +  getDateByMonth(lastDay));
};

// get month date range for given date
export const getMonthDateRangeFromStartDate = (dateObj) => {
    let [month, day, year] = dateObj.split('-').map(Number);
    let firstDay = new Date(year, month - 1, 1);
    let lastDay = new Date(year, month, 0);
    return (getDateByMonth(firstDay) + " to " +  getDateByMonth(lastDay))
};

// get week date range for given date
export const getMonthRange = (dateObj) => {
    let [year, month, day] = dateObj.split('-').map(Number);
    let firstDay = new Date(year, month - 1, 1);
    let lastDay = new Date(year, month, 0);
    return (getDateByMonth(firstDay) + " to " +  getDateByMonth(lastDay))
};

export const monthDifference = (dateFrom, dateTo) => {
    let startDate = new Date(dateFrom);
    let endDate = new Date(dateTo);
    return (endDate.getMonth() - startDate.getMonth()) + (12 * (endDate.getFullYear() - startDate.getFullYear()));
};


export const getQuarter = (date) => {
    return date.getFullYear() + 'Q' + Math.ceil((date.getMonth()+ 1)/3);
};

export const listQuarters = (sDate, eDate) => {
    // Ensure start is the earlier date;
    if (sDate > eDate) {
        let t = eDate;
        eDate = sDate;
        sDate = t;
    }

    // Copy input start date do don't affect original
    sDate = new Date(sDate);
    // Set to 2nd of month so adding months doesn't roll over
    // and not affected by daylight saving
    sDate.setDate(2);

    // Initialise result array with start quarter
    let startQ = getQuarter(sDate);
    let endQ   = getQuarter(eDate);
    let result = [startQ];

    // List quarters from start to end
    while (startQ !== endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = getQuarter(sDate);
        result.push(startQ);
    }

    return result.length;
};

export const checkDateExistBetweenDaterange = (dateObj) => {
    let currentDate = new Date(); // get current date
    let first = currentDate.getDate() - currentDate.getDay(); // First day is the  day of the month - the day of the week
    let today = new Date(currentDate.setDate(first)).toUTCString();
    let lastDay = new Date(currentDate.setDate(currentDate.getDate()-6)).toUTCString();
    let checkDate = new Date(dateObj).toUTCString(); // get current date

    let compareDate = new Date(checkDate).setHours(0, 0, 0, 0);
    let startDate = new Date(lastDay).setHours(0, 0, 0, 0);
    let endDate = new Date(today).setHours(0, 0, 0, 0);
    return (compareDate <= endDate && compareDate >= startDate);
};

export const checkDateBetweenGivenDateRange = (dateRange, dateObj) => {
    let start, end;
    if(dateRange[0].indexOf('/') !== -1) {
        start = dateRange[0].split('/').map(Number);
        end = dateRange[1].split('/').map(Number);
    } else if (dateRange[0].indexOf('-') !== -1) {
        start = dateRange[0].split('-').map(Number);
        end = dateRange[1].split('-').map(Number);
    }
    let dateValue = getDateByMonth(dateObj);
    let newDate = dateValue.split('-').map(Number);
    let compareDate = new Date(newDate[2], newDate[0]-1, newDate[1]);
    let startDate = new Date(start[2], start[0]-1, start[1]);
    let endDate = new Date(end[2], end[0]-1, end[1]);
    return (compareDate <= endDate && compareDate >= startDate);
};

export const getWeeksBetweenDates = (date1, date2) => {
// Parse the dates and create date objects
    let [year1, month1, day1] = date1.split('-').map(Number);
    let [year2, month2, day2] = date2.split('-').map(Number);
    let d1 = new Date(Date.UTC(year1, month1 - 1, day1));
    let d2 = new Date(Date.UTC(year2, month2 - 1, day2));
// Check if the first date is after the second date and swap them if needed
    if (d1 > d2) {
        [d1, d2] = [d2, d1];
    }
// Create an empty array to store the weeks
    let weeks = [];
// Loop from the first date to the second date
    while (d1 <= d2) {
// Get the start and end date of the current week
        let start = new Date(d1);
        start.setUTCDate(start.getUTCDate() - (start.getUTCDay()) - 1); // Set to Saturday
        let end = new Date(start);
        end.setUTCDate(end.getUTCDate() + 6); // Set to Friday
// Format the dates in dd.mm.yyyy format
        let formatDate = (date) => {
            let day = date.getUTCDate().toString().padStart(2, '0');
            let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            let year = date.getUTCFullYear();
            return `${month}/${day}/${year}`;
        };
// Push the formatted dates as an array to the weeks array
        weeks.push([formatDate(start), formatDate(end)]);
// Increment the first date by one week
        d1.setUTCDate(d1.getUTCDate() + 7);
    }
// Return the weeks array
    return weeks;
};

export const getWeeksBetweenDatesEndATSat = (date1, date2) => {
// Parse the dates and create date objects
    let [year1, month1, day1] = date1.split('-').map(Number);
    let [year2, month2, day2] = date2.split('-').map(Number);
    let d1 = new Date(Date.UTC(year1, month1 - 1, day1));
    let d2 = new Date(Date.UTC(year2, month2 - 1, day2));
// Check if the first date is after the second date and swap them if needed
    if (d1 > d2) {
        [d1, d2] = [d2, d1];
    }
// Create an empty array to store the weeks
    let weeks = [];
// Loop from the first date to the second date
    while (d1 <= d2) {
// Get the start and end date of the current week
        let start = new Date(d1);
        start.setUTCDate(start.getUTCDate() - (start.getUTCDay() || 7) - 1); // Set to Saturday
        let end = new Date(start);
        end.setUTCDate(end.getUTCDate() + 6); // Set to Friday
// Format the dates in dd.mm.yyyy format
        let formatDate = (date) => {
            let day = date.getUTCDate().toString().padStart(2, '0');
            let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            let year = date.getUTCFullYear();
            return `${month}/${day}/${year}`;
        };
// Push the formatted dates as an array to the weeks array
        weeks.push([formatDate(start), formatDate(end)]);
// Increment the first date by one week
        d1.setUTCDate(d1.getUTCDate() + 7);
    }
// Return the weeks array
    return weeks;
};

export const getMonthsBetweenDates = (startDate, endDate) => {
    let start      = startDate.split('-');
    let end        = endDate.split('-');
    let startYear  = parseInt(start[0]);
    let endYear    = parseInt(end[0]);
    let dates      = [];

    for(let i = startYear; i <= endYear; i++) {
        let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        let startMon = i === startYear ? parseInt(start[1])-1 : 0;
        for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            let month = j+1;
            let displayMonth = month < 10 ? '0'+month : month;
            dates.push(formatDate(i, displayMonth, '01'));
        }
    }
    return dates;
};

export const formatDate = (year, month, day) => {
    let firstDay = new Date(year, month - 1, 1);
    let lastDay = new Date(year, month, 0);
    return [getDateByMonth(firstDay),  getDateByMonth(lastDay)];
};

export const getDateInUTC = (dateValue) => {
    if(dateValue) {
        let date = new Date(dateValue);

        if(date != "Invalid Date") {
            let splitValueOfDate = dateValue.split("-");
            let month = updateMonthDigit(splitValueOfDate[1]);
            let day = updateDateDigit((splitValueOfDate[2].split("T"))[0]);
            let year = splitValueOfDate[0];

            return year + "-" + month + "-" + day
        } else {
            return dateValue;
        }
    } else {
        return dateValue;
    }
};

export const getDateInUTCByMonth = (dateValue) => {
    if(dateValue) {
        let date = new Date(dateValue);

        if(date != "Invalid Date") {
            let splitValueOfDate = dateValue.split("-");
            let month = updateMonthDigit(splitValueOfDate[1]);
            let day = updateDateDigit((splitValueOfDate[2].split("T"))[0]);
            let year = splitValueOfDate[0];

            return month + "-" + day + "-" + year
        } else {
            return dateValue;
        }
    } else {
        return dateValue;
    }
};

export const getDateInUTCTwoMonthBack = (dateValue) => {
    if(dateValue) {
        let splitValueOfDate = getMonthEndDate();
        const today = new Date(splitValueOfDate);
        let priorDate = new Date(today.setDate(today.getDate() - 90));
        let dateValue = (priorDate.toISOString()).split("-");
        let day = updateDateDigit((dateValue[2].split("T"))[0]);

        return dateValue[0] + "-" + dateValue[1] + "-" + day;
    } else {
        return dateValue;
    }
};

export const getDate90DaysBack = (givenDateStr) => {
    // Parse the given date string
    let givenDate = new Date(givenDateStr);

    // Subtract 90 days (90 * 24 * 60 * 60 * 1000 milliseconds)
    let date90DaysBack = new Date(givenDate.getTime() - (90 * 24 * 60 * 60 * 1000));

    // Get the date in 'YYYY-MM-DD' format
    let year = date90DaysBack.getUTCFullYear();
    let month = (date90DaysBack.getUTCMonth() + 1).toString().padStart(2, '0');
    let day = date90DaysBack.getUTCDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const getDateInUTCByMonthStartDate = (dateValue) => {
    if(dateValue) {
        let date = new Date(dateValue);

        if(date != "Invalid Date") {
            let splitValueOfDate = dateValue.split("-");
            let month = updateMonthDigit(splitValueOfDate[1]);
            let year = splitValueOfDate[0];
            return month + "-0" + 1 + "-" + year
        } else {
            return dateValue;
        }
    } else {
        return dateValue;
    }
};

export const getDateInUTCByWeekStartDate = (dateValue) => {
    if(dateValue) {
        let splitValueOfDate = dateValue.split("-");
        let month = updateMonthDigit(splitValueOfDate[1]);
        let day = updateDateDigit((splitValueOfDate[2].split("T"))[0]);
        let year = splitValueOfDate[0];
        let currentDate = new Date(year, month, day);
        let firstDay = (currentDate.getDate() - currentDate.getDay());
        let firstDayOfWeek = new Date(currentDate.setDate(firstDay)).toUTCString();

        return getDateInUTCByMonth(firstDayOfWeek);
    } else {
        return dateValue;
    }
};

export const getMonthStartDate = () => {
    let date = new Date();

    let month =  updateMonthDigit(date.getMonth());
    let day = updateDateDigit(1);
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getMonthEndDate = () => {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let month =  updateMonthDigit(lastDay.getMonth());
    let day = lastDay.getDate();
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
};

export const getDateFromDatepicker = (date) => {
    if(date) {
        let month = updateMonthDigit(date.getMonth());
        let day = updateDateDigit(date.getDate());
        let year = date.getFullYear();

        return year + "-" + month + "-" + day;
    }
};

export const convertUTCToLocalDate = (date) => {
    if (!date) {
        return date
    }
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date
};

export const  convertLocalToUTCDate = (date) => {
    if (!date) {
        return date
    }
    date = new Date(date);

    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date
};

export const getDateInMonth = (dateValue) => {
    if(dateValue) {
        let splitValueOfDate = dateValue.split("-");
        let month = updateMonthDigit(splitValueOfDate[1]);
        let day = updateDateDigit((splitValueOfDate[2].split("T"))[0]);
        let year = splitValueOfDate[0];
        return month + "-" + day + "-" + year
    } else {
        return dateValue;
    }
};

export const getWeekRanges = (startDateValue, endDateValue) => {
    let [year1, month1, day1] = startDateValue.split('-').map(Number);
    let [year2, month2, day2] = endDateValue.split('-').map(Number);
    let startDate = new Date(Date.UTC(year1, month1 - 1, day1)); // Universal Time
    let endDate = new Date(Date.UTC(year2, month2 - 1, day2)); // Universal Time

    const weekRanges = [];
    let currentDate = new Date(startDate);
    let index = 1;
    // Iterate through each week until the endDate
    while (currentDate <= endDate) {
        let startOfWeek = new Date(currentDate);
        startOfWeek.setUTCDate(startOfWeek.getUTCDate() - startOfWeek.getUTCDay()); // Start of the current week (Sunday)

        // If the startOfWeek is before the startDate, set startOfWeek to startDate
        if (startOfWeek < startDate) {
            startOfWeek = new Date(startDate);
        }

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setUTCDate(endOfWeek.getUTCDate() + (6 - endOfWeek.getUTCDay())); // End of the current week (Saturday)

        // If the endOfWeek is after the endDate, set endOfWeek to endDate
        if (endOfWeek > endDate) {
            endOfWeek = new Date(endDate);
        }

        let week = "w" + index;
        weekRanges.push({ start: startOfWeek.toUTCString(), end: endOfWeek.toUTCString(), week: week });

        // Move to the start of the next week
        currentDate = new Date(endOfWeek);
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        index += 1;
    }

    return weekRanges;
};


export const getWeekRangeForGivenDate = (dateString, weekRanges) => {
    // Convert the input string to a Date object
    const dateToCheck = new Date(dateString);
    for (const week of weekRanges) {
        const startOfWeek = new Date(week.start);
        const endOfWeek = new Date(week.end);
        if (dateToCheck >= startOfWeek && dateToCheck <= endOfWeek) {
            return week;
        }
    }

    // Return false if the date does not fall within any week range
    return false;
};

export const  convertToGMT = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const dateInUTC = new Date(Date.UTC(year, month - 1, day));
    return dateInUTC;
};

export const convertToUTC = (dateString) => {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Construct the desired format
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
};

export const getFourWeeksBackUTC = (inputDateStr) => {
    // Parse the input date string to a Date object in UTC
    let inputDate = new Date(inputDateStr + 'T00:00:00Z');

    // Calculate the date exactly four weeks (28 days) before the input date in UTC
    let fourWeeksBackDate = new Date(inputDate);
    fourWeeksBackDate.setUTCDate(inputDate.getUTCDate() - 28);
    return fourWeeksBackDate.toISOString().split('T')[0];
};

export const currentWeekRange = () => {
    // Get the current date in UTC
    let now = new Date();
    let nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

    // Calculate the start (Sunday) and end (Saturday) of the current week
    let dayOfWeek = nowUTC.getUTCDay();
    let startOfWeekUTC = new Date(nowUTC);
    startOfWeekUTC.setUTCDate(nowUTC.getUTCDate() - dayOfWeek);

    let endOfWeekUTC = new Date(startOfWeekUTC);
    endOfWeekUTC.setUTCDate(startOfWeekUTC.getUTCDate() + 6);

    // Format the dates
    let startOfWeekStr = startOfWeekUTC.toISOString().split('T')[0];
    let endOfWeekStr = endOfWeekUTC.toISOString().split('T')[0];

    return {"start": startOfWeekStr, "end" : endOfWeekStr};
};

export const lastWeekRange = () => {
    // Get the current date in UTC
    let now = new Date();
    let nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

    // Calculate the start (Sunday) and end (Saturday) of the current week
    let dayOfWeek = nowUTC.getUTCDay();
    let startOfCurrentWeekUTC = new Date(nowUTC);
    startOfCurrentWeekUTC.setUTCDate(nowUTC.getUTCDate() - dayOfWeek);

    let endOfCurrentWeekUTC = new Date(startOfCurrentWeekUTC);
    endOfCurrentWeekUTC.setUTCDate(startOfCurrentWeekUTC.getUTCDate() + 6);

    // Calculate the start (Sunday) and end (Saturday) of the last week
    let startOfLastWeekUTC = new Date(startOfCurrentWeekUTC);
    startOfLastWeekUTC.setUTCDate(startOfCurrentWeekUTC.getUTCDate() - 7);

    let endOfLastWeekUTC = new Date(startOfLastWeekUTC);
    endOfLastWeekUTC.setUTCDate(startOfLastWeekUTC.getUTCDate() + 6);

    // Format the dates
    let startOfLastWeekStr = startOfLastWeekUTC.toISOString().split('T')[0];
    let endOfLastWeekStr = endOfLastWeekUTC.toISOString().split('T')[0];

    return {"start": startOfLastWeekStr, "end" : endOfLastWeekStr};
};

export const lastQuarterRange = () => {
    // Get the current date in UTC
    let now = new Date();
    let nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

    // Determine the current quarter
    let currentQuarter = Math.floor((nowUTC.getUTCMonth() + 3) / 3);

    let startOfLastQuarter;
    let endOfLastQuarter;

    // Calculate the start and end dates of the last quarter
    if (currentQuarter === 1) {
        // Last quarter is Q4 of the previous year
        startOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear() - 1, 9, 1)); // October 1
        endOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear() - 1, 11, 31)); // December 31
    } else if (currentQuarter === 2) {
        // Last quarter is Q1 of the current year
        startOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 0, 1)); // January 1
        endOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 2, 31)); // March 31
    } else if (currentQuarter === 3) {
        // Last quarter is Q2 of the current year
        startOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 3, 1)); // April 1
        endOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 5, 30)); // June 30
    } else if (currentQuarter === 4) {
        // Last quarter is Q3 of the current year
        startOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 6, 1)); // July 1
        endOfLastQuarter = new Date(Date.UTC(nowUTC.getUTCFullYear(), 8, 30)); // September 30
    }

    // Format the dates
    let startOfLastQuarterStr = startOfLastQuarter.toISOString().split('T')[0];
    let endOfLastQuarterStr = endOfLastQuarter.toISOString().split('T')[0];

    return {
        start: startOfLastQuarterStr,
        end: endOfLastQuarterStr
    };
};