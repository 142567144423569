import { useEffect, useRef } from 'react';
import {encodeSpecialCharacters} from "../commom/CommonFunction";

export const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    e.target &&
                    innerRef.current.outerHTML
                ) {
                    if(!((innerRef.current.outerHTML).includes(encodeSpecialCharacters(e.target.getAttribute("name"))))) {
                        callbackRef.current(e);
                    }
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
};