import React, {useContext, useState} from "react";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import ResetPasswordForm from "./ResetPasswordForm";
import {useSelector, useDispatch} from "react-redux";
import {updateResetForm} from "../../reducer/ModalReducer";
import Modal from 'react-bootstrap/Modal';
import Message from "../../commom/Message";
import {logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import Loader from "../../commom/LoaderComponent";

const ResetPassword = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const user = useSelector((state) => state.userState.user);
    const showResetForm = useSelector((state) => state.modalReducer.showResetForm);
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({
        oldPassword: "",
        password: "",
        newPassword: ""
    });
    const [inputValidation, setInputValidation] = useState({
        oldPassword: true,
        password: true,
        newPassword: true
    });
    const fieldValidation = {
        oldPassword: "string",
        password: "string",
        newPassword: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [showPasswordMessage, setPasswordMessage] = useState(true);

    const handleClose = () => {
        cancelHandler();
        setPasswordMessage(true);
        setShowMessage(false);
        dispatch(updateResetForm());
        localStorage.setItem('isShow', "false");
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const cancelHandler = () => {
        setShowMessage(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setPasswordMessage(true);

        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        if (formValue.newPassword  !== formValue.password) {
            setPasswordMessage(false);
            return false;
        } else {
            setPasswordMessage(true);
        }
        if (!checkValidate) {
            return false;
        }

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/resetPassword',
            requestConfig: {
                id: user._id,
                oldPassword: formValue.oldPassword,
                password: formValue.password
            }
        });

        setShowMessage(true);
        if (response.data) {
            auth.logout();
            dispatch(logOut());
            handleClose();
            navigate('/login')
        }
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={showResetForm} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading} formValidation={formValidation}
                             message='Password reset successfully!'/>
                    <ResetPasswordForm submitHandler={submitForm} handleChange={handleChange} formValue={formValue}
                                       inputValidation={inputValidation} showPasswordMessage={showPasswordMessage}
                                       formValidation={formValidation}/>
                    <small className="form-text text-muted">
                        NOTE : Once successfully submitted, will redirect to login page. Login again with new password.
                    </small>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ResetPassword;