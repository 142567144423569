import { createSlice } from '@reduxjs/toolkit'

export const userReducer = createSlice({
    name: "user",
    initialState: {
        user: {}
    },
    reducers: {
        userDetail: (state,action) => {
            state.user = action.payload.user;
        }
    }
});

export const { userDetail } = userReducer.actions;

export default userReducer.reducer;