import React from "react";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {SpeechToText} from "../../commom/SpeechToText";

const SummaryOfDayScreenForm = (props) => {

    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3 mb-1">
            <Row>
                <div className="form-group col">
                    <label htmlFor="uniqueId" className="col-form-label">
                        <span className="text-danger starCss">*</span>Plant</label>
                    <div>
                        <input type="text" name="plant"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.plant} disabled={true}/>
                        {/*<select className="form-select"
                                value={props.formValue.plant} onChange={props.inputChangeHandler}
                                name="plant" disabled={true}>
                            <option value="select">Select</option>
                            {
                                props.plantList && props.plantList.length
                                && props.plantList.map((obj, index) => {
                                    return <option key={obj._id + index} value={obj._id}>{obj._id}</option>
                                })
                            }
                        </select>*/}
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="uniqueId" className="col-form-label">
                        <span className="text-danger starCss">*</span>Site</label>
                    <div>
                        <input type="text" name="site"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.site} disabled={true}/>
                        {/*<select className="form-select"
                                value={props.formValue.site} onChange={props.inputChangeHandler}
                                name="site" disabled={true}>
                            <option value="select">Select</option>
                            {
                                props.siteList && props.siteList.length
                                && props.siteList.map((obj, index) => {
                                    return <option key={obj.site + index} value={obj.site}>{obj.site}</option>
                                })
                            }
                        </select>*/}
                    </div>
                </div>
                <div className="form-group col ">
                    <label htmlFor="startDate" className="col-form-label">Start Date</label>
                    <div>
                        <input type="date" name="startDate"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.startDate}
                               disabled={true}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="endDate" className="col-form-label">End Date</label>
                    <div>
                        <input type="date" name="endDate"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.endDate} disabled={true}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label className="col-form-label" htmlFor="siteContact">Site Contact</label>
                    <div>
                        <select onChange={props.handleChange} name="siteContact"
                                value={props.formValue.siteContact} className="form-select">
                            <option value="select">Select</option>
                            {
                                props.siteEmailList && props.siteEmailList.length
                                && props.siteEmailList.map((obj, index) => {
                                    return <option key={obj.label + index}
                                                   value={obj.label}>{obj.label}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="receiverName" className="col-form-label">Contact Name</label>
                    <div>
                        <input type="text" name="receiverName"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.receiverName}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="subjectOfEmail" className="col-form-label">Subject of Email</label>
                    <div>
                        <input type="text" name="subjectOfEmail"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.subjectOfEmail}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="senderEmail" className="col-form-label">Email</label>
                    <div>
                        <input type="email" name="senderEmail"
                               className={`form-control ${!props.showEmailMessage ? "errorDiv" : ""}`}
                               onChange={props.handleChange}
                               value={props.formValue.senderEmail}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <SpeechToText fieldName={"Comment"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="comment"
                                  inputValueData={props.formValue.comment}/>
                </div>
            </Row>
        </form>
    )
};

export default SummaryOfDayScreenForm;