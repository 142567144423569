import React, {useContext, useState} from "react";
import {Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import {useNavigate} from "react-router-dom";
import {logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useDispatch, useSelector} from "react-redux";
import {updateTokenModal} from "../../reducer/TokenModalReducer";

const  TokenModalBox = ()=> {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const showModal = useSelector(state => (state.tokenModalReducer.showTokenModal));

    const handleClose = () => {
        dispatch(updateTokenModal(false));
    };

    const navigateToLogin = () => {
        auth.logout();
        localStorage.removeItem('userDetail');
        dispatch(logOut());
        dispatch(updateTokenModal(false));
        navigate('/login')
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>Token Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Please login again as you won't be able to perform any action. Click on ok button it will take you to login page.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonDisableCss" onClick={handleClose}>
                        Close
                    </Button>
                    <Button className="buttonCss" onClick={navigateToLogin}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TokenModalBox;