import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Message from "../../commom/Message";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import PlantQASurveyForm from "./PlantQASurveyForm";
import SurveyDetailForm from "./SurveyDetailForm";
import {
    formInputChangeHandler,
    formLabelClick,
    formResetHandler,
    formValueSetup,
    handleCheckboxInputChange,
    handleInputChange,
    resetValidation
} from "../../commom/FormHandler";
import {validateForm, validateFormInputOnSave, validateFormOnSave} from "../../commom/ValidateFormData";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {
    getSelectedAllFlag,
    getUserAccessToUrl,
    getUserDataFromArray,
    plantSurveyQuestionArray,
    searchArray, searchArrayWithActiveAndShow, updateMultiSelectSearch,
} from "../../commom/CommonFunction";
import {getCurrentDateOnly, getDateInUTC} from "../../commom/DateFormat";
import {useOuterClick} from "../../hook/VisibilityHook";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {Link, useNavigate} from "react-router-dom";

const PlantQASurvey = () => {
    const dispatch = useDispatch();
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [showMessage, setShowMessage] = useState(false);
    const [siteList, setSiteList] = useState();
    const [plantList, setPlantList] = useState();
    const [surveyList, setSurveyList] = useState();
    const [config, setConfig] = useState();
    const [inputSurveyValidation, setInputSurveyValidation] = useState();
    const [surveyFormValidation, setSurveyFormValidation] = useState(true);
    const [formValue, setFormValue] = useState({
        survey: "",
        plant: "",
        site: "",
        dateStarted: getCurrentDateOnly(),
        externalUser: false,
        uniqueId: "",
        status: 0,
        clientEmail: "",
        showSiteEmail: false,
        selectAllSiteEmail: false,
        siteEmailCount: 0,
        userContactName: "",
        emailContactValue: ""
    });
    const [inputValidation, setInputValidation] = useState({});
    const fieldValidation = {};
    const [formValidationMessage, setFormValidationMessage] = useState("");
    const [formValidation, setFormValidation] = useState(true);
    const [message, setMessage] = useState("Saved Successfully.");
    const [siteEmailList, setSiteEmailList] = useState("");
    const [siteAllEmailList, setSiteAllEmailList] = useState("");
    const innerRef = useOuterClick(() => {
        setFormValue({...formValue, showSiteEmail: false});
    });
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [fieldValidationForSurvey, setFieldValidationForSurvey] = useState("");
    const [resetComment, setResetComment] = useState(true);
    const [sitesData, setSitesData] = useState();
    const [userData, setUserData] = useState();
    const [selectedSurvey, setSelectedSurvey] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [samEmail, setSamEmail] = useState("");
    const [showExternalSurvey, setShowExternalSurvey] = useState(false);
    useEffect(() => {
        dispatch(setDateRange());
        dispatch(showDateInHeader(false));

        getUserDetail().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;

            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            setSitesData(response.sites);
            setUserData(response._doc);
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setSurveyList(response.surveys);
            if (response.plants && response.plants.length) {
                setPlantList(response.plants);
                let siteData = getSites(response.plants[0]._id, response.sites);

                if (siteData && siteData.length) {
                    setSiteList(siteData);
                    setFormValue({...formValue, plant: response.plants[0]._id, site: siteData[0].site});
                    setSiteEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                    setSiteAllEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                }
            }
        });

    }, []);

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetail = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetail',
            requestConfig: {
                "data": {
                    "id": user.userId,
                    "isSam": true
                }
            }
        });
        return await response.data;
    };

    const updateMultiSelectOption = (fetchedValue, formValueSite) => {
        let siteObj = searchArray(formValueSite, fetchedValue, "site");
        let newArray = [];
        getUserFromSam(siteObj.sam).then((response) => {
            if (response[0]) {
                setSamEmail(response[0].email);
            } else {
                setSamEmail("");
            }
        });
        for (let key in siteObj) {
            if (["contactEmail1", "contactEmail2", "contactEmail3", "contactEmail4", "contactEmail5"].indexOf(key) > -1) {
                if (siteObj[key]) {
                    let contactName = "";
                    let ketLastValue = key.slice(-1);
                    if (["1", "2", "3", "4", "5"].indexOf(ketLastValue) > -1) {
                        let userKey = "contactName" + ketLastValue;
                        contactName = siteObj[userKey];
                    }
                    let obj = {
                        label: siteObj[key],
                        value: false,
                        userContactName: contactName
                    };

                    newArray.push(obj);
                }
            }
        }

        return newArray.length ? newArray : "";
    };

    const getUserFromSam = async (samName) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserFromSam',
            requestConfig: {
                data: {"samName": samName}
            }
        });

        return await response.data;
    };

    const getFormData = (title) => {
        setResetComment(true);

        async function getSurveyByTitle() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getSurveyByTitle',
                requestConfig: {
                    data: {"title": title}
                }
            });

            return await response.data;
        }

        getSurveyByTitle().then((fetchedValue) => {
            const {configData, inputValidationData, fieldValidationData} = plantSurveyQuestionArray(fetchedValue, true);
            setInputSurveyValidation(inputValidationData);
            setConfig(configData);
            setFieldValidationForSurvey(fieldValidationData);
        });
    };

    const getSites = (plantName, sitesArray) => {
        let sites = [];

        sitesArray.map(obj => {
            let plantObj = Object.assign({}, obj);
            if (plantObj.plant === plantName) {
                sites.push(plantObj);
            }
        });

        return sites;
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const updateListSelection = (event) => {
        if (event.currentTarget) {
            let eventObject = {
                "currentTarget": {
                    "value": event.currentTarget.innerText,
                    "name": event.currentTarget.getAttribute("name"),
                }
            };
            handleChange(eventObject)
        }
    };

    const handleCheckboxLabelClick = (event) => {
        const currentFormState = handleCheckboxInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const handleChange = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);
        setFormValidationMessage("");
        setFormValidation(true);
        setResetComment(false);
        const currentFormState = handleInputChange(event, formValue);
        if (event.currentTarget.name === "externalUser") {
        }
        if (event.currentTarget.name !== "plant" && event.currentTarget.name !== "survey") {
            setFormValue(currentFormState);
        }

        if (event.currentTarget.name === "plant") {
            setResetComment(true);
            let siteData = getSites(event.currentTarget.value, sitesData);

            if (siteData && siteData.length) {
                setSiteList(siteData);
                setSiteEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                setSiteAllEmailList(updateMultiSelectOption(siteData, siteData[0].site));

                setFormValue({
                    ...formValue,
                    plant: event.currentTarget.value,
                    site: siteData[0].site,
                    selectAllSiteEmail: false,
                    siteEmailCount: 0
                })
            }
        } else if (event.currentTarget.name === "survey") {
            let showExternalFlag = searchArrayWithActiveAndShow(event.currentTarget.value, surveyList, "title", "active", "sendEmail");
            if (formValue.plant) {
                if (showExternalFlag) {
                    setShowExternalSurvey(true);
                    setFormValue({
                        ...formValue,
                        survey: event.currentTarget.value
                    })
                } else {
                    setShowExternalSurvey(false);
                    setFormValue({
                        ...formValue,
                        externalUser: false,
                        survey: event.currentTarget.value
                    })
                }

                console.log(formValue.plant);

                getFormData(event.currentTarget.value);
            }
        } else if (event.currentTarget.name === "site") {
            setResetComment(true);
            setSiteEmailList(updateMultiSelectOption(siteList, event.currentTarget.value));
            setSiteAllEmailList(updateMultiSelectOption(siteList, event.currentTarget.value));
            setFormValue({...formValue, site: event.currentTarget.value, selectAllSiteEmail: false, siteEmailCount: 0})
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const validateSurveyInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputSurveyValidation, fieldValidationForSurvey);
        setSurveyFormValidation(checkValidate);
        setInputSurveyValidation(currentFormState);
    };

    const inputLabelClickHandler = (event) => {
        const currentFormState = formLabelClick(event, config);
        setConfig(currentFormState);
    };

    const inputChangeHandler = (event, flag = "") => {
        setResetComment(false);
        if (!flag) {
            setShowMessage(false);
            const configData = formInputChangeHandler(event, config);
            setConfig(configData);

            if (event.currentTarget.value && fieldValidationForSurvey.hasOwnProperty(event.currentTarget.name)) {
                validateSurveyInputValue(event);
            }
        } else {
            let newObj = {
                "currentTarget": {
                    "name": event.name,
                    "value": event.transcript
                }
            };
            setShowMessage(false);
            const configData = formInputChangeHandler(newObj, config);
            setConfig(configData);
            /*let data = {
                "target": {
                    "name": event.name,
                    "value": event.transcript
                }
            };
            if (event.transcript && fieldValidationForSurvey.hasOwnProperty(event.name)) {
                validateSurveyInputValue(data);
            }*/
        }
    };

    const cancelHandler = (isDataSubmit = false, date = "") => {
        setResetComment(true);
        setShowMessage(false);
        const configData = formResetHandler(config, isDataSubmit, date);
        setConfig(configData);

        const currentFormState = resetValidation(inputSurveyValidation);
        setSurveyFormValidation(true);
        setInputSurveyValidation(currentFormState);
    };

    const resetForm = () => {
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(false);
        cancelHandler(false, "");
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        setFormValidationMessage("");
        setFormValidation(true);

        setSiteEmailList(newData);
        setFormValue({...formValue, selectAllSiteEmail: checked, siteEmailCount: count});
    };

    const handleClick = () => {
        let flag = formValue.showSiteEmail;

        if (!flag) {
            let data = updateMultiSelectSearch(siteAllEmailList, "label", "", siteEmailList);
            setSiteEmailList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, showSiteEmail: !flag, selectAllSiteEmail: selectedAll});
        } else {
            setFormValue({...formValue, showSiteEmail: !flag});

        }
    };

    const handleSearch = (event) => {
        const {value} = event.currentTarget;
        let data = updateMultiSelectSearch(siteAllEmailList, "label", value, siteEmailList);
        setSiteEmailList(data);

        let selectedAll = getSelectedAllFlag(data);
        setFormValue({...formValue, "selectAllSiteEmail": selectedAll});
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormInputOnSave(config, inputSurveyValidation, fieldValidationForSurvey);
        setSurveyFormValidation(checkValidate);
        setInputSurveyValidation(currentFormState);
        if (!checkValidate) {
            return false;
        }

        let dateValue = getDateInUTC(formValue.dateStarted);
        config.forEach((obj) => {
            let data = Object.assign({}, obj);

            if (data.type === "Date") {
                dateValue = getDateInUTC(data.value);
            }
        });

        let newEntry = {
            _id: null,
            survey: formValue.survey,
            plant: formValue.plant,
            site: formValue.site,
            dateStarted: dateValue,
            surveyDetail: config,
            userId: userData.uniqueId,
            username: userData.firstName,
            surveyDate: dateValue,
            externalUser: false,
            clientEmail: "No Email",
            uniqueId: "",
            status: 2,
            samEmail: samEmail
        };

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addPlantSurvey',
            requestConfig: {
                data: newEntry
            }
        });

        if (response.data) {
            cancelHandler(true, dateValue);
        }

        setShowMessage(true);
        setResetComment(true);
    };

    const submitSurvey = async (event) => {
        event.preventDefault();
        let formData;
        if (formValue.externalUser) {
            formData = {...formValue};
        } else {
            formData = {...formValue, "clientEmail": "No Email"};
        }

        const {checkValidate, currentFormState} = validateFormOnSave(formData, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        if (!checkValidate) {
            return false;
        }
        if (formValue.externalUser) {
            setDisableSubmit(true);

            let emailArray = [];
            let contactArray = [];

            if (siteEmailList && siteEmailList.length) {
                let data = getUserDataFromArray(siteEmailList);
                emailArray = data[0];
                contactArray = data[1];
            }

            if (formValue.clientEmail) {
                let clientEmailArray = (formValue.clientEmail).split(",");
                clientEmailArray.map((obj) => {
                    if (obj) {
                        emailArray.push(obj);
                    }
                });
            }

            if (formValue.emailContactValue) {
                let clientEmailContactArray = (formValue.emailContactValue).split(",");
                clientEmailContactArray.map((obj) => {
                    if (obj) {
                        contactArray.push(obj);
                    }
                });
            }

            if (!emailArray.length) {
                setFormValidation(false);
                setFormValidationMessage("Survey must have 1 email.");
                return false
            } else {
                setFormValidation(true);
                setFormValidationMessage("");
            }

            let dateValue = getDateInUTC(formValue.dateStarted);

            config.forEach((obj) => {
                if (obj.type === "Date") {
                    obj.value = dateValue;
                }
            });


            let newEntry = {
                _id: null,
                survey: formValue.survey,
                plant: formValue.plant,
                site: formValue.site,
                dateStarted: dateValue,
                surveyDetail: config,
                userId: userData.uniqueId,
                username: userData.firstName,
                surveyDate: dateValue,
                clientEmail: formValue.clientEmail,
                externalUser: true,
                uniqueId: "",
                status: 0,
                emailList: emailArray,
                userContactName: contactArray,
                samEmail: samEmail
            };

            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addPlantSurvey',
                requestConfig: {
                    data: newEntry
                }
            });

            if (response.data) {
                setMessage(response.message)
            }
            setShowMessage(true);
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {formValue.externalUser && <Row>
                <Message showMessage={showMessage} response={response} error={error} loading={loading} message={message}
                         formValidation={formValidation} formValidationMessage={formValidationMessage}/>
            </Row>}
            <Row>
                <PlantQASurveyForm handleChange={handleChange} formValue={formValue} plantList={plantList}
                                   surveyList={surveyList} submitSurvey={submitSurvey} siteEmailList={siteEmailList}
                                   formValidation={formValidation} inputValidation={inputValidation}
                                   resetForm={resetForm} siteList={siteList} response={response}
                                   error={error} loading={loading} showMessage={showMessage} message={message}
                                   handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                   handleSearch={handleSearch} innerRef={innerRef} disableSubmit={disableSubmit}
                                   updateListSelection={updateListSelection}
                                   handleCheckboxLabelClick={handleCheckboxLabelClick}
                                   showExternalSurvey={showExternalSurvey}/>
            </Row>
            {console.log(formValue.externalUser)}
            {(!formValue.externalUser && config && config.length) &&
            <>
                <Message showMessage={showMessage}
                         response={response} error={error} loading={loading}
                         formValidation={surveyFormValidation}/>
                <SurveyDetailForm config={config} handleChange={inputChangeHandler}
                                  submitHandler={submitHandler} inputValidation={inputSurveyValidation}
                                  cancelHandler={cancelHandler} formValidation={surveyFormValidation}
                                  response={response}
                                  error={error} loading={loading} showMessage={showMessage}
                                  resetComment={resetComment} inputLabelClickHandler={inputLabelClickHandler}/>
            </>
            }
            {!formValue.externalUser && config && !config.length &&
            <Row gap={3} className="col-md-5 mx-auto text-center fs-2">
                Please Add Question field to selected survey!!
            </Row>
            }
        </Container>
    )
};

export default PlantQASurvey;

