import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend, Cell, LineChart, RadarChart
} from 'recharts';

const BarChartWithTopLabel = ({data, name, label, nextLabel, flag}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5
                }}>
                <XAxis dataKey={name} height={15}/>
                <YAxis yAxisId="left" domain={[0, 6]} type="number" width={15}/>
                <Legend/>
                <Tooltip />
                <Bar yAxisId="left" dataKey={label} layout="horizontal" fill="#8cc5e3"/>
                {flag && <Bar yAxisId="left" dataKey={nextLabel} layout="horizontal" fill="#1a80bb"/>}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartWithTopLabel;