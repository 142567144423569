import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './PlantQAConfig.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {handleInputChange} from "../../commom/FormHandler";
import EnterpriseForm from "./EnterpriseForm";
import {list, updateList} from "../../reducer/FrequencyReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {getUserAccessToUrl, trimObjectValues} from "../../commom/CommonFunction";
import {logIn, logOut} from "../../reducer/LoginReducer";

const Enterprise = () => {
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const [formValue, setFormValue] = useState({
        "_id": "",
        "value": 0,
    });
    const [existingEntry, setExistingEntry] = useState({
        "_id": "",
        "value": 0,
    });
    const [inputValidation, setInputValidation] = useState({
        value: true,
    });
    const fieldValidation = {
        value: "number"
    };
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [formValidation, setFormValidation] = useState(true);
    const [tableData, setFrequencyData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        let obj = "";

        userDetailWithPlantAndAllMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            setFrequencyData(response.masterData);
            dispatch(list(response.masterData));
            dispatch(setPlant(response.plants));
            dispatch(setDateRange());
            dispatch(setUserRole(response.securityRoles));

            response.masterData.forEach(stateObj => {
                if(stateObj.type === "Imperfection Threshold") {
                    obj = stateObj
                }
            });

            if(obj) {
                setExistingEntry((prevState) => ({
                    ...prevState,
                    _id: obj._id,
                    value: obj.value
                }));
                setFormValue((prevState) => ({
                    ...prevState,
                    _id: obj._id,
                    value: obj.value
                }));
            }
        });
    },[]);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const userDetailWithPlantAndAllMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/userDetailWithPlantAndAllMasterData',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const cancelHandler = () => {
        setShowMessage(false);

        if(existingEntry._id !== "") {
            setFormValue((prevState) => ({
                ...prevState,
                _id: existingEntry._id,
                value: existingEntry.value
            }));
        }
    };

    const submitHandler = async(event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);
        if (!checkValidate) {
            return false;
        }

        let newData = trimObjectValues({...formValue});

        newData["modAt"] = new Date();
        newData["type"] = screen;

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateMasterData',
            requestConfig: {
                data : newData
            }
        });
        setShowMessage(true);
        dispatch(updateList(response.data));
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                    <EnterpriseForm submitHandler={submitHandler} inputValidation={inputValidation}
                                    handleChange={handleChange} formValue={formValue} formValidation={formValidation}
                                    response={response} error={error} loading={loading} showMessage={showMessage}
                                    cancelHandler={cancelHandler} disableSubmit={disableSubmit}/>
                </Col>
            </Row>
        </Container>
    )
};

export default Enterprise;