import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import Message from "../../commom/Message";
import SignUpForm from "./SignUpForm";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import Loader from "../../commom/LoaderComponent";

const SignUp = (props) => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [formValue, setFormValue] = useState({
        uniqueId: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: "",
        securityRole: "",
        idnAffiliation: "",
        passwordSalt: "",
        roleCurrent: "",
        surveysGroup: "",
        surveyAdd: false,
        active: false,
        authorizationTokens: "",
        comments: "",
        externalUser: true
    });
    const [inputValidation, setInputValidation] = useState({
        uniqueId: true,
        firstName: true,
        lastName: true,
        email: true,
        password: true,
        phoneNo: true,
    });
    const fieldValidation = {
        uniqueId: "string",
        firstName: "string",
        lastName: "string",
        email: "email",
        password: "string",
        phoneNo: "string",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    const handleClose = () => {
        props.closeSignUpModalBox(true);
        setShowMessage(false);
    };

    const handleChange = (event) => {
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const cancelHandler = () => {
        setShowMessage(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
    };

    const sendPasswordUpdateEmail = async () => {
        await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/sendEmailAdminGrantUser',
            requestConfig: {
                data: formValue
            }
        });
        setShowMessage(true);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let newData = {...formValue};
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateUser',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            sendPasswordUpdateEmail().then();
            cancelHandler();
        }
        setShowMessage(true);
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal backdrop="static" show={props.show} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage} response={response} error={error} loading={loading}
                             formValidation={formValidation}
                             message="Sign up details has been sent to the Administrator."/>
                    <SignUpForm submitHandler={submitHandler} sendPasswordUpdateEmail={sendPasswordUpdateEmail}
                                cancelHandler={cancelHandler} handleChange={handleChange} formValue={formValue}
                                formValidation={formValidation} inputValidation={inputValidation} response={response}
                                error={error} loading={loading} showMessage={showMessage}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SignUp;