import React, {useContext, useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ScoreCardList from "./ScoreCardList";
import ScorecardForm from "./ScorecardForm";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {setScorecard, updateScorecard} from "../../reducer/ScorecardReducer";
import {
    getArrayByKey,
    getPercentageValue,
    getSelectedArray,
    getUserAccessToUrl,
    modifiedArrayData
} from "../../commom/CommonFunction";
import {getWeekDateRange, getWeekDateRangeFromStartDate} from "../../commom/DateFormat";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";

const ScoreCard = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [weekRange, setWeekRange] = useState();
    const [siteList, setSiteList] = useState();
    const [formValue, setFormValue] = useState({
        dateRange: "",
        userId: "",
        username: "",
        soilCleanRatio: "",
        fillRate: "",
        onTimeDeliveryPercent: "",
        totalDeliveries: "",
        lateDeliveries: "",
        site: "",
        plant: "",
        _id: "",
        dateOfSurvey: "",
        active: false,

    });
    const [inputValidation, setInputValidation] = useState({
        soilCleanRatio: true,
        fillRate: true,
        totalDeliveries: true,
        lateDeliveries: true,
        site: true,
        plant: true,
        dateOfSurvey: true
    });
    const fieldValidation = {
        soilCleanRatio: "Number",
        fillRate: "Number",
        totalDeliveries: "Number",
        lateDeliveries: "Number",
        site: "select",
        plant: "select",
        dateOfSurvey: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [plantData, setPlantData] = useState();
    const [sitesData, setSiteData] = useState();
    const [userData, setUserData] = useState();

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        getUserDetailWithMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            let data = getSelectedArray("Master Role", "type", response.masterData);
            setUserData(response._doc);
            setPlantData(response.plants);
            setSiteData(response.sites);
            let siteData = getArrayByKey(response.sites, "site");
            getScorecard(response._doc.securityRole, data, siteData).then((fetchedValue) => {
                dispatch(setScorecard(fetchedValue));
            });
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId,
                    "isSam": true
                }
            }
        });
        return await response.data;
    };

    const getScorecard = async (securityRole, masterRoleValue, siteData) => {
        if (securityRole === masterRoleValue) {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getScorecard'
            });
            return modifiedArrayData(response.data, true);
        } else {
            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getScorecardBySites',
                requestConfig: {
                    data: siteData
                }
            });

            return modifiedArrayData(response.data, true);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const inputChangeHandler = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);
        let {name, value} = event.currentTarget;

        if (name !== "totalDeliveries" || name !== "lateDeliveries") {
            const currentFormState = handleInputChange(event, formValue);
            setFormValue(currentFormState);
        }

        if (name === "plant") {
            setSiteList(getSelectedArray(event.currentTarget.value, "plant", sitesData));
        }

        if (name === "dateOfSurvey") {
            setWeekRange(getWeekDateRange(event.currentTarget.value))
        }

        if (name === "totalDeliveries") {
            let percentValue = 100;

            if (!isNaN(formValue.lateDeliveries) && formValue.lateDeliveries > 0 && !isNaN(value)) {
                let pValue = getPercentageValue(value, formValue.lateDeliveries);
                percentValue = isNaN(pValue) ? percentValue : pValue;
            }

            setFormValue((preState) => ({
                ...preState,
                "totalDeliveries": value,
                "onTimeDeliveryPercent": percentValue
            }));
        }

        if (name === "lateDeliveries") {
            let percentValue = 0;

            if (!isNaN(formValue.totalDeliveries) && formValue.totalDeliveries > 0 && !isNaN(value)) {
                let pValue = getPercentageValue(formValue.totalDeliveries, value);
                percentValue = isNaN(pValue) ? percentValue : pValue;
            }

            setFormValue((preState) => ({
                ...preState,
                lateDeliveries: value,
                "onTimeDeliveryPercent": percentValue
            }));
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setSiteList(getSelectedArray(value["plant"], "plant", sitesData));

        let data = {...value};
        let range = getWeekDateRangeFromStartDate((data["dateRange"].split('to'))[0]);
        data["dateOfSurvey"] = (data["dateOfSurvey"]);
        data["dateRange"] = ((data["dateRange"].split('to'))[0]);
        formInputValueSetup(true, data);
        setReadOnly(true);
        setWeekRange(range);
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setReadOnly(false);
        setWeekRange("")
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let newData = {...formValue};
        newData.userId = userData.uniqueId;
        newData.username = userData.firstName;
        newData.dateRange = weekRange;

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateScorecard',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            let savedData = {...response.data};
            let date = savedData.dateRange.split(" ");
            savedData["siteDate"] = savedData.site + " - " + date[0];

            dispatch(updateScorecard(savedData));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
            setReadOnly(true);
        }
        setShowMessage(true);
    };


    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={6}><ScoreCardList selectedRow={selectedRow}/></Col>
                <Col sm={6}>
                    <Message showMessage={showMessage}
                    response={response} error={error} loading={loading}
                    formValidation={formValidation}/>
                    <ScorecardForm submitHandler={submitHandler} cancelHandler={cancelHandler}
                                           handleChange={inputChangeHandler} buttonTitle={buttonTitle}
                                           readOnly={readOnly}
                                           siteList={siteList} plantList={plantData} weekRange={weekRange}
                                           formValue={formValue} formValidation={formValidation}
                                           inputValidation={inputValidation}
                                           response={response} error={error} loading={loading}
                                           showMessage={showMessage} disableSubmit={disableSubmit}/></Col>
            </Row>
        </Container>
    )
};

export default ScoreCard;