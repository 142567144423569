import React, {memo, useContext, useEffect, useState} from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import ThemeSelector from "./ThemeSelector";
import {logOut} from "../../reducer/LoginReducer";
import {useNavigate, useLocation} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {updateVisibility} from "../../reducer/HeaderReducer";
import {updateDefaultScreenForm, updateResetForm} from "../../reducer/ModalReducer";
import DateRange from "../dateSelection/DateRange";
import DefaultScreenSelection from "./DefaultScreenSelection";
import {getScreenName} from "../../commom/CommonFunction";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons/faUserEdit";
import {faUser} from "@fortawesome/fontawesome-free-solid";

const Header = memo(() => {
    const user = useSelector((state) => state.userState.user);
    const isProdEnv = useSelector((state) => state.loginState.isProductionEnv);
    const showDate = useSelector((state) => state.showDateReducer.showDate);
    const showSideNav = useSelector((state) => state.header.showSideNav);
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [parentNav, setParentNav] = useState("Dashboard");
    const [childNav, setChildNav] = useState("");
    let selectedNavigation = window.localStorage.getItem('selectedNavigation');
    const location = useLocation();

    useEffect(() => {
        // runs on route change
        let pathname = location.pathname.split("/");

        if(selectedNavigation && pathname) {
            let selectedNavigationArray = selectedNavigation.split(",");
            setParentNav(selectedNavigationArray[0]);
            let childNavValue = getScreenName(pathname[1]);
            setChildNav(childNavValue);
        }
    }, [location, selectedNavigation]);

    const handleLogout = ()=> {
        auth.logout();
        localStorage.removeItem('userDetail');
        localStorage.removeItem("endDate");
        localStorage.removeItem("startDate");
        localStorage.removeItem("idle_time_2");
        dispatch(logOut());
        navigate('/login')
    };

    const toggleSidebar = () => {
        dispatch(updateVisibility());
    };

    const openResetPasswordForm = () => {
       dispatch(updateResetForm());
    };

    const openDefaultScreenForm = () => {
        dispatch(updateDefaultScreenForm());
    };

    return(
        <div className={`row navbarHeader ${isProdEnv ? "prodBackground" : "devBackground"}`}>
            <div className="col-sm-1 fontArrowDiv">
                <div className="px-sm-1 fs-5" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars}/>
                </div>
                {!isProdEnv && <span>Dev</span>}
            </div>
            <div className="col">
                <span>{parentNav}</span>
                {childNav && <><span> / </span><span>{childNav}</span></>}
            </div>
            {showDate && <div className="col-sm-5"><DateRange/></div>}
            <div className="col-sm-2 userCol">
                <NavDropdown className=""
                             title={
                                 <>
                                     <FontAwesomeIcon icon={faUser} /> {`${user.firstName} ${user.lastName}`.toUpperCase()}
                                 </>
                             } id="">

                    <NavDropdown.Item as="button" eventKey="4.1" onClick={() => openResetPasswordForm()}>
                        Reset Password</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as="button" eventKey="4.5" onClick={() => openDefaultScreenForm()}>
                        Default Screen</NavDropdown.Item>
                    <DefaultScreenSelection/>
                    <NavDropdown.Divider />
                    <ThemeSelector/>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.2" onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
            </div>
        </div>
    )
});

export default Header