import React, {useContext, useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import UtilizationForm from "./UtilizationForm";
import UtilizationList from "./UtilizationList";
import {setUtilization, updateUtilization} from "../../reducer/UtilizationReducer";
import {getArrayByKey, getSelectedArray, getUserAccessToUrl, modifiedArrayData} from "../../commom/CommonFunction";
import {
    getDateInUTCByMonth,
    getMonthDateRangeFromStartDate,
    getMonthRange
} from "../../commom/DateFormat";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {useNavigate} from "react-router-dom";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";

const Utilization = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [weekRange, setWeekRange] = useState();
    const [siteList, setSiteList] = useState();
    const [formValue, setFormValue] = useState({
        dateRange: "",
        userId: "",
        username: "",
        patientDaysAdjusted: "",
        patientDays: "",
        totalCleanPounds: "",
        totalInvoice: "",
        site: "",
        plant: "",
        _id : "",
        dateOfSurvey: "",
        active: false,
    });
    const [inputValidation, setInputValidation] = useState({
        patientDaysAdjusted: true,
        patientDays: true,
        totalCleanPounds: true,
        totalInvoice: true,
        site: true,
        plant: true,
        dateOfSurvey: true
    });
    const fieldValidation = {
        patientDaysAdjusted: "number",
        patientDays: "number",
        totalCleanPounds: "number",
        totalInvoice: "number",
        site: "select",
        plant: "select",
        dateOfSurvey: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [frequencyData, setFrequencyData] = useState();
    const [plantData, setPlantData] = useState();
    const [sitesData, setSiteData] = useState();
    const [userData, setUserData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        getUserDetailWithMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            let data = getSelectedArray("Master Role", "type", response.masterData);
            setUserData(response._doc);
            setPlantData(response.plants);
            setFrequencyData(response.masterData);
            setSiteData(response.sites);
            let siteData = getArrayByKey(response.sites, "site");
            getUtilization(response._doc.securityRole, data, siteData).then((fetchedValue) => {
                dispatch(setUtilization(fetchedValue));
            });
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId,
                    "isSam": true
                }
            }
        });
        return await response.data;
    };

    const getUtilization = async (securityRole, masterRoleValue, siteData) => {
        if(securityRole === masterRoleValue) {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getUtilization'
            });
            return modifiedArrayData(response.data, false);
        } else {
            let response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getUtilizationBySites',
                requestConfig: {
                    data: siteData
                }
            });

            return modifiedArrayData(response.data, false);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.name === "plant") {
            setSiteList(getSelectedArray(event.currentTarget.value, "plant", sitesData));
        }

        if (event.currentTarget.name === "dateOfSurvey") {
            setWeekRange(getMonthRange(event.currentTarget.value))
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setSiteList(getSelectedArray(value["plant"], "plant", sitesData));

        let data = {...value};
        let range = getMonthDateRangeFromStartDate(getDateInUTCByMonth(data["dateOfSurvey"]));
        data["dateRange"] = ((data["dateRange"].split('to'))[0]);
        formInputValueSetup(true, data);
        setReadOnly(true);
        setWeekRange(range);
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setReadOnly(false);
        setWeekRange("")
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if(!checkValidate) {
            return false;
        }

        let newData = {...formValue};
        newData.userId = userData.uniqueId;
        newData.username = userData.firstName;
        newData.dateRange = weekRange;

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateUtilization',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            let savedData = {...response.data};
            let date = savedData.dateRange.split(" ");
            savedData["siteDate"] = savedData.site + " - " + date[0];

            dispatch(updateUtilization(savedData));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
            setReadOnly(true);
        }
        setShowMessage(true);
    };


    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={6}><UtilizationList selectedRow={selectedRow}/></Col>
                <Col sm={6}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                             <UtilizationForm submitHandler={submitHandler} cancelHandler={cancelHandler}
                                           handleChange={inputChangeHandler} buttonTitle={buttonTitle} readOnly={readOnly}
                                           siteList={siteList} plantList={plantData} weekRange={weekRange}
                                           formValue={formValue} formValidation={formValidation} inputValidation={inputValidation}
                                           response={response} error={error} loading={loading} showMessage={showMessage}
                                             disableSubmit={disableSubmit}/></Col>
            </Row>
        </Container>
    )
};

export default Utilization;