import { createSlice } from '@reduxjs/toolkit'

export const customerStatReducer = createSlice({
    name: "customerStatReducer",
    initialState: {
        sam: "",
        displayMode: "",
        visitFrequency: ""
    },
    reducers: {
        lastSelectedValue: (state, action) => {
            state.sam = action.payload.sam;
            state.displayMode = action.payload.displayMode;
            state.visitFrequency = action.payload.visitFrequency;
        },
    }
});

// Action creators are generated for each case reducer function
export const { lastSelectedValue } = customerStatReducer.actions;

export default customerStatReducer.reducer;