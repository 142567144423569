import React from "react";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const SignUpForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="uniqueId" className="col-form-label"><span className="text-danger starCss">*</span>Id</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.uniqueId ? "errorDiv" : ""}`}
                               value={props.formValue.uniqueId} name="uniqueId"
                               onChange={props.handleChange}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="firstName" className="col-form-label"><span className="text-danger starCss">*</span>First
                    Name</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.firstName ? "errorDiv" : ""}`}
                               value={props.formValue.firstName} name="firstName"
                               onChange={props.handleChange} readOnly={props.isReadonly}/>
                    </div>
                </div>

            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="lastName" className="col-form-label"><span className="text-danger starCss">*</span>Last
                    Name</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.lastName ? "errorDiv" : ""}`}
                               value={props.formValue.lastName} name="lastName"
                               onChange={props.handleChange}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="email" className="col-form-label"><span className="text-danger starCss">*</span>Email</label>
                    <div>
                        <input type="email" className={`form-control ${!props.inputValidation.email ? "errorDiv" : ""}`}
                               value={props.formValue.email} name="email"
                               onChange={props.handleChange}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="password" className="col-form-label"><span className="text-danger starCss">*</span>Password</label>
                    <div>
                        <input type="password"
                               className={`form-control ${!props.inputValidation.password ? "errorDiv" : ""}`}
                               value={props.formValue.password} name="password"
                               onChange={props.handleChange} value={props.formValue.password}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="phoneNo" className="col-form-label"><span className="text-danger starCss">*</span>Phone No.</label>
                    <div>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.phoneNo ? "errorDiv" : ""}`}
                               value={props.formValue.phoneNo} name="phoneNo"
                               onChange={props.handleChange}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="comments" className="col-form-label">Comment</label>
                    <div>
                        <input type="text"
                               className="form-control" value={props.formValue.comments} name="comments"
                               onChange={props.handleChange}/>
                    </div>
                </div>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss">Submit</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default SignUpForm;