import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setSite} from "../../reducer/SiteReducer";
import {setAllSite} from "../../reducer/SiteAllReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {exportSurveyData} from "../../commom/ExportToExcel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {InputGroup} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import UserLogTable from "./UserLogTable";
import Form from 'react-bootstrap/Form';
import {getUserAccessToUrl, specificArrayData} from "../../commom/CommonFunction";
import {getDateInUTC} from "../../commom/DateFormat";
import {userLogsReportData} from "../../commom/CommonReportFunction";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const UserLog = () => {
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);

    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [userLogData, setUserLog] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [allUserDataBySearch, setAllUserDataBySearch] = useState("");
    const [userList, setUserList] = useState(null);
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(true));
        dispatch(setDateRange());

        getUserDetailWithMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
        });
        getData().then((fetchedValue) => {
            let users = specificArrayData(fetchedValue, "_id");
            setUserList(users);
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserLogs = () => {
            let newData = [];

            getUserLogData(userList).then((fetchedValue) => {

                fetchedValue.forEach((userData) => {
                    if(userData && userData.body && userData.body.id) {
                        newData.push(userData);
                    }
                });

                setUserLog(newData);
                setAllUserDataBySearch(newData)
            });
    };

    useEffect(() => {
        if (isDateChanged && userList) {
            getUserLogs();
        }
    }, [isDateChanged, userList]);

    const getUserLogData = async (users) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserLoginData',
            requestConfig: {
                "data" : {
                    "users" : users,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        return await response.data;
    };

    const getData = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getUsers'
        });
        return await response.data;
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetail',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const exportReportData = () => {
        let reportHeaderData = "User Log Report";

        let data = {
            [reportHeaderData]: userLogsReportData(allUserDataBySearch)
        };

        let reportHeaderValue = reportHeaderData + ".xlsx";

        exportSurveyData(data, reportHeaderValue);
    };

    const changeInputHandler = (event) => {
        setSearchTerm(event.currentTarget.value);
    };

    const searchData = () => {
        let filteredData = [];
        if (searchTerm) {
            let searchArray = searchTerm.split(" ");

            userLogData.forEach((stateObj) => {
                let value = {...stateObj};

                if(value && value.body && value.body.id) {
                    let valueInLowerCase = (value.body.id + "").toLowerCase();
                    let isDataAlreadyPresent = false;
                    searchArray.forEach((searchVal) => {
                        let search = (searchVal + "").toLowerCase();
                        let data = valueInLowerCase.match(search);

                        if (data && !isDataAlreadyPresent) {
                            isDataAlreadyPresent = true;
                            filteredData.push(value);
                        }
                    });
                }
            });

            setAllUserDataBySearch(filteredData);
        } else if (!searchTerm) {
            setAllUserDataBySearch(userLogData);
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <div className="d-flex justify-content-end m-1">

            <Link to="/user"><Button className="buttonCss">Back To User Management</Button></Link>
            <Button className="mx-sm-1 buttonCss" onClick={exportReportData}>Export</Button>
            </div>
            <InputGroup className="my-1">
                <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    type="text"
                    value={searchTerm}
                    onChange={changeInputHandler}
                />
                <InputGroup.Text id="basic-addon2" onClick={searchData}>
                    Space Separated Search For Multi User Id
                </InputGroup.Text>
            </InputGroup>

            <Row className="qualityAudit">
                <Col>
                    <table className="table table-bordered">
                        <thead className="tableHeader">
                        <tr className="sortTableHeader">
                            <th className="firstCellWidth">User Id</th>
                            <th>Access URL</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (allUserDataBySearch && allUserDataBySearch.length ?
                                    <UserLogTable allUserDataBySearch={allUserDataBySearch}/>
                                    :
                                    (<tr>
                                        <td colSpan="5" className="text-center">NO DATA AVAILABLE</td>
                                    </tr>)
                            )
                        }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
};

export default UserLog;