import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import ForgetPasswordForm from "./ForgetPasswordForm";
import Message from "../../commom/Message";
import {validateFormOnSave} from "../../commom/ValidateFormData";
import {handleInputChange} from "../../commom/FormHandler";
import Loader from "../../commom/LoaderComponent";

const ForgetPassword = (props) => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [formValue, setFormValue] = useState({
        userId: ""
    });
    const [inputValidation, setInputValidation] = useState({
        userId: true
    });
    const fieldValidation = {
        userId: "string",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    const handleClose = () => {
        props.closeModalBox();
    };

    const handleChange = (event) => {
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/forgetPassword',
            requestConfig: {
                id: formValue.userId
            }
        });
        setShowMessage(true);
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={props.show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forget Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage} formValidation={formValidation}
                             response={response} error={error} loading={loading}
                             message="Successfully sent an email including login details !!"/>
                    <ForgetPasswordForm submitHandler={submitForm} handleChange={handleChange} formValue={formValue}
                                        inputValidation={inputValidation}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForgetPassword;