import React from "react";

function Loader(props) {
    return (
        <>
            {props.isLoading &&
            <div className="divLoader">
                <div className="loadingSpan">
                    <span>L</span>
                    <span>O</span>
                    <span>A</span>
                    <span>D</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </div>
            </div>
            }
        </>
    );
}

export default Loader;