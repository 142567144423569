import React from "react";
import CustomTable from "../../commom/CustomTable";
import {useSelector} from "react-redux";

function SurveyComputedList(props) {
    const tableData = useSelector((state) => state.surveyComputedReducer);
    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "title",
            header: "Title"
        },
        {
            name: "fieldName",
            header: "Field Name"
        },
        {
            name: "type",
            header: "Type"
        },
        {
            name: "active",
            header: "Active"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow}/>
    );
}

export default SurveyComputedList;