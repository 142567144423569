import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import './User.css';
import UserForm from "./UserForm";
import UserList from "./UserList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {setUser, updateUser} from "../../reducer/UserManagementReducer";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Button from "react-bootstrap/Button";
import {exportSurveyData} from "../../commom/ExportToExcel";
import {userReportData} from "../../commom/CommonReportFunction";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {
    getArrayValue,
    getSelectedAllFlag,
    getUserAccessToUrl, trimObjectValues, updatedObjectByActiveKey,
    updateMultiSelectSearch
} from "../../commom/CommonFunction";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {useOuterClick} from "../../hook/VisibilityHook";

const User = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [formValue, setFormValue] = useState({
        _id: "",
        uniqueId: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: "",
        securityRole: "",
        idnAffiliation: "",
        passwordSalt: "",
        roleCurrent: "",
        surveysGroup: "",
        surveyAdd: true,
        active: false,
        authorizationTokens: "",
        comments: "",
        externalUser: false,
        defaultScreen: "",
        dvp: false,
        gm: false,
        rdo: false,
        sam: false,
        showIdn: false,
        selectAllIdn: false,
        idnCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        uniqueId: true,
        firstName: true,
        lastName: true,
        email: true,
        password: true,
        securityRole: true,
        surveysGroup: true,
        idnCount: true
    });
    const fieldValidation = {
        uniqueId: "string",
        firstName: "string",
        lastName: "string",
        email: "email",
        password: "string",
        securityRole: "select",
        surveysGroup: "select",
        idnCount: "number"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [surveyList, setSurveyList] = useState();
    const [roleList, setRoleList] = useState();
    const [readOnly, setReadOnly] = useState(false);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [messageData, setMessageData] = useState("");
    const [userList, setUserList] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [defaultScreenArray, setDefaultScreenArray] = useState([]);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isManagement, setManagement] = useState(false);
    const [idnList, setIdnList] = useState();
    const [idnUpdatedList, setIdnUpdatedList] = useState();

    const [masterList, setMasterList] = useState();
    const [idnNewList, setIdnNewList] = useState();
    const innerIdnRef = useOuterClick(() => {
        setFormValue({...formValue, showIdn: false});
    });
    const [searchValue, setSearchValue] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
        dispatch(showDateInHeader(false));

        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getUsers'
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            dispatch(setUser(fetchedValue));
            setUserList(fetchedValue);
        });

        async function getIDNData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getActiveIdn'
            });
            return await response.data;

        }

        getIDNData().then((fetchedValue) => {
            let newIdnList = updateMultiSelectOption(fetchedValue);
            setIdnList(fetchedValue);
            setIdnUpdatedList(newIdnList);
            setIdnNewList(newIdnList);
        });

        async function getSurveyGroupData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getActiveSurveyGroupList'
            });
            return await response.data;

        }

        getSurveyGroupData().then((fetchedValue) => {
            setSurveyList(fetchedValue);
        });

        async function getSecurityRoleData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getActiveSecurityRole'
            });
            return await response.data;

        }

        getSecurityRoleData().then((fetchedValue) => {
            setRoleList(fetchedValue);
        });

        userDetailWithPlantAndMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setMasterList(response.masterData);

            if(response._doc.uniqueId === "nehab" || response._doc.uniqueId === "pavan.agrawal@iicorporate.com") {
                setIsUser(true);
            }
        });
    }, []);

    const updateMultiSelectOption = (fetchedValue) => {
        return fetchedValue.map((obj) => {
            let data = obj.idnAffiliation;

            return {
                label: data,
                value: false
            };
        });
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);

        if (name === "idn") {
            setIdnNewList(newData);
            setFormValue({...formValue, selectAllIdn: checked, idnCount: count});
            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "idnCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "idn") {
            let flag = formValue.showIdn;

            if(!flag) {
                let data = updateMultiSelectSearch(idnUpdatedList, "label", "", idnNewList);
                setIdnNewList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showIdn: !flag, selectAllIdn: selectedAll});
            } else {
                setFormValue({...formValue, showIdn: !flag});

            }
        }
    };

    const handleSearch = (event) => {
        const {value} = event.currentTarget;
        let data = updateMultiSelectSearch(idnUpdatedList, "label", value, idnNewList);
        setIdnNewList(data);
        let selectedAll = getSelectedAllFlag(data);
        setFormValue({...formValue, selectAllIdn: selectedAll});
    };

    const selectedIdnValue = (selectedData) => {
        selectedData["selectAllIdn"] = false;
        selectedData["idnCount"] = 0;
        selectedData["showIdn"] = false;
        let selectedIdns = selectedData.idnAffiliation;

        let newArray = [];
        let count = 0;
        idnNewList.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject.label;

            let index = selectedIdns.indexOf(data);

            if (index !== -1) {
                newArray.push({
                    "label": data,
                    "value": true
                });
                count += 1;
            } else {
                newArray.push({
                    "label": data,
                    "value": false
                });
            }
        });
        selectedData["idnCount"] = count;

        setIdnNewList(newArray);

        if (selectedData.securityRole == "Mgmt") {
            setManagement(true);
            selectedData["dvp"] = false;
            selectedData["gm"] = false;
            selectedData["rdo"] = false;
            selectedData["sam"] = false;
            if(masterList && masterList.length > 0) {
                masterList.forEach((masterListObj) => {
                    let value = masterListObj.value;
                    let userName = selectedData.firstName + " " + selectedData.lastName;
                    if(masterListObj.type === "RDO" && value === userName && masterListObj.isActive){
                        selectedData["rdo"] = true;
                    } else if (masterListObj.type === "DVP" && value === userName && masterListObj.isActive){
                        selectedData["dvp"] = true;
                    } else if(masterListObj.type === "GM" && value === userName && masterListObj.isActive){
                        selectedData["gm"] = true;
                    } else if(masterListObj.type === "Sam" && value === userName && masterListObj.isActive){
                        selectedData["sam"] = true;
                    }
                })
            }
        } else {
            setManagement(false);
        }
        formInputValueSetup(true, selectedData);
    };

    const unselectedIdnValue = () => {
        let newArray = [];

        idnNewList.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject.label;

            newArray.push({
                "label": data,
                "value": false
            });
        });

        setIdnNewList(newArray);
    };

    const handleIdnChange = (event) => {
        setDisableSubmit(false);
        let newIdn = event.currentTarget.value;
        if (formValue.idnAffiliation === newIdn) {
            return false;
        }
        setFormValue({...formValue, "idnAffiliation": newIdn});
    };

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const userDetailWithPlantAndMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/userDetailWithPlantAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);

        if (event.currentTarget.name === "securityRole") {
            getDefaultScreenData(event.currentTarget.value);
            const currentFormState = handleInputChange(event, formValue);

            if (event.currentTarget.value === "Mgmt") {
                setManagement(true);
                currentFormState.dvp = true;
                currentFormState.gm = true;
                currentFormState.rdo = true;
                currentFormState.sam = true;

                setFormValue(currentFormState);
            } else {
                setManagement(false);
                setFormValue(currentFormState);
            }
        } else {
            const currentFormState = handleInputChange(event, formValue);
            setFormValue(currentFormState);
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setReadOnly(true);
        getDefaultScreenData(value.securityRole);
        let newValue = Object.assign({}, value, {"selectAllIdn": false, "idnCount": 0, "showIdn": false});
        selectedIdnValue(newValue);
        setOldPassword(value.password);
    };

    const getDefaultScreenData = (securityRole) => {
        getRoleData(securityRole).then((response) => {
            let fetchedValue = [];
            if (response.data && response.data.modules) {
                (response.data.modules).forEach((obj) => {
                    if (obj && obj.subModule && obj.subModule.length) {
                        (obj.subModule).forEach((subObj) => {
                            if (subObj.isActive) {
                                fetchedValue.push(subObj.subModuleTitle);
                            }
                        })
                    }
                })
            }

            setDefaultScreenArray(fetchedValue);
        });
    };

    const getRoleData = async (securityRole) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getSecurityRoleByTitle',
            requestConfig: {
                role: {"title": securityRole}
            }
        });
        return await response;
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setReadOnly(false);
        setManagement(false);
        unselectedIdnValue();
    };

    const sendPasswordUpdateEmail = async (flag) => {
        if (formValue.uniqueId && formValue.email) {
            let data = {
                _id: formValue.uniqueId
            };

            await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/sendPasswordUpdateEmail',
                requestConfig: {
                    data: data
                }
            });

            if (!flag) {
                setMessageData(response.message);
            }
            setShowMessage(true);
        }
    };

    const updateMasterData = (data) => {
        let objIndex = masterList.findIndex(stateObj => {
            return ((stateObj._id === data._id)) ;
        });

        if (objIndex >= 0) {
            masterList.splice(objIndex, 1, data);
        } else if(objIndex === -1) {
            masterList.push(data);
        }
    };

    const createMGMTListOrSamList = async () => {
        let userName = (formValue.firstName).trim() + " " + (formValue.lastName).trim();
        let user = JSON.parse(localStorage.getItem('userDetail'));

        if (formValue.firstName && formValue.email && (formValue.securityRole === "Mgmt")) {
            let dvpResPonse = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdatePlantMasterData',
                requestConfig: {
                    data: {
                        "_id": "",
                        "type": "DVP",
                        "plant": [],
                        "isActive": formValue.dvp && formValue.active,
                        "value": userName,
                        "email": formValue.email,
                        "modBy": user.userId,
                        "flag": true
                    }
                }
            });

            if(dvpResPonse) {
                updateMasterData(dvpResPonse.data);
            }

            let gmResponse = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdatePlantMasterData',
                requestConfig: {
                    data: {
                        "_id": "",
                        "type": "GM",
                        "plant": [],
                        "isActive": formValue.gm && formValue.active,
                        "value": userName,
                        "email": formValue.email,
                        "modBy": user.userId,
                        "flag": true
                    }
                }
            });

            if(gmResponse) {
                updateMasterData(gmResponse.data);
            }

            let rdoResponse = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdatePlantMasterData',
                requestConfig: {
                    data: {
                        "_id": "",
                        "type": "RDO",
                        "plant": [],
                        "isActive": formValue.rdo && formValue.active,
                        "value": userName,
                        "email": formValue.email,
                        "modBy": user.userId,
                        "flag": true
                    }
                }
            });

            if(rdoResponse) {
                updateMasterData(rdoResponse.data);
            }
        }

        let samResponse = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateMasterData',
            requestConfig: {
                data: {
                    "_id": "",
                    "type": "Sam",
                    "plant": "",
                    "isActive": formValue.sam && formValue.active,
                    "value": userName,
                    "email": formValue.email,
                    "modBy": user.userId,
                    "flag": true
                }
            }
        });

        if(samResponse) {
            updateMasterData(samResponse.data);
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let selectedIdnData = getArrayValue(idnNewList);
        if (!selectedIdnData.length) {
            setFormValidation(false);
            setInputValidation({...currentFormState, idnCount: false});
            return false;
        }

        let newData = trimObjectValues({...formValue});

        newData.idnAffiliation = selectedIdnData.toString();

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateUser',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            let newPassword = formValue.password;

            dispatch(updateUser(response.data));
            setButtonTitle("Update");
            if(newPassword !== oldPassword) {
                sendPasswordUpdateEmail(true).then();
            }
            if (formValue.securityRole === "Mgmt") {
                createMGMTListOrSamList();
            }

            if (formValue.securityRole === "SAM") {
                createMGMTListOrSamList();
            }
            setMessageData(response.message);

            if (formValue.securityRole === "Mgmt") {
                let dvpValue = formValue.dvp;
                let gmValue = formValue.gm;
                let rdoValue = formValue.rdo;
                let samValue = formValue.sam;

                let selectAllIdn = formValue.selectAllIdn;
                let idnCount = formValue.idnCount;

                let resObject = {...response.data, "dvp": dvpValue, "gm": gmValue, "rdo": rdoValue, "sam": samValue, selectAllIdn: selectAllIdn, idnCount:idnCount};
                setFormValue(resObject);
            } else {
                let selectAllIdn = formValue.selectAllIdn;
                let idnCount = formValue.idnCount;

                let resObject = {...response.data, selectAllIdn: selectAllIdn, idnCount:idnCount};
                setFormValue(resObject);
            }
            setOldPassword(formValue.password);
        }
        setShowMessage(true);
    };

    const exportUser = () => {
        let data = {};
        data["userReport"] = userReportData(userList);
        exportSurveyData(data, "UserData.xlsx");
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <div className="d-flex justify-content-end m-1">
                    <Link to="/userLog" className="mx-1"><Button className="buttonCss">Users Log</Button></Link>
                    {isUser && <Link to="/errorLog" className="mx-1"><Button className="buttonCss">Errors Log</Button></Link>}
                    <Button type="button" className="mx-1 buttonCss" onClick={exportUser}>Export</Button>
                </div>
                <Col sm={6}><UserList selectedRow={selectedRow}/></Col>
                <Col sm={6}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message={messageData}/>
                    <UserForm submitHandler={submitHandler} cancelHandler={cancelHandler}
                              sendPasswordUpdateEmail={sendPasswordUpdateEmail}
                              inputChangeHandler={inputChangeHandler} buttonTitle={buttonTitle}
                              idnList={idnList} surveyList={surveyList} roleList={roleList} readOnly={readOnly}
                              formValue={formValue} formValidation={formValidation} disableSubmit={disableSubmit}
                              inputValidation={inputValidation} response={response} error={error}
                              loading={loading} showMessage={showMessage} messageData={messageData}
                              defaultScreenArray={defaultScreenArray} isManagement={isManagement}
                              handleIdnChange={handleIdnChange} innerIdnRef={innerIdnRef} idnNewList={idnNewList}
                              handleClick={handleClick} searchValue={searchValue}
                              handleSearch={handleSearch} handleCheckboxChange={handleCheckboxChange}
                    /></Col>
            </Row>
        </Container>
    )
};

export default User;