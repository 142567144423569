import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomTable from "../../commom/CustomTable";
import Button from "react-bootstrap/Button";
import Message from "../../commom/Message";

const ExternalSurveyTableModal = ({showReportTable, header, tableData, rowHeader, exportReportData, isCompletedSurvey,
                                      handleClose, handleModalInputChange, selectedSurvey, surveyList, selectedRow,
                                      showMessage, response, error, loading}) => {
    return (
        <>
            <Modal show={showReportTable}
                   aria-labelledby="contained-modal-title-vcenter" centered className="reportModal">
                <Modal.Header className="reportModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">{header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="reportModalBody">

                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={true} message="Send the email."/>
                    {isCompletedSurvey && <div className="form-group m-sm-1 row">
                        <label htmlFor="survey" className="col-sm-1 col-form-label">Surveys: </label>
                        <div className="col-sm-3 d-inline-block">
                            <select onChange={handleModalInputChange}
                                    className="form-select"
                                    value={selectedSurvey}>
                                <option value="select">Select</option>
                                {
                                    surveyList && surveyList.length
                                    && surveyList.map((obj, index) => {
                                        return <option key={obj.title + index}
                                                       value={obj.title}>{obj.title}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    }
                    <div className="divBorder"/>
                    {(tableData && tableData.length) ?
                        <div className="reportModalTableContainer">
                            <CustomTable rowHeader={rowHeader} tableData={tableData} hideSearch={true} selectedRow={selectedRow}/>
                        </div>
                        :
                        <div className="text-center">NO DATA AVAILABLE</div>
                    }

                </Modal.Body>
                <Modal.Footer className="reportModalFooter">
                    {(tableData && tableData.length) ?
                        <Button onClick={exportReportData} className="mx-sm-1 buttonCss">Export</Button> : null}
                    <Button onClick={handleClose} className="buttonDisableCss">Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ExternalSurveyTableModal;