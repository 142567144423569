import React from "react";
import Button from "react-bootstrap/Button";

const ResetPasswordForm = (props) => {
    return (
        <form onSubmit={props.submitHandler}>
            {
                props.formValidation && !props.showPasswordMessage &&
                <div className="formErrorDiv">Password and Confirm password must match.</div>
            }

            <div className="form-group row mb-1">
                <label htmlFor="oldPassword" className="col-sm-4 col-form-label">
                    <span className="text-danger col starCss">*</span>Old Password</label>
                <div className="col">
                    <input type="password" name="oldPassword"
                           onChange={props.handleChange} value={props.formValue.oldPassword}
                           className={`form-control ${!props.inputValidation.oldPassword ? "errorDiv" : ""}`}/>
                </div>
            </div>
            <div className="form-group row mb-1">
                <label htmlFor="password" className="col-sm-4 col-form-label">
                    <span className="text-danger col starCss">*</span>New Password</label>
                <div className="col">
                    <input type="password" name="password"
                           onChange={props.handleChange} value={props.formValue.password}
                           className={`form-control ${(!props.inputValidation.password || !props.showPasswordMessage) ? "errorDiv" : ""}`}/>
                </div>
            </div>
            <div className="form-group row mb-1">
                <label htmlFor="newPassword" className="col-sm-4 col-form-label">
                    <span className="text-danger col starCss">*</span>Confirm Password</label>
                <div className="col">
                    <input type="password" name="newPassword"
                           onChange={props.handleChange} value={props.formValue.newPassword}
                           className={`form-control ${(!props.inputValidation.newPassword || !props.showPasswordMessage) ? "errorDiv" : ""}`}/>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button type="submit" className="formButton buttonCss">Submit</Button>
            </div>
        </form>
    )
};

export default ResetPasswordForm;