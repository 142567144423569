import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export const IdleTimeOutModal = ({showModal, handleClose, keepSessionAlive}) => {
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Keep Session Alive</Modal.Title>
            </Modal.Header>
            <Modal.Body>To prevent logout, click 'Stay' to remain connected.</Modal.Body>
            <Modal.Footer>
                <Button className="buttonCss" onClick={keepSessionAlive}>
                    Stay
                </Button>
            </Modal.Footer>
        </Modal>
    )
};





