import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import PlantMultiSelectForm from "./PlantMultiSelectForm";
import axios from "../../apis/AxiosInstance";
import {useSelector} from "react-redux";
import useAxiosFunction from "../../hook/AxiosHook";
import {getArrayValue, getSelectedAllFlag, updateMultiSelectSearch} from "../../commom/CommonFunction";
import {exportSurveyData} from "../../commom/ExportToExcel";
import {plantQAConfigurationReportData, plantQAEntriesReportData} from "../../commom/CommonReportFunction";
import {useOuterClick} from "../../hook/VisibilityHook";
import Message from "../../commom/Message";
import Loader from "../../commom/LoaderComponent";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {getDateInUTC} from "../../commom/DateFormat";

const PlantQAExportModal = ({showPlantQAExport, handleClose}) => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const frequencyData = useSelector((state) => state.frequencyReducer);
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const plantData = useSelector((state) => state.plantReducer);
    const [formValue, setFormValue] = useState({
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        plantCount: true
    });
    const fieldValidation = {
        plantCount: "digit",
    };
    const [plantList, setPlantList] = useState("");
    const [itemList, setItemList] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const innerRef = useOuterClick(() => {
        setFormValue({...formValue, showPlant: false});
    });

    useEffect(() => {
        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setItemList((frequencyData).filter(value => value.type === "Item Category"));
    }, [plantData]);

    const updateMultiSelectOption = (fetchedValue, label) => {
        return fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        setShowMessage(false);
        setPlantList(newData);
        setFormValue({
            ...formValue,
            selectAllPlant: checked,
            plantCount: count,
            selectAllSite: false,
            siteCount: 0
        });

        if (count > 0) {
            let newEvent = {
                "target": {
                    "name": "plantCount",
                    "value": count,
                }
            };
            validateInputValue(newEvent);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);
        if (!checkValidate) {
            return false;
        }

        let plantData = getArrayValue(plantList);
        if(plantData && plantData.length) {
            const plantQAConfig = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantQAConfigByPlant',
                requestConfig: {
                    data: {
                        "plant": getArrayValue(plantList)
                    }
                }
            });

            const plantQualityAudit = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantQualityAuditByPlant',
                requestConfig: {
                    data: {
                        "plant": getArrayValue(plantList),
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });

            let imperfectionList = (frequencyData).filter(value => value.type === "Imperfection Type");
            setShowMessage(true);

            let data = {
                "PlantQAEntries": plantQAEntriesReportData(plantQualityAudit.data, imperfectionList),
                "PlantQAConfiguration": plantQAConfigurationReportData(plantQAConfig.data, itemList)
            };
            exportSurveyData(data, "PlantQA.xlsx");

        }
    };

    const closeModalBox = () => {
        setFormValue({
            showPlant: false,
            selectAllPlant: false,
            plantCount: 0
        });

        setPlantList(updateMultiSelectOption(plantData, "plant"));

        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"plantCount": true});
        handleClose();
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={showPlantQAExport} onHide={closeModalBox} backdrop="static"
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Plant QA Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message="Exported Data Successfully"/>
                    <PlantMultiSelectForm submitHandler={submitHandler} plantList={plantList} formValue={formValue}
                                          handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                          handleSearch={handleSearch} innerRef={innerRef} disableSubmit={disableSubmit}
                                          inputValidation={inputValidation} response={response} error={error}
                                          loading={loading} showMessage={showMessage} formValidation={formValidation} />
                </Modal.Body>
            </Modal>
        </>
    )
};

export default PlantQAExportModal;