import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import CustomTable from "../../commom/CustomTable";

function PlantConfigList(props) {
    const frequencyData = useSelector((state) => state.frequencyReducer);
    const header = useSelector((state) => state.plantDropdownReducer.screen);
    const [tableData, setTableData] = useState("");
    const [forceLoad, setForceLoad] = useState(false);

    useEffect(() => {
        setForceLoad(false);
        if(frequencyData && frequencyData.length) {
            let arrayOfObjects = (frequencyData).filter(value => value.type === props.screen);
            setTableData(arrayOfObjects);
        }
    }, [frequencyData, header]);

    useEffect(() => {
        setForceLoad(true);
    }, [tableData]);

    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "value",
            header: header
        },
        {
            name: "isActive",
            header: "Active"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow} forceLoad={forceLoad}/>
    );
}

export default PlantConfigList;