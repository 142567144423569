import React from "react";
import Button from "react-bootstrap/Button";

const LoginForm = (props) => {
    return (
        <form onSubmit={props.submitHandler}>
            <div className="form-group row mb-1">
                <label htmlFor="userId" className="col-sm-3 col-form-label">
                    <span className="text-danger col starCss">*</span>User Id</label>
                <div className="col">
                    <input type="text" name="userId"
                           onChange={props.handleChange} value={props.formValue.userId}
                           className={`form-control ${!props.inputValidation.userId ? "errorDiv" : ""}`}/>
                </div>
            </div>
            <div className="form-group row mb-1">
                <label htmlFor="password" className="col-sm-3 col-form-label">
                    <span className="text-danger col starCss">*</span>Password</label>
                <div className="col">
                    <input type="password" name="password"
                           onChange={props.handleChange} value={props.formValue.password}
                           className={`form-control ${!props.inputValidation.password ? "errorDiv" : ""}`}/>
                </div>
            </div>
            <div className="row mx-auto">
                <Button type="submit" className="formButton buttonCss">Submit</Button>
            </div>
        </form>
    )
};

export default LoginForm;