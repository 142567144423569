import { createSlice } from '@reduxjs/toolkit'

export const modalReducer = createSlice({
    name: "showModal",
    initialState: {
        "showResetForm": false,
        "showDefaultScreenForm": false
    },
    reducers: {
        updateResetForm: (state, action) => {
            state.showResetForm = !state.showResetForm;
            state.showDefaultScreenForm = false;
        },
        updateDefaultScreenForm: (state, action) => {
            state.showResetForm = false;
            state.showDefaultScreenForm = !state.showDefaultScreenForm;
        }
    }
});

export const { updateResetForm, updateDefaultScreenForm } = modalReducer.actions;

export default modalReducer.reducer;