import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const SurveyGroupForm = (props) => {

    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <div className="form-group row">
                <label htmlFor="title" className="col-sm-3 col-form-label">
                    <span className="text-danger starCss">*</span>Title
                </label>
                <div className="col-sm-9">
                    <input type="text"
                           className={`form-control ${!props.inputValidation.title ? "errorDiv" : ""}`}
                           value={props.formValue.title} onChange={props.inputChangeHandler}
                           name="title" disabled={props.readOnly}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="active" className="col-sm-3 col-form-label">Active</label>
                <div className="col-sm-9 py-sm-1">
                    <input type="checkbox" className="form-check-input" disabled={props.readOnly} id="active"
                           checked={props.formValue.active} onChange={props.inputChangeHandler} name="active"/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="surveys" className="col-sm-3 col-form-label">Surveys
                </label>
                <div className="col-sm-9">
                    <input type="text" className="form-control" value={`${props.surveyCount} selected`}
                           name="surveys" readOnly={true}/>
                </div>
            </div>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default SurveyGroupForm;