import React from "react";
import {Button} from "react-bootstrap";
import "./PlantQASurvey.css";
import {SpeechToText} from "../../commom/SpeechToText";

const SurveyDetailForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer formBorder w-100 p-3 mx-auto">
            <div className="row">
                {props.config && props.config.length && props.config.map((input, keyIndex) => {
                    const {id, type, required, fieldName, title, answers, value} = input;
                    return (
                        <div className="form-group col-6" key={id + keyIndex}>

                            {(type !== "Text" && type !== "Checkbox") &&
                            <label htmlFor={fieldName} className="col-form-label" name={fieldName}>
                                {required && <span className="text-danger starCss">*</span>}
                                {title}
                            </label>
                            }
                            {(type === "Checkbox") &&
                            <label htmlFor={fieldName} className="col-form-label" name={fieldName}
                                   onClick={props.inputLabelClickHandler}>
                                {required && <span className="text-danger starCss">*</span>}
                                {title}
                            </label>
                            }
                            {(type === "Text") &&
                                <SpeechToText fieldName={title} handleChange={props.handleChange} name={fieldName}
                                              showMessage={props.showMessage} resetComment={props.resetComment}
                                              required={required} inputValidation={props.inputValidation[fieldName]}/>
                            }
                            <div>
                                {((type === "Date" || type === "Number") && required) &&
                                <input onChange={props.handleChange} name={fieldName} type={type}
                                       className={`form-control ${(props.inputValidation[fieldName]) ? "" : "errorDiv"}`}
                                       value={value}/>
                                }
                                {((type === "Date" || type === "Number") && !required) &&
                                <input onChange={props.handleChange} name={fieldName} type={type}
                                       className="form-control"
                                       value={value}
                                />
                                }

                                {(type === "Dropdown" && required) &&
                                <select value={value} onChange={props.handleChange} name={fieldName}
                                        className={`form-select ${props.inputValidation[fieldName] ? "" : "errorDiv"}`}>
                                    <option value="select">Select</option>
                                    {
                                        answers && answers.length
                                        && answers.map((obj, index) => {
                                            return <option key={index + obj.value}
                                                           value={obj.value}>{obj.value}</option>
                                        })
                                    }
                                </select>
                                }

                                {(type === "Dropdown" && !required) &&
                                <select value={value} onChange={props.handleChange} name={fieldName}
                                        className="form-select">
                                    <option value="select">Select</option>
                                    {
                                        answers && answers.length
                                        && answers.map((obj, index) => {
                                            return <option key={index + obj.value}
                                                           value={obj.value}>{obj.value}</option>
                                        })
                                    }
                                </select>
                                }

                                {type === "Checkbox" &&
                                <input className="form-check-input" onChange={props.inputLabelClickHandler} name={fieldName}
                                       type={type} checked={value}/>}
                            </div>
                        </div>
                    )
                })}
            </div>

            < div
                className="row justify-content-center m-1">
                < Button
                    type="submit"
                    className="formButton mx-sm-1 buttonCss"> Submit </Button>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                    </div>
        </form>
)
};

export default SurveyDetailForm;