import React from 'react'
import * as XLSX from "xlsx-js-style";

export const exportSurveyData = (apiData, fileName) => {
    const workbook = XLSX.utils.book_new();
    for(let apiKey in apiData) {
        if (apiData.hasOwnProperty(apiKey)) {
            if(apiData[apiKey] && apiData[apiKey].length) {
                let worksheet = XLSX.utils.json_to_sheet(apiData[apiKey]);
                XLSX.utils.book_append_sheet(workbook, worksheet, apiKey);

                let range = XLSX.utils.decode_range(worksheet['!ref']);
                let noCols = range.e.c;
                let worksheetCols = [];
                for (let i = 1; i <= noCols; i++) {
                    let obj = { width: 30 };
                    worksheetCols.push(obj);
                }
                worksheet['!cols'] = worksheetCols;
            } else {
                let worksheet = XLSX.utils.json_to_sheet([{"NO DATA AVAILABLE": ""}]);
                XLSX.utils.book_append_sheet(workbook, worksheet, apiKey);
            }
        }
    }

    XLSX.writeFile(workbook, fileName);
};

export const exportToCSV = (apiData, fileName) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(apiData.ClientSatRawData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "ClientSatRawData", {origin: 'A2', cellStyles: true});

    let sheet2 = XLSX.utils.json_to_sheet(apiData.Scorecard);
    XLSX.utils.book_append_sheet(workbook, sheet2, "Scorecard");
    let firstCol = worksheet['!ref'].split(":")[0][0];
    let lastCol = worksheet['!ref'].split(":")[1][0];
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    var noRows = range.e.r; // No.of rows
    var noCols = range.e.c; // No. of cols
    let worksheetCols = [];
    for (let i = 1; i <= noCols; i++) {
        let obj = { width: 30 };
        worksheetCols.push(obj);
    }
    for (let worksheetIndex in worksheet) {
        console.log(worksheet[worksheetIndex] + "-----" + worksheetIndex);
        if(worksheetIndex.indexOf(":") === -1 && worksheetIndex.indexOf("1") !== -1) {
            worksheet[worksheetIndex].s = {
                fill: {
                    fgColor: {rgb: "0000FF"}
                },
                border: {
                    top: {style: "thin", color: {rgb: "E0E0E0"}},
                },
            };
        }
    }
    worksheet['!cols'] = worksheetCols;
    XLSX.writeFile(workbook, fileName);
};

export const exportToExcelData = (data) => {
    const ws = XLSX.utils.json_to_sheet(data, {header: Object.keys(data[0]), skipHeader: true});

    // Set the column widths
    const wscols = [
        {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 15}, {wch: 15}, {wch: 15},
        {wch: 20}, {wch: 15}, {wch: 15}, {wch: 20}, {wch: 15}, {wch: 20},
        {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}
    ];
    ws['!cols'] = wscols;

    // Define styles
    const headerStyle = {
        font: {bold: true},
        alignment: {horizontal: 'center', vertical: 'center'},
        fill: {fgColor: {rgb: 'e9ecef'}},
        border: {
            top: {style: 'medium', color: {rgb: 'cbcbcb'}},
            bottom: {style: 'medium', color: {rgb: 'cbcbcb'}},
            left: {style: 'medium', color: {rgb: 'cbcbcb'}},
            right: {style: 'medium', color: {rgb: 'cbcbcb'}}
        }
    };

    const subHeaderStyle = {
        font: {bold: true},
        alignment: {horizontal: 'center', vertical: 'center'},
        fill: {fgColor: {rgb: 'e9ecef'}},
        border: {
            top: {style: 'thin', color: {rgb: 'cbcbcb'}},
            bottom: {style: 'thin', color: {rgb: 'cbcbcb'}},
            left: {style: 'thin', color: {rgb: 'cbcbcb'}},
            right: {style: 'thin', color: {rgb: 'cbcbcb'}}
        }
    };

    /*const leftHeaderStyle = {
        font: {bold: true},
        alignment: {horizontal: 'center', vertical: 'center'},
        fill: {fgColor: {rgb: 'e9ecef'}},
        border: {
            left: {style: 'thick', color: {rgb: 'cbcbcb'}},
        }
    };

    const centerHeaderStyle = {
        font: {bold: true},
        alignment: {horizontal: 'center', vertical: 'center'},
        fill: {fgColor: {rgb: 'e9ecef'}},

    };

    const rightHeaderStyle = {
        font: {bold: true},
        alignment: {horizontal: 'center', vertical: 'center'},
        fill: {fgColor: {rgb: 'e9ecef'}},
        border: {
            right: {style: 'thick', color: {rgb: 'cbcbcb'}},
        }
    };
*/
    // Apply styles
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = {c: C, r: R};
            const cell_ref = XLSX.utils.encode_cell(cell_address);
            if (!ws[cell_ref]) continue;
            if (R === 0) {
                ws[cell_ref].s = headerStyle;
            } else if (R === 1) {
                ws[cell_ref].s = subHeaderStyle;
            } else {
                ws[cell_ref].s = {border: subHeaderStyle.border};
                /*if(C == 0) {
                    ws[cell_ref].s = {border: subHeaderStyle.border};
                } else if(C == 1 || C==4|| C==7|| C==10|| C==12|| C==14|| C==16) {
                    ws[cell_ref].s = {border: leftHeaderStyle.border};
                } else if(C == 2|| C==5|| C==8) {
                    ws[cell_ref].s = {border: centerHeaderStyle.border};
                }else if(C == 3|| C==6|| C==9|| C==11|| C==13|| C==15|| C==17) {
                    ws[cell_ref].s = {border: rightHeaderStyle.border};
                }*/
            }
        }
    }



    // Merge cells
    ws['!merges'] = [
        {s: {r: 0, c: 1}, e: {r: 0, c: 3}},  // Merge "Facility"
        {s: {r: 0, c: 4}, e: {r: 0, c: 6}},  // Merge "Client Sat"
        {s: {r: 0, c: 7}, e: {r: 0, c: 9}},  // Merge "Linen Quality & Availability"
        {s: {r: 0, c: 10}, e: {r: 0, c: 11}}, // Merge "Onsite Linen Inspection"
        {s: {r: 0, c: 12}, e: {r: 0, c: 13}}, // Merge "Linen Loss Reviews"
        {s: {r: 0, c: 14}, e: {r: 0, c: 15}}, // Merge "PAR Reviews"
        {s: {r: 0, c: 16}, e: {r: 0, c: 17}}  // Merge "Other Conducted"
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the file
    XLSX.writeFile(wb, "LeaderShipExport.xlsx");
};