import React, {useEffect, useState} from "react";
import Nav from 'react-bootstrap/Nav';
import NavButton from './NavButton'
import {useDispatch, useSelector} from "react-redux";
import {updateSubmodule} from "../../reducer/SidebarReducer";

const Sidebar = () => {
    const [role, setRole] = useState();
    const userRole = useSelector((state) => state.userRoleReducer.sidebarData);
    const dispatch = useDispatch();

    useEffect(() => {
        if(userRole && userRole.length > 0) {
            setRole(updateData(userRole));
        }
    }, [userRole]);

    const updateData = (fetchedValue) => {
        let roles = [];

        fetchedValue.forEach(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});
            roleValue.id = roleValue.moduleId;
            roleValue.name = roleValue.mainModuleTile;
            if (roleValue.isActive) {

                (obj.subModule).forEach((subVal) => {
                    let subModule;
                    subModule = Object.assign({}, subVal);

                    if (subModule.isActive) {
                        roleValue.subModule.push(subModule);
                    }

                });

                roles.push(roleValue);
            }
        });

        dispatch(updateSubmodule({"subModules": roles[0].subModule}));
        return roles;
    };

    return (
        <Nav className="flex-column">
            {
                (role) && (role.length) &&
                (role).map((value, index) => {
                    return (
                        <NavButton id={index} data={value} key={index}/>
                    )
                })
            }
        </Nav>
    )
};

export default Sidebar;