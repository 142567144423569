import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {exportSurveyData} from "../../commom/ExportToExcel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Col from "react-bootstrap/Col";
import {getUserAccessToUrl} from "../../commom/CommonFunction";
import {getDateInUTC} from "../../commom/DateFormat";
import {errorLogsReportData} from "../../commom/CommonReportFunction";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import ErrorLogTable from "./ErrorLogTable";

const ErrorLog = () => {
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [errorDataByDate, setErrorDataByDate] = useState("");
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(true));
        dispatch(setDateRange());

        getUserDetailOnly().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getErrorLogs = () => {
        getErrorLogData().then((fetchedValue) => {
            setErrorDataByDate(fetchedValue)
        });
    };

    useEffect(() => {
        if (isDateChanged && startDate) {
            getErrorLogs();
        }
    }, [isDateChanged, startDate]);

    const getErrorLogData = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getErrorLogByDate',
            requestConfig: {
                "data" : {
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        return await response.data;
    };

    const getUserDetailOnly = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailOnly',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const exportReportData = () => {
        let reportHeaderData = "Error Log Report";

        let data = {
            [reportHeaderData]: errorLogsReportData(errorDataByDate)
        };

        let reportHeaderValue = reportHeaderData + ".xlsx";

        exportSurveyData(data, reportHeaderValue);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <div className="d-flex justify-content-end m-1">
                <Link to="/user"><Button className="buttonCss">Back To User Management</Button></Link>
                <Button className="mx-sm-1 buttonCss" onClick={exportReportData}>Export</Button>
            </div>

            <Row className="errorLogRow">
                <Col>
                    <table className="table table-bordered">
                        <thead className="tableHeader">
                        <tr className="sortTableHeader">
                            <th>Date</th>
                            <th>Method</th>
                            <th>Url</th>
                            <th>Error</th>
                            <th>Headers</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (errorDataByDate && errorDataByDate.length ?
                                    <ErrorLogTable errorDataByDate={errorDataByDate}/>
                                    :
                                    (<tr>
                                        <td colSpan="5" className="text-center">NO DATA AVAILABLE</td>
                                    </tr>)
                            )
                        }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
};

export default ErrorLog;