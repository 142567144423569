import React from "react";
import {Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MultiSelect from "../../commom/MultiSelect";

const DataExportForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <Row>
                {/*<div className="form-group col-sm-6">
                    <label htmlFor="idnAffiliation" className="col-form-label">IDN System</label>
                    <div>
                        <select className="form-select" value={props.formValue.idnAffiliation}
                                onChange={props.inputChangeHandler} name="idnAffiliation" onBlur={props.onBlur}>
                            <option value="select">Select</option>
                            {
                                props.idnList && props.idnList.length
                                && props.idnList.map((obj, index) => {
                                    return <option key={obj.idnAffiliation + index}
                                                   value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>*/}
                <div className="form-group col-sm-6">
                    <label className="col-form-label" htmlFor="reportOption"><span className="text-danger starCss">*</span>Report</label>
                    <div ref={props.innerRefReportOption}>
                        <input type="text"
                               className={`form-control ${!props.inputValidation.reportOptionCount ? "errorDiv" : ""}`}
                               onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.reportOptionCount} selected`} name="reportOption"/>
                        {
                            props.formValue.showReportOption &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showReportOption} data={props.reportOptionList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllReportOption}
                                             label="reportOption" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
                <div className="form-group col-sm-6">
                    <label className="col-form-label" htmlFor="plant">Plant</label>
                    <div ref={props.innerRef}>
                        <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.plantCount} selected`} name="plant"/>
                        {
                            props.formValue.showPlant &&
                            <div className="selectDiv text-start px-sm-1" name="plant">
                                <MultiSelect show={props.formValue.showPlant} data={props.plantList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllPlant} label="plant"
                                             handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            <Row>

                <div className="form-group col-sm-6">
                    <label className="col-form-label" htmlFor="site">Site</label>
                    <div ref={props.innerRefSite}>
                        <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.siteCount} selected`} name="site"/>
                        {
                            props.formValue.showSite &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showSite} data={props.siteList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllSite} label="site"
                                             handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>

                <div className="form-group col-sm-6">
                    <label className="col-form-label" htmlFor="traumaLevel">Trauma Level</label>
                    <div ref={props.innerRefTraumaLevel}>
                        <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.traumaLevelCount} selected`} name="traumaLevel"/>
                        {
                            props.formValue.showTraumaLevel &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showTraumaLevel} data={props.traumaLevelList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllTraumaLevel}
                                             label="traumaLevel" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            <div className="d-flex justify-content-end">
                <Button type="submit" className="formButton buttonCss" onClick={props.submitHandler}
                        disabled={props.disableSubmit}>Export</Button>
            </div>
        </form>
    )
}

export default DataExportForm;