import React from 'react';
import {getDateInUTCByMonth} from "../../commom/DateFormat";

const ErrorLogTable = ({errorDataByDate}) => {
    return (
        <>
            {
                errorDataByDate && errorDataByDate.length &&
                (errorDataByDate).map((value, index) => {
                    return (
                        <tr key={value.userId + index} className="text-center">
                            <td>{getDateInUTCByMonth(value.date)}</td>
                            <td>{value.method}</td>
                            <td>{value.url}</td>
                            <td>{value.error}</td>
                            <td>{value.headers}</td>
                        </tr>
                    )

                })
            }
        </>
    )
};

export default ErrorLogTable;