import { createSlice } from '@reduxjs/toolkit'

export const SurveyDropdownReducer = createSlice({
    name: "showDropdownModal",
    initialState: {
        "showDropdownModal": false
    },
    reducers: {
        updateSurveyDropdownModal: (state, action) => {
            state.showDropdownModal = !state.showDropdownModal;
        }
    }
});

export const { updateSurveyDropdownModal } = SurveyDropdownReducer.actions;

export default SurveyDropdownReducer.reducer;