import { createSlice } from '@reduxjs/toolkit';
import { lightTheme } from '../constant/ThemeConstant';

export const themeReducer = createSlice({
    name: "themeSlice",
    initialState: {
        backgroundColor: lightTheme.backgroundColor,
        textColor: lightTheme.textColor,
        primary: lightTheme.primary,
        secondary: lightTheme.secondary
    },
    reducers: {
        applyTheme: (state, action) => {
            state.backgroundColor = action.payload.backgroundColor;
            state.textColor = action.payload.textColor;
            state.primary = action.payload.primary;
            state.secondary = action.payload.secondary;
        }
    }
});

export const { applyTheme } = themeReducer.actions;

export default themeReducer.reducer;