import React from "react";
import {
    XAxis,
    Tooltip,
    ResponsiveContainer,
    YAxis,
    Line,
    Legend,
    LineChart, RadarChart, BarChart,
} from 'recharts';

const TriangleDot = (props) => {
    const { cx, cy } = props;
    const size = 6; // Size of the triangle

    return (
        <path d={`M${cx},${cy - size} L${cx - size},${cy + size} L${cx + size},${cy + size} Z`} fill="#0d6efd" />
    );
};

const LineChartWithLabel = ({data, name, label, nextLabel, flag}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500}
                       height={400}
                       data={data}
                       margin={{
                           top: 5,
                           right: 5,
                           left: 5,
                           bottom: 5
                       }}>
                <XAxis dataKey={name} height={15}/>
                <YAxis yAxisId="left" domain={[0, 6]} type="number" width={10}/>
                <Legend/>
                <Tooltip/>
                <Line connectNulls yAxisId="left" type="monotone" dataKey={label} layout="horizontal" stroke="#1a80bb"
                      strokeWidth={2}/>
                {flag && <Line connectNulls yAxisId="left" dataKey={nextLabel} layout="horizontal" stroke="#ea801c"
                               strokeWidth={2}/>}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineChartWithLabel;