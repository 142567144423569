import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {faLeftRight} from "@fortawesome/free-solid-svg-icons/faLeftRight";

const StatusIcon = ({value}) => {
    let icon, classForIcon;
    if (value > 4) {
        icon = faArrowUp;
        classForIcon = "arrowGreenFontCss";
    } else if (value < 4) {
        icon = faArrowDown;
        classForIcon = "arrowRedFontCss";
    } else if (value == 4) {
        icon = faLeftRight;
        classForIcon = "arrowBlueFontCss";
    } else {
        icon = "";
        classForIcon = "";
    }

    return (
        <>
            {classForIcon ?
                <FontAwesomeIcon icon={icon} className={classForIcon}/>
                :
                <span></span>}
        </>
    );
};

export default StatusIcon;
