import { createSlice } from '@reduxjs/toolkit'

export const sideBarReducer = createSlice({
    name: "sideBar",
    initialState: {
        "subModules": []
    },
    reducers: {
        updateSubmodule: (state,action) => {
            state.subModules = action.payload.subModules;
        }
    }
});

export const { updateSubmodule } = sideBarReducer.actions;

export default sideBarReducer.reducer;