import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from "../../apis/AxiosInstance";
import {useSelector} from "react-redux";
import useAxiosFunction from "../../hook/AxiosHook";
import {
    getAllArrayValue,
    getArrayValue, getSelectedAllFlag,
    searchArrayByMultiKeyPair, updateMultiSelectSearch
} from "../../commom/CommonFunction";
import {
    getDateInUTC,
    getDateOnly,
    getMonthsBetweenDates,
    getWeeksBetweenDates
} from "../../commom/DateFormat";
import {exportSurveyData} from "../../commom/ExportToExcel";
import ReportExportModal from "./ReportExportForm";
import ReportTableModal from "../reports/ReportTableModal";
import {useOuterClick} from "../../hook/VisibilityHook";
import Loader from "../../commom/LoaderComponent";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import Message from "../../commom/Message";

const ReportExportForm = (props) => {
    const reportOption = useSelector((state) => state.reportOptionReducer.option);
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const plantData = useSelector((state) => state.plantReducer);
    const siteData = useSelector((state) => state.siteReducer);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [plantList, setPlantList] = useState("");
    const [siteList, setSiteList] = useState("");
    const [sitesList, setSitesList] = useState("");
    const [formValue, setFormValue] = useState({
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
        showSite: false,
        selectAllSite: false,
        siteCount: 0,
    });
    const [rowHeader, setRowHeader] = useState("");
    const [tableData, setTableData] = useState([]);
    const [reportHeader, setReportHeader] = useState(null);
    const [showReportTable, setShowReportTable] = useState(false);
    const [reportData, setReportData] = useState("");
    const innerRef = useOuterClick(e => {
        setFormValue({...formValue, showPlant: false});
    });
    const innerRefSite = useOuterClick(e => {
        setFormValue({...formValue, showSite: false});
    });
    const [disableExport, setDisableExport] = useState(true);

    useEffect(() => {
        setPlantList(updateMultiSelectOption(plantData, "plant"));
    }, []);
    const [inputValidation, setInputValidation] = useState({
        plantCount: true
    });
    const fieldValidation = {
        plantCount: "digit",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (rowHeader && tableData && rowHeader.length && tableData.length) {
            setShowReportTable(true);
        }
    }, [tableData, rowHeader]);

    const updateMultiSelectOption = (fetchedValue, label) => {
        return fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });
    };

    const getSites = (plantName) => {
        let sites = [];

        if (plantName && plantName.length) {
            siteData.forEach(obj => {
                let plantObj = Object.assign({}, obj);
                if (plantName.indexOf(plantObj.plant) !== -1) {
                    sites.push({
                        label: plantObj.site,
                        value: false
                    });
                }
            });
        }

        return sites;
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableExport(false);
        setShowMessage(false);

        if (name === "plant") {
            setPlantList(newData);
            let plantNames = getArrayValue(newData);
            let data = getSites(plantNames);
            setSiteList(data);
            setSitesList(data);
            setFormValue({
                ...formValue,
                selectAllPlant: checked,
                plantCount: count,
                selectAllSite: false,
                siteCount: 0
            });

            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "plantCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        } else if (name === "site") {
            setSiteList(newData);
            setFormValue({...formValue, selectAllSite: checked, siteCount: count});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        } else if (name === "site") {
            let flag = formValue.showSite;

            if(!flag) {
                let data = updateMultiSelectSearch(sitesList, "label", "", siteList);
                setSiteList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showSite: !flag, selectAllSite: selectedAll});
            } else {
                setFormValue({...formValue, showSite: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        } else if (name === "site") {
            let data = updateMultiSelectSearch(sitesList, "label", value, siteList);
            setSiteList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllSite: selectedAll});
        }
    };

    const getReportForMissingScorecardSurvey = async () => {
        let selectedSiteDate = getArrayValue(siteList);
        let sites;

        if (selectedSiteDate) {
            sites = selectedSiteDate;
        } else {
            sites = getAllArrayValue(siteList);

            if (!sites) {
                setDisableExport(true);
                return false;
            }
        }
        const scorecard = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getScorecardPlantMapping',
            requestConfig: {
                data: {
                    "site": sites,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        let missingScorecard = [];

        if(scorecard) {
            let dateRangeArray = getWeeksBetweenDates(getDateOnly(startDate), getDateOnly(endDate));
            siteData.forEach(obj => {
                let siteObject = Object.assign({}, obj);
                let isSiteExist = sites.indexOf(siteObject.site) !== -1;

                if (isSiteExist) {
                    dateRangeArray.forEach((obj) => {
                        let date = obj[0] + " To " + obj[1];
                        let checkDataExist = searchArrayByMultiKeyPair(siteObject.site, obj, scorecard.data, "site", "dateOfSurvey");
                        if (!checkDataExist) {
                            let surveyObj = {
                                "Division": siteObject.division,
                                "Plant HC": siteObject.plantHc,
                                "Plant": siteObject.plant,
                                "Site Id": siteObject.siteId,
                                "Site": siteObject.site,
                                "Weeks not Entered": date
                            };

                            missingScorecard.push(surveyObj);
                        }
                    });
                }
            });
        }

        return ({"MissingScorecard": missingScorecard});
    };

    const getReportForMissingUtilizationSurvey = async () => {
        let selectedSiteDate = getArrayValue(siteList);
        let sites;

        if (selectedSiteDate) {
            sites = selectedSiteDate;
        } else {
            sites = getAllArrayValue(siteList);

            if (!sites) {
                setDisableExport(true);
                return false;
            }
        }
        const utilization = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUtilizationPlantMapping',
            requestConfig: {
                data: {
                    "site": sites,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        let missingUtilization = [];

        if (utilization) {
            let dateRangeArray = getMonthsBetweenDates(getDateOnly(startDate), getDateOnly(endDate));
            siteData.map(obj => {
                let siteObject = Object.assign({}, obj);
                let isSiteExist = sites.indexOf(siteObject.site) !== -1;

                if (isSiteExist) {
                    dateRangeArray.forEach((obj) => {
                        let date = obj;
                        let checkMonthExist = searchArrayByMultiKeyPair(siteObject.site, obj, utilization.data, "site", "dateOfSurvey");

                        if (!checkMonthExist) {
                            let surveyObj = {
                                "Division": siteObject.division,
                                "Plant": siteObject.plant,
                                "Plant HC": siteObject.plantHc,
                                "Site Id": siteObject.siteId,
                                "Site": siteObject.site,
                                "Month not Entered": date[0],
                                "Pd Rqd.": siteObject.pdRqd ? siteObject.pdRqd : "",
                                "Adp Rqd.": siteObject.apdRqd ? siteObject.apdRqd : "",
                                "Clean Lbs.": siteObject.cleanLbs ? siteObject.cleanLbs : "",
                                "Inv$": siteObject.inv ? siteObject.inv : "",
                            };

                            missingUtilization.push(surveyObj);
                        }
                    });
                }
            });
        }

        return ({"MissingUtilization": missingUtilization});
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        if (!checkValidate) {
            return false;
        }

        if (reportOption === "Missing Scorecard Report") {
            getReportForMissingScorecardSurvey().then((data) => {
                let rowHeader = [
                    {
                        name: "Division",
                        header: "Division",
                        alignment: "left"
                    },
                    {
                        name: "Plant",
                        header: "Plant",
                        alignment: "left"
                    },
                    {
                        name: "Plant HC",
                        header: "Plant HC",
                        alignment: "left"
                    },
                    {
                        name: "Site Id",
                        header: "Site Id",
                        alignment: "left"
                    },
                    {
                        name: "Site",
                        header: "Site",
                        alignment: "left"
                    },
                    {
                        name: "Weeks not Entered",
                        header: "Weeks not Entered",
                        alignment: "left"
                    }
                ];
                setRowHeader(rowHeader);
                setTableData(data["MissingScorecard"]);
                setReportHeader("Missing Scorecard Report");
                setReportData(data);
            })
        } else if (reportOption === "Missing Utilization Report") {
            getReportForMissingUtilizationSurvey().then((data) => {
                let rowHeader = [
                    {
                        name: "Division",
                        header: "Division",
                        alignment: "left"
                    },
                    {
                        name: "Plant",
                        header: "Plant",
                        alignment: "left"
                    },
                    {
                        name: "Plant HC",
                        header: "Plant HC",
                        alignment: "left"
                    },
                    {
                        name: "Site Id",
                        header: "Site Id",
                        alignment: "left"
                    },
                    {
                        name: "Site",
                        header: "Site",
                        alignment: "left"
                    },
                    {
                        name: "Month not Entered",
                        header: "Month not Entered",
                        alignment: "left"
                    },
                    {
                        name: "Pd Rqd.",
                        header: "Pd Rqd.",
                        alignment: "left"
                    },
                    {
                        name: "Adp Rqd.",
                        header: "Adp Rqd.",
                        alignment: "left"
                    },
                    {
                        name: "Clean Lbs.",
                        header: "Clean Lbs.",
                        alignment: "left"
                    },
                    {
                        name: "Inv$",
                        header: "Inv$",
                        alignment: "left"
                    },
                ];
                setRowHeader(rowHeader);
                setTableData(data["MissingUtilization"]);
                setReportHeader("Missing Utilization Report");
                setReportData(data);
            });
        }
        setShowMessage(true);
    };

    const handleModalClose = () => {
        setShowReportTable(false);
        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"plantCount": true});
    };

    const exportReportFromTable = () => {
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        if (!checkValidate) {
            return false;
        }
        if (reportOption === "Missing Scorecard Report") {
            getReportForMissingScorecardSurvey().then((data) => {
                let newData = data ? data: [{"MissingScorecard":"NO Data"}];
                exportSurveyData(newData, "MissingScorecard.xlsx");
            });
        } else if (reportOption === "Missing Utilization Report") {
            getReportForMissingUtilizationSurvey().then((data) => {
                let newData = data ? data: [{"MissingUtilization":"NO Data"}];
                exportSurveyData(newData, "MissingUtilization.xlsx")
            });
        }
    };

    const exportReportData = () => {
        if (reportOption === "Missing Scorecard Report") {
            exportSurveyData(reportData, "MissingScorecard.xlsx");
        } else if (reportOption === "Missing Utilization Report") {
            exportSurveyData(reportData, "MissingUtilization.xlsx")
        }
    };

    const closeModalBox = () => {
        setFormValue({
            showPlant: false,
            selectAllPlant: false,
            plantCount: 0,
            showSite: false,
            selectAllSite: false,
            siteCount: 0,
        });

        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setSiteList("");
        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"plantCount": true});
        props.handleClose();
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={props.showReportExport} onHide={closeModalBox}  backdrop="static"
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{reportOption}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message="Exported Data Successfully"/>
                    <ReportExportModal plantList={plantList} siteList={siteList}
                                       formValue={formValue} submitHandler={submitHandler}
                                       handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                       handleSearch={handleSearch} exportReportFromTable={exportReportFromTable}
                                       innerRef={innerRef} innerRefSite={innerRefSite} disableExport={disableExport}
                                       inputValidation={inputValidation} response={response} error={error}
                                       loading={loading} showMessage={showMessage} formValidation={formValidation}/>
                    <ReportTableModal rowHeader={rowHeader} tableData={tableData} showReportTable={showReportTable}
                                      handleClose={handleModalClose} header={reportHeader}
                                      exportReportData={exportReportData}/>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ReportExportForm;