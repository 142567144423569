import React from 'react';
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";

const PlantItemTable = (props) => {
    return (
        <table className="table table-bordered">
            <tbody>
            {
                props.child &&
                (props.child).map((value, index) => {
                    return (
                        <>
                            <tr key={index + props.keyIndex} className="tableHeader">
                                <th>Item</th>
                                <th>Inspected</th>
                                <th>Imperfection</th>
                                <th>Inspected</th>
                                <th>Imperfection</th>
                                <th>Imperfection Type</th>
                            </tr>
                            <tr key={value.itemCategory + props.keyIndex} className="text-center">
                                <td>{value.itemCategory}</td>
                                <td>{value.Inspected}</td>
                                <td>{value.Imperfection}</td>
                                <td className="pieChartClass">
                                    {<BarChartWithTopLabel data={value.chart} label="Inspected"
                                               lineColor="#008000" name="date"/>}
                                </td>
                                <td className="pieChartClass">
                                    {value.Imperfection > 0 && <BarChartWithTopLabel data={value.chart} label="Imperfection"
                                               lineColor="#008000" name="date"/>}
                                </td>
                                <td className="pieChartClass">
                                    {value.Imperfection > 0 && <BarChartWithTopLabel data={value.imperfectionTypeChart} label="Imperfection"
                                               lineColor="#008000" name="imperfectionType"/>}
                                </td>
                            </tr>
                        </>
                    )
                })
            }
            </tbody>
        </table>
    )
};

export default PlantItemTable;