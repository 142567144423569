import React, {useContext, useEffect, useRef, useState} from "react";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import {handleCheckboxInputChange, handleInputChange} from "../../commom/FormHandler";
import {
    clientSatisfactionChart,
    endUserChart,
    getSelectedArray,
    getSites,
    getUserAccessToUrl,
    qualityChart,
    searchArray,
    surveyRelatedChart, trimObjectValues
} from "../../commom/CommonFunction";
import {
    getCurrentDateOnly,
    getDateInUTC
} from "../../commom/DateFormat";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AuthContext} from "../../context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "./SummaryOfDay.css";
import SummaryOfDayForm from "./SummaryOfDayForm";
import SummaryOfDayChart from "./SummaryOfDayChart";
import SummaryOfDayTable from "./SummaryOfDayTable";
import Col from "react-bootstrap/Col";
import {updateChartData} from "../../reducer/ImageOfChartReducer";
import {getOnlySurveyReportHeader} from "../../commom/CommonReportFunction";
import {validationCheck} from "../../commom/ValidateFormData";
import PdfGenerator from "./PdfGenerator";
import Message from "../../commom/Message";

const SummaryOfDay = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const user = useSelector((state) => state.userState.user);
    const [formValue, setFormValue] = useState({
        comment: "",
        emailSubject: "",
        userName: "",
        plant: "",
        site: "",
        chartValue: "BarChart",
        defaultDate: true,
        startDate: getCurrentDateOnly(),
        endDate: getCurrentDateOnly(),
        emailAddress: "",
        siteContact: "",
        siteContactEmail: "",
    });
    const [siteList, setSiteList] = useState();
    const [plantList, setPlantList] = useState();
    const [sitesData, setSitesData] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const [chartExport, setChartExport] = useState("");
    const [clientStatHeaderData, setClientStatHeaderData] = useState("");
    const [clientStatBodyData, setClientStatBodyData] = useState("");
    const [linenHeaderData, setLinenHeaderData] = useState("");
    const [linenBodyData, setLinenBodyData] = useState("");

    const [parHeaderData, setPARHeaderData] = useState("");
    const [parBodyData, setPARBodyData] = useState("");
    const [qbrHeaderData, setQBRHeaderData] = useState("");
    const [qbrBodyData, setQBRBodyData] = useState("");
    const [linenAwarenessHeaderData, setLinenAwarenessHeaderData] = useState("");
    const [linenAwarenessBodyData, setLinenAwarenessBodyData] = useState("");
    const [linenCommitteeHeaderData, setLinenCommitteeHeaderData] = useState("");
    const [linenCommitteeBodyData, setLinenCommitteeBodyData] = useState("");
    const [linenLossHeaderData, setLinenLossHeaderData] = useState("");
    const [linenLossBodyData, setLinenLossBodyData] = useState("");
    const [siteEmailList, setSiteEmailList] = useState("");
    const chartRef = useRef(null);
    const secondChartRef = useRef(null);

    const tableClientRef = useRef(null);
    const tableLinenRef = useRef(null);
    const tablePARRef = useRef(null);
    const tableQBRRef = useRef(null);
    const tableLinenAwarenessRef = useRef(null);
    const tableLinenCommitteeRef = useRef(null);
    const tableLinenLossRef = useRef(null);
    const [surveyData, setSurveyData] = useState("");
    const [linenImperfection, setLinenImperfection] = useState();
    const [linenItem, setLinenItem] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showEmailMessage, setShowEmailMessage] = useState(true);
    const [emailValidationMessage, setEmailValidationMessage] = useState("Please fill the email address.");
    const [screenMessage, setScreenMessage] = useState("Export Pdf successfully.");

    useEffect(() => {
        dispatch(setDateRange());
        dispatch(showDateInHeader(false));

        getUserDetail().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;

            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setPlantList(response.plants);
            setSiteList(response.sites);
            setSitesData(response.sites);
            setSurveyData(response.surveys);
            let imperfectionList = [];
            let itemList = [];
            if (response.surveys && response.surveys.length) {
                let data = (response.surveys).filter((data) => data.title === "Onsite Linen Inspection");

                data.forEach((obj) => {
                    if (obj.questions && obj.questions.length) {
                        obj.questions.forEach((detail) => {
                            if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected") {
                                imperfectionList.push(detail.fieldName);
                            }

                            if (detail.fieldName === "Item") {

                                detail.answers.forEach((answersObj) => {
                                    itemList.push(answersObj.displayValue);
                                });

                            }
                        })
                    }
                });
                setLinenImperfection(imperfectionList);
                setLinenItem(itemList);
            }

            if (response.plants && response.plants.length > 0 && response.sites && response.sites.length > 0) {
                let siteData = getSites(response.plants[0]._id, response.sites);

                if (siteData && siteData.length) {
                    setSiteList(siteData);
                    setFormValue({
                        ...formValue,
                        plant: response.plants[0]._id,
                        site: siteData[0].site,
                    });

                    createReportData(siteData[0].site, response.surveys);
                    setSiteEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                }
            }
        });
    }, []);

    useEffect(() => {
        if (formValue.site) {
            if (formValue.defaultDate && surveyData && surveyData.length > 0) {
                createReportData(formValue.site, surveyData);
            } else if (!formValue.defaultDate && surveyData && surveyData.length > 0 && (formValue.startDate && formValue.endDate && formValue.endDate >= formValue.startDate)) {
                createReportData(formValue.site, surveyData);
            }
        }
    }, [formValue.defaultDate, formValue.startDate, formValue.endDate, surveyData]);

    const updateMultiSelectOption = (fetchedValue, formValueSite) => {
        let siteObj = searchArray(formValueSite, fetchedValue, "site");
        let newArray = [];
        for (let key in siteObj) {
            if (["contactName1", "contactName2", "contactName3", "contactName4", "contactName5"].indexOf(key) > -1) {
                if (siteObj[key]) {
                    let contactName = "";
                    let ketLastValue = key.slice(-1);
                    if (["1", "2", "3", "4", "5"].indexOf(ketLastValue) > -1) {
                        let userKey = "contactEmail" + ketLastValue;
                        contactName = siteObj[userKey];
                    }
                    let obj = {
                        label: siteObj[key],
                        value: false,
                        userContactName: contactName
                    };

                    newArray.push(obj);
                }
            }
        }

        return newArray.length ? newArray : "";
    };

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetail = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const updateListSelection = (event) => {
        if (event.currentTarget) {
            let eventObject = {
                "currentTarget": {
                    "value": event.currentTarget.innerText,
                    "name": event.currentTarget.getAttribute("name"),
                }
            };
            handleChange(eventObject)
        }
    };

    const handleCheckboxLabelClick = (event) => {
        setShowMessage(false);
        setShowEmailMessage(true);

        const currentFormState = handleCheckboxInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const handleChange = (event, flag = "") => {
        setShowMessage(false);
        setShowEmailMessage(true);

        if (!flag) {
            const currentFormState = handleInputChange(event, formValue);
            currentFormState.siteContactEmail = "";
            if(event.currentTarget.name === "siteContact") {
                let siteContactData = searchArray(event.currentTarget.value, siteEmailList, "label");

                if(siteContactData) {
                    currentFormState.siteContactEmail = siteContactData.userContactName;
                }
            }
            if (event.currentTarget.name !== "plant" || event.currentTarget.name !== "site") {
                setFormValue(currentFormState);
            }

            if (event.currentTarget.name === "plant") {
                let siteData = getSites(event.currentTarget.value, sitesData);

                if (siteData && siteData.length) {
                    setSiteList(siteData);
                    setFormValue({
                        ...formValue,
                        plant: event.currentTarget.value,
                        site: siteData[0].site,
                        siteContact: "",
                        siteContactEmail: ""
                    });

                    setSiteEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                    createReportData(siteData[0].site, surveyData);
                }
            } else if (event.currentTarget.name === "site") {
                setFormValue({...formValue, site: event.currentTarget.value, siteContact: "",
                    siteContactEmail: ""});
                setSiteEmailList(updateMultiSelectOption(siteList, event.currentTarget.value));
                createReportData(event.currentTarget.value, surveyData);
            }
        } else {
            let newObj = {
                "currentTarget": {
                    "name": event.name,
                    "value": event.transcript
                }
            };
            const currentFormState = handleInputChange(newObj, formValue);
            setFormValue(currentFormState);
        }
    };

    const createReportData = (siteValue, surveysArray) => {
        let dataExportByAttribute = {};

        getReportData(siteValue).then(response => {
            dataExportByAttribute["LinenQuality&Availability"] = getSelectedArray("Linen Quality & Availability", "survey", response.data);
            dataExportByAttribute["ClientSatisfactionRawData"] = getSelectedArray("Client Satisfaction", "survey", response.data);
            dataExportByAttribute["OnsiteLinenInspectionRawData"] = getSelectedArray("Onsite Linen Inspection", "survey", response.data);
            dataExportByAttribute["PARRawData"] = getSelectedArray("PAR", "survey", response.data);
            dataExportByAttribute["QBRRawData"] = getSelectedArray("QBR", "survey", response.data);
            dataExportByAttribute["LinenAwarenessRawData"] = getSelectedArray("Linen Awareness", "survey", response.data);
            dataExportByAttribute["LinenCommitteeRawData"] = getSelectedArray("Linen Committee", "survey", response.data);
            dataExportByAttribute["LinenLossRawData"] = getSelectedArray("Linen Loss", "survey", response.data);

            let clientStatHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "Client Satisfaction");
            let linenHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "Linen Quality & Availability");
            let parHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "PAR");
            let qbrHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "QBR");
            let linenAwarenessHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "Linen Awareness");
            let linenCommitteeHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "Linen Committee");
            let linenLossHeaderForPDF = getOnlySurveyReportHeader(surveysArray, "Linen Loss");

            let fieldNameClientHeader = clientStatHeaderForPDF.map((obj) => obj.name);
            let fieldNameLinenHeader = linenHeaderForPDF.map((obj) => obj.name);
            let fieldNameParHeader = parHeaderForPDF.map((obj) => obj.name);
            let fieldNameQbrHeader = qbrHeaderForPDF.map((obj) => obj.name);
            let fieldNameLinenAwarenessHeader = linenAwarenessHeaderForPDF.map((obj) => obj.name);
            let fieldNameLinenCommitteeHeader = linenCommitteeHeaderForPDF.map((obj) => obj.name);
            let fieldNameLinenLossHeader = linenLossHeaderForPDF.map((obj) => obj.name);

            let endUserAllData = endUserChart(dataExportByAttribute["LinenQuality&Availability"], fieldNameLinenHeader);
            let clientSatisfactionAllData = clientSatisfactionChart(dataExportByAttribute["ClientSatisfactionRawData"], fieldNameClientHeader);
            let onsiteLinenInspectionAllData = qualityChart(dataExportByAttribute["OnsiteLinenInspectionRawData"], linenItem);
            let parAllData = surveyRelatedChart(dataExportByAttribute["PARRawData"], fieldNameParHeader);
            let qbrAllData = surveyRelatedChart(dataExportByAttribute["QBRRawData"], fieldNameQbrHeader);
            let linenAwarenessAllData = surveyRelatedChart(dataExportByAttribute["LinenAwarenessRawData"], fieldNameLinenAwarenessHeader);
            let linenCommitteeAllData = surveyRelatedChart(dataExportByAttribute["LinenCommitteeRawData"], fieldNameLinenCommitteeHeader);
            let linenLossAllData = surveyRelatedChart(dataExportByAttribute["LinenLossRawData"], fieldNameLinenLossHeader);

            let endUserChartData = endUserAllData.rawData;
            let clientSatisfactionRawData = clientSatisfactionAllData.rawData;
            let onsiteLinenInspectionChartData = onsiteLinenInspectionAllData["onSiteChartArray"];
            let parRawData = parAllData.rawData;
            let qbrRawData = qbrAllData.rawData;
            let linenAwarenessRawData = linenAwarenessAllData.rawData;
            let linenCommitteeRawData = linenCommitteeAllData.rawData;
            let linenLossRawData = linenLossAllData.rawData;

            let clientStatHeader = clientStatHeaderForPDF;
            let linenHeader = linenHeaderForPDF;

            const chartExportData = [
                {
                    type: 'barDouble',
                    width: 900,
                    height: 400,
                    margin: {top: 20, right: 20, left: 20, bottom: 20},
                    data: endUserAllData.deptTotalChart,
                    dataKey: "Quality",
                    dataKey2: "Availability",
                    headerName: "Dept Linen Feedback",
                    name: "Department",
                    flag: true,
                    selectedChart: "BarChart"
                },
                {
                    type: 'barDouble',
                    width: 700,
                    height: 350,
                    margin: {top: 20, right: 20, left: 20, bottom: 20},
                    data: onsiteLinenInspectionChartData,
                    dataKey: "Inspected",
                    dataKey2: "Flaw",
                    headerName: "Onsite Linen Inspection",
                    name: "Item",
                    flag: true,
                    selectedChart: "RadarChart"
                },
            ];

            setChartExport(chartExportData);
            setClientStatHeaderData(clientStatHeader);
            setClientStatBodyData(clientSatisfactionRawData);
            setLinenHeaderData(linenHeader);
            setLinenBodyData(endUserChartData);

            setPARHeaderData(parHeaderForPDF);
            setPARBodyData(parRawData);
            setQBRHeaderData(qbrHeaderForPDF);
            setQBRBodyData(qbrRawData);
            setLinenAwarenessHeaderData(linenAwarenessHeaderForPDF);
            setLinenAwarenessBodyData(linenAwarenessRawData);
            setLinenCommitteeHeaderData(linenCommitteeHeaderForPDF);
            setLinenCommitteeBodyData(linenCommitteeRawData);
            setLinenLossHeaderData(linenLossHeaderForPDF);
            setLinenLossBodyData(linenLossRawData);
            dispatch(updateChartData(chartExportData));
        });
    };

    const getReportData = async (siteValue) => {
        setShowEmailMessage(true);
        let todayDate = getCurrentDateOnly();
        let filteredArray = {
            "sites": siteValue,
            "startDate": getDateInUTC(formValue.startDate),
            "endDate": getDateInUTC(formValue.endDate)
        };
        if (formValue.defaultDate) {
            filteredArray["startDate"] = todayDate;
            filteredArray["endDate"] = todayDate;
        }

        return await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getReportData',
            requestConfig: {
                data: filteredArray
            }
        })
    };

    const submitHandler = async (flag) => {
        setShowEmailMessage(true);
        setShowMessage(true);

        let newEntry = trimObjectValues({...formValue});
        let newData = {};
        let plantObject = searchArray(formValue.plant, plantList, "_id");
        let siteObject = searchArray(formValue.site, sitesData, "site");
        newData["userId"] = user._id;
        newData["email"] = user.email;
        newData["plant"] = newEntry.plant;
        newData["plantId"] = plantObject.houseCode;
        newData["site"] = newEntry.site;
        newData["siteId"] = siteObject.siteId;
        newData["isActive"] = true;
        newData["defaultDate"] = newEntry.defaultDate;
        newData["startDate"] = getDateInUTC(newEntry.startDate);
        newData["endDate"] = getDateInUTC(newEntry.endDate);
        newData["receiverName"] = newEntry.userName;
        newData["senderName"] = user.firstName + " " + user.lastName;
        newData["senderEmail"] = newEntry.emailAddress;
        newData["subjectOfEmail"] = newEntry.emailSubject ? newEntry.emailSubject : "Summary Report";
        newData["comment"] = newEntry.comment;
        newData["siteContact"] = newEntry.siteContact === "select" ? "" : newEntry.siteContact;
        newData["siteContactEmail"] = newEntry.siteContactEmail ? newEntry.siteContactEmail : "";
        newData["chartExport"] = chartExport;
        newData["clientStatHeaderData"] = clientStatHeaderData;
        newData["clientStatBodyData"] = clientStatBodyData;
        newData["linenHeaderData"] = linenHeaderData;
        newData["linenBodyData"] = linenBodyData;
        newData["parHeaderData"] = parHeaderData;
        newData["parBodyData"] = parBodyData;
        newData["qbrHeaderData"] = qbrHeaderData;
        newData["qbrBodyData"] = qbrBodyData;
        newData["linenAwarenessHeaderData"] = linenAwarenessHeaderData;
        newData["linenAwarenessBodyData"] = linenAwarenessBodyData;
        newData["linenCommitteeHeaderData"] = linenCommitteeHeaderData;
        newData["linenCommitteeBodyData"] = linenCommitteeBodyData;
        newData["linenLossHeaderData"] = linenLossHeaderData;
        newData["linenLossBodyData"] = linenLossBodyData;

        await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSummaryOfDay',
            requestConfig: {
                data: newData
            }
        });
        setScreenMessage("The PDF has been saved successfully and exported.");
    };

    const sendPDFToServer = async (pdfBuffer) => {
        setShowMessage(false);

        let senderEmail = formValue.siteContactEmail ? formValue.siteContactEmail : "";
        let checkEmail = validationCheck("email", formValue.emailAddress);
        if(senderEmail || checkEmail) {
            setShowEmailMessage(true);
        } else {
            setShowEmailMessage(false);
            return false;
        }

        let newEntry = trimObjectValues({...formValue});
        let newData = {};
        let plantObject = searchArray(formValue.plant, plantList, "_id");
        let siteObject = searchArray(formValue.site, sitesData, "site");
        newData["siteContactEmail"] = newEntry.siteContactEmail ? newEntry.siteContactEmail : "";
        newData["userId"] = user._id;
        newData["email"] = user.email;
        newData["plant"] = newEntry.plant;
        newData["plantId"] = plantObject.houseCode;
        newData["site"] = newEntry.site;
        newData["siteId"] = siteObject.siteId;
        newData["isActive"] = true;
        newData["defaultDate"] = newEntry.defaultDate;
        newData["startDate"] = getDateInUTC(newEntry.startDate);
        newData["endDate"] = getDateInUTC(newEntry.endDate);
        newData["receiverName"] = newEntry.userName;
        newData["senderName"] = user.firstName + " " + user.lastName;
        newData["senderEmail"] = newEntry.emailAddress;
        newData["subjectOfEmail"] = newEntry.emailSubject ? newEntry.emailSubject : "Summary Report";
        newData["comment"] = newEntry.comment;
        newData["siteContact"] = newEntry.siteContact;
        newData["chartExport"] = chartExport;
        newData["clientStatHeaderData"] = clientStatHeaderData;
        newData["clientStatBodyData"] = clientStatBodyData;
        newData["linenHeaderData"] = linenHeaderData;
        newData["linenBodyData"] = linenBodyData;
        newData["parHeaderData"] = parHeaderData;
        newData["parBodyData"] = parBodyData;
        newData["qbrHeaderData"] = qbrHeaderData;
        newData["qbrBodyData"] = qbrBodyData;
        newData["linenAwarenessHeaderData"] = linenAwarenessHeaderData;
        newData["linenAwarenessBodyData"] = linenAwarenessBodyData;
        newData["linenCommitteeHeaderData"] = linenCommitteeHeaderData;
        newData["linenCommitteeBodyData"] = linenCommitteeBodyData;
        newData["linenLossHeaderData"] = linenLossHeaderData;
        newData["linenLossBodyData"] = linenLossBodyData;
        newData["pdfBuffer"] = Array.from(pdfBuffer);
        setShowMessage(true);

        try {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/sendPdfAttachmentByEmail',
                requestConfig: {
                    data: newData,
                },
            });

            if (response.data === "Successfully sent an email!") {
                setScreenMessage("The PDF has been saved successfully and sent via email.");
            } else {
                setScreenMessage("Failed to send PDF. Please try again.");
            }
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading || showLoading}/>
            <Message showMessage={showMessage}
                     response={response} error={error} loading={loading}
                     formValidation={showEmailMessage} formValidationMessage={emailValidationMessage}
                     message={screenMessage}/>
            <div className="justify-content-md-center">
                <SummaryOfDayForm handleChange={handleChange} formValue={formValue} plantList={plantList}
                                  siteList={siteList} response={response} error={error} loading={loading}
                                  updateListSelection={updateListSelection} showEmailMessage={showEmailMessage}
                                  handleCheckboxLabelClick={handleCheckboxLabelClick} siteEmailList={siteEmailList}
                />
            </div>

            <div className="d-flex justify-content-end my-1">
                <Link to="/summaryOfDayScreen"><Button className="buttonCss">Summary Of Day Listing</Button></Link>
                {chartExport.length > 0 &&
                <PdfGenerator userName={formValue.userName} siteContact={formValue.siteContact} user={user}
                              chartRef={chartRef.current} secondChartRef={secondChartRef.current}
                              defaultDate={formValue.defaultDate}
                              startDate={formValue.startDate} endDate={formValue.endDate}
                              emailSubject={formValue.emailSubject} site={formValue.site} comment={formValue.comment}
                              submitHandler={submitHandler} sendPDFToServer={sendPDFToServer}
                              clientStatHeaderData={clientStatHeaderData} clientStatBodyData={clientStatBodyData}
                              linenHeaderData={linenHeaderData} linenBodyData={linenBodyData}
                              parHeaderData={parHeaderData} parBodyData={parBodyData} qbrHeaderData={qbrHeaderData}
                              qbrBodyData={qbrBodyData} linenAwarenessHeaderData={linenAwarenessHeaderData}
                              linenAwarenessBodyData={linenAwarenessBodyData}
                              linenCommitteeHeaderData={linenCommitteeHeaderData}
                              linenCommitteeBodyData={linenCommitteeBodyData} linenLossHeaderData={linenLossHeaderData}
                              linenLossBodyData={linenLossBodyData} chartExport={chartExport}
                              emailAddress={formValue.emailAddress} siteContactEmail={formValue.siteContactEmail}/>
                }
            </div>
            {chartExport && chartExport.map((chartObj, index) => {
                if (index === 0) {
                    return <>
                        <div className="facilityHeader text-center">{chartObj.headerName}</div>
                        <Row className="justify-content-md-center marginUnset" ref={chartRef} key={chartObj.headerName + index}>
                            <Col xs={12} className="chartDataForPdf colBorder"
                                 key={chartObj.headerName + index}>
                                <SummaryOfDayChart chartData={chartObj.data} label={chartObj.dataKey}
                                                   nextLabel={chartObj.dataKey2} name={chartObj.name}
                                                   headerName={chartObj.headerName} flag={chartObj.flag}
                                                   selectedChart={chartObj.selectedChart}/></Col>
                        </Row></>
                } else {
                    return <>
                        <div className="facilityHeader text-center">{chartObj.headerName}</div>
                        <Row className="justify-content-md-center marginUnset" ref={secondChartRef}>
                            <Col xs={12} className="chartDataForPdf colBorder"
                                 key={chartObj.headerName + index}>
                                <SummaryOfDayChart chartData={chartObj.data} label={chartObj.dataKey}
                                                   nextLabel={chartObj.dataKey2} name={chartObj.name}
                                                   headerName={chartObj.headerName} flag={chartObj.flag}
                                                   selectedChart={chartObj.selectedChart}/></Col>
                        </Row></>
                }
            })

            }
            <>
                <Row className="justify-content-md-center marginUnset" ref={tableClientRef}>
                    <SummaryOfDayTable tableHeader={clientStatHeaderData} tableData={clientStatBodyData}
                                       headerName="Client Stat"/>
                </Row>
                <Row className="justify-content-md-center marginUnset" ref={tableLinenRef}>
                    <SummaryOfDayTable tableHeader={linenHeaderData} tableData={linenBodyData}
                                       headerName="Linen Quality & Availability"/></Row>
                <Row className="justify-content-md-center marginUnset" ref={tablePARRef}>
                    <SummaryOfDayTable tableHeader={parHeaderData} tableData={parBodyData}
                                       headerName="PAR"/></Row>
                <Row className="justify-content-md-center marginUnset" ref={tableQBRRef}>
                    <SummaryOfDayTable tableHeader={qbrHeaderData} tableData={qbrBodyData}
                                       headerName="QBR"/></Row>
                <Row className="justify-content-md-center marginUnset" ref={tableLinenAwarenessRef}>
                    <SummaryOfDayTable tableHeader={linenAwarenessHeaderData} tableData={linenAwarenessBodyData}
                                       headerName="Linen Awareness"/></Row>
                <Row className="justify-content-md-center marginUnset" ref={tableLinenCommitteeRef}>
                    <SummaryOfDayTable tableHeader={linenCommitteeHeaderData} tableData={linenCommitteeBodyData}
                                       headerName="Linen Committee"/></Row>
                <Row className="justify-content-md-center marginUnset" ref={tableLinenLossRef}>
                    <SummaryOfDayTable tableHeader={linenLossHeaderData} tableData={linenLossBodyData}
                                       headerName="Linen Loss"/></Row>
            </>
        </Container>
    );
};

export default SummaryOfDay;
