import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const SurveyQuestionReducer = createSlice({
    name: "surveyQuestionReducer",
    initialState,
    reducers: {
        setSurveyQuestionData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});
export const {setSurveyQuestionData} = SurveyQuestionReducer.actions;

export default SurveyQuestionReducer.reducer;