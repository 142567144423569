import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useAuth } from './hook/AuthHook';
import React,{ Suspense, lazy } from "react";
import AuthLogin from './Auth';
import {AuthContext} from './context/AuthContext';
import Login from "./component/login/Login";
import Dashboard from "./component/dashboard/Dashboard";
import Role from "./component/role/Role";
import CardContainer from "./component/dashboard/CardContainer";
import Idn from "./component/idn/Idn";
import Plant from "./component/plant/Plant";
import Site from "./component/site/Site";
import MultiSite from "./component/multiSite/MultiSite";
import PlantConfig from "./component/plantconfig/PlantConfig";
import User from "./component/user/User";
import Survey from "./component/survey/Survey";
import PlantQASurvey from "./component/plantQASurvey/PlantQASurvey";
import SurveyGroup from "./component/surveyGroup/SurveyGroup";
import PlantQAConfig from "./component/plantQAConfig/PlantQAConfig";
import PlantQualityAudit from "./component/plantQualityAudit/PlantQualityAudit";
import QualityAuditDashboard from "./component/qualityAuditDashboardOverview/QualityAuditDashboard";
import QualityDashboardDetail from "./component/qualityAuditDashboardOverview/QualityDashboardDetail";
import QualityDashboardToday from "./component/qualityAuditDashboardOverview/QualityDashboardToday";
import FacilitySurveyDashboard from "./component/facilitySurveyDashboard/FacilitySurveyDashboard";
import ScoreCard from "./component/scorecard/Scorecard";
import Utilization from "./component/utilization/Utilization";
import DefaultPage from "./component/defaultPage/DefaultPage";
import ExternalClientSurvey from "./component/externalClientSurvey/ExternalClientSurvey";
import CustomerServicePerformance from "./component/customerServicePerformance/CustomerServicePerformance";
import ReportContainer from "./component/reports/ReportContainer";
import SurveyReport from "./component/surveyReport/SurveyReport";
import UserLog from "./component/userLog/UserLog";
import ErrorLog from "./component/errorLog/ErrorLog";
import SamSummaryOfDay from "./component/samSummaryOfDay/SamSummaryOfDay";
import AddNewFacilityRequest from "./component/addNewfacilityRequest/AddNewFacilityRequest";
import FacilityScreen from "./component/facilitySurveyDashboard/FacilityScreen";
import SummaryOfDayScreen from "./component/summaryOfDayScreen/SummaryOfDayScreen";
import SummaryOfDay from "./component/summaryOfDay/SummaryOfDay";
import SamSummaryScreen from "./component/samSummaryScreen/SamSummaryScreen";
function App() {
    const { token, login, logout, userId } = useAuth();
    let routes = (
        <React.Fragment>
            <Route element={<AuthLogin />}>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/externalSurvey/:uniqueId"
                    loader={({ params }) => {}}
                    action={({ params }) => {}}
                    element={<ExternalClientSurvey/>} />
            </Route>
            <Route element={<Dashboard />}>
                <Route path="/dashboard" element={<CardContainer/>} />
                <Route path="/role" element={<Role/>} />
                <Route path="/idn" element={<Idn/>} />
                <Route path="/plant" element={<Plant/>} />
                <Route path="/site" element={<Site/>} />
                <Route path="/multiSite" element={<MultiSite/>} />
                <Route path="/plantconfig" element={<PlantConfig/>} />
                <Route path="/user" element={<User/>} />
                <Route path="/survey" element={<Survey/>} />
                <Route path="/surveyGroup" element={<SurveyGroup/>} />
                <Route path="/plantSurvey" element={<PlantQASurvey/>} />
                <Route path="/plantQAConfig" element={<PlantQAConfig/>} />
                <Route path="/plantQualityAudit" element={<PlantQualityAudit/>} />
                <Route path="/qualityAuditDashboard" element={<QualityAuditDashboard/>}/>
                <Route path="/qualityDetail" element={<QualityDashboardDetail/>}/>
                <Route path="/qualityTodayDetail" element={<QualityDashboardToday/>}/>
                <Route path="/facilitySurveyDashboard" element={<FacilityScreen/>}/>
                <Route path="/scorecard" element={<ScoreCard/>}/>
                <Route path="/utilization" element={<Utilization/>}/>
                <Route path="/customerServicePerformance" element={<CustomerServicePerformance/>}/>
                <Route path="/reports" element={<ReportContainer/>}/>
                <Route path="/surveyReport" element={<SurveyReport/>}/>
                <Route path="/userLog" element={<UserLog/>}/>
                <Route path="/errorLog" element={<ErrorLog/>}/>
                <Route path="/defaultPage" element={<DefaultPage/>}/>
                <Route path="/summaryOfDay" element={<SummaryOfDay/>}/>
                <Route path="/samSummaryOfDay" element={<SamSummaryOfDay/>}/>
                <Route path="/summaryOfDayScreen" element={<SummaryOfDayScreen/>}/>
                <Route path="/samSummaryScreen" element={<SamSummaryScreen/>}/>

                <Route path="/newFacilityRequest" element={<AddNewFacilityRequest/>}/>
            </Route>
            <Route
                path="*"
                element={
                    <div style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                    </div>
                }
            />
        </React.Fragment>

    );

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout,
            }}
        >
        <BrowserRouter>
            <Routes>
                {routes}
            </Routes>
        </BrowserRouter>
        </AuthContext.Provider>
  );
}

export default App;
