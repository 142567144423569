import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const SurveyForm = (props) => {
    return (
        <form className="formContainer w-100 p-3">
            <Row>

                <div className="form-group col">
                    <label htmlFor="title" className="col-form-label"><span className="text-danger starCss">*</span>Title</label>
                    <div>
                        <input type="text" name="title"
                               onChange={props.handleChange} value={props.formValue.title}
                               disabled={props.isReadOnly}  className={`form-control ${!props.inputValidation.title ? "errorDiv" : ""}`}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="frequency" className="col-form-label"><span className="text-danger starCss">*</span>Frequency</label>
                    <div>
                        <select onChange={props.handleChange} name="frequency"
                                className={`form-select ${!props.inputValidation.frequency ? "errorDiv" : ""}`}
                                value={props.formValue.frequency} disabled={props.isReadOnly}>
                            <option value="select">Select</option>
                            {
                                props.frequencyList && props.frequencyList.length
                                && props.frequencyList.map((obj, index) => {
                                    return <option key={obj._id + index} value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group col">
                    <label htmlFor="category" className="col-form-label"><span className="text-danger starCss">*</span>Category</label>
                    <div>
                        <select onChange={props.handleChange} name="category"
                                className={`form-select ${!props.inputValidation.category ? "errorDiv" : ""}`}
                                value={props.formValue.category} >
                            <option value="Select">Select</option>
                            {
                                props.categoryList && props.categoryList.length
                                && props.categoryList.map((obj, index) => {
                                    return <option key={obj._id + index} value={obj.value}>{obj.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="siteRequire" className="col-form-label">Site require</label>
                    <div>
                        <input type="checkbox" className="form-check-input" id="siteRequire" name="siteRequire"
                               onChange={props.handleChange} checked={props.formValue.siteRequire} disabled={props.isReadOnly}/>
                    </div>
                </div>

                <div className="form-group col">
                    <label htmlFor="active" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" id="active" name="active"
                               onChange={props.handleChange} checked={props.formValue.active}/>
                    </div>
                </div>

                <div className="form-group col">
                    <label htmlFor="sendEmail" className="col-form-label">Email Send</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="sendEmail"
                               onChange={props.handleChange} checked={props.formValue.sendEmail}/>
                    </div>
                </div>

                <div className="form-group col">
                    <label htmlFor="showOnReport" className="col-form-label">Show On Report</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="showOnReport"
                               onChange={props.handleChange} checked={props.formValue.showOnReport}/>
                    </div>
                </div>
            </Row>
            <Row>
                <Col>
                    <label className="col-form-label">
                        Survey Group List
                    </label>
                    <Link to="/surveyGroup"><FontAwesomeIcon icon={faCog} className="px-1"/></Link>
                </Col>
                <Row>
                    {props.surveyGroupData && props.surveyGroupData.length
                    && props.surveyGroupData.map((obj, index) => {
                        return <Col key={index + obj} xs={6}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="plant"
                                       data-label={obj.label}
                                       id={obj.label}
                                       checked={obj.value} onChange={props.handleCheckboxChange}/>
                                <label className="form-check-label" htmlFor={obj.label}>{obj.label}</label>
                            </div>
                        </Col>

                    })
                    }
                </Row>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button onClick={props.handleOpen}  className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default SurveyForm;