import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import styled from "@emotion/styled";
import TopContainer from "./TopContainer";
import BodyContainer from "./BodyContainer";
import FormContainer from "./FormContainer";
import {IdleTimeOutModal} from "../../commom/IdleTimeOutModal";
import {logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const theme = useSelector((state) => state.theme);
    const [showIdealModal, setShowIdealModal] = useState(false);
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let user = JSON.parse(localStorage.getItem('userDetail'));

    useEffect(() => {
        if(user) {
            window.localStorage.setItem('idle_time_2', 1);
            document.addEventListener('click', handleClickInside);
            return () => document.removeEventListener('click', handleClickInside);
        }
    }, []);

    const handleClickInside = () => {
        window.localStorage.setItem('idle_time_2', 1);
    };

    useEffect(() => {
        if(user) {
            const interval = setInterval(idleTime2, 1000);
            return () => {
                clearInterval(interval)
            };
        } else {
            handleLogout();
        }
    }, []);

    function idleTime2() {
        let current_idle_time = parseInt(window.localStorage.getItem('idle_time_2'));

        if (!current_idle_time) {
            window.localStorage.setItem('idle_time_2', 1);
        } else {
            window.localStorage.setItem('idle_time_2', (current_idle_time) + 1);
        }

        if(current_idle_time === 3300) {
            console.log("beforelogout");
            setShowIdealModal(true)
        }
        if(current_idle_time > 3600) {
            console.log("logout");
            handleLogout()
        }
    }

    const handleLogout = ()=> {
        setShowIdealModal(false);
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const handleClose = () => {
        setShowIdealModal(false);
        handleLogout();
    };

    const keepSessionAlive = () => {
        window.localStorage.setItem('idle_time_2', 1);
        setShowIdealModal(false);
    };

    const MainDiv = styled.div`
          background-color: ${theme.backgroundColor};
          height: 100vh
     `;

    return (
        <>
            <FormContainer/>
            <MainDiv id="launch-index-content" >
                <Container fluid>
                    <TopContainer/>
                    <BodyContainer/>

                    <IdleTimeOutModal
                        showModal={showIdealModal}
                        handleClose={handleClose}
                        keepSessionAlive={keepSessionAlive}
                    />
                </Container>
            </MainDiv>
        </>
    )
};

export default Dashboard;