import React from 'react';
import LineChartWithLabel from "../../commom/LineChartWithLabel";
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";
import RadarChartWithLabel from "../../commom/RadarChartWithLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faMinusSquare, faPlusSquare} from "@fortawesome/fontawesome-free-solid";
import FacilitySiteData from "./FacilitySiteData";
import {formatNumberWithCommas} from "../../commom/CommonFunction";
import StatusIcon from "./StatusIcon";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";

const FacilityIdnData = ({plants, linenImperfection, getChartDetail, chartColumn, showSiteDataForIdn, getChartFullScreenDetail}) => {
    return (
        <>
            {
                plants && plants.length ?
                    (plants).map((value, index) => {
                        return (
                            <>
                                <tr key={value.plant + index} className="text-center">
                                    <td className="colTitle">
                                        <div onClick={() => showSiteDataForIdn(value.idn, false)}>
                                            <FontAwesomeIcon icon={!value.show ? faPlusSquare : faMinusSquare}
                                                             className="mx-1 fs-7"/>
                                        </div>
                                        {value.idn}
                                        {console.log(value)}
                                    </td>
                                    {chartColumn.chart1 &&
                                    <td className="colChart">
                                        <div className="sortChartHeader">
                                        <span className="subHeaderCss">
                                            Avg {value.linenAvg}</span>
                                            <span className="subHeaderCss">
                                            Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                                            <span className="subHeaderIconCss"
                                                  onClick={() => getChartDetail(1, value.linenChartWithQADate, "site", value.idnSiteArray, value.linenQualityChartCount)}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                        </div>
                                        <div className="colChartClass">
                                            {(value.linenQualityChartWithWeek && value.linenQualityChartWithWeek.length > 0) &&
                                            <LineChartWithLabel data={value.linenQualityChartWithWeek} label="Quality"
                                                                name="WeekStart"
                                                                flag={true}
                                                                nextLabel="Availability"/>
                                            }
                                        </div>
                                    </td>
                                    }
                                    {chartColumn.chart2 &&
                                    <td className="colChart">
                                        <div className="sortChartHeader">
                                            <span className="subHeaderCss">Avg {value.linenAvg}</span>
                                            <span
                                                className="subHeaderCss"> Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                                            <span className="subHeaderIconCss"
                                                  onClick={() => getChartDetail(2, value.linenChartWithDDate, "site", value.idnSiteArray, value.linenQualityChartCount)}><FontAwesomeIcon
                                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                        </div>
                                        <div className="colChartClass">
                                            {(value.linenQualityChartWithDept && value.linenQualityChartWithDept.length > 0) &&
                                            <BarChartWithTopLabel data={value.linenQualityChartWithDept} label="Quality"
                                                                  name="Dept"
                                                                  flag={true}
                                                                  nextLabel="Availability"/>
                                            }
                                        </div>
                                    </td>
                                    }
                                    {chartColumn.chart3 &&
                                    <td className="colChart">
                                        <div className="sortChartHeader">
                                        <span
                                            className="subHeaderCss">Pieces {formatNumberWithCommas(value.onsiteLinenChartTotal)}</span>
                                            <span className="subHeaderCss">Imp% {value.onsiteLinenChartAvg}</span>
                                            <span className="subHeaderIconCss"
                                                  onClick={() => getChartDetail(3, value.onsiteLinenChartWithIDate, "site", value.idnSiteArray, "", value.onsiteLinenChartTotal, value.onsiteLinenChartTotalImperfection)}><FontAwesomeIcon
                                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                        </div>
                                        <div className="colChartClass">
                                            {(value.onsiteLinenChartWithItem && value.onsiteLinenChartWithItem.length > 0) &&
                                            <RadarChartWithLabel data={value.onsiteLinenChartWithItem} label="Inspected"
                                                                 name="Item"
                                                                 flag={true}
                                                                 nextLabel="Flaw"/>
                                            }
                                        </div>
                                    </td>
                                    }
                                    {chartColumn.chart4 &&
                                    <td className="colChart">
                                        <div className="sortChartHeader">
                                            <span className="subHeaderCss">Avg {value.clientSatChartAvg}</span>
                                            <span
                                                className="subHeaderCss"> Count {formatNumberWithCommas(value.clientSatChartCount)}</span>
                                            <span className="subHeaderIconCss"
                                                  onClick={() => getChartDetail(4, value.clientSatChartWithOPDate, "site", value.idnSiteArray, value.clientSatChartCount)}><FontAwesomeIcon
                                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                        </div>
                                        <div className="colChartClass">
                                            {(value.clientSatChartWithWeek && value.clientSatChartWithWeek.length > 0) &&
                                            <BarChartWithTopLabel data={value.clientSatChartWithWeek}
                                                                  label="Overall Performance" name="WeekStart"
                                                                  flag={false}
                                                                  nextLabel=""/>
                                            }
                                        </div>
                                    </td>
                                    }
                                    <td className="colSentiment">
                                        <div className="subHeaderCss">
                                            <StatusIcon value={value.sentimentScore}/>
                                        </div>
                                        <span onClick={() => getChartFullScreenDetail(value, "site", value.idnSiteArray)}><FontAwesomeIcon
                                            icon={faEye} className="eyeFontCss"/></span>
                                    </td>
                                </tr>
                                {
                                    value.show &&
                                    <FacilitySiteData sites={value.sites}
                                                      linenImperfection={linenImperfection}
                                                      getChartFullScreenDetail={getChartFullScreenDetail}
                                                      getChartDetail={getChartDetail} chartColumn={chartColumn}/>
                                }
                            </>
                        )
                    })
                    :
                    <tr>
                        <td colSpan="6" className="text-center">NO DATA AVAILABLE</td>
                    </tr>
            }
        </>
    )
};

export default FacilityIdnData;