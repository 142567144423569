import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

const UtilizationForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="uniqueId" className="col-form-label"><span className="text-danger starCss">*</span>Plant</label>
                    <div>
                        <select onChange={props.handleChange} name="plant"
                                className={`form-select ${!props.inputValidation.plant ? "errorDiv" : ""}`}
                                value={props.formValue.plant} disabled={props.readOnly}>
                            <option value="select">Select</option>
                            {
                                props.plantList && props.plantList.length
                                && props.plantList.map((obj, index) => {
                                    return <option key={obj._id + index}
                                                   value={obj._id}>{obj._id}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="site" className="col-form-label"><span className="text-danger starCss">*</span>Site</label>
                    <div>
                        <select onChange={props.handleChange} name="site"
                                className={`form-select ${!props.inputValidation.site ? "errorDiv" : ""}`}
                                value={props.formValue.site} disabled={props.readOnly}>
                            <option value="select">Select</option>
                            {
                                props.siteList && props.siteList.length
                                && props.siteList.map((obj, index) => {
                                    return <option key={obj.site + index}
                                                   value={obj.site}>{obj.site}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="patientDaysAdjusted" className="col-form-label"><span className="text-danger starCss">*</span>Adjust Patient Days</label>
                    <div>
                        <input type="number"
                               className={`form-control ${!props.inputValidation.patientDaysAdjusted ? "errorDiv" : ""}`}
                               value={props.formValue.patientDaysAdjusted} name="patientDaysAdjusted"
                               onChange={props.handleChange}/>
                    </div>
                </div>
                <div className="form-group col">
                    <span className="text-danger starCss">*</span><label htmlFor="patientDays" className="col-form-label">Patient Days</label>
                    <div>
                        <input type="number"
                               className={`form-control ${!props.inputValidation.patientDays ? "errorDiv" : ""}`}
                               value={props.formValue.patientDays} name="patientDays"
                               onChange={props.handleChange}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="totalCleanPounds" className="col-form-label"><span className="text-danger starCss">*</span>Total Clean Pounds</label>
                    <div>
                        <input type="number"
                               className={`form-control ${!props.inputValidation.totalCleanPounds ? "errorDiv" : ""}`}
                               value={props.formValue.totalCleanPounds} name="totalCleanPounds"
                               onChange={props.handleChange}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="totalInvoice" className="col-form-label"><span className="text-danger starCss">*</span>Total Invoice</label>
                    <div>
                        <input type="number"
                               className={`form-control ${!props.inputValidation.totalInvoice ? "errorDiv" : ""}`}
                               value={props.formValue.totalInvoice} name="totalInvoice"
                               onChange={props.handleChange}/>
                    </div>
                </div>

            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="dateOfSurvey" className="col-form-label"><span className="text-danger starCss">*</span>Date
                    Of Survey</label>
                    <div>
                        <input type="date"
                               className={`form-control ${!props.inputValidation.dateOfSurvey ? "errorDiv" : ""}`}
                               value={props.formValue.dateOfSurvey} name="dateOfSurvey"
                               onChange={props.handleChange} disabled={props.readOnly}/>
                    </div>
                    <span><strong>Range : </strong>{props.weekRange}</span>
                </div>
                <div className="form-group col">
                    <label htmlFor="active" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="active"
                               onChange={props.handleChange} checked={props.formValue.active}/>
                    </div>
                </div>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default UtilizationForm;