import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const PlantQAConfigReducer = createSlice({
    name: "PlantQAConfigReducer",
    initialState,
    reducers: {
        setPlantQAConfig: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});
export const {setPlantQAConfig} = PlantQAConfigReducer.actions;

export default PlantQAConfigReducer.reducer;