import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from "../../apis/AxiosInstance";
import {useSelector} from "react-redux";
import useAxiosFunction from "../../hook/AxiosHook";
import {
    getAllArrayValue,
    getArrayValue,
    getSelectedAllFlag,
    updateMultiSelectSearch
} from "../../commom/CommonFunction";
import SiteExportModalForm from "./SiteExportModalForm";
import {masterReportData} from "../../commom/CommonReportFunction";
import {exportSurveyData} from "../../commom/ExportToExcel";
import {useOuterClick} from "../../hook/VisibilityHook";
import Loader from "../../commom/LoaderComponent";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import Message from "../../commom/Message";

const SiteExportModal = (props) => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const plantData = useSelector((state) => state.plantReducer);
    const siteData = useSelector((state) => state.siteReducer);
    const [plantList, setPlantList] = useState("");
    const [siteList, setSiteList] = useState("");
    const [sitesList, setSitesList] = useState("");
    const [formValue, setFormValue] = useState({
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
        showSite: false,
        selectAllSite: false,
        siteCount: 0,
    });
    const [inputValidation, setInputValidation] = useState({
        plantCount: true
    });
    const fieldValidation = {
        plantCount: "digit",
    };
    const [formValidation, setFormValidation] = useState(true);
    const innerRef = useOuterClick(e => {
        setFormValue({...formValue, showPlant: false});
    });
    const innerRefSite = useOuterClick(e => {
        setFormValue({...formValue, showSite: false});
    });
    const [disableExport, setDisableExport] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        setPlantList(updateMultiSelectOption(plantData, "plant"));
    }, []);

    const updateMultiSelectOption = (fetchedValue, label) => {
        return fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });
    };

    const getSites = (plantName) => {
        let sites = [];

        if (plantName && plantName.length) {
            siteData.forEach(obj => {
                let plantObj = Object.assign({}, obj);
                if (plantName.indexOf(plantObj.plant) !== -1) {
                    sites.push({
                        label: plantObj.site,
                        value: false
                    });
                }
            });
        }

        return sites;
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableExport(false);
        setShowMessage(false);

        if (name === "plant") {
            setPlantList(newData);
            let plantNames = getArrayValue(newData);
            let data = getSites(plantNames);
            setSiteList(data);
            setSitesList(data);
            setFormValue({
                ...formValue,
                selectAllPlant: checked,
                plantCount: count,
                selectAllSite: false,
                siteCount: 0
            });

            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "plantCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        } else if (name === "site") {
            setSiteList(newData);
            setFormValue({...formValue, selectAllSite: checked, siteCount: count});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        } else if (name === "site") {
            let flag = formValue.showSite;

            if(!flag) {
                let data = updateMultiSelectSearch(sitesList, "label", "", siteList);
                setSiteList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showSite: !flag, selectAllSite: selectedAll});
            } else {
                setFormValue({...formValue, showSite: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        } else if (name === "site") {
            let data = updateMultiSelectSearch(sitesList, "label", value, siteList);
            setSiteList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllSite: selectedAll});
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }
        let selectedSiteDate = getArrayValue(siteList);
        let sites;

        if (selectedSiteDate) {
            sites = selectedSiteDate;
        } else {
            sites = getAllArrayValue(siteList);

            if (!sites) {
                setDisableExport(true);
                return false;
            }
        }

        if(sites && sites.length) {
            setShowMessage(true);
            setDisableExport(true);
            const siteBySiteName = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getSiteBySiteName',
                requestConfig: {
                    data: {
                        "sites": sites,
                    }
                }
            });

            let siteArray = {
                "MasterData": masterReportData(siteBySiteName.data)
            };
            exportSurveyData(siteArray, "MasterData.xlsx");
        }
    };

    const closeModalBox = () => {
        setFormValue({
            showPlant: false,
            selectAllPlant: false,
            plantCount: 0,
            showSite: false,
            selectAllSite: false,
            siteCount: 0
        });

        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setSiteList("");
        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"plantCount": true});
        props.handleClose();
    };

    return(
        <>
            <Loader isLoading={loading}/>
            <Modal show={props.showSiteModal} onHide={closeModalBox}
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Site Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message="Exported Data Successfully"/>
                    <SiteExportModalForm plantList={plantList} siteList={siteList}
                                         formValue={formValue} submitHandler={submitHandler}
                                          handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                          handleSearch={handleSearch} innerRef={innerRef} innerRefSite={innerRefSite}
                                         disableExport={disableExport} inputValidation={inputValidation} response={response} error={error}
                                         loading={loading} showMessage={showMessage} formValidation={formValidation}/>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default SiteExportModal;