import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './PlantQAConfig.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlantQAConfigList from "./PlantQAConfigList";
import PlantQAConfigForm from "./PlantQAConfigForm";
import useAxiosFunction from "../../hook/AxiosHook";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {setPlantQAConfig} from "../../reducer/PlantQAConfigReducer";
import Enterprise from "./Enterprise";
import ItemCategory from "./ItemCategory";
import {Button} from "react-bootstrap";
import {updatePlantDropdownScreen} from "../../reducer/PlantDropdownReducer";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import PlantQAExportModal from "../plantQAExport/PlantQAExportModal";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {getActivePlantConfigArray, getSelectedPlantConfigArray} from "../../commom/CommonFunction";

const PlantQAConfig = () => {
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [isPlant, setIsPlant] = useState("Threshold");
    const [plantSelected, setPlantSelected] = useState(false);
    const [itemCategoryList, setItemCategoryList] = useState();
    const [plantQAConfigListData, setPlantQAConfigListData] = useState();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [showPlantQAExport, setShowPlantQAExport] = useState(false);
    const [formValue, setFormValue] = useState({
        id: "",
        houseCode: "",
        name: "",
        date: new Date(),
        item: "",
        count: ""
    });
    const [inputValidation, setInputValidation] = useState({
        item: true,
        count: true
    });

    const fieldValidation = {
        item: "select",
        count: "number"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const plantData = useSelector((state) => state.plantReducer);
    const frequencyData = useSelector((state) => state.frequencyReducer);

    useEffect(() => {
        let selectedPlant = plantData[0];
        if (selectedPlant) {
            setFormValue((prevState) => ({
                ...prevState,
                name: selectedPlant._id,
                houseCode: selectedPlant.houseCode
            }));
            setPlantSelected(true);
            getQAConfig(selectedPlant._id);
        } else {
            setPlantSelected(false);
        }
        setItemCategoryList(getActivePlantConfigArray("Item Category", "type",frequencyData , "value"));
    }, []);

    useEffect(() => {
        if(frequencyData && frequencyData.length > 0) {
            setItemCategoryList(getActivePlantConfigArray("Item Category", "type", frequencyData, "value"));
        }
    }, [frequencyData]);

    const levelSelection = (level) => {
        setIsPlant(level);
        dispatch(updatePlantDropdownScreen(level));
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        if (event.currentTarget.name !== "name") {
            const currentFormState = handleInputChange(event, formValue);
            setFormValue(currentFormState);
        } else {
            let data = (event.currentTarget.value);
            let selectedPlant = plantData.find((obj) => {
                if (obj._id === data) {
                    return obj;
                }
            });
            if (selectedPlant) {
                setFormValue((prevState) => ({
                    ...prevState,
                    name: data,
                    houseCode: selectedPlant.houseCode
                }));
                setPlantSelected(true);
                getQAConfig(data);
            } else {
                setPlantSelected(false);
                dispatch(setPlantQAConfig([]));
            }
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const getQAConfig = (value) => {
        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantQAConfigByTitle',
                requestConfig: {
                    data: value
                }
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            if (fetchedValue) {
                setPlantQAConfigListData(fetchedValue);
                dispatch(setPlantQAConfig(fetchedValue.inspections))
            } else {
                setPlantQAConfigListData("");
                dispatch(setPlantQAConfig([]));
            }
        });
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setReadOnly(true);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(true, value);
    };

    const cancelHandler = () => {
        setReadOnly(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        setDisableSubmit(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(false);
    };

    const stringGen = () => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let x = 7;
        for (let i = 0; i < x; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);

        if (!checkValidate) {
            return false;
        }

        let idValue = "";

        if (formValue.id) {
            idValue = formValue.id;
        } else {
            idValue = stringGen();
        }

        let inspectionData = {
            "id": idValue,
            "item": formValue.item,
            "count": formValue.count
        };
        let newData;

        if (plantQAConfigListData) {
            newData = Object.assign({}, plantQAConfigListData, {inspections: []});
            let flag = false;

            plantQAConfigListData.inspections.forEach((value) => {
                let obj = {...value};
                if (formValue.item === obj.item) {
                    obj.count = formValue.count;
                    flag = true;
                }
                newData.inspections.push(obj);
            });

            if (!flag) {
                newData.inspections.push(inspectionData);
            }
        } else {
            newData = {...formValue};
            newData["inspections"] = [];
            newData["inspections"].push(inspectionData);
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdatePlantQAConfig',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(setPlantQAConfig(response.data.inspections));
            setPlantQAConfigListData(response.data);

            setButtonTitle("Update");
            setFormValue((prevState) => ({
                ...prevState,
                name: response.data.plant,
                houseCode: response.data.houseCode
            }));

            if(formValue.id === "") {
                setFormValue({...formValue, "id": idValue});
            }
        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowPlantQAExport(false);
    };

    const showModal = () => {
        setShowPlantQAExport(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row className="mb-1">
                <Col md={{span: 6, offset: 3}}>
                    <Button onClick={() => levelSelection("Threshold")} className="formButton mx-sm-1 buttonCss">Enterprise
                        Level</Button>
                    <Button onClick={() => levelSelection("Item Category")} className="formButton mx-sm-1 buttonCss">Item
                        Category</Button>
                    <Button onClick={() => levelSelection("Imperfection Type")} className="formButton mx-sm-1 buttonCss">Imperfection
                        Type</Button>
                    <Button onClick={() => levelSelection("Plant QA Configuration")} className="formButton mx-sm-1 buttonCss">Plant
                        Level</Button>
                    <Button className="buttonCss" onClick={showModal}>Export</Button>
                </Col>
            </Row>
            {isPlant === "Threshold" && <Enterprise/>}
            {isPlant === "Item Category" && <ItemCategory/>}
            {isPlant === "Imperfection Type" && <ItemCategory/>}
            <PlantQAExportModal showPlantQAExport={showPlantQAExport} handleClose={handleClose}/>

            {
                isPlant === "Plant QA Configuration" &&
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <Row className="align-items-center mb-1">
                                <div className="">
                                    <label className="d-inline-block" htmlFor="name">Select Plant:</label>
                                    <div className="d-inline-block w-75 p-sm-1">
                                        <select onChange={handleChange} name="name"
                                                value={formValue.name} className="form-select">
                                            <option value="select">Select</option>
                                            {
                                                plantData && plantData.length
                                                && plantData.map((obj, index) => {
                                                    return <option key={index} value={obj._id}>{obj._id}</option>
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
            }

            {
                isPlant === "Plant QA Configuration" && plantSelected &&
                <Row>
                    <Col sm={6}><PlantQAConfigList selectedRow={selectedRow} screen={screen}/></Col>
                    <Col sm={6}>
                        <Message showMessage={showMessage}
                                 response={response} error={error} loading={loading}
                                 formValidation={formValidation}/>
                                 <PlantQAConfigForm plantData={plantData} submitHandler={submitHandler}
                                                   cancelHandler={cancelHandler} buttonTitle={buttonTitle}
                                                   handleChange={handleChange} itemCategoryList={itemCategoryList}
                                                   formValue={formValue} formValidation={formValidation}
                                                   response={response} error={error} loading={loading}
                                                   inputValidation={inputValidation} readOnly={readOnly}
                                                   showMessage={showMessage} disableSubmit={disableSubmit}/></Col>
                </Row>
            }

        </Container>
    )
};

export default PlantQAConfig;