import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const SurveyAnswerReducer = createSlice({
    name: "surveyAnswerReducer",
    initialState,
    reducers: {
        setSurveyAnswerData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});
export const {setSurveyAnswerData} = SurveyAnswerReducer.actions;

export default SurveyAnswerReducer.reducer;