import React from "react";
import {Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MultiSiteForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="plantId" className=" col-form-label">Plant Id :</label>
                    <div className="d-inline-block mx-2">
                        {props.plantId}
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="houseCode" className="col-form-label">House Code :</label>
                    <div className="d-inline-block mx-2">
                        {props.houseCode}
                    </div>
                </div>
            </Row>
            <div className="form-group row   ">
                <label htmlFor="dvp" className="col-sm-2 col-form-label">DVP</label>
                <div className="col-sm-10">
                    <select onChange={props.handleChange} name="dvp"
                            value={props.formValue.dvp}  className="form-select">
                        <option value="select">Select</option>
                        {
                            props.dvpList && props.dvpList.length
                            && props.dvpList.map((obj, index) => {
                                return <option key={obj.value + index} value={obj.value}>{obj.value}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="form-group row   ">
                <label htmlFor="rdo" className="col-sm-2 col-form-label">RDO</label>
                <div className="col-sm-10">
                    <select onChange={props.handleChange} name="rdo"
                             value={props.formValue.rdo}  className="form-select">
                        <option value="select">Select</option>
                        {
                            props.rdoList && props.rdoList.length
                            && props.rdoList.map((obj, index) => {
                                return <option key={obj.value + index} value={obj.value}>{obj.value}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="form-group row   ">
                <label htmlFor="gm" className="col-sm-2 col-form-label">GM</label>
                <div className="col-sm-10">
                    <select onChange={props.handleChange} name="gm"
                            className="form-select" value={props.formValue.gm}>
                        <option value="select">Select</option>
                        {
                            props.gmList && props.gmList.length
                            && props.gmList.map((obj, index) => {
                                return <option key={obj.value + index} value={obj.value}>{obj.value}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="form-group row   ">
                <label htmlFor="sam" className="col-sm-2 col-form-label">SAM</label>
                <div className="col-sm-10">
                    <select onChange={props.handleChange} name="sam"
                            className="form-select" value={props.formValue.sam}>
                        <option value="select">Select</option>
                        {
                            props.samList && props.samList.length
                            && props.samList.map((obj, index) => {
                                return <option key={obj.value + index} value={obj.value}>{obj.value}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>Update</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
}

export default MultiSiteForm;