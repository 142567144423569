import React from "react";
import {ListGroup} from "react-bootstrap";

const ReportOptionContainer = (props) => {
    return (
            <div className="row">
                <label className="col-form-label">Select Report :</label>
                <div>
                    <ListGroup className="divReportListCSS">
                        {
                            props.reportDropdown && props.reportDropdown.length
                            && props.reportDropdown.map((obj, index) => {
                                return <ListGroup.Item name="survey" key={obj + index} onClick={props.updateListSelection}
                                                       className={`listClass ${(props.title == obj) ? "active": "" }`}
                                >{obj}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
    );
};

export default ReportOptionContainer;