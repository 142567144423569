import React, {useState} from 'react';

function CheckboxInput({label, isChecked, onChange}) {
    const [checked, setChecked] = useState(isChecked);

    const handleInputChange = (event) => {
        let name = event.currentTarget.getAttribute("name");
        const newChecked = !checked;
        setChecked(newChecked);
        if (onChange) {
            onChange(newChecked, name);
        }
    };

    return (
        <>
            <div className="checkboxCss">
                <input className="form-check-input addCursorPointer mx-sm-1"
                       type="checkbox"
                       checked={checked}
                       onChange={handleInputChange}
                       name={label}/>
                <label className="form-check-label addCursorPointer mx-sm-1"
                       name={label}
                       onClick={handleInputChange}>
                    {label}</label>
            </div>
        </>
    )
}

export default CheckboxInput;