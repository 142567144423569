import React from "react";
import CustomTable from "../../commom/CustomTable";
import {useSelector} from "react-redux";

function SamSummaryScreenList(props) {
    const tableData = useSelector((state) => state.samSummaryScreenReducer);
    let rowHeader = [
        {
            name: "userName",
            header: "Sender Name"
        },
        {
            name: "sam",
            header: "Sam"
        },
        {
            name: "emailAddress",
            header: "Email"
        },
        {
            name: "gmEmail",
            header: "GM"
        },
        {
            name: "rdoEmail",
            header: "RDO"
        },
        {
            name: "topIssues",
            header: "Top Issues"
        },
        {
            name: "noteToManagement",
            header: "Note To Management"
        },
        {
            name: "createdAt",
            header: "Created At"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} hideSearch={true}
                     selectedRow={props.selectedRow}/>
    )
}

export default SamSummaryScreenList;