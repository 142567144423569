import React, {useContext, useEffect, useRef, useState} from "react";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import {formValueSetup, handleCheckboxInputChange, handleInputChange} from "../../commom/FormHandler";
import {
    getSites,
    getSummaryOfPdfSurvey,
    getUniqueArrayOfObject,
    getUserAccessToUrl,
    searchArray,
    specificArrayData,
    trimObjectValues, updateSummaryOfPdfSurvey,
} from "../../commom/CommonFunction";
import {
    getCurrentDateOnly, getDateInMonth, getDateInUTC
} from "../../commom/DateFormat";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AuthContext} from "../../context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import coreLogo from "../../assets/CoreLinenLogo.png";
import {PDFDocument, rgb} from "pdf-lib";
import * as htmlToImage from "html-to-image";
import {setSummaryOfDayScreen, updateSummaryOfDayScreen} from "../../reducer/SummaryOfDayScreenReducer";
import SummaryOfDayScreenList from "./SummaryOfDayScreenList";
import Modal from "react-bootstrap/Modal";
import SummaryOfDayChart from "../summaryOfDay/SummaryOfDayChart";
import SummaryOfDayTable from "../summaryOfDay/SummaryOfDayTable";
import SummaryOfDayScreenForm from "./SummaryOfDayScreenForm";
import "../summaryOfDay/SummaryOfDay.css";
import Message from "../../commom/Message";
import PdfGenerator from "../summaryOfDay/PdfGenerator";
import {validationCheck} from "../../commom/ValidateFormData";

const SummaryOfDayScreen = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const user = useSelector((state) => state.userState.user);
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const [formValue, setFormValue] = useState({
        _id: "",
        userId: "",
        email: "",
        plant: "",
        plantId: "",
        site: "",
        siteId: "",
        isActive: true,
        defaultDate: false,
        startDate: "",
        endDate: "",
        receiverName: "",
        senderName: "",
        senderEmail: "",
        subjectOfEmail: "",
        comment: "",
        siteContact: "",
        chartExport: [],
        clientStatHeaderData: [],
        clientStatBodyData: [],
        linenHeaderData: [],
        linenBodyData: [],
        parHeaderData: [],
        parBodyData: [],
        qbrHeaderData: [],
        qbrBodyData: [],
        linenAwarenessHeaderData: [],
        linenAwarenessBodyData: [],
        linenCommitteeHeaderData: [],
        linenCommitteeBodyData: [],
        linenLossHeaderData: [],
        linenLossBodyData: [],
        siteContactEmail: "",
    });
    const [siteList, setSiteList] = useState();
    const [plantList, setPlantList] = useState();
    const [sitesData, setSitesData] = useState();
    const [samList, setSamList] = useState();
    const [samValue, setSamValue] = useState();
    const [siteValue, setSiteValue] = useState();
    const [siteEmailList, setSiteEmailList] = useState("");
    const [surveyData, setSurveyData] = useState("");
    const [linenImperfection, setLinenImperfection] = useState();
    const [linenItem, setLinenItem] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [loadRef, setLoadRef] = useState(false);

    const secondChartRef = useRef(null);
    const chartRef = useRef(null);
    const tableClientRef = useRef(null);
    const tableLinenRef = useRef(null);
    const tablePARRef = useRef(null);
    const tableQBRRef = useRef(null);
    const tableLinenAwarenessRef = useRef(null);
    const tableLinenCommitteeRef = useRef(null);
    const tableLinenLossRef = useRef(null);
    const [showMessage, setShowMessage] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [showEmailMessage, setShowEmailMessage] = useState(true);
    const [emailValidationMessage, setEmailValidationMessage] = useState("Please fill the email address.");
    const [screenMessage, setScreenMessage] = useState("Export Pdf successfully.");
    useEffect(() => {
        dispatch(setDateRange());
        dispatch(showDateInHeader(true));
        getUserDetail().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;

            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setPlantList(response.plants);
            setSiteList(response.sites);
            setSitesData(response.sites);
            setSurveyData(response.surveys);
            let imperfectionList = [];
            let itemList = [];
            if (response.surveys && response.surveys.length) {
                let data = (response.surveys).filter((data) => data.title === "Onsite Linen Inspection");

                data.forEach((obj) => {
                    if (obj.questions && obj.questions.length) {
                        obj.questions.forEach((detail) => {
                            if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected") {
                                imperfectionList.push(detail.fieldName);
                            }

                            if (detail.fieldName === "Item") {

                                detail.answers.forEach((answersObj) => {
                                    itemList.push(answersObj.displayValue);
                                });

                            }
                        })
                    }
                });
                setLinenImperfection(imperfectionList);
                setLinenItem(itemList);
            }
        });
    }, []);

    useEffect(() => {
        if (isDateChanged && siteList && siteList.length) {
            getSummaryOfDayDetail(siteValue, samValue).then((response) => {
                if (response) {
                    dispatch(setSummaryOfDayScreen(response));
                    setSamList(getUniqueArrayOfObject(response));
                }
            });
        }
    }, [isDateChanged, siteList]);

    const updateMultiSelectOption = (fetchedValue, formValueSite) => {
        let siteObj = searchArray(formValueSite, fetchedValue, "site");
        let newArray = [];
        for (let key in siteObj) {
            if (["contactName1", "contactName2", "contactName3", "contactName4", "contactName5"].indexOf(key) > -1) {
                if (siteObj[key]) {
                    let contactName = "";
                    let ketLastValue = key.slice(-1);
                    if (["1", "2", "3", "4", "5"].indexOf(ketLastValue) > -1) {
                        let userKey = "contactEmail" + ketLastValue;
                        contactName = siteObj[userKey];
                    }
                    let obj = {
                        label: siteObj[key],
                        value: false,
                        userContactName: contactName
                    };

                    newArray.push(obj);
                }
            }
        }

        return newArray.length ? newArray : "";
    };

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetail = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getSummaryOfDayDetail = async (selectedSite, selectedSam) => {

        let siteData = [];
        if (selectedSite) {
            siteData = [selectedSite];
        } else {
            siteData = specificArrayData(siteList, "site");
        }

        let samData = [];
        if (selectedSam) {
            samData = [selectedSam];
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getSummaryOfDay',
            requestConfig: {
                "data": {
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                    "receiverName": samData,
                    "site": siteData
                }
            }
        });
        return await getSummaryOfPdfSurvey(response.data);
    };

    const updateListSelection = (event) => {
        if (event.currentTarget) {
            let eventObject = {
                "currentTarget": {
                    "value": event.currentTarget.innerText,
                    "name": event.currentTarget.getAttribute("name"),
                }
            };
            handleChange(eventObject)
        }
    };

    const handleCheckboxLabelClick = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);
        setShowEmailMessage(true);
        const currentFormState = handleCheckboxInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    useEffect(() => {
        if(chartRef.current && secondChartRef.current) {
            setLoadRef(true);
        }
    });

    const handleChange = (event, flag = "") => {
        setDisableSubmit(false);
        setShowMessage(false);
        setShowEmailMessage(true);
        if (!flag) {
            const currentFormState = handleInputChange(event, formValue);
            currentFormState.siteContactEmail = "";
            if(event.currentTarget.name === "siteContact") {
                let siteContactData = searchArray(event.currentTarget.value, siteEmailList, "label");

                if(siteContactData) {
                    currentFormState.siteContactEmail = siteContactData.userContactName;
                }
            }
            if (event.currentTarget.name !== "plant" || event.currentTarget.name !== "site") {
                setFormValue(currentFormState);
            }

            if (event.currentTarget.name === "plant") {
                let siteData = getSites(event.currentTarget.value, sitesData);

                if (siteData && siteData.length) {
                    setSiteList(siteData);
                    setFormValue({
                        ...formValue,
                        plant: event.currentTarget.value,
                        site: siteData[0].site,
                        siteContact: "",
                        siteContactEmail: ""
                    });

                    setSiteEmailList(updateMultiSelectOption(siteData, siteData[0].site));
                }
            } else if (event.currentTarget.name === "site") {
                setFormValue({...formValue, site: event.currentTarget.value, siteContact: "",
                    siteContactEmail: ""});
                setSiteEmailList(updateMultiSelectOption(siteList, event.currentTarget.value));
            }
        } else {
            let newObj = {
                "currentTarget": {
                    "name": event.name,
                    "value": event.transcript
                }
            };
            const currentFormState = handleInputChange(newObj, formValue);
            setFormValue(currentFormState);
        }
    };

    const handleSelectChange = (event) => {
        if (event.currentTarget.name === "samValue") {
            setSamValue(event.currentTarget.value);

            let value = event.currentTarget.value === "select" ? "" : event.currentTarget.value;
            let nextValue = siteValue === "select" ? "" : siteValue;
            getSummaryOfDayDetail(nextValue, value).then((response) => {
                if (response) {
                    dispatch(setSummaryOfDayScreen(response));
                }
            });
        }

        if (event.currentTarget.name === "siteValue") {
            setSiteValue(event.currentTarget.value);

            let value = event.currentTarget.value === "select" ? "" : event.currentTarget.value;
            let nextValue = samValue === "select" ? "" : samValue;
            getSummaryOfDayDetail(value, nextValue).then((response) => {
                if (response) {
                    dispatch(setSummaryOfDayScreen(response));
                }
            });
        }
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        setShowEmailMessage(true);
        setShowMessage(false);
        const currentFormState = formValueSetup(true, value, formValue);
        setFormValue(currentFormState);
        setSiteEmailList(updateMultiSelectOption(siteList, value.site));
        setShowModal(true);
        setLoadRef(false);
    };

    const handleClose = () => {
        setShowModal(false);
        setShowMessage(false);
        setShowEmailMessage(true);
    };

    const submitHandler = async (flag) => {
        let newEntry = trimObjectValues({...formValue});
        setDisableSubmit(true);
        setShowEmailMessage(true);
        newEntry["siteContact"] = newEntry.siteContact === "select" ? "" : newEntry.siteContact;
        newEntry["siteContactEmail"] = newEntry.siteContactEmail ? newEntry.siteContactEmail : "";

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSummaryOfDay',
            requestConfig: {
                data: newEntry
            }
        });

        if (response) {
            dispatch(updateSummaryOfDayScreen(updateSummaryOfPdfSurvey(response.data)));
        }
        setShowMessage(true);

        if(flag) {
            setScreenMessage("The PDF has been saved successfully.")
        } else {
            setScreenMessage("The PDF has been saved successfully and exported.")
        }
    };

    const sendPDFToServer = async (pdfBuffer) => {
        setShowMessage(false);

        let senderEmail = formValue.siteContactEmail ? formValue.siteContactEmail : "";
        let checkEmail = validationCheck("email", formValue.emailAddress);
        if(senderEmail || checkEmail) {
            setShowEmailMessage(true);
        } else {
            setShowEmailMessage(false);
            return false;
        }
        let newEntry = trimObjectValues({...formValue});
        let newData = {};
        let plantObject = searchArray(formValue.plant, plantList, "_id");
        let siteObject = searchArray(formValue.site, sitesData, "site");
        newData["userId"] = user._id;
        newData["email"] = user.email;
        newData["plant"] = newEntry.plant;
        newData["plantId"] = plantObject.houseCode;
        newData["site"] = newEntry.site;
        newData["siteId"] = siteObject.siteId;
        newData["isActive"] = true;
        newData["defaultDate"] = newEntry.defaultDate;
        newData["startDate"] = getDateInUTC(newEntry.startDate);
        newData["endDate"] = getDateInUTC(newEntry.endDate);
        newData["receiverName"] = newEntry.receiverName;
        newData["senderName"] = user.firstName + " " + user.lastName;
        newData["senderEmail"] = newEntry.senderEmail;
        newData["subjectOfEmail"] = newEntry.subjectOfEmail ? newEntry.subjectOfEmail : "Summary Report";
        newData["comment"] = newEntry.comment;
        newData["siteContact"] = newEntry.siteContact === "select" ? "" : newEntry.siteContact;
        newData["siteContactEmail"] = newEntry.siteContactEmail ? newEntry.siteContactEmail : "";
        newData["chartExport"] = newEntry.chartExport;
        newData["clientStatHeaderData"] = newEntry.clientStatHeaderData;
        newData["clientStatBodyData"] = newEntry.clientStatBodyData;
        newData["linenHeaderData"] = newEntry.linenHeaderData;
        newData["linenBodyData"] = newEntry.linenBodyData;
        newData["parHeaderData"] = newEntry.parHeaderData;
        newData["parBodyData"] = newEntry.parBodyData;
        newData["qbrHeaderData"] = newEntry.qbrHeaderData;
        newData["qbrBodyData"] = newEntry.qbrBodyData;
        newData["linenAwarenessHeaderData"] = newEntry.linenAwarenessHeaderData;
        newData["linenAwarenessBodyData"] = newEntry.linenAwarenessBodyData;
        newData["linenCommitteeHeaderData"] = newEntry.linenCommitteeHeaderData;
        newData["linenCommitteeBodyData"] = newEntry.linenCommitteeBodyData;
        newData["linenLossHeaderData"] = newEntry.linenLossHeaderData;
        newData["linenLossBodyData"] = newEntry.linenLossBodyData;
        newData["pdfBuffer"] = Array.from(pdfBuffer);
        setShowMessage(true);

        try {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/sendPdfAttachmentByEmail',
                requestConfig: {
                    data: newData,
                },
            });

            if (response.data === "Successfully sent an email!") {
                setScreenMessage("The PDF has been saved successfully and sent via email.");
            } else {
                setScreenMessage("Failed to send PDF. Please try again.");
            }
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <div className="d-flex justify-content-end my-1">
                <Link to="/summaryOfDay"><Button className="buttonCss">Back To End Of The Day Summary</Button></Link>
            </div>
            <Row>
                <Col>
                    <select className="form-select"
                            value={samValue} onChange={handleSelectChange}
                            name="samValue">
                        <option value="select">Select Sender Name</option>
                        {
                            samList && samList.length
                            && samList.map((obj, index) => {
                                return <option key={obj.senderName + index}
                                               value={obj.senderName}>{obj.senderName}</option>
                            })
                        }
                    </select>
                </Col>
                <Col>
                    <select className="form-select"
                            value={siteValue} onChange={handleSelectChange}
                            name="siteValue">
                        <option value="select">Select Site</option>
                        {
                            siteList && siteList.length
                            && siteList.map((obj, index) => {
                                return <option key={obj.site + index} value={obj.site}>{obj.site}</option>
                            })
                        }
                    </select>
                </Col>
            </Row>
            <Row className="my-2">
                <SummaryOfDayScreenList selectedRow={selectedRow}/>
            </Row>

            <Modal show={showModal}
                   aria-labelledby="contained-modal-title-vcenter" centered className="summaryReportDiv">
                <Modal.Header className="reportModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">Exported Data</Modal.Title>
                </Modal.Header>
                <Modal.Body className="summaryReportModal">
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={showEmailMessage} formValidationMessage={emailValidationMessage}
                             message={screenMessage}/>
                    <SummaryOfDayScreenForm handleChange={handleChange} formValue={formValue} plantList={plantList}
                                            siteList={siteList} response={response} error={error} loading={loading}
                                            updateListSelection={updateListSelection}
                                            handleCheckboxLabelClick={handleCheckboxLabelClick}
                                            siteEmailList={siteEmailList} showEmailMessage={showEmailMessage}/>

                    {formValue.chartExport.map((chartObj, index) => {
                        if (index === 0) {
                            return <>
                                <div className="facilityHeader text-center">{chartObj.headerName}</div>
                                <Row className="justify-content-md-center marginUnset" ref={chartRef}
                                     key={chartObj.headerName + index}>
                                    <Col className="chartDataForPdf colBorder"
                                         key={chartObj.headerName + index}>
                                        <SummaryOfDayChart chartData={chartObj.data} label={chartObj.dataKey}
                                                           nextLabel={chartObj.dataKey2} name={chartObj.name}
                                                           headerName={chartObj.headerName} flag={chartObj.flag}
                                                           selectedChart={chartObj.selectedChart}/></Col>
                                </Row></>
                        } else {
                            return <>
                                <div className="facilityHeader text-center">{chartObj.headerName}</div>
                                <Row className="justify-content-md-center marginUnset" ref={secondChartRef}>
                                    <Col className="chartDataForPdf colBorder"
                                         key={chartObj.headerName + index}>
                                        <SummaryOfDayChart chartData={chartObj.data} label={chartObj.dataKey}
                                                           nextLabel={chartObj.dataKey2} name={chartObj.name}
                                                           headerName={chartObj.headerName} flag={chartObj.flag}
                                                           selectedChart={chartObj.selectedChart}/></Col>
                                </Row></>
                        }
                    })
                    }
                    <>
                        <Row className="justify-content-md-center marginUnset" ref={tableClientRef}>
                            <SummaryOfDayTable tableHeader={formValue.clientStatHeaderData}
                                               tableData={formValue.clientStatBodyData}
                                               headerName="Client Stat"/>
                        </Row>
                        <Row className="justify-content-md-center marginUnset" ref={tableLinenRef}>
                            <SummaryOfDayTable tableHeader={formValue.linenHeaderData}
                                               tableData={formValue.linenBodyData}
                                               headerName="Linen Quality & Availability"/></Row>
                        <Row className="justify-content-md-center marginUnset" ref={tablePARRef}>
                            <SummaryOfDayTable tableHeader={formValue.parHeaderData} tableData={formValue.parBodyData}
                                               headerName="PAR"/></Row>
                        <Row className="justify-content-md-center marginUnset" ref={tableQBRRef}>
                            <SummaryOfDayTable tableHeader={formValue.qbrHeaderData} tableData={formValue.qbrBodyData}
                                               headerName="QBR"/></Row>
                        <Row className="justify-content-md-center marginUnset" ref={tableLinenAwarenessRef}>
                            <SummaryOfDayTable tableHeader={formValue.linenAwarenessHeaderData}
                                               tableData={formValue.linenAwarenessBodyData}
                                               headerName="Linen Awareness"/></Row>
                        <Row className="justify-content-md-center marginUnset" ref={tableLinenCommitteeRef}>
                            <SummaryOfDayTable tableHeader={formValue.linenCommitteeHeaderData}
                                               tableData={formValue.linenCommitteeBodyData}
                                               headerName="Linen Committee"/></Row>
                        <Row className="justify-content-md-center marginUnset" ref={tableLinenLossRef}>
                            <SummaryOfDayTable tableHeader={formValue.linenLossHeaderData}
                                               tableData={formValue.linenLossBodyData}
                                               headerName="Linen Loss"/></Row>
                    </>
                </Modal.Body>
                <Modal.Footer className="reportModalFooter">
                    <PdfGenerator userName={formValue.receiverName} siteContact={formValue.siteContact} user={user}
                                  chartRef={chartRef.current} secondChartRef={secondChartRef.current}
                                  defaultDate={formValue.defaultDate}
                                  startDate={formValue.startDate} endDate={formValue.endDate}
                                  emailSubject={formValue.subjectOfEmail} site={formValue.site}
                                  comment={formValue.comment}
                                  submitHandler={submitHandler} sendPDFToServer={sendPDFToServer}
                                  clientStatHeaderData={formValue.clientStatHeaderData}
                                  clientStatBodyData={formValue.clientStatBodyData}
                                  linenHeaderData={formValue.linenHeaderData} linenBodyData={formValue.linenBodyData}
                                  parHeaderData={formValue.parHeaderData} parBodyData={formValue.parBodyData}
                                  qbrHeaderData={formValue.qbrHeaderData}
                                  qbrBodyData={formValue.qbrBodyData}
                                  linenAwarenessHeaderData={formValue.linenAwarenessHeaderData}
                                  linenAwarenessBodyData={formValue.linenAwarenessBodyData}
                                  linenCommitteeHeaderData={formValue.linenCommitteeHeaderData}
                                  linenCommitteeBodyData={formValue.linenCommitteeBodyData}
                                  linenLossHeaderData={formValue.linenLossHeaderData}
                                  linenLossBodyData={formValue.linenLossBodyData}
                                  chartExport={formValue.chartExport} emailAddress={formValue.senderEmail}
                                  siteContactEmail={formValue.siteContactEmail}/>
                    <Button type="button" className="buttonCss mx-sm-1" onClick={() => submitHandler(true)}
                            disabled={disableSubmit}>Update</Button>
                    <Button onClick={handleClose} className="buttonDisableCss">Close</Button>

                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default SummaryOfDayScreen;