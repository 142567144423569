import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const UserManagementReducer = createSlice({
    name: "userManagement",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        },
        updateUser: (state, action) => {
            let objIndex = state.findIndex(stateObj => {
                return (stateObj._id === action.payload._id) ;
            });

            if (objIndex !== -1) {
                state.splice(objIndex, 1, action.payload);
            } else if(objIndex === -1) {
                state.push(action.payload);
            }
        },
    }
});
export const {setUser, updateUser} = UserManagementReducer.actions;

export default UserManagementReducer.reducer;