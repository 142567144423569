import React from 'react';
import {getDateInUTCByMonth} from "../../commom/DateFormat";

const UserLogTable = ({allUserDataBySearch}) => {
    return (
        <>
            {
                allUserDataBySearch && allUserDataBySearch.length &&
                (allUserDataBySearch).map((value, index) => {
                        return (
                            <tr key={value.userId + index} className="text-center">
                                <td>{value.body ? value.body.id : ""}</td>
                                <td>Logged In</td>
                                <td>{getDateInUTCByMonth(value.date)}</td>
                            </tr>
                        )

                })
            }
        </>
    )
};

export default UserLogTable;