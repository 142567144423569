import React from "react";
import {Row, ListGroup} from "react-bootstrap";
import {SpeechToText} from "../../commom/SpeechToText";
import MultiSelect from "../../commom/MultiSelect";

const SamSummaryScreenForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3 mb-1">
            <Row className="mb-1">
                <div className="form-group col">
                    <label htmlFor="sam" className="col-form-label"><span className="text-danger starCss">*</span>Sam</label>
                    <div>
                        <input type="text" name="sam"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.sam} disabled={true}/>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="dateValue" className="col-form-label">Date</label>
                    <div>
                        <input type="date" name="dateValue"
                               onChange={props.handleChange}
                               className="form-control"
                               value={props.formValue.dateValue} disabled={true}/>
                    </div>
                </div>
            </Row>
            <Row className="mb-1">
                <div className="form-group col">
                    <label htmlFor="emailAddress" className="col-form-label">Email</label>
                    <div>
                        <input type="email" name="emailAddress"
                               className={`form-control ${!props.showEmailMessage ? "errorDiv" : ""}`}
                               onChange={props.handleChange}
                               value={props.formValue.emailAddress}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label className="col-form-label" htmlFor="gmEmail">GM</label>
                    <div ref={props.innerGMRef} className="multiSelectField">
                        <input className="form-select"
                               type="text" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.gmCount} selected`} name="gmEmail"/>
                        {
                            props.formValue.showGM &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showGM} data={props.gmList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllGM}
                                             label="gmEmail" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
                <div className="form-group col">
                    <label className="col-form-label" htmlFor="rdoEmail">RDO</label>
                    <div ref={props.innerRDORef} className="multiSelectField">
                        <input className="form-select"
                               type="text" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.rdoCount} selected`} name="rdoEmail"/>
                        {
                            props.formValue.showRDO &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showRDO} data={props.rdoList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllRDO}
                                             label="rdoEmail" handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>
            </Row>

            <Row className="mb-1">
                <div className="form-group col">
                    <SpeechToText fieldName={"Top issues"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="topIssues"
                                  inputValueData={props.formValue.topIssues}/>
                </div>
                <div className="form-group col">
                    <SpeechToText fieldName={"Note to Management"} handleChange={props.handleChange}
                                  showMessage={false} resetComment={false}
                                  required={false} inputValidation="" name="noteToManagement"
                                  inputValueData={props.formValue.noteToManagement}/>
                </div>

            </Row>

            <Row className="mb-1">
                <div className="form-group col">
                    <label htmlFor="images" className="col-form-label">Upload Image</label>
                    <div>
                        <input
                            type="file"
                            onChange={props.handleChange}
                            accept="image/*"
                            multiple
                            required
                            className="form-control file-input"
                            name="images"
                            style={{ display: 'none' }}
                            id="file-input"
                        />
                        <button
                            type="button"
                            className="btn buttonCss"
                            onClick={() => document.getElementById('file-input').click()}
                        >Choose Files
                        </button>
                        <span className="p-sm-1">
                            {props.imagePreviews.length > 0 && (
                                <span> {props.imagePreviews.length} file(s) selected</span>
                            )}
                        </span>
                    </div>
                </div>
                <p>
                    <small className="form-text text-muted">
                        NOTE : Once jpg and png image allowed.
                    </small>
                </p>
            </Row>
        </form>
    )
};

export default SamSummaryScreenForm;