import {createSlice} from "@reduxjs/toolkit";

export const IdealTimeOutReducer = createSlice({
    name: "IdealTimeOutReducer",
    initialState: {
        "showIdleModal": false,
    },
    reducers: {
        setIdealTime: (state) => {
            state.showIdleModal = false;
        },
        updateIdealTime: (state, action) => {
            state.showIdleModal = action.payload;
        }
    }
});

export const {setIdealTime, updateIdealTime} = IdealTimeOutReducer.actions;
export default IdealTimeOutReducer.reducer;