import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

const TreeCheckbox = (props) => {
    const [buttonLabel, setButtonLabel] = useState("Expand All");
    const [selectAll, setSelectAll] = useState(false);
    const [isExpand, setIsExpand] = useState(false);


    const onCheckAllCheckbox = (event) => {
        let name = event.currentTarget.getAttribute("name");
        props.onCheckAllCheckbox(name);
    };

    const toggleOpen = (event) => {
        props.toggleOpen(event);
    };

    const toggleButton = () => {
        let selectValue = !isExpand;
        setIsExpand(selectValue);


        if (selectValue) {
            setButtonLabel("Expand All");
        } else {
            setButtonLabel("Collapse All");
        }
        props.toggleExpand(selectValue);
    };

    const selectedAllClickHandler = () => {
        let selectValue = !selectAll;
        setSelectAll(selectValue);

        props.selectedAllCheckboxHandler(selectValue);
    };

    return (
        <>
            {!props.hideSelectAll &&
            <button type="button" className="btn btn-primary mx-sm-1 buttonCss" onClick={selectedAllClickHandler}>Select
                All</button>}
            <button type="button" className="btn btn-primary buttonCss" onClick={toggleButton}>{buttonLabel}</button>
            {
                (props.data && props.data.length && props.data.length > 0) ?
                Object.values(props.data).map(function (value, index) {
                    return (
                        <div key={index + "div"}>
                            <li key={value.id + "li"}>
                                <span onClick={toggleOpen} name={value.id}
                                      className="p-1">
                                {value.open && <FontAwesomeIcon icon={faMinusSquare}/>}
                                    {!value.open && <FontAwesomeIcon icon={faPlusSquare}/>}
                                </span>
                                <div className="form-check d-inline-block">
                                    <input className="form-check-input" type="checkbox" value="" id={value.id}
                                           checked={value.isActive} onChange={onCheckAllCheckbox} name={value.id}/>
                                    <label className="form-check-label"
                                           htmlFor={value.id}
                                           name={value.id}>
                                        {value.name}
                                    </label>
                                </div>
                            </li>
                            {
                                value.open && value.subModule && value.subModule.length && value.subModule.length > 0 ?
                                <ul className="nested" key={value.id + "ul"}>
                                    <div id="example-collapse-text">
                                        {value.subModule && value.subModule.length &&
                                        (value.subModule).map((subModule) => {
                                            return (
                                                <li key={subModule.id + subModule.name}
                                                    name={value.id + "," + subModule.id}
                                                    htmlFor={value.id + "," + subModule.id}>
                                                    <Form.Check
                                                        label={subModule.name}
                                                        name={value.id + "," + subModule.id}
                                                        id={value.id + "," + subModule.id}
                                                        type="checkbox"
                                                        checked={subModule.isActive}
                                                        onChange={onCheckAllCheckbox}
                                                        key={subModule.name}/>
                                                    <ul className="nested">
                                                        {
                                                            subModule.subModules && subModule.subModules.length &&
                                                            (subModule.subModules).map((subSubModule) => {
                                                                return (
                                                                    <li key={subSubModule.name}
                                                                        htmlFor={value.id + "," + subModule.id + "," + subSubModule.id}
                                                                        name={value.id + "," + subModule.id + "," + subSubModule.id}>
                                                                        <Form.Check
                                                                            label={subSubModule.name}
                                                                            name={value.id + "," + subModule.id + "," + subSubModule.id}
                                                                            type="checkbox"
                                                                            checked={subSubModule.isActive}
                                                                            onChange={onCheckAllCheckbox}
                                                                            id={value.id + "," + subModule.id + "," + subSubModule.id}
                                                                            key={subSubModule.id}/>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                        }
                                    </div>
                                </ul>
                                    :
                                    ""
                            }
                        </div>
                    )
                })
                    :
                    <div className="">NO DATA AVAILABLE</div>
            }
        </>
    )
};

export default TreeCheckbox;