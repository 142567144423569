import {getCurrentDateOnly, getDateInUTC} from "./DateFormat";

export const formValueSetup = (flag, value = "", formValue) => {
    const currentFormState = {...formValue};

    if (flag) {
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                for (const index in currentFormState) {
                    if (key === index) {
                        if (Array.isArray(value[key])) {
                            currentFormState[key] = [...value[key]];
                        } else if(value[key] === null) {
                            currentFormState[key] = value[key];
                        } else if(typeof value[key] === "object") {
                            currentFormState[key] = {...value[key]};
                        } else {
                            if((typeof value[key] === "string") && (value[key].split("-")).length === 3) {
                                currentFormState[key] = getDateInUTC(value[key]);
                            } else {
                                currentFormState[key] = value[key];
                            }
                        }
                    }
                }
            }
        }
    } else {
        for (const key in currentFormState) {
            if (currentFormState.hasOwnProperty(key)) {

                if (typeof currentFormState[key] == "boolean") {
                    currentFormState[key] = false;
                } else {
                    currentFormState[key] = "";
                }
            }
        }
    }

    return currentFormState;
};

export const handleInputChange = (event, formValue) => {
    let { name, value, checked, type } = event.currentTarget;
    value = type === "checkbox" ? checked : value;

    if(type === "date") {
        value = (value);
    } else if(type === "text") {
    }

    return {
        ...formValue,
        [name]: value,
    };
};

export const handleCheckboxInputChange = (event, formValue) => {
    let name = event.currentTarget.getAttribute("name");
    const newChecked = !formValue[name];

    return {
        ...formValue,
        [name]: newChecked,
    };
};

export const resetValidation = (inputValidation) => {
    const currentFormState = {...inputValidation};

    for (const key in currentFormState) {
        if (currentFormState.hasOwnProperty(key)) {
            currentFormState[key] = true;
        }
    }

    return currentFormState;
};

export const formInputChangeHandler = (event, formValue) => {
    let configData = [];

    formValue.forEach((obj) => {
        let data = Object.assign({}, obj);

        if (data.fieldName === event.currentTarget.name) {
            data.value = event.currentTarget.value;
        }

        configData.push(data);
    });

    return configData;
};

export const formLabelClick = (event, formValue) => {
    let configData = [];
    let name = event.currentTarget.getAttribute("name");

    formValue.forEach((obj) => {
        let data = Object.assign({}, obj);

        if (data.fieldName === name) {
            let newChecked = !data.value;
            data.value = newChecked;
        }

        configData.push(data);
    });

    return configData;
};

export const formResetHandler = (formValue, isDataSubmit, date) => {
    let configData = [];

    formValue.forEach((obj) => {
        let data = Object.assign({}, obj);
        data.value = "";
        if(!isDataSubmit && data && data.type === "Date") {
            data.value = getCurrentDateOnly();
        }
        if(isDataSubmit && data && data.type === "Date") {
            data.value = date;
        }
        if(data && data.type === "Checkbox") {
            data.value = false;
        }
        configData.push(data);
    });

    return configData;
};