import React, { useState } from 'react';
import { Button, Form, Collapse } from 'react-bootstrap';

function MultiColumnSelection({chartColumn, handleChartColChange, handleChartColVisibility}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Button variant="primary" onClick={toggleCollapse}>
                Show/Hide Chart Column
            </Button>
            <Collapse in={isOpen} className="multiColPosition">
                <div>
            <div className="mb-3">
                <Button variant="primary" size="md" name="hide" onClick={handleChartColVisibility}>Hide all</Button>{' '}
                <Button variant="primary" size="md" name="show" onClick={handleChartColVisibility}>Show all</Button>
            </div>
            <hr />
            <div>
                <Form.Check
                    type="switch"
                    label="Linen Col"
                    id="chart1"
                    name="chart1"
                    checked={chartColumn.chart1}
                    onChange={handleChartColChange}
                />
            </div>
            <div>
                <Form.Check
                    type="switch"
                    label="Department Col"
                    id="chart2"
                    name="chart2"
                    checked={chartColumn.chart2}
                    onChange={handleChartColChange}
                />
            </div>
            <div>
                <Form.Check
                    type="switch"
                    label="On-Site Col"
                    id="chart3"
                    name="chart3"
                    checked={chartColumn.chart3}
                    onChange={handleChartColChange}
                />
            </div>
            <div>
                <Form.Check
                    type="switch"
                    label="Client Stat Col"
                    id="chart4"
                    name="chart4"
                    checked={chartColumn.chart4}
                    onChange={handleChartColChange}
                />
            </div>
                </div>
            </Collapse>
        </>
    );
}

export default MultiColumnSelection;
