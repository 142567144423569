import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const SiteAllReducer = createSlice({
    name: "SiteAllReducer",
    initialState,
    reducers: {
        setAllSite: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        },
        updateAllSite: (state, action) => {
            let objIndex = state.findIndex(stateObj => {
                return (stateObj.siteId === action.payload.siteId) ;
            });

            if (objIndex >= 0) {
                state.splice(objIndex, 1, action.payload);
            } else if(objIndex === -1) {
                state.push(action.payload);
            }
        },
        updateSiteIdn: (state, action) => {
            for (let element of state) {
                if(action.payload.removeSiteIds) {
                    let removeIdnIndex = (action.payload.removeSiteIds).findIndex(stateObj => {
                        return ((stateObj === element["siteId"]) && (element["idnAffiliation"] === action.payload.idnAffiliation));
                    });
                    if (removeIdnIndex != -1) {
                        element["idnAffiliation"] = "";
                    }
                }
            }
        },
    }
});
export const {setAllSite, updateAllSite, updateSiteIdn} = SiteAllReducer.actions;

export default SiteAllReducer.reducer;