import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../reducer/UserManagementReducer";
import Loader from "./ResetPassword";
import Modal from 'react-bootstrap/Modal';
import {updateDefaultScreenForm} from "../../reducer/ModalReducer";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import Message from "../../commom/Message";
import DefaultScreenSelectionForm from "./DefaultScreenSelectionForm";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {updateDefaultScreen} from "../../reducer/DefaultScreenReducer";

const DefaultScreenSelection = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userState.user);
    const [defaultScreenArray, setDefaultScreenArray] = useState([]);
    const showDefaultScreenForm = useSelector((state) => state.modalReducer.showDefaultScreenForm);
    const defaultScreenValue =  useSelector((state) => state.defaultScreenReducer.defaultScreen);
    const [formValue, setFormValue] = useState({
        defaultScreen: ""
    });
    const [inputValidation, setInputValidation] = useState({
        defaultScreen: true
    });
    const fieldValidation = {
        defaultScreen: "select"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        getDefaultScreenData();
    }, []);

    useEffect(() => {
        if(showDefaultScreenForm) {
            setFormValue(state => ({"defaultScreen": defaultScreenValue}));
        }
    }, [showDefaultScreenForm]);

    const getDefaultScreenData = () => {
        getRoleData().then((response) => {
            let fetchedValue = [];
            if (response.data && response.data.modules) {
                (response.data.modules).forEach((obj) => {
                    if (obj && obj.subModule && obj.subModule.length) {
                        (obj.subModule).forEach((subObj) => {
                            if (subObj.isActive) {
                                fetchedValue.push(subObj.subModuleTitle);
                            }
                        })
                    }
                })
            }

            setDefaultScreenArray(fetchedValue);
        });
        setFormValue({"defaultScreen": defaultScreenValue});
    };

    const getRoleData = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getSecurityRoleByTitle',
            requestConfig: {
                role: {"title": user.securityRole}
            }
        });
        return await response;
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleClose = () => {
        cancelHandler();
        setShowMessage(false);
        dispatch(updateDefaultScreenForm());
    };

    const cancelHandler = () => {
        setShowMessage(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if(!checkValidate) {
            return false;
        }

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/updateUserDefaultScreen',
            requestConfig: {
                data: {
                    "defaultScreen": formValue.defaultScreen,
                    "uniqueId": user.uniqueId
                }
            }
        });

        if (response.data) {
            dispatch(updateUser(response.data));
            dispatch(updateDefaultScreen(response.data.defaultScreen))
        }
        setShowMessage(true);
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={showDefaultScreenForm} onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Default Screen Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading} formValidation={formValidation}/>
                    <DefaultScreenSelectionForm submitHandler={submitHandler} handleChange={handleChange}
                                                formValue={formValue}
                                                inputValidation={inputValidation}
                                                defaultScreenArray={defaultScreenArray}
                                                formValidation={formValidation} disableSubmit={disableSubmit}/>
                    <small className="form-text text-muted">
                        NOTE : Once successfully submitted. On Login again, will redirect to the default screen
                        selection.
                    </small>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default DefaultScreenSelection;