import React from 'react';
import * as htmlToImage from 'html-to-image';
import {PDFDocument, StandardFonts, rgb} from 'pdf-lib';
import coreLogo from "../../assets/CoreLinenLogo.png";
import {getCurrentDateOnly, getDateInMonth} from "../../commom/DateFormat";
import Button from "react-bootstrap/Button";
import {validationCheck} from "../../commom/ValidateFormData";

const PdfGenerator = ({
                          userName, siteContact, user, chartRef, secondChartRef, defaultDate, startDate, endDate,
                          emailSubject, site, comment, submitHandler, sendPDFToServer, clientStatHeaderData,
                          clientStatBodyData, linenHeaderData, linenBodyData, parHeaderData, parBodyData, qbrHeaderData,
                          qbrBodyData, linenAwarenessHeaderData, linenAwarenessBodyData, linenCommitteeHeaderData,
                          linenCommitteeBodyData, linenLossHeaderData, linenLossBodyData, chartExport, emailAddress,
                          siteContactEmail
                      }) => {

    const convertBase64ToUint8Array = async (imageData) => {
        //Chart Image in base 64
        const dataUrl = await htmlToImage.toPng(imageData);
        const base64String = dataUrl.replace(/^data:image\/(png|jpg);base64,/, '');
        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes;
    };

    // Function to split string by width
    const splitStringByWidth = (str, fontSize, maxWidth, font) => {
        const words = str.trim().replace(/\n/g, ' ').split(' ').filter(word => word !== '');
        const lines = [];
        let currentLine = '';

        words.forEach(word => {
            let testLine = currentLine + (currentLine ? ' ' : '') + word;

            if(testLine){
                // Replace the matched characters with an empty string
                testLine = testLine.replace(/\n/g, ' ');
            }
            const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);
            if (testLineWidth <= maxWidth) {
                currentLine = testLine;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        if (currentLine) {
            lines.push(currentLine);
        }

        return lines;
    };

    const prepareEmailLines = (headerOfPdf, receiverName, site, comment, senderName, contentWidth, fontSize, font) => {
        let line = `I visited your facility ${site} and I wanted to share the information that I collected while I was there. Let me know if you have any questions.`;
        let splitLineField = splitStringByWidth(line, fontSize, contentWidth, font);
        let lines = [];

        if (comment && receiverName) {
            let splitCommentField = splitStringByWidth(comment, fontSize, contentWidth, font);
            lines = [
                headerOfPdf,
                `Hello ${receiverName},`,
                "",
                ...splitLineField,
                "",
                ...splitCommentField,
                "",
                "Enjoy your day,",
                senderName
            ];
        } else if (receiverName) {
            lines = [
                headerOfPdf,
                `Hello ${receiverName},`,
                "",
                ...splitLineField,
                "",
                "Enjoy your day,",
                senderName
            ];
        } else if (comment) {
            let splitCommentField = splitStringByWidth(comment, fontSize, contentWidth, font);
            lines = [
                headerOfPdf,
                "",
                ...splitLineField,
                "",
                ...splitCommentField,
                "",
                "Enjoy your day,",
                senderName
            ];
        } else {
            lines = [
                headerOfPdf,
                "",
                ...splitLineField,
                "",
                "Enjoy your day,",
                senderName
            ];
        }

        return lines;
    };

    const getDateForPdf = () => {
        let headerOfPdf = "";
        if (defaultDate) {
            let todayDate = getCurrentDateOnly();
            headerOfPdf = getDateInMonth(todayDate);
        } else {
            if (startDate == endDate) {
                headerOfPdf = getDateInMonth(startDate);
            } else {
                headerOfPdf = getDateInMonth(startDate) + " to " + getDateInMonth(endDate);
            }
        }

        return headerOfPdf;
    };

    const drawCenteredText = (page, text, font, fontSize, yPosition, margin, width, lineHeight) => {
        if(text){
            // Replace the matched characters with an empty string
            text = text.replace(/\n/g, ' ');
        }
        const textWidth = font.widthOfTextAtSize(text, fontSize);
        page.drawText(text, {
            x: margin + (width / 2) - (textWidth / 2),
            y: yPosition - lineHeight,
            size: fontSize,
            font: font,
            color: rgb(0, 0.53, 0.71)
        });
        return yPosition - lineHeight;
    };

    const drawChart = async (imageBytes, page, yPosition, chartWidth, margin, pageWidth, pageHeight, pdfDoc) => {
        const image = await pdfDoc.embedPng(imageBytes);
        let scaleFactor = 1;
        const imageDims = image.scale(scaleFactor);
        const aspectRatio = imageDims.width / imageDims.height;
        const chartHeight = chartWidth / aspectRatio;

        const requiredHeight = chartHeight + margin;
        if (yPosition < requiredHeight) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            yPosition = pageHeight - margin;
        }

        // Draw the background rectangle with the specified color
        page.drawRectangle({
            x: margin,
            y: yPosition - chartHeight,
            width: chartWidth,
            height: chartHeight,
            borderColor: rgb(0.502, 0.502, 0.502),
            borderWidth: .5,
        });

        page.drawImage(image, {
            x: margin,
            y: yPosition - chartHeight,
            width: chartWidth,
            height: chartHeight
        });

        yPosition -= (chartHeight);
        return { page, yPosition };
    };

    const drawHeader = (header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, fontSize) => {
        header.forEach((header, colIndex) => {
            const x = margin + colIndex * columnWidth;
            const cellWidth = header.cellSpan ? columnWidth * header.cellSpan : columnWidth;

            // Draw the rectangle for the header cell
            page.drawRectangle({
                x,
                y: yPosition - lineHeight,
                width: cellWidth,
                height: lineHeight,
                borderColor: rgb(0.502, 0.502, 0.502),
                borderWidth: 0.5,
                color: rgb(0.91, 0.93, 0.94),
            });

            // Calculate the vertical center position for the text
            const textHeight = font.heightAtSize(fontSize);
            const textY = yPosition - lineHeight + (lineHeight - textHeight) / 2;

            // Draw the text in the header cell
            page.drawText(header.header, {
                x: x + (cellWidth - font.widthOfTextAtSize(header.header, fontSize)) / 2,
                y: textY,
                size: fontSize,
                font,
                color: rgb(0, 0, 0),
            });
        });
        return yPosition - lineHeight;
    };

    const drawRow = (row, header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, fontSize) => {
        const cellHeights = header.map((header) => {
            const text = String(row[header.name] || '');
            const cellWidth = columnWidth - cellPadding * 2;
            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';
                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ');
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);
            return lines.length * lineHeight + cellPadding * 2;
        });

        const rowHeight = Math.max(...cellHeights);

        header.forEach((header, colIndex) => {
            const text = String(row[header.name] || '');
            const cellWidth = columnWidth - cellPadding * 2;
            const x = margin + colIndex * columnWidth;

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';

                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ');
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);

            lines.forEach((line, lineIndex) => {
                page.drawText(line.trim(), {
                    x: x + cellPadding,
                    y: yPosition - (lineIndex + 1) * lineHeight,
                    size: fontSize,
                    font,
                    color: rgb(0, 0, 0),
                });
            });

            page.drawRectangle({
                x,
                y: yPosition - rowHeight,
                width: columnWidth,
                height: rowHeight,
                borderColor: rgb(0.502, 0.502, 0.502),
                borderWidth: 0.5,
            });
        });

        return yPosition - rowHeight;
    };

    const getRowHeight = (row, header, columnWidth, cellPadding, lineHeight, margin, font, fontSize) => {
        const cellHeights = header.map((header) => {
            const text = String(row[header.name] || '');
            const cellWidth = columnWidth - cellPadding * 2;
            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';

                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ')
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);
            return lines.length * lineHeight + cellPadding * 2;
        });

        return Math.max(...cellHeights);
    };

    const drawTableTitle = (page, text, margin, yPosition, width, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc) => {
        // Draw the background rectangle with the specified color
        page.drawRectangle({
            x: margin,
            y: yPosition - lineHeight,
            width: width,
            height: lineHeight,
            borderColor: rgb(0.502, 0.502, 0.502),
            borderWidth: .5,
            color: rgb(0.91, 0.93, 0.94),  // Background color #e9ecef
        });

        if(text){
            // Replace the matched characters with an empty string
            text = text.replace(/\n/g, ' ');
        }

        // Draw the text over the background rectangle
        page.drawText(text, {
            x: (pageWidth - font.widthOfTextAtSize(text, fontSize)) / 2,
            y: yPosition - lineHeight + cellPadding,
            size: fontSize,
            font,
            color: rgb(0, 0, 0),  // Text color
        });

        // Update yPosition for the next element
        yPosition -= lineHeight;
        return {page, yPosition};
    };

    const drawNoDataMessage = (page, margin, yPosition, width, lineHeight, font, tableFontSize, cellPadding, pageWidth) => {
        let noDataMessage = 'NO DATA AVAILABLE';

        // Draw the background rectangle with the specified color
        page.drawRectangle({
            x: margin,
            y: yPosition - lineHeight,
            width: width,
            height: lineHeight,
            borderColor: rgb(0.502, 0.502, 0.502),
            borderWidth: .5,
        });

        if(noDataMessage){
            // Replace the matched characters with an empty string
            noDataMessage = noDataMessage.replace(/\n/g, ' ');
        }

        // Draw the "NO DATA AVAILABLE" message
        page.drawText(noDataMessage, {
            x: (pageWidth - font.widthOfTextAtSize(noDataMessage, tableFontSize)) / 2,
            y: yPosition - lineHeight + cellPadding,
            size: tableFontSize,
            font,
            color: rgb(0, 0, 0),  // Text color
        });

        // Update yPosition for the next element
        return yPosition - lineHeight;
    };

    const drawTableWithTitleAndHeader = (page, title, header, bodyData, yPosition, cellPadding,
                                         lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight,
                                         contentWidth, fontSize) => {
        const columnWidth = contentWidth / header.length;

        // Calculate the height required for the title, header, and one row
        const titleHeight = lineHeight; // Assuming title height is the same as line height
        const headerHeight = lineHeight;
        let rowHeight = 0;
        if (bodyData.length !== 0) {
            rowHeight = getRowHeight(bodyData[0], header, columnWidth, cellPadding, lineHeight, margin, font, tableFontSize);
        }

        // Check if there is enough space for the title, header, and one row, if not create a new page
        if (yPosition - (titleHeight + headerHeight + rowHeight) < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            yPosition = pageHeight - margin;
        }

        // Draw Table Title
        ({ page, yPosition } = drawTableTitle(page, title, margin, yPosition, contentWidth, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc));

        // If no data, show "NO DATA AVAILABLE" message
        if (bodyData.length === 0) {
            yPosition = drawNoDataMessage(page, margin, yPosition, contentWidth, lineHeight, font, tableFontSize, cellPadding, pageWidth);
        } else {
            // Draw the table header
            yPosition = drawHeader(header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, boldFont, tableFontSize);

            // Draw table rows
            bodyData.forEach((row) => {
                const rowHeight = getRowHeight(row, header, columnWidth, cellPadding, lineHeight, margin, font, tableFontSize);

                // Check if there is enough space for the next row, if not create a new page with the title and header
                if (yPosition - rowHeight < margin) {
                    page = pdfDoc.addPage([pageWidth, pageHeight]);
                    yPosition = pageHeight - margin;

                    // Draw Table Title
                    ({ page, yPosition } = drawTableTitle(page, title, margin, yPosition, contentWidth, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc));

                    // Draw the table header
                    yPosition = drawHeader(header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, boldFont, tableFontSize);
                }

                yPosition = drawRow(row, header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, tableFontSize);
            });
        }
        yPosition -= margin;
        return { page, yPosition };
    };

    // Draw the title and chart, ensuring they fit on one page
    const drawTitleAndChart = async (page, yPosition, pageWidth, pageHeight, pdfDoc, imageBytes, chartData, lineHeight, title, margin, contentWidth, font, fontSize, cellPadding, tableFontSize, index) => {
        // Calculate required space for title and chart
        let chartHeight = lineHeight;
        let onSiteChart = chartData[index].data;
        if (onSiteChart && onSiteChart.length !== 0) {
            const image = await pdfDoc.embedPng(imageBytes);
            const imageDims = image.scale(1);
            const aspectRatio = imageDims.width / imageDims.height;
            chartHeight = (pageWidth - 2 * margin) / aspectRatio;
        }
        const requiredHeight = lineHeight + chartHeight + margin;
        if (yPosition < requiredHeight) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            yPosition = pageHeight - margin;
        }

        // Draw Table Title
        ({ page, yPosition } = drawTableTitle(page, title, margin, yPosition, contentWidth, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc));


        // Check if there is data for the chart
        if (onSiteChart && onSiteChart.length === 0) {
            // Draw "NO DATA AVAILABLE" message
            yPosition = drawNoDataMessage(page, margin, yPosition, contentWidth, lineHeight, font, tableFontSize, cellPadding, pageWidth);
        } else {
            // Draw the chart
            ({ page, yPosition } = await drawChart(imageBytes, page, yPosition, pageWidth - 2 * margin, margin, pageWidth, pageHeight, pdfDoc));
        }

        // Adjust yPosition for next element
        yPosition -= margin;

        return { page, yPosition };
    };

    const getReceiverName = (userName, siteContact) => {
        if (userName && userName.trim() !== "") {
            return userName.trim();
        } else if (siteContact && siteContact.trim() !== "" && siteContact.trim() !== "select") {
            return siteContact.split(' ')[0]; // Take the first word of siteContact
        }
        return null; // Or any default value you want to return when both are empty
    };

    const handlePDFExport = async (sendPfdByEmail) => {
        let checkEmail = validationCheck("email", emailAddress);
        let senderEmail = siteContactEmail ? siteContactEmail : "";
        if(sendPfdByEmail && (checkEmail || senderEmail)) {
            submitHandler(false);
        } else if(!sendPfdByEmail) {
            submitHandler(false);
        }
        const companyImageBytes = await fetch(coreLogo).then((res) => res.arrayBuffer());
        let receiverName = getReceiverName(userName, siteContact);
        let senderName = user.firstName + " " + user.lastName;
        const bytes = await convertBase64ToUint8Array(chartRef);
        const bytesSecondChartRef = await convertBase64ToUint8Array(secondChartRef);
        let headerOfPdf = getDateForPdf();
        let subjectOfEmail = (emailSubject || "Summary Report").trim();

        // Create a new PDF document
        let pdfDoc = await PDFDocument.create();
        // Define A4 dimensions (595.28 x 841.89 points)
        let pageWidth = 595.28;
        let pageHeight = 841.89;
        const margin = 36; //  0.5 inches (36 points):
        let page = pdfDoc.addPage([pageWidth, pageHeight]);
        let width = pageWidth - 2 * margin;
        let height = pageHeight;
        const tableFontSize = 7;
        const fontSize = 12;
        const headerFontSize = 15;
        const lineHeight = 18;
        const cellPadding = 5;
        let yPosition = height - margin;
        // Load the standard font
        const font = await pdfDoc.embedStandardFont('Helvetica');
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const contentWidth = pageWidth - 2 * margin;

        // Company Logo
        const companyImage = await pdfDoc.embedPng(companyImageBytes);
        const companyImageSize = companyImage.scale(0.8);
        page.drawImage(companyImage, {
            x: margin + (width / 2) - (companyImageSize.width / 2),
            y: yPosition - companyImageSize.height,
            width: companyImageSize.width,
            height: companyImageSize.height
        });

        yPosition -= companyImageSize.height + lineHeight;

        // Usage for Site Name
        yPosition = drawCenteredText(page, site, font, headerFontSize, yPosition, margin, width, lineHeight);

        // Usage for Subject of Email
        yPosition = drawCenteredText(page, subjectOfEmail, font, headerFontSize, yPosition, margin, width, lineHeight);

        yPosition -= margin;

        const emailLines = prepareEmailLines(headerOfPdf, receiverName, site, comment, senderName, width, fontSize, font);

        // Generate Email
        emailLines.forEach(line => {
            if (yPosition < margin + lineHeight) {
                page = pdfDoc.addPage([pageWidth, pageHeight]);
                yPosition = height - margin;
            }
            page.drawText(line, {
                x: margin,
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0)
            });
            yPosition -= lineHeight;
        });

        // Draw Chart DEPT LINEN FEEDBACK
        ({ page, yPosition } =
            await drawTitleAndChart(page, yPosition, pageWidth, pageHeight, pdfDoc, bytes, chartExport, lineHeight, 'DEPT LINEN FEEDBACK', margin, contentWidth, font, fontSize, cellPadding, tableFontSize, 0));

        // Draw Chart 'ONSITE LINEN INSPECTION'
        ({ page, yPosition } = await drawTitleAndChart(page, yPosition, pageWidth, pageHeight, pdfDoc, bytesSecondChartRef, chartExport, lineHeight, 'ONSITE LINEN INSPECTION', margin, contentWidth, font, fontSize, cellPadding, tableFontSize, 1));

        // Draw Table Title CLIENT STAT
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'CLIENT STAT', clientStatHeaderData, clientStatBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title LINEN QUALITY & AVAILABILITY
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'LINEN QUALITY & AVAILABILITY', linenHeaderData, linenBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title PAR
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'PAR', parHeaderData, parBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title QBR
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'QBR', qbrHeaderData, qbrBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title LINEN AWARENESS
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'LINEN AWARENESS', linenAwarenessHeaderData, linenAwarenessBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title LINEN COMMITTEE
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'LINEN COMMITTEE', linenCommitteeHeaderData, linenCommitteeBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Draw Table Title LINEN LOSS
        ({
            page,
            yPosition
        } = drawTableWithTitleAndHeader(page, 'LINEN LOSS', linenLossHeaderData, linenLossBodyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        if (sendPfdByEmail) {
            // Send PDF to server for email
            sendPDFToServer(pdfBytes);
        } else {
            // Trigger download of the PDF
            const blob = new Blob([pdfBytes], {type: 'application/pdf'});
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'chart_document.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            window.localStorage.setItem('chartRef', "");
        }
    };

    return (
        <>
            <Button onClick={() => handlePDFExport(false)} className="mx-sm-1 buttonCss">
                Export as PDF
            </Button>
            <Button onClick={() => handlePDFExport(true)} className="mx-sm-1 buttonCss">
                Send Email With PDF
            </Button>
        </>
    );
};

export default PdfGenerator;