import React, {useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import ItemCategoryForm from "./ItemCategoryForm";
import ItemCategoryList from "./ItemCategoryList";
import {updateList} from "../../reducer/FrequencyReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {trimObjectValues} from "../../commom/CommonFunction";

const ItemCategory = () => {
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [id, setId] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: "",
        value: "",
        isActive: false
    });
    const [inputValidation, setInputValidation] = useState({
        value: true
    });
    const fieldValidation = {
        value: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [readOnlyField, setReadOnlyField] = useState(false);

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setReadOnlyField(true);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(true, value);
        setId(value._id);
    };

    const cancelHandler = () => {
        setReadOnlyField(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        setDisableSubmit(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setId(null);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);
        if (!checkValidate) {
            return false;
        }

        let newData = trimObjectValues({...formValue});
        newData["modAt"] = new Date();
        newData["type"] = screen;

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateMasterData',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(updateList(response.data));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
        }
        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={6}><ItemCategoryList selectedRow={selectedRow}/></Col>
                <Col sm={6}>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/><ItemCategoryForm submitHandler={submitHandler}
                                              cancelHandler={cancelHandler} buttonTitle={buttonTitle}
                                              handleChange={handleChange} screen={screen} disableSubmit={disableSubmit}
                                              formValue={formValue} formValidation={formValidation}
                                              inputValidation={inputValidation}
                                              response={response} error={error} loading={loading}
                                              showMessage={showMessage} readOnlyField={readOnlyField}/></Col>
            </Row>
        </Container>
    )
};

export default ItemCategory;