import { createSlice } from '@reduxjs/toolkit'

export const ItemCategoryReducer = createSlice({
    name: "ItemCategoryReducer",
    initialState: {
        itemCategory: "",
        houseCode: ""
    },
    reducers: {
        updateItemCategoryData: (state, action) => {
            state.itemCategory = action.payload.itemCategory;
            state.imperfectionType = action.payload.imperfectionType;
        }
    }
});

// Action creators are generated for each case reducer function
export const {updateItemCategoryData} = ItemCategoryReducer.actions;

export default ItemCategoryReducer.reducer;