import React from 'react';
import {
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    Radar,
    PolarRadiusAxis,
    PolarAngleAxis,
    Tooltip,
    ScatterChart, Legend
} from 'recharts';

const RadarChartWithLabel = ({data, name, label, nextLabel, flag, referenceValue, headerName, linenImperfection}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
                    <PolarGrid />
                    <Tooltip/>
                    <PolarAngleAxis dataKey={name} />
                    <PolarRadiusAxis angle={90} domain={[0, 100]} />
                    <Legend/>
                    <Radar name={label} dataKey={label} stroke="#1a80bb" fill="#1a80bb" fillOpacity={1} />
                    {flag &&
                    <Radar name={nextLabel} dataKey={nextLabel} stroke="#a00000" fill="#a00000" fillOpacity={1} />}
                    {
                        linenImperfection && linenImperfection.length &&
                        linenImperfection.map((linenImperfectionObj) => {
                            return <Radar name={linenImperfectionObj} dataKey={linenImperfectionObj} stroke="#8884d8" fill="#8884d8" fillOpacity={1} />
                        })
                    }
                </RadarChart>
        </ResponsiveContainer>
    );
};

export default RadarChartWithLabel;