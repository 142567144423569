import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const PlantConfigForm = (props) => {
    return (
        <form className="formContainer w-100 p-3">
            <div className="form-group row mb-1">
                <label htmlFor="value" className="col-sm-2 col-form-label"><span
                    className="text-danger starCss">*</span>Value</label>
                <div className="col-sm-10">
                    {props.screen === "Admin Email" &&
                    <input type="email" name="value"
                           onChange={props.handleChange}
                           className={`form-control ${!props.inputValidation.value ? "errorDiv" : ""}`}
                           value={props.formValue.value}/>
                    }
                    {props.screen === "Imperfection Threshold" &&
                    <input type="number" name="value"
                           onChange={props.handleChange}
                           className={`form-control ${!props.inputValidation.value ? "errorDiv" : ""}`}
                           value={props.formValue.value}/>
                    }
                    {(props.screen !== "Admin Email" && props.screen !== "Imperfection Threshold") &&
                    <input type="text" id="value" name="value"
                           onChange={props.handleChange}
                           className={`form-control ${!props.inputValidation.value ? "errorDiv" : ""}`}
                           value={props.formValue.value} disabled={props.isReadonly}/>
                    }
                </div>
            </div>
            {
                props.isPlantDropdown && <div className="form-group row mb-1">
                    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                        <input type="email" className={`form-control`}
                               id="email" name="email"
                               onChange={props.handleChange} value={props.formValue.email}/>
                    </div>
                </div>
            }
            {(props.screen !== "Admin Email" && props.screen !== "Imperfection Threshold" && props.screen !== "Master Role") &&
            <div className="form-group row mb-1">
                <label htmlFor="active" className="col-sm-2 col-form-label">Active</label>
                <div className="col-sm-10">
                    <input type="checkbox" className="form-check-input" id="active" name="isActive"
                           onChange={props.handleChange} checked={props.formValue.isActive}/>
                </div>
            </div>
            }
            {
                props.isPlantDropdown &&
                <div className="form-group row mb-1">
                    <Row>
                        <label className="col-form-label">
                            <span className="text-danger starCss">*</span>Plant List</label>
                    </Row>
                    <Row>
                        {props.plantList && props.plantList.length
                        && props.plantList.map((obj, index) => {
                            return <Col key={index + obj} xs={6}>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name="plant"
                                           data-label={obj.label} id={obj.label}
                                           checked={obj.value} onChange={props.handleCheckboxChange}/>
                                    <label className="form-check-label" htmlFor={obj.label}>{obj.label}</label>
                                </div>
                            </Col>

                        })
                        }
                    </Row>
                </div>

            }
            {(props.screen !== "Admin Email" && props.screen !== "Imperfection Threshold" && props.screen !== "Master Role") &&
            <div className="row mx-auto form">
                <Col>
                    <Button onClick={props.handleOpen} className="formButton buttonCss"
                            disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
            }
            {((props.screen === "Admin Email" || props.screen === "Imperfection Threshold" || props.screen === "Master Role") && props.buttonTitle !== "ADD") &&
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss"
                            disabled={props.disableSubmit}>Submit</Button>
                </Col>
            </div>
            }
        </form>
    )
};

export default PlantConfigForm;