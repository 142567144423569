import
    React
    from
        "react";

const MultiSelect = (props) => {
    const { show, data, label, handleSearch, searchValue, handleCheckboxChange, allSelected } = props;

    const handleChange = (event) => {
        let value = event.currentTarget.getAttribute("name");
        let checked;
        let name = event.currentTarget.attributes['data-name'].value;
        let allChecked = false;
        let count = 0;
        let selectData = [];
        let arrayList = data;
        let flag = true;

        if (value === "selectAll") {
            checked = !allSelected;
            allChecked = true;
        }

        const newData = arrayList.map(obj => {
            let data = Object.assign({}, obj);

            if (allChecked) {
                data.value = checked;

                if (checked) {
                    count = arrayList.length;
                    selectData.push(data);
                } else {
                    count = 0;
                }
                return data
            } else {
                if (data.label === value) {
                    checked = !data.value;
                    data.value = checked;
                }

                if (data.value) {
                    count += 1;
                    selectData.push(data);
                }

                if (!data.value) {
                    flag = false;
                }

                return data;
            }
        });

        if (!allChecked) {
            checked = flag;
        }
        handleCheckboxChange(event, newData, checked, count, name);
    };

    return (
        <>
            {show &&
            <div>
                <input
                    name={label}
                    type="text"
                    onChange={handleSearch}
                    placeholder="Search"
                    className="form-control mb-sm-1"
                    value={searchValue}
                />
            </div>
            }
            {show && data && data.length > 0 &&
            <div data-name={label} name="selectAll" htmlFor={label}
                 onClick={handleChange}>
                <input
                    name="selectAll"
                    type="checkbox"
                    id={label}
                    checked={allSelected}
                    onChange={handleChange}
                    data-name={label}
                    className="form-check-inline"
                />
                Select All
            </div>
            }
            {show && data && data.length > 0 &&
            data.map((obj, index) => {
                if (obj.value) {
                    return (
                        <div key={index + obj.label} data-name={label} name={obj.label} htmlFor={obj.label}
                             onClick={handleChange}>
                            <input
                                className="form-check-inline"
                                data-name={label}
                                name={obj.label}
                                id={obj.label}
                                type="checkbox"
                                checked={obj.value}
                                onChange={handleChange}
                            />
                            {obj.label}
                        </div>
                    );
                }
            })
            }
            {show && data && data.length > 0 &&
            data.map((obj, index) => {
                if (!obj.value) {
                    return (
                        <div key={index + obj.label} data-name={label} name={obj.label} htmlFor={obj.label}
                             onClick={handleChange}>
                            <input
                                className="form-check-inline"
                                data-name={label}
                                name={obj.label}
                                id={obj.label}
                                type="checkbox"
                                checked={obj.value}
                                onChange={handleChange}
                            />
                            {obj.label}
                        </div>
                    );
                }
            })
            }
            {show && data && !data.length &&
            <div name="No Option" htmlFor={label}>
                No Option Available
            </div>
            }
        </>
    );
};

export default MultiSelect;
