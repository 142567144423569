import { createSlice } from '@reduxjs/toolkit'

export const PlantDropdownReducer = createSlice({
    name: "plantDropdown",
    initialState: {
        "screen": "Visit Frequency"
    },
    reducers: {
        updatePlantDropdownScreen: (state, action) => {
            state.screen = action.payload
        }
    }
});

export const { updatePlantDropdownScreen } = PlantDropdownReducer.actions;

export default PlantDropdownReducer.reducer;