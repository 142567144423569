import { createSlice } from '@reduxjs/toolkit'

export const TokenModalReducer = createSlice({
    name: "TokenModalReducer",
    initialState: {
        "showTokenModal": false
    },
    reducers: {
        updateTokenModal: (state, action) => {
            state.showTokenModal = action.payload;
        }
    }
});

export const { updateTokenModal } = TokenModalReducer.actions;

export default TokenModalReducer.reducer;