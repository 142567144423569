import { createSlice } from "@reduxjs/toolkit";
import {updatedObjectByActiveKey} from "../commom/CommonFunction";

const initialState = [];

export const SiteReducer = createSlice({
    name: "site",
    initialState,
    reducers: {
        setSite: (state, action) => {
            state.length = 0;
            action.payload.forEach(obj => {
                if(obj.siteStatus === "Open") {
                    state.push(obj);
                }
            });
        },
        updateSite: (state, action) => {
            let objIndex = state.findIndex(stateObj => {
                return ((stateObj.siteId === action.payload.siteId)) ;
            });

            let data = updatedObjectByActiveKey(action.payload);
            if (objIndex >= 0) {
                state.splice(objIndex, 1, data);
            } else if(objIndex === -1) {
                state.push(data);
            }
        },
        searchSite: (state, action) => {
            let objIndex = state.filter(stateObj => {
                return (stateObj.siteId === action.payload) ;
            });

            return objIndex;
        }
    }
});
export const {setSite, updateSite, searchSite} = SiteReducer.actions;

export default SiteReducer.reducer;