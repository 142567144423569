import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const IdnReducer = createSlice({
    name: "idn",
    initialState,
    reducers: {
        setIdnData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        },
        updateIdn: (state, action) => {
            let objIndex = state.findIndex(stateObj => {
                return (stateObj._id === action.payload._id) ;
            });

            if (objIndex >= 0) {
                state.splice(objIndex, 1, action.payload);
            } else if(objIndex === -1) {
                state.push(action.payload);
            }
        }
    }
});
export const {setIdnData, updateIdn} = IdnReducer.actions;

export default IdnReducer.reducer;