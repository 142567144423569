import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const PlantQualityDashboardDataReducer = createSlice({
    name: "PlantQualityDashboardData",
    initialState,
    reducers: {
        setPlantQualityDashboardData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});
export const {setPlantQualityDashboardData} = PlantQualityDashboardDataReducer.actions;

export default PlantQualityDashboardDataReducer.reducer;