import { createSlice } from "@reduxjs/toolkit";

export const FrequencyReducer = createSlice({
    name: "frequency",
    initialState:[],
    reducers: {
        list: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        },
        updateList: (state, action) => {
            let objIndex = state.findIndex(stateObj => {
                return (stateObj._id === action.payload._id) ;
            });

            if (objIndex >= 0) {
                state.splice(objIndex, 1, action.payload);
            } else if(objIndex === -1) {
                state.push(action.payload);
            }
        }
    }
});
export const {list, updateList} = FrequencyReducer.actions;

export default FrequencyReducer.reducer;