import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './QualityAuditDashboard.css';
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import Table from 'react-bootstrap/Table';
import {getCurrentDateOnly, getNextDateOnly} from "../../commom/DateFormat";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {checkValue, convertStringToNumber, searchArray} from "../../commom/CommonFunction";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Loader from "../../commom/LoaderComponent";
import BarChartD from "../../commom/BarChartD";
import {setPlant} from "../../reducer/PlantReducer";
import {userDetail} from "../../reducer/UserReducer";

const QualityDashboardToday = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [plantQualityAudit, setPlantQualityAudit] = useState("");
    const plantDataObj = useSelector((state) => state.plantNameReducer);
    const userId = useSelector((state) => state.userState.user.uniqueId);
    const [plantData, setPlants] = useState();


    useEffect(() => {
        dispatch(showDateInHeader(false));

        getUserDetailWithPlant().then(response => {
            dispatch(setPlant(response.plants));
            dispatch(userDetail({"user": response._doc}));
            if(plantDataObj.houseCode !== "") {
                setPlants(plantDataObj);
                getPlantQuality(plantDataObj);
            } else {
                let plantValue = response.plants[0];
                setPlants({
                    plant: plantValue._id,
                    houseCode: plantValue.houseCode
                });
                getPlantQuality(plantValue);
            }
        });
    }, []);

    const getUserDetailWithPlant = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithPlant',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getPlantQuality = (plantValue) => {
        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantQualityAuditByDates',
                requestConfig: {
                    data: {
                        "plant": [plantValue.plant],
                        "startDate": getCurrentDateOnly(),
                        "endDate": getNextDateOnly(),
                        "userId": userId
                    }
                }
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            let newArray = [];
            fetchedValue.forEach(obj => {
                let objData = Object.assign({}, obj);
                newArray.push(objData)
            });
            getPlantQualityAudit(plantValue).then((fetchedPlantQualityAudit) => {
                setPlantQualityAudit(plantItemCategoryDetail(newArray, fetchedPlantQualityAudit[0].inspections));
            });
        });
    };

    const getPlantQualityAudit = async (plantValue) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantQAConfigByHouseCode',
            requestConfig: {
                data: plantValue.houseCode
            }
        });
        return await response.data;
    };

    const getImperfectionCount = (imperfectionArray) => {
        let count = 0;
        imperfectionArray.forEach((obj) => {
            if (obj.value) {
                count += convertStringToNumber(obj.value);
            }
        });
        return count;
    };

    const plantItemCategoryDetail = (fetchedValue, inspectedPlantItem) => {
        let itemNewArray = [];
        let itemCatArr = []; // add unique housecode present in the fetched data
        let plantArray = []; // create new array for table insertion
        let newArray = [];

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            newArray.push(objData)
        });

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            itemNewArray.push(objData)
        });
        // fetched data array
        newArray.forEach((plantObject) => {
            // Add unique object in plantArray with new key as "chart"
            if (itemCatArr.indexOf(plantObject.itemCategory) < 0) {
                itemCatArr.push(plantObject.itemCategory);
                let data = {};
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.inspected) + imperfectionValue;
                let itemCount = searchArray(plantObject.itemCategory, inspectedPlantItem, "item");
                let totalIns = checkValue(itemCount.count);
                data["chart"] = [];
                let chart1 = {
                    "title": "Inspected Done",
                    "inspected": inspectValue
                };
                data["chart"].push(chart1);
                let chart2 = {
                    "title": "Inspected Needed",
                    "inspected": totalIns
                };
                data["chart"].push(chart2);
                data["itemCategory"] = plantObject.itemCategory;
                data["inspected"] = inspectValue;
                data["imperfections"] = imperfectionValue;

                let insAvg = Math.ceil((imperfectionValue / inspectValue) * 100);
                data["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                data["inspectedAvg"] = Math.ceil(inspectValue);
                data["imperfectionTypeChart"] = imperfectionTypeDetail(plantObject.itemCategory, itemNewArray);

                plantArray.push(data);
            } else {
                const resultObject = searchArray(plantObject.itemCategory, plantArray, "itemCategory");
                const chartObject = resultObject["chart"][0];
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.inspected) + imperfectionValue;

                if (chartObject) {
                    chartObject.inspected = chartObject.inspected + inspectValue;
                }

                // Duplicate plant object should sum value
                resultObject.inspected = convertStringToNumber(plantObject.inspected) + resultObject.inspected;
                resultObject.imperfections = convertStringToNumber(plantObject.imperfections) + resultObject.imperfections;
                let insAvg = Math.ceil((resultObject.imperfections / resultObject.inspected) * 100);
                resultObject["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                resultObject["inspectedAvg"] = Math.ceil(resultObject.inspected / resultObject["chart"].length);
            }
        });

        let totalArray = totalItemCategoryDetail(plantArray, inspectedPlantItem);
        plantArray.unshift(totalArray);
        return plantArray;
    };

    const imperfectionTypeDetail = (itemCategory, fetchedValue) => {
        let newArray = [];

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            if (itemCategory === objData.itemCategory) {
                newArray.push(objData);
            }
        });

        let chart = [];
        newArray.forEach((itemData) => {
            itemData.imperfectionArray.map((plantData) => {
                let imperfectionValue = convertStringToNumber(plantData.value);
                const chartObject = searchArray(plantData.imperfectTitle, chart, "imperfectionType");

                if (chartObject) {
                    // Duplicate imperfection Type object should sum value
                    chartObject.imperfections = chartObject.imperfections + imperfectionValue;
                } else {
                    // unique imperfection Type should append in "chart" key
                    let chartObj = {
                        "imperfections": imperfectionValue,
                        "imperfectionType": plantData.imperfectTitle
                    };

                    chart.push(chartObj)
                }
            });
        });
        return chart;
    };

    const totalItemCategoryDetail = (fetchedValue, inspectedPlantItem) => {
        let totalArray = {
            "inspected": 0,
            "imperfections": 0,
            "itemCategory": "Total",
            "imperfectionType": "Total",
            "imperfectionsAvg": 0,
            "imperfectionTypeChart": [],
            "chart": [],
            "pie": [],
            "totalInspected": 0
        };
        totalArray["chart"] = [];
        totalArray["pie"] = [];

        fetchedValue.forEach((plantObject) => {
            let imperfectionValue = convertStringToNumber(plantObject.imperfections);
            let inspectValue = convertStringToNumber(plantObject.inspected);
            let itemCount = searchArray(plantObject.itemCategory, inspectedPlantItem, "item");
            let totalIns = convertStringToNumber(itemCount.count);
            totalArray["totalInspected"] += totalIns;
            totalArray["inspected"] += inspectValue;
            totalArray["imperfections"] += imperfectionValue;
            totalArray["imperfectionTypeChart"] = totalImperfectionTypeDetail(fetchedValue);
        });
        let chart1 = {
            "title": "Inspected Done",
            "inspected": totalArray["inspected"]
        };
        totalArray["chart"].push(chart1);
        let chart2 = {
            "title": "Inspected Needed",
            "inspected": totalArray["totalInspected"]
        };
        totalArray["chart"].push(chart2);

        let insAvg = Math.ceil((totalArray["imperfections"] / totalArray["inspected"]) * 100);
        totalArray["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
        totalArray["inspectedAvg"] = Math.ceil(totalArray["inspected"]);
        return totalArray;
    };

    const totalImperfectionTypeDetail = (itemCategory) => {
        let chart = [];
        itemCategory.forEach((itemData) => {
            itemData.imperfectionTypeChart.map((plantData) => {
                let imperfectionValue = convertStringToNumber(plantData.imperfections);
                const chartObject = searchArray(plantData.imperfectionType, chart, "imperfectionType");

                if (chartObject) {
                    // Duplicate imperfection Type object should sum value
                    chartObject.imperfections = chartObject.imperfections + imperfectionValue;
                } else {
                    // unique imperfection Type should append in "chart" key
                    let chartObj = {
                        "imperfections": imperfectionValue,
                        "imperfectionType": plantData.imperfectionType
                    };

                    chart.push(chartObj)
                }
            });
        });
        return chart;
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Button className="m-1 buttonCss"><Link to="/qualityAuditDashboard">Back to Quality Audit Dashboard</Link></Button>
            <Row>
                {
                    plantQualityAudit && plantQualityAudit.length &&
                    plantQualityAudit.map((obj, i) => {
                        return (
                            <Col key={i} className="colClass" xs={4}>
                                <div className="text-center fs-4">{(obj.itemCategory).toUpperCase()}</div>
                                <table className="fixedHeaderTable">
                                    <thead>
                                    <tr>
                                        <th>Imperfection</th>
                                        <th>Count</th>
                                        <th>Percentage %</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        obj.imperfectionTypeChart &&
                                        obj.imperfectionTypeChart.map((item, index) => {
                                            return (
                                                <tr key={index} className="text-center">
                                                    <td>{item.imperfectionType}</td>
                                                    <td>{item.imperfections}</td>
                                                    <td>{isNaN(item.imperfections / obj.inspected) ? 0 : Math.ceil((item.imperfections / obj.inspected) * 100)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                                <Row>
                                    <Col>Inspected : {obj.inspected}</Col>
                                </Row>
                                <Row>
                                    <Col>Imperfection % : {obj.imperfectionsAvg}</Col>
                                </Row>
                                <Row>
                                    <Col className="pieChartClass">
                                        {obj.imperfections > 0 && <BarChartD data={obj.chart} label="inspected"
                                                   lineColor="#008000" name="title"/>}
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
};

export default QualityDashboardToday;