import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch} from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {handleInputChange} from "../../commom/FormHandler";
import PlantQualityAuditForm from "./PlantQualityAuditForm";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Message from "../../commom/Message";
import "./PlantQualityAudit.css";
import {getSelectedPlantConfigArray, getSites, getUserAccessToUrl, sortArrayData} from "../../commom/CommonFunction";
import Loader from "../../commom/LoaderComponent";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";

const PlantQualityAudit = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [itemCategoryList, setItemCategoryList] = useState([]);
    const [imperfectionList, setImperfectionList] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: null,
        houseCode: "",
        plant: "",
        date: new Date(),
        user: "",
        plantHc: "",
        site: ""
    });
    const [config, setConfig] = useState("");
    const [flag, setFlag] = useState(false);
    const [siteList, setSiteList] = useState();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [plantData, setPlantData] = useState();
    const [sitesData, setSitesData] = useState();
    const [userData, setUserData] = useState();
    const [frequencyData, setFrequencyData] = useState();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        getUserDetailWithMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            setPlantData(response.plants);
            setSitesData(response.sites);
            setUserData(response._doc);
            setFrequencyData(response.masterData);
            setImperfectionList(getSelectedPlantConfigArray("Imperfection Type", "type",response.masterData, "value"));
            dispatch(setUserRole(response.securityRoles));

            if(response.plants && response.plants.length) {
                let selectedPlant = response.plants[0];
                let siteValue = getSites(response.plants[0]._id, response.sites);
                if (siteValue && siteValue.length) {
                    setSiteList(siteValue);

                    setFormValue((prevState) => ({
                        ...prevState,
                        plant: selectedPlant._id,
                        houseCode: selectedPlant.houseCode,
                        plantHc: selectedPlant.houseCode,
                        site: siteValue[0].site
                    }));
                }
                let dataArray;
                getQAConfig(selectedPlant._id).then((fetchedValue) => {
                    if (fetchedValue && fetchedValue.inspections) {
                        let sortInspectionsData = sortArrayData(fetchedValue.inspections, "item");
                        setItemCategoryList(sortInspectionsData);
                        dataArray = sortInspectionsData;
                        setFlag(false);
                        setConfData(dataArray, response.masterData);
                    }
                });
            }
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithMasterData',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const setConfData = (fetchedInspection, frequencyDataArray) => {
        let imperfectionTypes = getSelectedPlantConfigArray("Imperfection Type", "type",frequencyDataArray, "value");
        let configData = [];

        fetchedInspection.map(inspectObj => {
            let inspectObject = Object.assign({}, inspectObj);
            let newObj = {
                id: null,
                itemCategory: inspectObject.item,
                inspected: 0,
                imperfectionArray: [],
                uniqueKey: Math.random()
            };
            imperfectionTypes.map(imperfectObj => {
                let data = Object.assign({}, imperfectObj);
                let imperfectionObject = {
                    imperfectTitle: data.value,
                    value: "",
                    type: "number",
                    name: inspectObject.item + ","+ data.value
                };
                newObj.imperfectionArray.push(imperfectionObject);
            });

            configData.push(newObj);
        });

        setConfig(configData);
    };

    const getQAConfig = async (value) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantQAConfigByTitle',
            requestConfig: {
                data: value
            }
        });
        return await response.data;
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);

        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.name === "plant") {
            let data = (event.currentTarget.value);
            let selectedPlant = plantData.find((obj) => {
                if (obj._id === data) {
                    return obj;
                }
            });
            let dataArray;
            getQAConfig(data).then((fetchedValue) => {
                if (fetchedValue) {
                    let sortInspectionsData = sortArrayData(fetchedValue.inspections, "item");
                    setItemCategoryList(sortInspectionsData);
                    dataArray = sortInspectionsData;
                    setFlag(false);
                    setConfData(dataArray, frequencyData);
                } else {
                    setItemCategoryList([]);
                    dataArray = [];
                    setFlag(false);
                    setConfData(dataArray, frequencyData);
                }
            });

            if (selectedPlant) {
                let siteValue = "";
                siteValue = getSites(event.currentTarget.value, sitesData);

                if (siteValue && siteValue.length) {
                    setSiteList(siteValue);
                    setFormValue((prevState) => ({
                        ...prevState,
                        plant: data,
                        houseCode: selectedPlant.houseCode,
                        plantHc: selectedPlant.houseCode,
                        site: siteValue[0].site
                    }));
                } else {
                    setSiteList(siteValue);
                    setFormValue((prevState) => ({
                        ...prevState,
                        plant: data,
                        houseCode: selectedPlant.houseCode,
                        plantHc: selectedPlant.houseCode,
                        site: siteValue
                    }));
                }
            }
        }
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);

        let { name, value } = event.currentTarget;
        let nameData = name.includes(',');
        let configData = [];
        let numberValue;
        //let numberValue = value.replace(/[^0-9]/g,"");
        let numberRegExp = /^(0|[1-9][0-9]?|100)$/;
        let valid = numberRegExp.test(value);
        if(valid) {
            numberValue = value;
        } else {
            let newValue = value.replace(/[^0-9]/g,"");
            let valLength = newValue.length > 2;
            numberValue = valLength ? value.substring(0,2) : newValue;
        }
        config.forEach((obj) => {
            let data = Object.assign({}, obj, {imperfectionArray: []});

            if (!nameData && data.itemCategory === name) {
                data.inspected = numberValue;
            }

            obj.imperfectionArray.map(imperfectObj => {
                let imperfectObject = Object.assign({}, imperfectObj);

                if (nameData && imperfectObject.name == name) {
                    imperfectObject.value = numberValue;
                }
                data.imperfectionArray.push(imperfectObject);
            });

            configData.push(data);
        });

        setConfig(configData);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        let newData = {...formValue};
        newData["userId"] = userData.uniqueId;
        newData["username"] = userData.firstName;
        newData["config"] = config;
        console.log(newData["date"]);
        newData["date"] = (newData["date"]).toUTCString();
        setDisableSubmit(true);

        if(flag && newData.config) {
            newData["_id"] = newData.config[0].id;
        }
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdatePlantQualityAudit',
            requestConfig: {
                data: newData
            }
        });

        if (response) {
            setFlag(false);
            setConfData(itemCategoryList, frequencyData);
        }
        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col>
                    <Message showMessage={showMessage} response={response} error={error} loading={loading} message=""
                             formValidation={true}/>
                    <PlantQualityAuditForm plantData={plantData} submitHandler={submitHandler}
                                                   handleChange={handleChange} formValue={formValue}
                                                   response={response} error={error} loading={loading}
                                                   showMessage={showMessage} siteList={siteList}
                                                   itemCategoryList={itemCategoryList}
                                                   imperfectionList={imperfectionList} config={config}
                                                   inputChangeHandler={inputChangeHandler} disableSubmit={disableSubmit}/></Col>
            </Row>
        </Container>
    )
};

export default PlantQualityAudit;