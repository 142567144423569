import React, {useEffect, useState} from 'react';
import './QualityAuditDashboard.css';
import {useDispatch, useSelector} from "react-redux";
import BarChartD from "../../commom/BarChartD";

const QualityDashboardDetailTotal = () => {
    const dispatch = useDispatch();
    const [plantQualityAudit, setPlantQualityAudit] = useState("");
    const itemCategory = useSelector((state) => state.itemCategoryReducer.itemCategory);
    const plantQualityDashboardData = useSelector((state) => state.plantQualityDashboardDataReducer);


    useEffect(() => {
        getPlantQuality(itemCategory);
    }, [itemCategory]);

    const getPlantQuality = (itemData) => {
        let newArray = [];

        if(plantQualityDashboardData && plantQualityDashboardData.length) {
            plantQualityDashboardData.forEach(obj => {
                let objData = Object.assign({}, obj);
                if (itemData === objData.itemCategory) {
                    newArray.push(objData);
                } else if (itemData === "Total") {
                    newArray.push(objData);
                }
            });

            if (newArray && newArray.length) {
                setPlantQualityAudit(plantSpecificRange(newArray));
            }
        }
    };

    const getDate = (dateObj) => {
        let date = new Date(dateObj);

        let month = date.getMonth() + 1; //months from 1-12
        let day = date.getDate();
        let year = date.getFullYear();

        return (month + "/" + day + "/" + year);
    };

    const search = (nameKey, myArray, key) => {
        for (let i = 0; i < myArray.length; i++) {
            let date = myArray[i][key];
            if (date === nameKey) {
                return myArray[i];
            }
        }
    };

    const plantSpecificRange = (fetchedValue) => {
        let totalArray = {
            "imperfectionsAvg" : 0,
            "inspectedAvg" : 0,
            "inspected" : 0,
            "imperfections" : 0,
            "itemCategory" : "Total",
            "imperfectionType" : "Total"
        };
        totalArray["chart"] = [];

        let itemArr = []; // add unique housecode present in the fetched data

        // fetched data array
        fetchedValue.forEach((plantData) => {
            let plantObject = {...plantData};
            let date = getDate(plantObject.date);
            // Add unique object in plantArray with new key as "chart"
            if (itemArr.indexOf(plantObject.imperfectionType) < 0) {
                itemArr.push(plantObject.imperfectionType);
                let chart = {
                    "date": (plantObject.imperfectionType),
                    "inspected": parseInt(plantObject.inspected),
                    "imperfections": parseInt(plantObject.imperfections),
                };
                totalArray["chart"].push(chart);
            } else {
                const chartObject = search(date, totalArray["chart"], "imperfectionType");

                // Duplicate (plant and  date) object should sum value
                if (chartObject) {
                    chartObject.inspected = chartObject.inspected + parseInt(plantObject.inspected);
                    chartObject.imperfections = chartObject.imperfections + parseInt(plantObject.imperfections);
                }
            }

            totalArray.inspected = parseInt(plantObject.inspected) + totalArray.inspected;
            totalArray.imperfections = parseInt(plantObject.imperfections) + totalArray.imperfections;
        });

        totalArray["imperfectionsAvg"] = Math.ceil((totalArray.imperfections / totalArray.inspected) * 100) + "%";
        totalArray["inspectedAvg"] = Math.ceil(totalArray.inspected / totalArray.chart.length);
        return totalArray;
    };

    return (
        <div className="h-75 w-100">
            {plantQualityAudit &&
                <div className="h-75 w-100">
                    <BarChartD key={plantQualityAudit.inspectedAvg} data={plantQualityAudit.chart}/>
                </div>
            }
        </div>
    )
};

export default QualityDashboardDetailTotal;