export const validateForm = (event, inputValidation, fieldValidation) => {
    const {name, value} = event.target;
    let validationCase = fieldValidation[name];
    let valid = validationCheck(validationCase, value);
    let checkValidate = true;

    const currentFormState = {
        ...inputValidation,
        [name]: valid,
    };

    const formState = {...currentFormState};

    for (const key in formState) {
        if (formState.hasOwnProperty(key)) {
            if (!formState[key]) {
                checkValidate = false;
            }
        }
    }

    return {checkValidate, currentFormState}
};

export const validateFormOnSave = (formValue, inputValidation, fieldValidation) => {
    let formState = {...formValue};
    let valid;
    let currentFormState = {...inputValidation};
    let checkValidate = true;

    for (const key in formState) {
        if (formState.hasOwnProperty(key) && currentFormState.hasOwnProperty(key)) {
            let validationType = fieldValidation[key];
            if (validationType !== "boolean") {
                valid = validationCheck(validationType, formValue[key]);
                currentFormState[key] = valid;

                if (!valid) {
                    checkValidate = false;
                }
            }
        }
    }

    return {checkValidate, currentFormState}
};

export const validationCheck = (validationCase, value) => {
    let valid = true;

    if(value === 0 && (validationCase === "Number" || validationCase === "number")) {
        return valid;
    }

    if (!value) {
        valid = false;
    } else {

        switch (validationCase) {
            case "string":
            case "Text":
                const stringRegExp = /[(){}[\]\"]+/si;
                //let stringRegExp = /^([\<\>]?[\w]{1,15}((([\s]?)|([.'@]?[\s]?)|([\s]?[-+_\<\>%]?[\s]?))[\w]{1,15})*)$/;
                //let stringRegExp = /^([\<\>]?[\w]{1,15}(([\s]?[–!–@#$%^&*_+\-={};:\\|,.<>\/?]?[\s]?)[\w]{0,15})*)$/;
                let va = stringRegExp.test(value);
                if(va){
                    valid = false;
                } else {
                    valid = value.length < 10000;
                }
                break;
            case "Date":
                let selectedDate = new Date(value);
                let now = new Date();
                valid = now >= selectedDate;
                break;
            case "phone":
                let regExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
                valid = regExp.test(value);
                break;
            case "email":
                let emailRegex = /^\w+([-+_.]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                valid = (emailRegex).test(value);

                //valid = value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                break;
            case "number":
            case "Number":
                let numberRegExp = /^([0-9]+)([.][0-9]+)?$/;
                valid = numberRegExp.test(value);
                break;
            case "digit":
                let digitRegExp = /^([1-9][0-9]*)$/;
                valid = digitRegExp.test(value);
                break;
            case "select":
                if ((value.toLowerCase()) === "select") {
                    valid = false;
                } else {
                    valid = value.trim().length > 0;
                }
                break;
            default:
                break;
        }
    }

    return valid;
};

export const validateFormInputOnSave = (formValue, inputValidation, fieldValidation) => {
    let valid;
    let currentFormState = {...inputValidation};
    let checkValidate = true;

    formValue.forEach((obj) => {
        let data = Object.assign({}, obj);

        if (currentFormState.hasOwnProperty(data.fieldName)) {
            let validationType = fieldValidation[data.fieldName];
            if (validationType !== "boolean") {
                valid = validationCheck(validationType, data.value);
                currentFormState[data.fieldName] = valid;

                if (!valid) {
                    checkValidate = false;
                }
            }
        }
    });

    return {checkValidate, currentFormState}
};