import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './PlantConfig.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlantConfigList from "./PlantConfigList";
import PlantConfigForm from "./PlantConfigForm";
import {updateList, list} from "../../reducer/FrequencyReducer";
import useAxiosFunction from "../../hook/AxiosHook";
import PlantDropdown from "./PlantDropdown";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {updatePlantDropdownScreen} from "../../reducer/PlantDropdownReducer";
import {
    getActivePlantConfigArray,
    getArrayValue,
    getUserAccessToUrl,
    trimObjectValues
} from "../../commom/CommonFunction";
import Loader from "../../commom/LoaderComponent";
import {showMasterDateOption} from "../../reducer/HideMasterDropdownReducer";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";
import ConfirmationModal from "../../commom/ConfirmationModal";

const PlantConfig = () => {
    const screen = useSelector((state) => state.plantDropdownReducer.screen);
    const userState = useSelector((state) => state.userState.user._id);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [isPlantDropdown, setPlantDropdown] = useState();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [formValue, setFormValue] = useState({
        _id: "",
        email: "",
        plant: "",
        value: "",
        isActive: false,
    });
    const [inputValidation, setInputValidation] = useState({
        value: true
    });
    const fieldValidation = {
        value: "string",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [plantList, setPlantList] = useState("");
    const [plantData, setPlantDataArray] = useState("");
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMasterRole, setIsMasterRole] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [isReadonly, setIsReadonly] = useState(false);

    useEffect(() => {
        userDetailWithPlantAndAllMasterData().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            setPlantList(updateMultiSelectOption(response.plants, "plant"));
            setPlantDataArray(response.plants);
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(list(response.masterData));

            let activeMasterDate = getActivePlantConfigArray("Master Role", "type", response.masterData, "value");

            if(activeMasterDate[0].value === response._doc.securityRole) {
                setIsMasterRole(true);
                console.log(isMasterRole + "isMasterRole");
            }
        });
        dispatch(showDateInHeader(false));
        dispatch(updatePlantDropdownScreen("Visit Frequency"));
        dispatch(showMasterDateOption(true));
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    useEffect(() => {
        if (["DVP", "RDO", "GM"].indexOf(screen) > -1) {
            setPlantDropdown(true);
        } else {
            setPlantDropdown(false);
        }

        setShowMessage(false);
        cancelHandler();
    }, [screen]);

    const userDetailWithPlantAndAllMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/userDetailWithPlantAndAllMasterData',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const handleChange = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);


        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const updateMultiSelectOption = (fetchedValue, label) => {
        if (fetchedValue && fetchedValue.length) {
            return fetchedValue.map((obj) => {
                let data;

                if (label === "plant") {
                    data = obj._id;
                } else {
                    data = obj.value;
                }

                return {
                    label: data,
                    value: false
                };
            });
        }
    };

    const handleCheckboxChange = (event) => {
        setDisableSubmit(false);
        let value = event.currentTarget.attributes['data-label'].value;
        let checked = event.currentTarget.checked;

        const newData = plantList.map(obj => {
            let data = Object.assign({}, obj);

            if (data.label === value) {
                data.value = checked;
            }

            return data;
        });

        setPlantList(newData);
    };

    const selectedRow = (value) => {
        setShowMessage(false);
        setButtonTitle("UPDATE");
        setDisableSubmit(true);
        setIsReadonly(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        if (["DVP", "RDO", "GM"].indexOf(screen) > -1) {
            setPlantData(value.plant, value);
        } else {
            formInputValueSetup(true, value);
        }
    };

    const setPlantData = (plantListData, value) => {
        let newArray = [];

        plantData.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let data = dataObject._id;

            if (plantListData.indexOf(data) !== -1) {
                newArray.push({
                    "label": data,
                    "value": true
                });
            } else {
                newArray.push({
                    "label": data,
                    "value": false
                });
            }
        });
        setPlantList(newArray);
        const currentFormState = formValueSetup(true, value, formValue);
        setFormValue(currentFormState);
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setIsReadonly(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setPlantList(updateMultiSelectOption(plantData, "plant"));
    };

    const submitHandler = async () => {
        handleClose();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let newData = trimObjectValues({...formValue});

        newData["modAt"] = new Date();
        newData["modBy"] = userState;
        newData["type"] = screen;
        let response;
        if (["DVP", "RDO", "GM"].indexOf(screen) > -1) {
            let plantData = getArrayValue(plantList);

            if (plantData.length === 0 && newData.isActive) {
                setFormValidation(false);
                return false;
            } else {
                setFormValidation(true);
            }

            newData["plant"] = plantData;
            response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdatePlantMasterData',
                requestConfig: {
                    data: newData
                }
            });
        } else {
            response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/addUpdateMasterData',
                requestConfig: {
                    data: newData
                }
            });
        }

        if (response.data) {
            dispatch(updateList(response.data));
            setButtonTitle("Update");
            setIsReadonly(true);
            if (formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowConfirmationBox(false);
        setDisableSubmit(false);
    };

    const handleOpen = () => {
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }
        if(formValue._id === "") {
            submitHandler();
        } else {
            if(formValue.isActive) {
                submitHandler();
            } else {
                setShowConfirmationBox(true);
            }
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {showConfirmationBox && <ConfirmationModal handleClose={handleClose} showModal={showConfirmationBox}
                                                       submitForm={submitHandler}/>}
            <Row className="align-items-center mb-sm-1">
                <PlantDropdown isMasterRole={isMasterRole}/>
            </Row>
            <Row>
                <Col sm={6}><PlantConfigList selectedRow={selectedRow} screen={screen}
                                             isPlantDropdown={isPlantDropdown}/></Col>

                <Col sm={6}>

                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                    <PlantConfigForm plantData={plantData} handleOpen={handleOpen}
                                     cancelHandler={cancelHandler} buttonTitle={buttonTitle}
                                     handleChange={handleChange} isPlantDropdown={isPlantDropdown}
                                     formValue={formValue} formValidation={formValidation}
                                     inputValidation={inputValidation}
                                     response={response} error={error} loading={loading}
                                     showMessage={showMessage}
                                     disableSubmit={disableSubmit} screen={screen} plantList={plantList}
                                     handleCheckboxChange={handleCheckboxChange} isReadonly={isReadonly}/></Col>
            </Row>
        </Container>
    )
};

export default PlantConfig;