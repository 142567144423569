import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import './Survey.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosFunction from "../../hook/AxiosHook";
import {updateSurvey} from "../../reducer/SurveyReducer";
import SurveyQuestionForm from "./SurveyQuestionForm";
import SurveyQuestionList from "./SurveyQuestionList";
import {setSurveyQuestionData} from "../../reducer/SurveyQuestionReducer";
import {selectedSurvey} from "../../reducer/SurveySelectionReducer";
import {selectedSurveyQuestion} from "../../reducer/SurveySelectedQuestionReducer";
import {setSurveyAnswerData} from "../../reducer/SurveyAnswerReducer";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {Button} from "react-bootstrap";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {searchArray, sortArrayAndAddId, sortArrayOfObjects, trimObjectValues} from "../../commom/CommonFunction";
import ConfirmationModal from "../../commom/ConfirmationModal";
import DraggableList from "./DraggableList";
const { v4: uuidv4 } = require('uuid');

const SurveyQuestion = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const selectedSurveyData = useSelector((state) => state.surveySelectionReducer);
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [showDropdownForm, setShowDropdownForm] = useState(false);
    const [id, setId] = useState();
    const [readOnly, setReadonly] = useState(false);
    const [formValue, setFormValue] = useState({
        title: "",
        description: "",
        type: "",
        fieldName: "",
        shown: "",
        isRequire: false,
        order: 0,
        active: false,
        answers: [],
        _id: ""
    });
    const [inputValidation, setInputValidation] = useState({
        title: true,
        type: true,
        fieldName: true,
        shown: true,
        order: true
    });
    const fieldValidation = {
        title: "string",
        type: "select",
        fieldName: "string",
        shown: "select",
        order: "number"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [formFields, setFormFields] = useState([
        {id: uuidv4(), value: '', displayValue: ''},
    ]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [sortOrder, setSortOrder] = useState(true);

    const handleFormChange = (event, index) => {
        setDisableSubmit(false);
        let data = [...formFields];

        const updatedArray = data.map((elem, id) => {
            if(index !== id) {
                return Object.assign({}, elem);
            } else {
                return Object.assign({}, elem, {"displayValue" : event.target.value, "value": event.target.value});
            }
        });
        setFormFields(updatedArray);
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const submit = () => {
        dispatch(setSurveyAnswerData(formFields));
    };

    const addFields = () => {
        let object = {
            id: uuidv4(),
            value: '',
            displayValue: ''
        };

        setFormFields([object, ...formFields])
    };

    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1);
        setFormFields(data);
        dispatch(setSurveyAnswerData(data))
    };

    const handleChange = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.name === "type" && event.currentTarget.value === "Dropdown") {
            setShowDropdownForm(true);
        } else if (event.currentTarget.name === "type" && event.currentTarget.value !== "Dropdown") {
            setShowDropdownForm(false);
        }

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        setReadonly(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        formInputValueSetup(true, value);

        setShowMessage(false);
        setButtonTitle("UPDATE");
        setId(value._id);
        dispatch(selectedSurveyQuestion(value));
        dispatch(setSurveyAnswerData(value.answers));
        if (value.type === "Dropdown") {
            setShowDropdownForm(true);
            let answerArray = [];

            if (value.answers && value.answers.length) {
                value.answers.forEach((answers) => {
                    let answerData = Object.assign({}, answers);
                    let objData = {
                        "value": answerData.value,
                        "displayValue": answerData.displayValue
                    };

                    answerArray.push(objData);
                });
            }
console.log(answerArray);
            let sortAnswerArray = sortArrayAndAddId(answerArray, "value");
            setFormFields(sortAnswerArray);
        } else {
            setShowDropdownForm(false);
            setFormFields([
                {value: '', displayValue: ''},
            ]);
        }
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setReadonly(false);
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("Add");
        setId("");
        setShowDropdownForm(false);
        setFormFields([
            {value: '', displayValue: ''},
        ]);
    };

    const stringGen = () => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let x = 7;
        for (let i = 0; i < x; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    const submitHandler = async () => {
        handleClose();
        let flag = false;
        let newArray = {};
        newArray._id = selectedSurveyData._id;
        newArray.active = selectedSurveyData.active;
        newArray.title = selectedSurveyData.title;
        newArray.collectionName = selectedSurveyData.collectionName;
        newArray.questions = [];
        newArray.freq = selectedSurveyData.freq;
        newArray.path = selectedSurveyData.path;
        newArray.computedFields = [];
        newArray.dateChoicesAvailable = selectedSurveyData.dateChoicesAvailable;

        let newEntry = trimObjectValues({...formValue});

        if (newEntry.type === "Dropdown") {
            let answerArray = [];

            if (formFields && formFields.length) {
                formFields.forEach((answers) => {
                    let answerData = Object.assign({}, answers);
                    let objData = {
                        "value": answerData.value,
                        "displayValue": answerData.displayValue
                    };

                    answerArray.push(objData);
                });
            }
            newEntry.answers = answerArray
        }
        newEntry["_id"] = stringGen();

        if (selectedSurveyData.questions && selectedSurveyData.questions.length) {
            selectedSurveyData.questions.forEach((obj, index) => {
                let data = Object.assign({}, obj, {answers: []});
                if (data._id === id && id) {
                    flag = true;
                    data.title = formValue.title;
                    data.description = formValue.description;
                    data.shown = formValue.shown;
                    data.fieldName = formValue.fieldName;
                    data.isRequire = formValue.isRequire;
                    data.order = formValue.order;
                    data.type = formValue.type;
                    data.active = formValue.active;
                    setId(data._id);
                }
                newArray.questions.push(data);

                if (data._id === id && id) {
                    if (newEntry.answers && newEntry.answers.length) {
                        newEntry.answers.forEach((answers) => {
                            let answerData = Object.assign({}, answers);
                            newArray.questions[index]['answers'].push(answerData)
                        });
                    }
                } else {
                    if (obj.answers && obj.answers.length) {
                        obj.answers.forEach((answers) => {
                            let answerData = Object.assign({}, answers);
                            newArray.questions[index]['answers'].push(answerData)
                        });
                    }
                }
            });
        }

        if (selectedSurveyData.computedFields && selectedSurveyData.computedFields.length) {
            selectedSurveyData.computedFields.forEach((obj) => {
                let data = Object.assign({}, obj);
                newArray.computedFields.push(data);
            });
        }

        if (!flag) {
            newArray['questions'].push(newEntry);
            setId(newEntry._id);
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSurvey',
            requestConfig: {
                data: newArray
            }
        });

        if (response.data) {
            dispatch(updateSurvey(response.data));
            setButtonTitle("Update");
            setReadonly(true);
            if (response.data.questions) {
                dispatch(setSurveyQuestionData(response.data.questions));
                dispatch(selectedSurvey(response.data));

                if(formValue._id === "" && response.data.questions && response.data.questions.length) {
                    let data = searchArray(formValue.fieldName, response.data.questions, "fieldName");
                    setFormValue({...formValue, "_id": data._id});
                }
            }

        }
        setShowMessage(true);
    };

    const handleClose = () => {
        setShowConfirmationBox(false);
        setDisableSubmit(false);
    };

    const existingValueCheck = async () => {
        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/checkDuplicateSurveyParameter',
            requestConfig: {
                data: {
                    _id: selectedSurveyData._id,
                    title: formValue.fieldName,
                    key: "questions.fieldName"
                }
            }
        });
        return response;
    };

    const handleOpen = () => {
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        if (formValue._id === "") {
            existingValueCheck().then(response => {
                if (response.code === 400) {
                    setShowMessage(true);
                    return false;
                } else {
                    submitHandler();
                }
            });
        } else {
            if (formValue.active) {
                submitHandler();
            } else {
                setShowConfirmationBox(true);
            }
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        setDisableSubmit(false);
        const newItems = Array.from(formFields);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setFormFields(newItems);
    };

    const handleSorting = () => {
        let sortVale = sortOrder;
        setSortOrder(!sortVale);
        setDisableSubmit(false);
        let isNumberArray = false;
        if (formFields && formFields.length) {
            isNumberArray = isNaN(formFields[0].value);
        }
        const newItems =  sortArrayOfObjects(formFields , "value", isNumberArray, sortVale);
        setFormFields(newItems);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {showConfirmationBox && <ConfirmationModal handleClose={handleClose} showModal={showConfirmationBox}
                                                       submitForm={submitHandler}/>}
            <Row>
                <Col sm={6}>
                    <SurveyQuestionList selectedRow={selectedRow}/></Col>
                <Col sm={6}>

                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                    <div className="formContainer w-100 p-3">
                        <SurveyQuestionForm handleChange={handleChange} formValue={formValue}
                                            formValidation={formValidation} readOnly={readOnly}
                                            inputValidation={inputValidation} response={response} error={error}
                                            loading={loading} showMessage={showMessage}/>
                        {showDropdownForm && <DraggableList items={formFields} handleFormChange={handleFormChange}
                                                            removeFields={removeFields} submit={submit}
                                                            addFields={addFields} onDragEnd={onDragEnd}
                                                            handleSorting={handleSorting}/>}
                        <div className="row mx-auto form">
                            <Col>
                                <Button type="button" className="formButton buttonCss" onClick={handleOpen}
                                        disabled={disableSubmit}>{buttonTitle}</Button>
                            </Col>
                            <Col>
                                <Button onClick={cancelHandler} className="formButton buttonCss">Reset</Button>
                            </Col>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};

export default SurveyQuestion;