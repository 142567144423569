import { createSlice } from '@reduxjs/toolkit'

export const ShowDateReducer = createSlice({
    name: "ShowDateReducer",
    initialState: {
        showDate: false,
    },
    reducers: {
        showDateInHeader: (state, action) => {
            state.showDate = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { showDateInHeader } = ShowDateReducer.actions;

export default ShowDateReducer.reducer;