import {
    checkDateBetweenGivenDateRange, convertToUTC,
    getCurrentDateOnly, getDateInUTC,
    getDateInUTCByMonth,
    getDateInUTCByMonthStartDate, getWeekRangeForGivenDate
} from "./DateFormat";
import React from "react";
import {getOnlySurveyReportHeader, getSurveyReportHeaderFromQuestions} from "./CommonReportFunction";

const {v4: uuidv4} = require('uuid');

export const getSites = (plantName, sitesData) => {
    let sites = [];

    sitesData.forEach(obj => {
        let plantObj = Object.assign({}, obj);

        if (plantObj.plant === plantName) {
            sites.push(plantObj);
        }
    });

    return sites;
};

export const searchArray = (nameKey, arrayData, key) => {
    if (arrayData && arrayData.length) {

        for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i][key] === nameKey) {
                return arrayData[i];
            }
        }
    }

    return false;
};

export const searchArrayWithActive = (nameKey, arrayData, key, activeKey) => {
    if (arrayData && arrayData.length) {

        for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i][activeKey] && arrayData[i][key] === nameKey) {
                return arrayData[i];
            }
        }
    }

    return false;
};


export const searchArrayWithActiveAndShow = (nameKey, arrayData, key1, activeKey, showKey) => {
    if (arrayData && arrayData.length) {

        for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i][activeKey] && arrayData[i][showKey] && arrayData[i][key1] === nameKey) {
                return arrayData[i];
            }
        }
    }

    return false;
};

export const getAverageValue = (arrayData, key1, key2) => {
    if (arrayData && arrayData[key1] > 0) {
        return {
            "site": arrayData.site,
            "avg": Math.round(arrayData[key1] + ""),
            "chartData": arrayData[key2]
        };
    }

    return "";
};

export const searchArrayCaseInsensitive = (nameKey, arrayData, key) => {
    for (let i = 0; i < arrayData.length; i++) {
        let matchData = nameKey.toLowerCase();
        let matchDataFromArray = (arrayData[i][key]).toLowerCase();
        if (matchDataFromArray === matchData) {
            return arrayData[i];
        }
    }
};

export const searchArrayByMultiKeyPair = (firstKeyValue, dateRange, arrayData, firstKey, secondKey) => {
    for (let i = 0; i < arrayData.length; i++) {
        let dateFlag = checkDateBetweenGivenDateRange(dateRange, arrayData[i][secondKey]);
        if (arrayData[i][firstKey] === firstKeyValue && dateFlag) {
            return arrayData[i];
        }
    }
};

export const searchArrayBYIndex = (nameKey, arrayData, key) => {
    for (let i = 0; i < arrayData.length; i++) {
        if (arrayData[i][key] === nameKey) {
            return arrayData[i];
        }
    }
};

export const getDateFromArray = (firstKeyData, arrayData, firstKey) => {
    let siteObj = {};

    for (let i = 0; i < arrayData.length; i++) {
        if (arrayData[i][firstKey] === firstKeyData) {
            let surveyDate = arrayData[i]["SurveyDate"];
            let key = arrayData[i]["Survey"];
            siteObj[key] = getDateInUTCByMonth(surveyDate);
        }
    }

    return siteObj;
};

export const specificArrayData = (arrayData, key) => {
    let newArray = [];

    for (let i = 0; i < arrayData.length; i++) {
        newArray.push(arrayData[i][key]);
    }

    return newArray;
};

export const specificArrayDataWithActive = (arrayData, key, activeKey) => {
    let newArray = [];

    for (let i = 0; i < arrayData.length; i++) {
        if(arrayData[i][activeKey]) {
            newArray.push(arrayData[i][key]);
        }
    }

    return newArray;
};

export const specificArrayDataWithActiveAndKey = (arrayData, key, activeKey, showKey) => {
    let newArray = [];

    for (let i = 0; i < arrayData.length; i++) {
        if(arrayData[i][activeKey] && arrayData[i][showKey]) {
            newArray.push(arrayData[i]);
        }
    }

    return newArray;
};

export const searchObject = (value, objectData, keyData) => {
    for (const key in objectData) {
        if (objectData.hasOwnProperty(key)) {
            if (objectData[key][keyData] === value) {
                return objectData[key];
            }
        }
    }
};

export const searchArrayTotalByField = (objectData, firstKey, firstValue) => {
    let obj = {
        "inspected": 0,
        "imperfections": 0,
        "percent": 0
    };

    for (let key = 0; key < objectData.length; key++) {
        let data = objectData[key];
        if (data[firstKey] === firstValue) {
            obj.inspected += parseInt(data.inspected);
            obj.imperfections += parseInt(data.imperfections);
            obj.percent += Math.ceil((data["imperfections"] * 100) / data["inspected"]);
        }
    }

    return obj;
};

export const searchArrayTotal = (objectData, firstKey, secondKey, firstValue, secondValue) => {
    let obj = {
        "inspected": 0,
        "imperfections": 0,
        "percent": 0
    };

    for (let key = 0; key < objectData.length; key++) {
        let data = objectData[key];
        if (data[firstKey] === firstValue && data[secondKey] === secondValue) {
            obj.inspected += parseInt(data.inspected);
            obj.imperfections += parseInt(data.imperfections);
            obj.percent += Math.ceil((data["imperfections"] * 100) / data["inspected"]);
        }
    }

    return obj;
};

export const searchArrayTotalForDate = (objectData, firstKey, secondKey, firstValue, secondValue) => {
    let obj = {
        "inspected": 0,
        "imperfections": 0,
        "percent": 0
    };

    for (let key = 0; key < objectData.length; key++) {
        let data = objectData[key];
        let dateFlag = checkDateBetweenGivenDateRange(data[secondKey], secondValue);
        if (data[firstKey] === firstValue && dateFlag) {
            obj.inspected += parseInt(data.inspected);
            obj.imperfections += parseInt(data.imperfections);
            obj.percent += Math.ceil((obj["imperfections"] * 100) / obj["inspected"]);
        }
    }

    return obj;
};

export const getItemByPlantDate = (objectData, dateRangeArray, firstValue, secondValue, dateRange) => {
    let obj = {
        "inspected": 0,
        "imperfections": 0,
        "percent": 0
    };

    for (let key = 0; key < objectData.length; key++) {
        let data = objectData[key];
        let getData = checkDateBetweenGivenDateRange(dateRange, data.date);

        if (data.plant === firstValue && data.itemCategory === secondValue && getData) {
            if (getData) {
                obj.inspected += parseInt(data.inspected);
                obj.imperfections += parseInt(data.imperfections);
                obj.percent += Math.ceil((obj["imperfections"] * 100) / obj["inspected"]);
            }
        }
    }

    return obj;
};

export const checkValue = (data) => {
    if (data) {
        if (typeof (data) === "string") {
            return parseInt(data);
        }
        return data;
    } else {
        return 0;
    }
};

export const convertStringToNumber = (data) => {
    if (data) {
        if (typeof (data) == "string") {
            return parseInt(data);
        }
        return data;
    } else {
        return 0;
    }
};

export const getSelectedArray = (value, key, data) => {
    let newArray = [];

    data.forEach(obj => {
        let objectData = Object.assign({}, obj);

        if (objectData[key] === value) {
            newArray.push(objectData);
        }
    });

    return newArray;
};

export const getActiveSelectedArray = (arrayData, key, data) => {
    let newArray = [];

    data.forEach(obj => {
        let objectData = Object.assign({}, obj);

        if (arrayData.indexOf(objectData[key]) !== -1) {
            newArray.push(objectData);
        }
    });

    return newArray;
};

export const getSelectedPlantConfigArray = (value, key, data, sortValue) => {
    let newArray = [];

    data.forEach(obj => {
        let objectData = Object.assign({}, obj);

        if (objectData[key] === value) {
            newArray.push(objectData);
        }
    });

    newArray.sort(function (a, b) {
        return (a["value"]).localeCompare(b["value"])
    });

    return newArray;
};

export const getSelectedPlantMasterData = (value, key, data, sortValue) => {
    const uniqueArray = [];
    const filteredData = [];

    data.forEach(obj => {
        if (obj[key] === value && obj[sortValue] !== "") {
            if (uniqueArray.indexOf(obj[sortValue]) === -1) {
                uniqueArray.push(obj[sortValue]);
                filteredData.push({ ...obj });
            }
        }
    });

    filteredData.sort((a, b) => a[sortValue].localeCompare(b[sortValue]));

    return filteredData;
};

export const getActivePlantConfigArray = (value, key, data) => {
    let newArray = [];

    data.forEach(obj => {
        let objectData = Object.assign({}, obj);

        if (objectData[key] === value && objectData["isActive"]) {
            newArray.push(objectData);
        }
    });

    newArray.sort(function (a, b) {
        return (a["value"]).localeCompare(b["value"])
    });

    return newArray;
};

export const getPlantMasterData = (data, type, selectedPlant) => {
    let newData = [];

    data.forEach((obj) => {
        if ((obj.type === type) && (obj.plant && obj.plant.length && ((obj.plant).indexOf(selectedPlant) !== -1))) {
            newData.push(obj);
        }
    });

    newData.sort(function (a, b) {
        return (a["value"]).localeCompare(b["value"])
    });

    return newData;
};

export const getPercentageValue = (numeratorValue, denominatorValue) => {
    return Math.ceil(((parseInt(numeratorValue) - parseInt(denominatorValue)) / parseInt(numeratorValue)) * 100);
};

export const getArrayByKey = (data, key) => {
    let newArray;

    newArray = data.map(obj => {
        let objectData = Object.assign({}, obj);
        return objectData[key];
    });

    return newArray;
};

//Plant QA Survey
export const plantSurveyQuestionArray = (fetchedValue, flag) => {
    let configData = [];
    let surveyQuestions;
    let inputValidationData = {};
    let fieldValidationData = {};

    if (flag) {
        surveyQuestions = [...fetchedValue.questions];

        // Sorting the array based on the 'order' key
        surveyQuestions.sort((a, b) => {
            const orderA = parseInt(a.order);
            const orderB = parseInt(b.order);

            // Compare the numeric values of 'order' key
            return orderA - orderB;
        });
    } else {
        surveyQuestions = [...fetchedValue.surveyDetail];
    }

    surveyQuestions.forEach((obj) => {
        if (obj["active"] || !flag) {
            let data = Object.assign({}, obj);
            let id = flag ? data._id : data.id;
            let required = flag ? data.isRequire : data.required;
            let value = flag ? "" : data.value;
            let valueForDate = data.type === "Date" ? new Date() : value;

            let newObj = {
                id: id,
                title: data.title,
                type: data.type,
                required: required,
                fieldName: data.fieldName,
                answers: data.answers,
                value: valueForDate
            };

            if (newObj.type === "Date" && !flag) {
                newObj.value = (data.value);
            } else if (newObj.type === "Date" && flag) {
                newObj.value = getCurrentDateOnly();
            }

            if (newObj.type === "Checkbox" && value === "") {
                newObj.value = false;
            }

            if (newObj.required) {
                inputValidationData[newObj.fieldName] = true;
                fieldValidationData[newObj.fieldName] = getInputType(newObj.type);
            }

            configData.push(newObj);
        }
    });

    return {configData, inputValidationData, fieldValidationData}
};

export const getInputType = (inputType) => {
    let value = "string";

    switch (inputType) {
        case "Date":
        case "Text":
            value = "string";
            break;
        case "Number":
            value = "number";
            break;
        case "Dropdown":
            value = "select";
            break;
        default:
            value = "string";
            break;
    }

    return value;
};

export const getArrayValue = (data) => {
    let filteredArray = [];
    if (data && data.length) {
        data.forEach((obj) => {
            if (obj.value === true) {
                filteredArray.push(obj.label);
            }
        });
    }

    return filteredArray.length ? filteredArray : "";
};

export const getArrayData = (data) => {
    let filteredArray = [];
    if (data && data.length) {
        data.forEach((obj) => {
            if (obj.value === true) {
                filteredArray.push(obj.label);
            }
        });
    }

    return filteredArray;
};

export const getUserDataFromArray = (data) => {
    let emailArray = [];
    let contactArray = [];

    if (data && data.length) {
        data.forEach((obj) => {
            if (obj.value === true) {
                emailArray.push(obj.label);
                contactArray.push(obj.userContactName);
            }
        });
    }

    return emailArray.length ? [emailArray, contactArray] : [[], []];
};

export const getAllArrayValue = (data) => {
    let filteredArray = [];
    if (data && data.length) {
        data.forEach((obj) => {
            filteredArray.push(obj.label);
        });
    }

    return filteredArray.length ? filteredArray : "";
};

export const tabFromString = (data) => {
    return data.toString().replace(/[\r\n\t]/g, "")

};

export const modifiedArrayData = (data, isScorecardScreen) => {
    if (data && data.length) {
        return data.map((obj) => {
            let newObj = {...obj};
            let date = "";
            if (isScorecardScreen) {
                date = (newObj.dateRange.split(" "))[0];
            } else {
                date = getDateInUTCByMonthStartDate(newObj.dateOfSurvey);
            }
            newObj["siteDate"] = newObj.site + " - " + date;
            return newObj;
        });
    }

    return data;
};

export const getReportName = (reportData) => {
    let data = getArrayValue(reportData);
    let reportArray = [];

    if (data && data.length) {
        data.forEach((value) => {
            if (value == 'Activity Raw Data') {
                reportArray.push("Activities");
            } else if (value == 'Add On Sales Data') {
                reportArray.push("Add On Sales");
            } else if (value == 'Client Sat Raw Data') {
                reportArray.push("Client Satisfaction");
                reportArray.push("Client Satisfaction - No distribution");
            } else if (value == 'Linen Quality & Availability Raw Data') {
                reportArray.push("Linen Quality & Availability");
            } else if (value == 'Linen Awareness Data') {
                reportArray.push("Linen Awareness");
            } else if (value == 'Linen Committee Data') {
                reportArray.push("Linen Committee");
            } else if (value == 'Linen Loss Raw Data') {
                reportArray.push("Linen Loss");
            } else if (value === 'Facility Master Data') {
                reportArray.push("Facility Master Data");
            } else if (value == 'PAR Raw Data') {
                reportArray.push("PAR");
            } else if (value == 'Onsite Linen Inspection Raw Data') {
                reportArray.push("Onsite Linen Inspection");
            } else if (value == 'QBR Data') {
                reportArray.push("QBR");
            } else if (value === 'Scorecard') {
                reportArray.push("Scorecard");
            } else if (value === 'Utilization') {
                reportArray.push("Utilization");
            } else if (value == 'Sub Contractor Survey Data') {
                reportArray.push("Subcontractor End User");
            } else {
                let surveyTitle = value.replace(/\s*Data\s*/, '');
                reportArray.push(surveyTitle);
            }
        });
    }

    return reportArray;
};

export const sortArrayData = (newArray, sortValue) => {
    newArray.sort(function (a, b) {
        return (a[sortValue]).localeCompare(b[sortValue])
    });

    return newArray;
};

export const sortNumberArrayData = (newArray, sortValue) => {
    newArray.sort(function (a, b) {
        return (b[sortValue]) - (a[sortValue]);
    });
    let sortArray = [];
    newArray.map((obj) => {
        let newObj = Object.assign({}, obj);

        if (newObj[sortValue]) {
            sortArray.push(newObj);
        }
    });

    return sortArray;
};

export const sortArrayAndAddId = (newArray, sortValue, isNumber) => {
    let updatedArray = [];
    newArray.forEach((obj) => {
        let newObj = Object.assign({}, obj);

        if (newObj[sortValue]) {
            newObj["id"] = uuidv4();
            updatedArray.push(newObj);
        }
    });

    return updatedArray;
};

export const sortArrayOfObjects = (updatedArray, sortValue, isNumber, flag) => {
    if (flag) {
        if (!isNumber) {
            updatedArray.sort(function (a, b) {
                return (a[sortValue]) - (b[sortValue]);
            });
        } else {
            updatedArray.sort(function (a, b) {
                return (a[sortValue]).localeCompare(b[sortValue])
            });
        }
    } else {
        if (!isNumber) {
            updatedArray.sort(function (a, b) {
                return (b[sortValue]) - (a[sortValue]);
            });
        } else {
            updatedArray.sort(function (a, b) {
                return (b[sortValue]).localeCompare(a[sortValue])
            });
        }
    }

    return updatedArray;
};

export const sortNumberArrayInAscData = (newArray, sortValue) => {
    newArray.sort(function (a, b) {
        return (a[sortValue]) - (b[sortValue]);
    });
    let sortArray = [];
    newArray.map((obj) => {
        let newObj = Object.assign({}, obj);

        if (newObj[sortValue]) {
            sortArray.push(newObj);
        }
    });

    return sortArray;
};

export const getChartData = (fetchedValue, firstKey) => {
    if (fetchedValue && fetchedValue.length > 0) {
        fetchedValue.forEach((dataObj) => {
            if (dataObj) {
                let value = convertStringToNumber(dataObj[firstKey]);

                if (!(isNaN(value))) {
                    let count = dataObj["count"];
                    dataObj["value"] = value;
                    let data = (value / count);
                    if (data) {
                        dataObj[firstKey] = Math.round(value / count);
                    } else {
                        dataObj[firstKey] = 0;
                    }
                } else {
                    dataObj[firstKey] = 0;
                }
            }
        });
    }

    return fetchedValue;
};

export const getPlant = () => {
    return ["Augusta", "Birmingham", "Clermont", "Coastal", "Gilroy", "Goodwill", "Johnson City", "Kansas City", "La Mirada", "Milwaukee", "Northern California", "Ontario", "Phoenix", "Portland", "Rome", "Winston Salem"];
};

export const getNavigationLink = (optionData) => {
    let link = "/dashboard";

    if (optionData) {
        let menuOption = optionData.trim();

        switch (menuOption) {
            case "Compliance Statistics":
                link = "/customerServicePerformance";
                break;
            case "Facility Survey Data Dashboard":
                link = "/facilitySurveyDashboard";
                break;
            case "Plant QA Dashboard":
                link = "/qualityAuditDashboard";
                break;
            case "Reports & Export":
                link = "/reports";
                break;
            case "SAM Surveys & Activities":
                link = "/plantSurvey";
                break;
            case "Plant QA Survey":
                link = "/plantQualityAudit";
                break;
            case "Facility Master Data":
                link = "/site";
                break;
            case "Plant QA Config":
                link = "/plantQAConfig";
                break;
            case "User Management":
                link = "/user";
                break;
            case "Survey Configuration":
                link = "/survey";
                break;
            case "Plant Configuration":
                link = "/plantconfig";
                break;
            default:
                link = "/dashboard";
                break;
        }
    }

    return link;
};

export const updateMultiSelectOptionForDropdown = (fetchedValue, key) => {
    return fetchedValue.map((obj) => {
        let data = obj[key];

        return {
            label: data,
            value: false
        };
    });
};

export const updateMultiSelectSearchForDropdown = (fetchedValue, key, value) => {
    let newArray = [];

    fetchedValue.forEach((obj) => {
        let dataObject = Object.assign({}, obj);
        let searchData = dataObject[key];
        let data = dataObject[key];

        if (value && (searchData).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            newArray.push({
                "label": data,
                "value": false
            });
        } else if (value === "") {
            newArray.push({
                "label": data,
                "value": false
            });
        }
    });

    return newArray.length ? newArray : "";
};

export const updateDropdownSelectionData = (selectedData, fetchedValue, key) => {
    fetchedValue.forEach((obj) => {
        let data = obj[key];

        obj["value"] = selectedData.indexOf(data) !== -1;
    });

    return fetchedValue;
};

export const getExistingSurveyGroup = (fetchedValue, title) => {
    let newArray = [];

    fetchedValue.forEach((surveyGroupObj) => {
        let obj = Object.assign({}, surveyGroupObj);
        let flag = false;

        if (obj.surveys && obj.surveys.length) {
            obj.surveys.forEach((surveyObj) => {
                if (surveyObj.label === title) {
                    flag = true;
                }
            });
        }

        if (flag) {
            newArray.push(obj.title)
        }
    });

    return newArray;
};

//Survey Field Array
export const surveyFieldArray = (fetchedValue) => {
    let configData = [];
    let surveyFields = [...fetchedValue.questions];

    surveyFields.forEach((obj) => {
        let newObj = {
            title: obj.title,
            fieldName: obj.fieldName,
            value: true
        };
        configData.push(newObj);
    });

    return configData
};


export const getUserAccessToUrl = (url, securityRole) => {
    let accessUrlArray = "/dashboard";

    securityRole.forEach(securityRoleObj => {
        if (securityRoleObj.isActive) {
            (securityRoleObj.subModule).forEach((subModule) => {

                if (subModule.isActive) {
                    let urlValue = getUrl(subModule.subModuleTitle);
                    accessUrlArray += urlValue;
                }
            });
        }
    });

    return accessUrlArray.includes(url);
};

const getUrl = (optionData) => {
    let link = "";

    if (optionData) {
        let menuOption = optionData.trim();

        switch (menuOption) {
            case "Compliance Statistics":
                link = "/customerServicePerformance";
                break;
            case "Facility Survey Data Dashboard":
                link = "/facilitySurveyDashboard, /scorecard, /utilization, /facilityOverview";
                break;
            case "Plant QA Dashboard":
                link = "/qualityAuditDashboard, /qualityTodayDetail";
                break;
            case "Reports & Export":
                link = "/reports, /surveyReport, /summaryOfDay, /samSummaryOfDay, /summaryOfDayScreen, /samSummaryScreen";
                break;
            case "SAM Surveys & Activities":
                link = "/plantSurvey";
                break;
            case "End Of The Day Summary":
                link = "/summaryOfDay";
                break;
            case "SAM Daily Summary":
                link = "/samSummaryOfDay";
                break;
            case "SAM Daily Summary Listing":
                link = "/samSummaryScreen";
                break;
            case "End Of The Day Summary Listing":
                link = "/summaryOfDayScreen";
                break;
            case "Plant QA Survey":
                link = "/plantQualityAudit";
                break;
            case "ScoreCard":
                link = "/scorecard";
                break;
            case "Utilization":
                link = "/utilization";
                break;
            case "Facility Master Data":
                link = "/site, /multiSite, /plant, /plantconfig";
                break;
            case "Plant QA Config":
                link = "/plantQAConfig";
                break;
            case "User Management":
                link = "/user, /role, /idn, /surveyGroup, /userLog, /errorLog";
                break;
            case "Survey Configuration":
                link = "/survey";
                break;
            case "Form Attributes":
                link = "/plantconfig";
                break;
            case "Plant Master Data":
                link = "/plant";
                break;
            case "New Facility Request":
                link = "/newFacilityRequest";
                break;
            case "Multi-Site Update":
                link = "/multiSite";
                break;
            case "IDN Configuration":
                link = "/idn";
                break;
            case "Security Role":
                link = "/role";
                break;
            case "Survey Group":
                link = "/surveyGroup";
                break;
            default:
                link = "/dashboard";
                break;
        }
    }

    return link;
};

export const getScreenName = (optionData) => {
    let screen = "";

    if (optionData) {
        let menuOption = optionData.trim();

        switch (menuOption) {
            case "customerServicePerformance":
                screen = "Compliance Statistics";
                break;
            case "facilitySurveyDashboard":
                screen = "Facility Survey Data Dashboard";
                break;
            case "facilityOverview":
                screen = "Facility Survey Data Dashboard";
                break;
            case "qualityAuditDashboard":
                screen = "Plant QA Dashboard";
                break;
            case "qualityTodayDetail":
                screen = "Plant QA Dashboard";
                break;
            case "reports":
                screen = "Reports & Export";
                break;
            case "surveyReport":
                screen = "Reports & Export";
                break;
            case "plantSurvey":
                screen = "SAM Surveys & Activities";
                break;
            case "summaryOfDay":
                screen = "End Of The Day Summary";
                break;
            case "summaryOfDayScreen":
                screen = "End Of The Day Summary Listing";
                break;
            case "samSummaryOfDay":
                screen = "SAM Daily Summary";
                break;
            case "samSummaryScreen":
                screen = "SAM Daily Summary Listing";
                break;
            case "plantQualityAudit":
                screen = "Plant QA Survey";
                break;
            case "scorecard":
                screen = "ScoreCard";
                break;
            case "utilization":
                screen = "Utilization";
                break;
            case "site":
                screen = "Facility Master Data";
                break;
            case "plantQAConfig":
                screen = "Plant QA Config";
                break;
            case "user":
                screen = "User Management";
                break;
            case "userLog":
                screen = "User Logs";
                break;
            case "errorLog":
                screen = "Error Logs";
                break;
            case "survey":
                screen = "Survey Configuration";
                break;
            case "plantconfig":
                screen = "Form Attributes";
                break;
            case "plant":
                screen = "Plant Master Data";
                break;
            case "newFacilityRequest":
                screen = "New Facility Request";
                break;
            case "multiSite":
                screen = "Multi-Site Update";
                break;
            case "idn":
                screen = "IDN Configuration";
                break;
            case "role":
                screen = "Security Role";
                break;
            case "surveyGroup":
                screen = "Survey Group";
                break;
            default:
                screen = "Dashboard";
                break;
        }
    }

    return screen;
};

export const convertValidStringToNumber = (data) => {
    let value = data ? data.value : "";
    if (value) {
        if (typeof (value) == "string") {
            return parseInt(data);
        }
        return data;
    }
};

export const endUserChart = (fetchData, headerArray) => {
    let data = [];
    let dataWithOriginalValue = [];
    let originalData = [], header = [];
    let num = 1;
    originalData["deptTotalChart"] = [];
    originalData["deptChart"] = [];
    originalData["rawData"] = [];
    originalData["header"] = [];

    if (fetchData && fetchData.length) {
        fetchData.forEach((fetchObj, index) => {
            if (fetchObj.status === 2) {
                let surveyObj = {
                    "Index": num++,
                    "Quality": 0,
                    "Availability": 0,
                    "Department": "",
                    "Survey Date": ""
                };

                (fetchObj.surveyDetail).forEach((detailObj) => {
                    if (detailObj.fieldName === "Quality") {
                        surveyObj["Quality"] = determineRange(detailObj.value);
                        surveyObj["QualityValue"] = detailObj.value;
                        header.push("Quality");
                    } else if (detailObj.fieldName === "Quantity") {
                        surveyObj["Availability"] = determineRange(detailObj.value);
                        surveyObj["AvailabilityValue"] = detailObj.value;
                        header.push("Availability");
                    } else if (detailObj.fieldName === "Visitation Date") {
                        surveyObj["Survey Date"] = detailObj.value;
                        header.push("Survey Date");
                    } else if (detailObj.fieldName === "Department") {
                        surveyObj["Department"] = detailObj.value;
                        header.push("Department");
                    } else if (detailObj.fieldName === "Comments") {
                        surveyObj[detailObj.fieldName] = detailObj.value;
                        header.push(detailObj.fieldName);
                    } else if (headerArray.includes(detailObj.fieldName)) {
                        surveyObj[detailObj.fieldName] = detailObj.value;
                        header.push(detailObj.fieldName);
                    }
                });

                data.push(surveyObj);
            }
        });

        fetchData.forEach((fetchObj) => {
            let flag = false;
            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Visitation Date" && detailObj.value) {
                    flag = true;
                }
            });
            if (fetchObj.status === 2 && flag) {
                let surveyObj = {
                    "Quality": 0,
                    "Availability": 0,
                    "Department": "",
                    "Survey Date": ""
                };

                (fetchObj.surveyDetail).forEach((detailObj) => {
                    if (detailObj.fieldName === "Visitation Date") {
                        surveyObj["Survey Date"] = detailObj.value;
                        header.push("Survey Date");
                    } else if (headerArray.includes(detailObj.fieldName)) {
                        surveyObj[detailObj.fieldName] = detailObj.value;
                        header.push(detailObj.title);
                    }
                });

                dataWithOriginalValue.push(surveyObj)
            }
        });

        let deptId = [];
        let deptChartArray = [];
        data.forEach((dataObj) => {
            let dataObject = Object.assign({}, dataObj);

            if (deptId.indexOf(dataObject.Department) === -1) {
                deptId.push(dataObject.Department);
                let surveyObj = {
                    "Quality": dataObject.Quality,
                    "Availability": dataObject.Availability,
                    "Department": dataObject.Department,
                    "Survey Date": dataObject["Survey Date"],
                    "Count": 1
                };

                deptChartArray.push(surveyObj);
            } else {
                let resultObject = searchArray(dataObject.Department, deptChartArray, "Department");
                resultObject.Quality += dataObject.Quality;
                resultObject.Availability += dataObject.Availability;
                resultObject.Count += 1;
            }
        });

        let deptTotalChart = [];

        deptChartArray.forEach((deptChartArrayObject) => {
            let fetchObj = Object.assign({}, deptChartArrayObject);

            let quality = fetchObj["Quality"];
            let quantity = fetchObj["Availability"];
            let count = fetchObj["Count"];
            let qualityAvg = getAvgOfNumberInIntFormat(quality, count);
            let quantityAvg = getAvgOfNumberInIntFormat(quantity, count);

            let chartObj = {
                "Date": fetchObj["Survey Date"],
                "Quality": qualityAvg,
                "Availability": quantityAvg,
                "Count": fetchObj.Count,
                "Department": fetchObj.Department,
            };

            deptTotalChart.push(chartObj);
        });


        originalData["deptTotalChart"] = sortArrayOfObject(deptTotalChart, "Department");
        originalData["deptChart"] = deptChartArray;
        originalData["rawData"] = dataWithOriginalValue;
        originalData["header"] = header;


    }
    return originalData;
};

export const clientSatisfactionChart = (fetchData, headerArray) => {
    let data = [], originalData = [], header = [];
    let num = 1;
    if (fetchData && fetchData.length) {
        fetchData.forEach((fetchObj) => {
            let flag = false;
            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Visitation Date" && detailObj.value) {
                    flag = true;
                }
            });
            if (fetchObj.status === 2 && flag) {
                let surveyObj = {
                    "Overall Performance": 0,
                    "Department": "",
                    "Survey Date": ""
                };

                (fetchObj.surveyDetail).forEach((detailObj) => {
                    if (detailObj.fieldName === "Visitation Date") {
                        surveyObj["Survey Date"] = detailObj.value;
                        header.push("Survey Date");
                    } else if (headerArray.includes(detailObj.fieldName)) {
                        surveyObj[detailObj.fieldName] = detailObj.value;
                        header.push(detailObj.title);
                    }
                });

                data.push(surveyObj);
            }
        });
    }

    originalData["rawData"] = data;
    originalData["header"] = header;

    return originalData;
};

export const qualityChart = (fetchData, linenItem) => {
    let data = [], originalData = [], header = [];
    let num = 1;

    originalData["onSiteChartArray"] = [];
    originalData["rawData"] = data;
    originalData["header"] = header;
    if (fetchData && fetchData.length) {
        fetchData.forEach((fetchObj) => {
            let flag = false;
            fetchObj.surveyDetail.forEach((surveyDetailObject) => {
                if (surveyDetailObject.fieldName === "Item") {
                    flag = linenItem.includes(surveyDetailObject.value);
                }
            });

            if (flag && fetchObj.status === 2) {
                let surveyObj = {
                    "Index": num++,
                    "Inspected": 0,
                    "Flaw": 0,
                    "Survey Date": "",
                    "Item": ""
                };
                fetchObj.surveyDetail.forEach((detailObj) => {
                    if (detailObj.fieldName === "Visitation Date") {
                        surveyObj["Flaw"] = 0;
                        surveyObj["Survey Date"] = detailObj.value;
                        header.push("Survey Date");
                    } else if (detailObj.fieldName === "Pieces Inspected") {
                        surveyObj["Inspected"] = convertStringToNumber(detailObj.value);
                        header.push("Inspected");
                    } else if (detailObj.type === "Number" && detailObj.fieldName !== "Pieces Inspected") {
                        let imperfection = convertStringToNumber(detailObj.value);
                        if (imperfection) {
                            surveyObj["Flaw"] += imperfection;
                        }
                        header.push("Flaw");
                    } else if (detailObj.fieldName === "Item") {
                        surveyObj["Item"] = detailObj.value;
                        header.push("Item");
                    }
                });
                data.push(surveyObj);
            }
        });

        let itemId = [];
        let onSiteChartArray = [];
        data.forEach((dataObj) => {
            let dataObject = Object.assign({}, dataObj);

            if (itemId.indexOf(dataObject.Item) === -1) {
                itemId.push(dataObject.Item);
                let surveyObj = {
                    "Inspected": dataObject.Inspected,
                    "Flaw": dataObject.Flaw,
                    "Item": dataObject.Item,
                    "Survey Date": dataObject["Survey Date"],
                    "Count": 1
                };

                onSiteChartArray.push(surveyObj);
            } else {
                let resultObject = searchArray(dataObject["Item"], onSiteChartArray, "Item");
                resultObject.Inspected += dataObject.Inspected;
                resultObject.Flaw += dataObject.Flaw;
                resultObject.Count += 1;
            }
        });

        originalData["onSiteChartArray"] = sortArrayOfObject(onSiteChartArray, "Item");
        originalData["rawData"] = data;
        originalData["header"] = header;
    }

    return originalData;
};

export const surveyRelatedChart = (fetchData, headerArray) => {
    let data = [], originalData = [], header = [];
    if (fetchData && fetchData.length) {
        fetchData.forEach((fetchObj) => {
            if (fetchObj.status === 2) {
                let surveyObj = {
                    "Survey Date": ""
                };

                (fetchObj.surveyDetail).forEach((detailObj) => {
                    if (detailObj.type === "Date") {
                        surveyObj["Survey Date"] = detailObj.value;
                        header.push("Survey Date");
                    } else if (headerArray.includes(detailObj.fieldName)) {
                        surveyObj[detailObj.fieldName] = detailObj.value;
                        header.push(detailObj.title);
                    }
                });

                data.push(surveyObj);
            }
        });
    }

    originalData["rawData"] = data;
    originalData["header"] = header;

    return originalData;
};

export const linenLossChart = (fetchData) => {
    let data = [];
    let typeArray = [];

    fetchData.forEach((fetchObj) => {
        if (fetchObj.status === 2) {
            let newObj = {
                name: "",
                pieces: 0
            };

            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Type") {
                    newObj.name = detailObj.value;
                }

                if (detailObj.fieldName === "Pieces Identified") {
                    newObj.pieces = parseInt(detailObj.value);
                }
            });

            data.push(newObj);
        }
    });

    let newData = [];
    data.forEach((fetchObj) => {
        if (typeArray.indexOf(fetchObj.name) == -1) {
            newData.push(fetchObj);
            typeArray.push(fetchObj.name)
        } else {
            newData.forEach((newDataObj) => {
                if (newDataObj.name === fetchObj.name) {
                    newDataObj.pieces += fetchObj.pieces;
                }
            });
        }
    });

    return newData;
};

export const parTable = (fetchData) => {
    let data = [];
    fetchData.forEach((fetchObj) => {
        if (fetchObj.status === 2) {
            let newObj = {
                name: "",
                level: "",
                adjustment: ""
            };

            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Department") {
                    newObj.name = detailObj.value;
                }

                if (detailObj.fieldName === "Inventory Level") {
                    newObj.level = detailObj.value;
                }

                if (detailObj.fieldName === "PAR Adjustments") {
                    newObj.adjustment = detailObj.value;
                }
            });

            data.push(newObj);
        }
    });

    return data;
};

export const parTableInArray = (fetchData) => {
    let data = [];
    fetchData.forEach((fetchObj) => {
        if (fetchObj.status === 2) {
            let newObj = [];

            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Department") {
                    newObj.push(detailObj.value);
                }

                if (detailObj.fieldName === "Inventory Level") {
                    newObj.push(detailObj.value);
                }

                if (detailObj.fieldName === "PAR Adjustments") {
                    newObj.push(detailObj.value);
                }
            });

            data.push(newObj);
        }
    });

    return data;
};

export const linenAwareData = (fetchData) => {
    let data = [];
    fetchData.forEach((fetchObj) => {
        if (fetchObj.status === 2) {
            let newObj = {
                name: "",
                level: ""
            };

            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Type") {
                    newObj.name = detailObj.value;
                }

                if (detailObj.fieldName === "Audience") {
                    newObj.level = detailObj.value;
                }
            });

            data.push(newObj);
        }
    });

    return data;
};

export const linenAwareInArray = (fetchData) => {
    let data = [];
    fetchData.forEach((fetchObj) => {
        if (fetchObj.status === 2) {
            let newObj = [];

            (fetchObj.surveyDetail).forEach((detailObj) => {
                if (detailObj.fieldName === "Type") {
                    newObj.push(detailObj.value);
                }

                if (detailObj.fieldName === "Audience") {
                    newObj.push(detailObj.value);
                }
            });

            data.push(newObj);
        }
    });

    return data;
};

export const getSortKeyValue = (objectData) => {
    let sortKeys = ["active", "isActive", "siteStatus"];

    for (const key of sortKeys) {
        if (objectData.hasOwnProperty(key)) {
            return key;
        }
    }

    return null;
};

export const updatedArrayByActiveKey = (arrayOfObjects) => {
    if (arrayOfObjects && arrayOfObjects.length) {
        return arrayOfObjects.map(obj => {
            let status = (obj["siteStatus"]).toLowerCase();
            if (status === "open") {
                return {...obj, active: true};
            } else {
                return {...obj, active: false};
            }
        });
    }
    return arrayOfObjects;
};

export const updatedObjectByActiveKey = (object) => {
    let status = (object["siteStatus"]).toLowerCase();
    if (status === "open") {
        return {...object, active: true};
    } else {
        return {...object, active: false};
    }
};

// Regular expression to match more than two "z"s at the start of the string
export const startsStringWithZs = (str) => {
    const regex = /^[zZ]{3,}/;
    return regex.test(str);
};

export const getSpecificArray = (value, data, key) => {
    let newArray = [];

    data.forEach(obj => {
        if (obj[key] !== value) {
            newArray.push(obj);
        }
    });
    return newArray;
};

export const getFilteredArray = (value, data, key) => {
    let newArray = [];

    data.forEach(obj => {
        if (value.indexOf(obj[key]) === -1) {
            newArray.push(obj);
        }
    });

    return newArray;
};

export const parentMenuOption = (url, securityRoleData) => {
    let mainTitle = "";
    (securityRoleData).forEach((moduleObj) => {
        (moduleObj.subModule).forEach((subModuleObj) => {
            if (subModuleObj.href == url) {
                mainTitle = moduleObj.name + "," + subModuleObj.name;
            }
        })

    });

    return mainTitle;
};

export const updateMultiSelectSearch = (fetchedValue, label, value, selectedArrayData) => {
    let newArray = [];

    if (fetchedValue && fetchedValue.length) {
        fetchedValue.forEach((obj) => {
            let dataObject = Object.assign({}, obj);
            let searchData = dataObject[label];
            let data = dataObject[label];

            if (value && (searchData).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                let flag = false;

                if(selectedArrayData && selectedArrayData.length) {
                    selectedArrayData.forEach((selectedArrayDataObj) => {
                        if (selectedArrayDataObj.value === true && (selectedArrayDataObj.label === searchData)) {
                            flag = true;
                        }
                    });
                }

                if (!flag) {
                    newArray.push({
                        "label": data,
                        "value": false
                    });
                }
            } else if (value === "") {
                let flag = false;
                if(selectedArrayData && selectedArrayData.length) {
                    selectedArrayData.forEach((selectedArrayDataObj) => {
                        if (selectedArrayDataObj.value === true && (selectedArrayDataObj.label === searchData)) {
                            flag = true;
                            newArray.push({
                                "label": data,
                                "value": true
                            });
                        }
                    });
                }

                if (!flag) {
                    newArray.push({
                        "label": data,
                        "value": false
                    });
                }
            }
        });
    }

    if (value !== "" && selectedArrayData && selectedArrayData.length) {
        selectedArrayData.forEach((selectedArrayDataObj) => {
            if (selectedArrayDataObj.value === true) {
                newArray.push({
                    "label": selectedArrayDataObj.label,
                    "value": true
                });
            }
        });
    }

    if (newArray && newArray.length > 0) {
        return sortArrayOfObject(newArray, "label");
    }

    return newArray.length ? newArray : "";
};

export const getSelectedAllFlag = (arrayOfObjects) => {
    let selectedAll = true;

    if (arrayOfObjects && arrayOfObjects.length && arrayOfObjects.length > 0) {
        arrayOfObjects.forEach(data => {
            if (!data.value) {
                selectedAll = false;
            }
        });
        return selectedAll;
    } else {
        return false;
    }

};

export const updateMultiSelectOption = (fetchedValue, key) => {
    if (fetchedValue && fetchedValue.length) {
        let arrayOfObjects = fetchedValue.map((obj) => {
            let data = obj[key];

            return {
                label: data,
                value: false
            };
        });

        return sortArrayOfObject(arrayOfObjects, "label");
    } else {
        return [];
    }
};

export const sortArrayOfObjectByNumber = (arrayOfObjects, sortValue) => {
    if (arrayOfObjects && arrayOfObjects.length > 0) {
        return arrayOfObjects.sort((a, b) => {
            // Extract numbers from the strings
            const numA = extractNumber(a[sortValue]);
            const numB = extractNumber(b[sortValue]);

            // Compare the numbers
            return numA - numB;
        });
    }
};

export const extractNumber = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
};

export const sortArrayOfObject = (arrayOfObjects, sortValue) => {
    if (arrayOfObjects && arrayOfObjects.length > 0) {
        arrayOfObjects.sort(function (a, b) {
            return (a[sortValue]).localeCompare(b[sortValue])
        });
    }

    return arrayOfObjects;
};

export const encodeSpecialCharacters = (inputString) => {
    if (inputString && inputString.includes('&')) {
        const htmlEntities = {
            '&': '&amp;',
        };

        // Create a regular expression pattern to match any of the special characters
        const pattern = new RegExp(`[${Object.keys(htmlEntities).join('')}]`, 'g');
        // Replace special characters with their HTML entity representations
        return inputString.replace(pattern, char => htmlEntities[char]);
    }

    return inputString;
};

export const trimObjectValues = (obj) => {
    // Iterate over each property of the object
    for (let key in obj) {
        // Check if the property value is a string
        if (typeof obj[key] === 'string') {
            // Trim the string value
            if (obj[key]) {
                obj[key] = obj[key].trim();
            }
        }
    }
    return {...obj};
};

// Function to determine the range for a given value
export const determineRange = (inputValue) => {
    if (inputValue) {
        let value = inputValue.trim();
        if (value === "Unsatisfactory") {
            return 1;
        } else if (value === "Needs Improvement") {
            return 2;
        } else if (value === "Neutral") {
            return 3;
        } else if (value === "Meets Expectation") {
            return 4;
        } else if (value === "Exceeds Expectation") {
            return 5;
        } else {
            return 0;
        }
    }

    return inputValue;
};

export const getAvgOfNumber = (value, count) => {
    if (value && !isNaN(value)) {
        return (value / count).toFixed(1);
    }

    return 0;
};

export const getAvgOfNumberInIntFormat = (value, count) => {
    if (value && !isNaN(value)) {
        let data = (value / count).toFixed(1);
        return parseFloat(data);
    }

    return 0;
};

export const getLinenQualityChartByWeek = (fetchData) => {
    let data = [];

    fetchData.forEach((fetchObject) => {
        let fetchObj = Object.assign({}, fetchObject);

        let quality = fetchObj["Quality"];
        let quantity = fetchObj["Availability"];
        let count = fetchObj["Count"];
        let qualityAvg = getAvgOfNumber(quality, count);
        let quantityAvg = getAvgOfNumber(quantity, count);

        let chartObj = {
            "Date": fetchObj.Date,
            "Quality": qualityAvg,
            "Availability": quantityAvg,
            "Count": fetchObj.Count,
            "ReferenceValue": fetchObj.ReferenceValue,
            "Week": fetchObj.Week,
            "WeekStart": fetchObj.WeekStart,
            "WeekRange": fetchObj.WeekRange
        };

        data.push(chartObj);
    });

    let sortData = data.sort((a, b) => new Date(a.WeekStart) - new Date(b.WeekStart));

    return sortData;
};

export const getLinenQualityChartByDept = (fetchData) => {
    let data = [];

    fetchData.forEach((fetchObject) => {
        let fetchObj = Object.assign({}, fetchObject);

        let quality = fetchObj["Quality"];
        let quantity = fetchObj["Availability"];
        let count = fetchObj["Count"];
        let qualityAvg = getAvgOfNumber(quality, count);
        let quantityAvg = getAvgOfNumber(quantity, count);

        let chartObj = {
            "Date": fetchObj.Date,
            "Dept": fetchObj.Dept,
            "Quality": qualityAvg,
            "Availability": quantityAvg,
            "Count": fetchObj.Count,
            "ReferenceValue": fetchObj.ReferenceValue,
        };

        data.push(chartObj);
    });

    return data;
};

export const getLinenQualityChartByDeptTopBottom = (fetchData) => {
    let data = [];

    fetchData.forEach((fetchObject) => {
        let fetchObj = Object.assign({}, fetchObject);

        let quality = fetchObj["Quality"];
        let quantity = fetchObj["Availability"];
        let count = fetchObj["Count"];
        let qualityAvg = getAvgOfNumber(quality, count);
        let quantityAvg = getAvgOfNumber(quantity, count);

        let total = fetchObj["Quality"] + fetchObj["Availability"];
        let totalCount = fetchObj["Count"] + fetchObj["Count"];
        let avg = getAvgOfNumber(total, totalCount);

        let chartObj = {
            "Date": fetchObj.Date,
            "Dept": fetchObj.Dept,
            "Quality": qualityAvg,
            "Availability": quantityAvg,
            "Count": fetchObj.Count,
            "ReferenceValue": fetchObj.ReferenceValue,
            "Avg": avg
        };

        data.push(chartObj);
    });

    // Sort the array in descending order based on the "Avg" property, converting strings to numbers
    data.sort((a, b) => parseFloat(b.Avg) - parseFloat(a.Avg));

    let totalArray = [];

    if (data.length >= 4) {
        // Get the top 2 objects
        let top2 = data.slice(0, 2);

        // Get the bottom 2 objects
        let bottom2 = data.slice(-2);
        totalArray = top2.concat(bottom2);
    } else {
        totalArray = data;
    }


    return totalArray;
};

export const getClientSatChartByWeek = (fetchData) => {
    let data = [];

    fetchData.forEach((fetchObject) => {
        let fetchObj = Object.assign({}, fetchObject);

        let overAllScore = fetchObj["Overall Performance"];
        let count = fetchObj["Count"];
        let overAllScoreAvg = getAvgOfNumber(overAllScore, count);

        let chartObj = {
            "Date": fetchObj.Date,
            "Dept": fetchObj.Dept,
            "Week": fetchObj.Week,
            "WeekRange": fetchObj.WeekRange,
            "WeekStart": fetchObj.WeekStart,
            "Overall Performance": overAllScoreAvg,
            "Count": fetchObj.Count,
            "ReferenceValue": fetchObj.ReferenceValue,
        };

        data.push(chartObj);
    });
    let sortData = data.sort((a, b) => new Date(a.WeekStart) - new Date(b.WeekStart));
    return sortData;
};

export const getOnsiteLinenChartByItem = (fetchData) => {
    let data = [];

    fetchData.forEach((fetchObject) => {
        let fetchObj = Object.assign({}, fetchObject);

        let inspected = fetchObj["Inspected"];
        let flaws = fetchObj["Flaw"];
        let count = fetchObj["Count"];
        let inspectedAvg = getAvgOfNumber(inspected, count);
        let flawsAvg = getAvgOfNumber(flaws, count);

        let chartObj = {
            "Date": fetchObj.Date,
            "Item": fetchObj.Item,
            "Inspected": inspectedAvg,
            "Flaw": flawsAvg,
            "Count": fetchObj.Count,
            "ReferenceValue": fetchObj.ReferenceValue,
        };

        data.push(chartObj);
    });

    return data;
};

export const getChartCount = (fetchData) => {
    let count = 0;

    fetchData.forEach((fetchObj) => {
        count += fetchObj["Count"];
    });

    return count;
};

export const formatNumberWithCommas = (number) => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
};

export const deepCloneObject = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        for (let i = 0; i < obj.length; i++) {
            arrCopy[i] = deepCloneObject(obj[i]);
        }
        return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            objCopy[key] = deepCloneObject(obj[key]);
        }
    }
    return objCopy;
};

export const getTableBodyData = (rawData, headerData) => {
    let pdfBodyData = [];
    // Check if rawData is defined and empty
    if (rawData && rawData.length === 0) {
        let headerLength = headerData.length;
        let middleIndex = Math.floor(headerLength / 2);

        // Create a new row with empty strings
        let newRow = new Array(headerLength).fill("");

        // Set the middle index to "No Data"
        newRow[middleIndex] = "NO DATA AVAILABLE";

        // Push the new row
        pdfBodyData.push(newRow);
    } else {
        pdfBodyData = rawData.map(item => {
            let arrayOfData = [];

            headerData.map((headerDataObj) => {
                let bodyData = item[headerDataObj];
                bodyData = bodyData ? bodyData : "";
                arrayOfData.push(bodyData);
            });

            return arrayOfData;
        });
    }

    return pdfBodyData;
};

export const getSummaryOfPdfSurvey = (fetchData) => {
    let data = [];

    if (fetchData && fetchData.length && fetchData.length > 0)
        fetchData.forEach((fetchObject) => {
            let fetchObj = Object.assign({}, fetchObject);

            fetchObj["startDate"] = getDateInUTC(fetchObj["startDate"]);
            fetchObj["endDate"] = getDateInUTC(fetchObj["endDate"]);
            fetchObj["createdAt"] = getDateInUTC(fetchObj["createdAt"]);

            data.push(fetchObj);
        });

    return data;
};

export const getSamSurveyPDFData = (fetchData) => {
    let data = [];

    if (fetchData && fetchData.length && fetchData.length > 0)
        fetchData.forEach((fetchObject) => {
            let fetchObj = Object.assign({}, fetchObject);

            fetchObj["gmEmail"] = fetchObj["gmEmail"].toString();
            fetchObj["rdoEmail"] = fetchObj["rdoEmail"].toString();
            fetchObj["createdAt"] = getDateInUTC(fetchObj["createdAt"]);

            data.push(fetchObj);
        });

    return data;
};

export const updateSummaryOfPdfSurvey = (fetchObj) => {
    fetchObj["startDate"] = getDateInUTC(fetchObj["startDate"]);
    fetchObj["endDate"] = getDateInUTC(fetchObj["endDate"]);
    fetchObj["createdAt"] = getDateInUTC(fetchObj["createdAt"]);
    return fetchObj;
};

export const getUniqueArrayOfObject = (fetchData) => {
    let data = [];
    let nameArray = [];

    if (fetchData && fetchData.length && fetchData.length > 0) {
        fetchData.forEach((fetchObject) => {
            let fetchObj = Object.assign({}, fetchObject);

            if(nameArray.indexOf(fetchObj["senderName"]) === -1) {
                data.push(fetchObj);
                nameArray.push(fetchObj["senderName"]);
            }
        });
    }

    return data;
};

export const getUniqueSenderArrayOfObject = (fetchData) => {
    let data = [];
    let nameArray = [];

    if (fetchData && fetchData.length && fetchData.length > 0) {
        fetchData.forEach((fetchObject) => {
            let fetchObj = Object.assign({}, fetchObject);

            if(nameArray.indexOf(fetchObj["userName"]) === -1) {
                data.push(fetchObj);
                nameArray.push(fetchObj["userName"]);
            }
        });
    }

    return data;
};

export const getSurveyDataGroupBySite = (activities, surveyData) => {
    // Grouping activities by site
    const groupedActivities = activities.reduce((acc, activity) => {
        const { site, survey, surveyDetail, ...rest} = activity;

        if (!acc[site]) {
            acc[site] = { site, activity: [] };
        }

        let activityGroup = acc[site].activity.find(act => act.survey === survey);
        let surveyHeader = getOnlySurveyReportHeader(surveyData, survey);

        if (!activityGroup) {
            activityGroup = { survey, data: [], surveyData: [], header: surveyHeader };
            acc[site].activity.push(activityGroup);
        }
        activityGroup.data.push({ survey, ...rest });
        let surveyObject = getSurveyDetailObject(activity.surveyDate, activity.surveyDetail, surveyHeader);
        activityGroup.surveyData.push(surveyObject);

        return acc;
    }, {});

    // Converting groupedActivities to an array of objects
    const data = Object.values(groupedActivities);
    return data;
};

export const getSurveyDetailObject = (surveyDate, surveyDetail, surveyHeader) => {
    let obj = {
        "Survey Date": getDateInUTCByMonth(surveyDate),
    };

    if (surveyDetail && surveyDetail.length) {
        surveyDetail.map(surveyObj => {
            let surveyDetail = Object.assign({}, surveyObj);
            let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");

            if (surveyDetail && surveyDetail.fieldName && surveyFieldName) {
                obj[surveyFieldName.name] = surveyDetail.value;                         }
        });
    }

    return obj;
};

export const addRecipientsIfFieldHasValue = (array1, array2, stringField) => {
    let recipients = [];

    if (array1 && array1.length > 0) {
        recipients = recipients.concat(array1);
    }

    if (array2 && array2.length > 0) {
        recipients = recipients.concat(array2);
    }

    if (stringField && stringField.trim() !== '') {
        recipients.push(stringField);
    }

    return recipients;
};

export const getFirstArrayValue = (data, key) => {
    let value = "";
    if (data && data.length > 0) {
        value = data[0][key];
    }
    return value;
};

export const getFileExtension = (fileName) => {
    return fileName.split('.').pop();
};

export const getMimeType = (file) => {
    return file.type;
};



