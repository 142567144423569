import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './QualityAuditDashboard.css';
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {useNavigate} from "react-router-dom";
import {updatePlantData} from "../../reducer/PlantNameReducer";
import PlantItemTable from "./PlantItemTable";
import Button from 'react-bootstrap/Button';
import {getDateByMonth, getDateInUTC, getDateOnly, getNextDate} from "../../commom/DateFormat";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {convertStringToNumber, getUserAccessToUrl, specificArrayData} from "../../commom/CommonFunction";
import PlantQAExportModal from "../plantQAExport/PlantQAExportModal";
import Loader from "../../commom/LoaderComponent";
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";

import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {AuthContext} from "../../context/AuthContext";

const QualityAuditDashboard = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [plantQualityAudit, setPlantQualityAudit] = useState("");
    const navigate = useNavigate();
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [showPlantQAExport, setShowPlantQAExport] = useState(false);
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const [plantsData, setPlantData] = useState();
    const [userId, setUserData] = useState();
    const [plants, setPlants] = useState([]);
    const auth = useContext(AuthContext);
    useEffect(() => {
        dispatch(showDateInHeader(true));
        dispatch(setDateRange());

        getUserDetailWithPlant().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            setPlantData(response.plants);
            setPlants(specificArrayData(response.plants, "_id"));
            setUserData(response._doc._id);
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
        });

    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailWithPlant = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithPlant',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const getPlantQuality = async (data, userIdData) => {
        let plants = [];
        data.map((plantObj) => {
            let obj = {...plantObj};
            plants.push(obj._id);
        });

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantQualityAuditByDates',
            requestConfig: {
                data: {
                    "plant": plants,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                    "userId": userIdData
                }
            }
        });

        return await response.data;
    };

    useEffect(() => {
        if (isDateChanged && plantsData) {
            getPlantQuality(plantsData, userId).then((fetchedValue) => {
                let newArray = [];
                fetchedValue.forEach(obj => {
                    let objData = Object.assign({}, obj);
                    newArray.push(objData)
                });
                let data = plantDetailData(newArray);
                setPlantQualityAudit(data);
            });
        }
    }, [isDateChanged, plantsData]);

    const search = (nameKey, myArray, key) => {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i][key] === nameKey) {
                return myArray[i];
            }
        }
    };

    const getImperfectionCount = (imperfectionArray) => {
        let count = 0;
        imperfectionArray.forEach((obj) => {
            if (obj.value) {
                count += convertStringToNumber(obj.value);
            }
        });
        return count;
    };

    const plantDetailData = (fetchedValue) => {
        let plantArr = []; // add unique housecode present in the fetched data
        let plantArray = []; // create new array for table insertion
        let newArray = [];
        let newArray2 = [];

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            newArray.push(objData)
        });
        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            newArray2.push(objData)
        });

        // fetched data array
        newArray.forEach((plantObject) => {
            // Add unique object in plantArray with new key as "chart"
            if (plantArr.indexOf(plantObject.houseCode) < 0) {
                plantArr.push(plantObject.houseCode);
                let data = {};
                data["houseCode"] = plantObject.houseCode;
                data["plant"] = plantObject.plant;
                data["itemCategory"] = plantObject.itemCategory;
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.Inspected) + imperfectionValue;

                let chart = {
                    "date": getDateByMonth(plantObject.date),
                    "Inspected": inspectValue,
                    "Imperfection": imperfectionValue,
                };
                data["chart"] = [];
                data["chart"].push(chart);
                data["Inspected"] = inspectValue;
                data["Imperfection"] = imperfectionValue;
                let insAvg = Math.ceil((imperfectionValue / inspectValue) * 100);
                data["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                data["inspectedAvg"] = Math.ceil(plantObject.Inspected / 1);
                data["child"] = plantItemCategoryDetail(plantObject.houseCode, newArray2);
                plantArray.push(data);
            } else {
                const resultObject = search(plantObject.houseCode, plantArray, "houseCode");
                let date = getDateByMonth(plantObject.date);
                const chartObject = search(date, resultObject["chart"], "date");
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.Inspected) + imperfectionValue;

                if (chartObject) {
                    // Duplicate (plant and  date) object should sum value

                    chartObject.Inspected = chartObject.Inspected + inspectValue;
                    chartObject.Imperfection = chartObject.Imperfection + imperfectionValue;
                } else {
                    // Duplicate plant and  unique date object should append in "chart" key

                    let chart = {
                        "date": getDateByMonth(plantObject.date),
                        "Inspected": inspectValue,
                        "Imperfection": imperfectionValue,
                    };
                    resultObject["chart"].push(chart);
                }

                // Duplicate plant object should sum value
                resultObject.Inspected = inspectValue + resultObject.Inspected;
                resultObject.Imperfection = imperfectionValue + resultObject.Imperfection;

                let insAvg = Math.ceil((resultObject.Imperfection / resultObject.Inspected) * 100);
                resultObject["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                resultObject["inspectedAvg"] = Math.ceil(resultObject.Inspected / resultObject["chart"].length);
            }
        });
        return plantArray;
    };

    const plantItemCategoryDetail = (houseCode, newArray2) => {
        let itemCatArr = []; // add unique housecode present in the fetched data
        let plantArray = []; // create new array for table insertion
        let newArray = [];
        let itemNewArray = [];

        newArray2.forEach(obj => {
            let objData = Object.assign({}, obj);
            let objData2 = Object.assign({}, obj);

            if (houseCode === objData.houseCode) {
                newArray.push(objData);
                itemNewArray.push(objData2);
            }
        });
        // fetched data array
        newArray.forEach((plantObject) => {
            // Add unique object in plantArray with new key as "chart"
            if (itemCatArr.indexOf(plantObject.itemCategory) < 0) {
                itemCatArr.push(plantObject.itemCategory);
                let data = {};
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.Inspected) + imperfectionValue;

                let chart = {
                    "date": getDateByMonth(plantObject.date),
                    "Inspected": inspectValue,
                    "Imperfection": imperfectionValue,
                    "itemCategory": plantObject.itemCategory,
                };
                data["chart"] = [];
                data["chart"].push(chart);
                data["itemCategory"] = plantObject.itemCategory;
                data["Inspected"] = inspectValue;
                data["Imperfection"] = imperfectionValue;
                let insAvg = Math.ceil((imperfectionValue / inspectValue) * 100);
                data["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                data["inspectedAvg"] = Math.ceil(inspectValue);
                data["imperfectionTypeChart"] = imperfectionTypeDetail(plantObject.itemCategory, itemNewArray);

                plantArray.push(data);
            } else {
                const resultObject = search(plantObject.itemCategory, plantArray, "itemCategory");
                let date = getDateByMonth(plantObject.date);
                const chartObject = search(date, resultObject["chart"], "date");
                let imperfectionValue = getImperfectionCount(plantObject.imperfectionArray);
                let inspectValue = convertStringToNumber(plantObject.Inspected) + imperfectionValue;

                if (chartObject) {
                    // Duplicate (item category and date) object should sum value

                    chartObject.Inspected = chartObject.Inspected + inspectValue;
                    chartObject.Imperfection = chartObject.Imperfection + imperfectionValue;
                } else {
                    // Duplicate item category and  unique date object should append in "chart" key

                    let chart = {
                        "date": getDateByMonth(plantObject.date),
                        "Inspected": inspectValue,
                        "Imperfection": imperfectionValue,
                        "itemCategory": plantObject.itemCategory,
                    };
                    resultObject["chart"].push(chart);
                }

                // Duplicate plant object should sum value
                resultObject.Inspected = inspectValue + resultObject.Inspected;
                resultObject.Imperfection = imperfectionValue + resultObject.Imperfection;

                let insAvg = Math.ceil((resultObject.Imperfection / resultObject.Inspected) * 100);
                resultObject["imperfectionsAvg"] = isNaN(insAvg) ? 0 : insAvg;
                resultObject["inspectedAvg"] = Math.ceil(resultObject.Inspected / resultObject["chart"].length);
            }
        });

        return plantArray;
    };

    const imperfectionTypeDetail = (itemCategory, fetchedValue) => {
        let newArray = [];

        fetchedValue.forEach(obj => {
            let objData = Object.assign({}, obj);
            if (itemCategory === objData.itemCategory) {
                newArray.push(objData);
            }
        });

        let chart = [];
        newArray.forEach((itemData) => {
            itemData.imperfectionArray.map((plantData) => {
                let imperfectionValue = convertStringToNumber(plantData.value);
                const chartObject = search(plantData.imperfectTitle, chart, "imperfectionType");

                if (chartObject) {
                    // Duplicate imperfection Type object should sum value
                    chartObject.Imperfection = chartObject.Imperfection + imperfectionValue;
                } else {
                    // unique imperfection Type should append in "chart" key
                    let chartObj = {
                        "Imperfection": imperfectionValue,
                        "imperfectionType": plantData.imperfectTitle
                    };

                    chart.push(chartObj)
                }
            });
        });
        return chart;
    };

    const getTodayScreen = (data) => {
        dispatch(updatePlantData({"plant": data.plant, "houseCode": data.houseCode}));
        navigate("/qualityTodayDetail");
    };

    const handleClose = () => {
        setShowPlantQAExport(false);
    };

    const showModal = () => {
        setShowPlantQAExport(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <PlantQAExportModal showPlantQAExport={showPlantQAExport} handleClose={handleClose}/>
            <Row className="qualityAudit">

                <div className="d-flex justify-content-end">
                    <Button type="button" className="formButton buttonCss" onClick={showModal}>Export</Button>
                </div>
                {
                    (plantQualityAudit && plantQualityAudit.length) ?
                        (plantQualityAudit.map((plantObj, index) => {
                            return (
                                <Col key={index + plantObj.plant} className="colClass" xs={12}>
                                    <Button size="lg" className="w-100 buttonCss">
                                        {(plantObj.plant).toUpperCase()}
                                        <span className="float-end" onClick={() => getTodayScreen(plantObj)}>Today's View</span>
                                    </Button>
                                    <Row>
                                        <Col xs={12}>
                                            <table className="fixedHeaderTable">
                                                <thead>
                                                <tr>
                                                    <th>Plant</th>
                                                    <th>Inspected</th>
                                                    <th>Imperfection</th>
                                                    <th>Inspected Avg</th>
                                                    <th>Imperfection %</th>
                                                    <th>Inspected</th>
                                                    <th>Imperfection</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="text-center">
                                                    <td>{(plantObj.plant)}</td>
                                                    <td>{plantObj.Inspected}</td>
                                                    <td>{plantObj.Imperfection}</td>
                                                    <td>{plantObj.inspectedAvg}</td>
                                                    <td>{plantObj.imperfectionsAvg}</td>
                                                    <td className="pieChartClass">
                                                        {plantObj.chart &&
                                                        <BarChartWithTopLabel data={plantObj.chart} label="Inspected"
                                                                   lineColor="#008000" name="date"/>}
                                                    </td>
                                                    <td className="pieChartClass">
                                                        {plantObj.chart &&
                                                        <BarChartWithTopLabel data={plantObj.chart} label="Imperfection"
                                                                   lineColor="#008000" name="date"/>}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PlantItemTable child={plantObj.child} keyIndex={plantObj.plant}/>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }))
                        :
                        (
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <table className="fixedHeaderTable">
                                            <thead>
                                            <tr>
                                                <th>Plant</th>
                                                <th>Inspected</th>
                                                <th>Imperfection</th>
                                                <th>Inspected Avg</th>
                                                <th>Imperfection %</th>
                                                <th>Inspection</th>
                                                <th>Imperfection</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td colSpan="7" className="text-center">NO DATA AVAILABLE</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Col>
                        )
                }
            </Row>
        </Container>
    )
};

export default QualityAuditDashboard;