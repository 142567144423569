import React, {useState} from "react";
import {Button} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useSelector} from "react-redux";
import MultiSelect from "../../commom/MultiSelect";

const SurveyComputedForm = (props) => {
    const questions = useSelector((state) => state.surveyQuestionReducer);

    return (
        <form className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="title" className="col-form-label"><span className="text-danger starCss">*</span>Title</label>
                    <div>
                        <input type="text" name="title"
                               onChange={props.handleChange} className={`form-control ${!props.inputValidation.title ? "errorDiv" : ""}`}
                               value={props.formValue.title}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="description" className="col-form-label">Description</label>
                    <div>
                        <input type="text" className="form-control" name="description"
                               onChange={props.handleChange} value={props.formValue.description}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="fieldName" className="col-form-label"><span className="text-danger starCss">*</span>Field Name</label>
                    <div>
                        <input type="text" name="fieldName"
                               onChange={props.handleChange} className={`form-control ${!props.inputValidation.fieldName ? "errorDiv" : ""}`}
                               value={props.formValue.fieldName} disabled={props.readOnly}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="type" className="col-form-label"><span className="text-danger starCss">*</span>Type</label>
                    <div>
                        <select onChange={props.handleChange} name="type"
                                className={`form-select ${!props.inputValidation.type ? "errorDiv" : ""}`}
                                value={props.formValue.type}>
                            <option value="select">Select</option>
                            <option value="Sum Multiple Survey Answers">Sum Multiple Survey Answers</option>
                            <option value="Divide Two Survey Answers">Divide Two Survey Answers</option>
                        </select>
                    </div>
                </div>
                {!props.showCompute && <div className="form-group col">
                    <label htmlFor="computation" className="col-form-label">Computation</label>
                    <div ref={props.innerRef} className="multiSelectField">
                        <input type="text" className="form-select" onClick={props.handleClick} readOnly={true}
                               value={`${props.formValue.computationCount} selected`} name="computation"/>
                        {
                            props.formValue.showComputation &&
                            <div className="selectDiv text-start px-sm-1">
                                <MultiSelect show={props.formValue.showComputation} data={props.questionList}
                                             handleCheckboxChange={props.handleCheckboxChange}
                                             allSelected={props.formValue.selectAllComputation} label="computation"
                                             handleSearch={props.handleSearch}/>
                            </div>
                        }
                    </div>
                </div>}
                {props.showCompute && <div className="form-group col">
                    <label htmlFor="numerator" className="col-form-label">Numerator</label>
                    <div>
                        <select value={props.formValue.numerator} onChange={props.handleChange} name="numerator"
                                className="form-select" disabled={props.isReadOnly}>
                            <option value="select">Select</option>
                            {
                                questions && questions.length
                                && questions.map((obj, index) => {
                                    return <option key={obj.title + index} value={obj.title}>{obj.title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                }
                {props.showCompute && <div className="form-group col">
                    <label htmlFor="denominator" className="col-form-label">Denominator</label>
                    <div>
                        <select value={props.formValue.denominator} onChange={props.handleChange} name="denominator"
                                className="form-select" disabled={props.isReadOnly}>
                            <option value="select">Select</option>
                            {
                                questions && questions.length
                                && questions.map((obj, index) => {
                                    return <option key={obj.title + index} value={obj.title}>{obj.title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>}
                <div className="form-group col">
                    <label htmlFor="active" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" id="active" name="active"
                               onChange={props.handleChange} checked={props.formValue.active}/>
                    </div>
                </div>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button onClick={props.handleOpen} className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default SurveyComputedForm;