import React from 'react';
import RadarChartWIthCustomTick from "../../commom/RadarChartWIthCustomTick";
import BarChartWithCustomTick from "../../commom/BarChartWithCustomTick";

const SummaryOfDayChart = ({headerName, chartData, label, name, flag, nextLabel, selectedChart}) => {
    return (
        <>
            {
                (chartData && chartData.length > 0) ?
                    <div className="chartArea">
                        {
                            (selectedChart === "BarChart") &&
                            <BarChartWithCustomTick data={chartData} label={label} name={name} flag={flag}
                                                                                    nextLabel={nextLabel}/>
                        }

                        {
                            (selectedChart === "RadarChart") &&
                            <RadarChartWIthCustomTick data={chartData} label={label} name={name} flag={flag}
                                                                                     nextLabel={nextLabel}/>
                        }
                    </div>
                    :
                    <div className="text-center">NO DATA AVAILABLE</div>
            }
        </>
    )
};

export default SummaryOfDayChart;