import React from 'react';
import LineChartWithLabel from "../../commom/LineChartWithLabel";
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";
import RadarChartWithLabel from "../../commom/RadarChartWithLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {formatNumberWithCommas} from "../../commom/CommonFunction";
import StatusIcon from "./StatusIcon";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";

const FacilitySiteData = ({sites, linenImperfection, getChartDetail, chartColumn, showSiteDataForPlant, getChartFullScreenDetail}) => {
    return (
        <>
            {
                sites && sites.length ?
                    (sites).map((value, index) => {
                        return (
                            <tr key={value.site + index} className="text-center">
                                <td className="colTitle">
                                    {value.site}
                                </td>
                                {chartColumn.chart1 &&
                                <td className="colChart">
                                    <div className="sortChartHeader">
                                        <span className="subHeaderCss">
                                            Avg {value.linenAvg}</span>
                                        <span className="subHeaderCss">
                                            Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                                        <span className="subHeaderIconCss"
                                              onClick={() => getChartDetail(1, value.linenChartWithQADate, "site", [value.site], value.linenQualityChartCount)}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                    </div>
                                    <div className="colChartClass">
                                        {(value.linenQualityChartWithWeek && value.linenQualityChartWithWeek.length > 0) &&
                                        <LineChartWithLabel data={value.linenQualityChartWithWeek} label="Quality"
                                                            name="WeekStart"
                                                            flag={true}
                                                            nextLabel="Availability"/>
                                        }
                                    </div>
                                </td>
                                }
                                {chartColumn.chart2 &&
                                <td className="colChart">
                                    <div className="sortChartHeader">
                                        <span className="subHeaderCss">Avg {value.linenAvg}</span>
                                        <span
                                            className="subHeaderCss"> Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                                        <span className="subHeaderIconCss"
                                              onClick={() => getChartDetail(2, value.linenChartWithDDate, "site", [value.site], value.linenQualityChartCount)}><FontAwesomeIcon
                                            icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                    </div>
                                    <div className="colChartClass">
                                        {(value.linenQualityChartWithDept && value.linenQualityChartWithDept.length > 0) &&
                                        <BarChartWithTopLabel data={value.linenQualityChartWithDept} label="Quality"
                                                              name="Dept"
                                                              flag={true}
                                                              nextLabel="Availability"/>
                                        }
                                    </div>
                                </td>
                                }
                                {chartColumn.chart3 &&
                                <td className="colChart">
                                    <div className="sortChartHeader">
                                        <span
                                            className="subHeaderCss">Pieces {formatNumberWithCommas(value.onsiteLinenChartTotal)}</span>
                                        <span className="subHeaderCss">Imp% {value.onsiteLinenChartAvg}</span>
                                        <span className="subHeaderIconCss"
                                              onClick={() => getChartDetail(3, value.onsiteLinenChartWithIDate, "site", [value.site], "", value.onsiteLinenChartTotal, value.onsiteLinenChartTotalImperfection)}><FontAwesomeIcon
                                            icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                    </div>
                                    <div className="colChartClass">
                                        {(value.onsiteLinenChartWithItem && value.onsiteLinenChartWithItem.length > 0) &&
                                        <RadarChartWithLabel data={value.onsiteLinenChartWithItem} label="Inspected"
                                                             name="Item"
                                                             flag={true}
                                                             nextLabel="Flaw"/>
                                        }
                                    </div>
                                </td>
                                }
                                {chartColumn.chart4 &&
                                <td className="colChart">
                                    <div className="sortChartHeader">
                                        <span className="subHeaderCss">Avg {value.clientSatChartAvg}</span>
                                        <span
                                            className="subHeaderCss"> Count {formatNumberWithCommas(value.clientSatChartCount)}</span>
                                        <span className="subHeaderIconCss"
                                              onClick={() => getChartDetail(4, value.clientSatChartWithOPDate, "site", [value.site], value.clientSatChartCount)}><FontAwesomeIcon
                                            icon={faInfoCircle} className="mx-1 fs-7"/></span>
                                    </div>
                                    <div className="colChartClass">
                                        {(value.clientSatChartWithWeek && value.clientSatChartWithWeek.length > 0) &&
                                        <BarChartWithTopLabel data={value.clientSatChartWithWeek}
                                                              label="Overall Performance" name="WeekStart"
                                                              flag={false}
                                                              nextLabel=""/>
                                        }
                                    </div>
                                </td>
                                }
                                <td className="colSentiment">
                                    <div className="subHeaderCss">
                                        <StatusIcon value={value.sentimentScore}/>
                                    </div>
                                    <span onClick={() => getChartFullScreenDetail(value, "site", [value.site])}><FontAwesomeIcon
                                        icon={faEye} className="eyeFontCss"/></span>
                                </td>
                            </tr>
                        )
                    })
                    :
                    <tr>
                        <td colSpan="6" className="text-center">NO DATA AVAILABLE</td>
                    </tr>
            }
        </>
    )
};

export default FacilitySiteData;