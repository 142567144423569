import React from 'react';
import ResetPassword from "./ResetPassword";
import TokenModalBox from "../login/TokenModalBox";

function FormContainer() {
    return (
        <>
            <ResetPassword/>
            <TokenModalBox/>
        </>
    );
}

export default FormContainer;
