import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, PieChart} from 'recharts';

const BarChartD = (props) => {
    return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={props.data}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey={props.name}/>
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey={props.label} fill="#0d6efd" background={{ fill: '#e9ecef' }}/>
                    <Legend layout="horizontal"/>
                </BarChart>
            </ResponsiveContainer>
        );
    };

export default BarChartD;