import React from "react";
import Row from "react-bootstrap/Row";

const SurveyQuestionForm = (props) => {
    return (
        <form className="px-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="title" className="col-form-label"><span
                        className="text-danger starCss">*</span>Form Label</label>
                    <div>
                        <input type="text" name="title"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.title ? "errorDiv" : ""}`}
                               value={props.formValue.title}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="description" className="col-form-label">Description</label>
                    <div>
                        <input type="text" className="form-control" name="description"
                               onChange={props.handleChange} value={props.formValue.description}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="fieldName" className="col-form-label"><span className="text-danger starCss">*</span>Field
                        Name</label>
                    <div>
                        <input type="text" name="fieldName"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.fieldName ? "errorDiv" : ""}`}
                               value={props.formValue.fieldName} disabled={props.readOnly}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="active" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" id="active" name="active"
                               onChange={props.handleChange} checked={props.formValue.active}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col  ">
                    <label htmlFor="type" className="col-form-label"><span className="text-danger starCss">*</span>Type</label>
                    <div>
                        <select onChange={props.handleChange} name="type"
                                className={`form-select ${!props.inputValidation.type ? "errorDiv" : ""}`}
                                value={props.formValue.type}>
                            <option value="select">Select</option>
                            <option value="Text">Text</option>
                            <option value="Number">Number</option>
                            <option value="Date">Date</option>
                            <option value="Dropdown">Dropdown</option>
                            <option value="Checkbox">Checkbox</option>
                        </select>
                    </div>
                </div>
                <div className="form-group col  ">
                    <label htmlFor="shown" className="col-form-label"><span
                        className="text-danger starCss">*</span>Shown</label>
                    <div>
                        <select onChange={props.handleChange} name="shown"
                                className={`form-select ${!props.inputValidation.shown ? "errorDiv" : ""}`}
                                value={props.formValue.shown}>
                            <option value="select">Select</option>
                            <option value="Always">Always</option>
                            <option value="By Rule">By Rule</option>
                            <option value="Never">Never</option>
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="order" className="col-form-label"><span
                        className="text-danger starCss">*</span>Order</label>
                    <div>
                        <input type="Number" name="order"
                               onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.order ? "errorDiv" : ""}`}
                               value={props.formValue.order}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="isRequire" className="col-form-label">Require</label>
                    <div>
                        <input type="checkbox" className="form-check-input" id="isRequire" name="isRequire"
                               onChange={props.handleChange} checked={props.formValue.isRequire}/>
                    </div>
                </div>
            </Row>
        </form>
    )
}

export default SurveyQuestionForm;