import React, {useState, useContext, useEffect} from 'react';
import {Container} from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from 'react-redux';
import {logIn} from '../../reducer/LoginReducer';
import {userDetail} from '../../reducer/UserReducer';
import axios from '../../apis/AxiosInstance';
import './login.css';
import {useNavigate} from "react-router-dom";
import {AuthContext} from '../../context/AuthContext';
import cr from '../../assets/Core_White_Transparent.png';

import ForgetPassword from "./ForgetPassword";
import LoginForm from "./LoginForm";
import Message from "../../commom/Message";
import {setPlant} from "../../reducer/PlantReducer";
import {setSite} from "../../reducer/SiteReducer";
import {setSurveyData} from "../../reducer/SurveyReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {list} from "../../reducer/FrequencyReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import SignUp from "./SignUp";
import {validateFormOnSave} from "../../commom/ValidateFormData";
import {handleInputChange} from "../../commom/FormHandler";
import {setAllSite} from "../../reducer/SiteAllReducer";
import Loader from "../../commom/LoaderComponent";
import {getNavigationLink, parentMenuOption} from "../../commom/CommonFunction";
import {updateDefaultScreen} from "../../reducer/DefaultScreenReducer";
import AddNewFacilityRequest from "../addNewfacilityRequest/AddNewFacilityRequest";
import {updateTokenModal} from "../../reducer/TokenModalReducer";

const Login = () => {
    let navigate = useNavigate();
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const authContext = useContext(AuthContext);
    const [showSignUp, setSignUp] = useState(false);
    const [showFacility, setFacility] = useState(false);

    const [formValue, setFormValue] = useState({
        userId: "",
        password: ""
    });
    const [inputValidation, setInputValidation] = useState({
        userId: true,
        password: true
    });
    const fieldValidation = {
        userId: "string",
        password: "string"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        dispatch(showDateInHeader(false));
    }, []);

    const handleChange = (event) => {
        setShowMessage(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }

        let data = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/login',
            requestConfig: {
                id: formValue.userId,
                password: formValue.password
            }
        });

        setShowMessage(true);
        if (data && data.code !== 400) {
            let val = data.data._doc;
            let isProductionEnv = (data.data.prodEnv == "true");
            let navigationLink = getNavigationLink(val.defaultScreen);

            let userData = {
                'userId': val.uniqueId,
                'token': val.authorizationTokens,
                'defaultScreen': navigationLink
            };

            if(navigationLink) {
                let parentMenu = parentMenuOption(navigationLink, data.data.securityRoles.modules);
                window.localStorage.setItem('selectedNavigation',parentMenu);
            }

            dispatch(updateDefaultScreen(val.defaultScreen));

            localStorage.setItem(
                'userDetail',
                JSON.stringify(userData)
            );

            authContext.login(val.authorizationTokens, "", val.uniqueId);
            authContext.token = val.authorizationTokens;
            authContext.userId = val.uniqueId;
            dispatch(userDetail(
                {"user": val}
            ));
            dispatch(logIn({"token": val.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(updateTokenModal(false));
            authContext.isLoggedIn = true;
            dispatch(setPlant(data.data.plants));
            dispatch(setSite(data.data.sites));
            dispatch(setAllSite(data.data.sites));
            dispatch(setSurveyData(data.data.surveys));
            dispatch(setUserRole(data.data.securityRoles.modules));
            dispatch(list(data.data.masterData));
            dispatch(setDateRange());
        }
    };

    const openModalBox = (event) => {
        event.preventDefault();
        setShow(true);
    };

    const closeModalBox = () => {
        setShow(false);
    };

    const openSignUpModalBox = (event) => {
        event.preventDefault();
        setSignUp(true);
    };

    const closeSignUpModalBox = () => {
        setSignUp(false);
    };

    const openAddNewFacilityModalBox = (event) => {
        event.preventDefault();
        setFacility(true);
    };

    const closeAddNewFacilityModalBox = () => {
        setFacility(false);
    };

    return (
        <>
            <Loader isLoading={loading}/>
            {show &&
            <ForgetPassword closeModalBox={closeModalBox} show={show}/>
            }
            {showSignUp &&
            <SignUp closeSignUpModalBox={closeSignUpModalBox} show={showSignUp}/>
            }
            {showFacility &&
            <AddNewFacilityRequest closeAddNewFacilityModalBox={closeAddNewFacilityModalBox} show={showFacility}/>
            }

            <Container fluid="sm" id="loginContainer">
                <div>
                    <Image src={cr} className="fluid rounded thumbnail img-fluid rounded mx-auto d-block"/>
                </div>
                <Message showMessage={showMessage}
                         response={response} error={error} loading={loading} formValidation={formValidation}
                         message="User Id or Password is Incorrect!"/>

                <LoginForm submitHandler={submitForm} handleChange={handleChange} formValue={formValue}
                           inputValidation={inputValidation}/>

                <div className="d-flex mb-3">
                    <div className="p-2 text-primary">
                        <a href="#" className="link-danger" onClick={openSignUpModalBox}>SignUp</a></div>
                    <div className="ms-auto p-2">
                        <a href="#" className="link-danger" onClick={openModalBox}>Forgot Password</a>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <i>For Better View Use Chrome.</i>
                </div>
                <div className="d-flex justify-content-center" id='login-form-powered'>
                    Improvement Interactive | Powered by Cycligent
                </div>
            </Container>
        </>
    )
};

export default Login;