import {createSlice} from "@reduxjs/toolkit";
const updateMonthDigit = (value) => {
    let month = value + 1;
    return month < 10 ? '0' + month: '' + month;
};

const updateDateDigit = (date) => {
    return date < 10 ? '0' + date: '' + date;
};

const getMonthStartDate = () => {
    let date = new Date();

    let month =  updateMonthDigit(date.getMonth());
    let day = updateDateDigit(1);
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
};

const getMonthEndDate = () => {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let month =  updateMonthDigit(lastDay.getMonth());
    let day = lastDay.getDate();
    let year = date.getFullYear();

    return year + "-" + month + "-" + day;
};

export const DateRangeReducer = createSlice({
    name: "DateRangeReducer",
    initialState: {
        "startDate": "",
        "endDate": ""
    },
    reducers: {
        setDateRange: (state) => {
            let localStorageStartDate = window.localStorage.getItem('startDate');
            let localStorageEndDate = window.localStorage.getItem('endDate');

            if (localStorageStartDate) {
                state.startDate = localStorageStartDate;
            } else {
                state.startDate = getMonthStartDate();
                window.localStorage.setItem('startDate', getMonthStartDate());
            }

            if (localStorageEndDate) {
                state.endDate = localStorageEndDate;
            } else {
                state.endDate = getMonthEndDate();
                window.localStorage.setItem('endDate', getMonthEndDate());
            }
        },
        updateDate: (state, action) => {
            if (action.payload.name === "endDate") {
                state.endDate = action.payload.value;
            } else {
                state.startDate = action.payload.value;
            }
        },
        updateRangeOfDate: (state, action) => {
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
        }
    }
});

export const {setDateRange, updateDate, updateRangeOfDate} = DateRangeReducer.actions;
export default DateRangeReducer.reducer;