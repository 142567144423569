import React, {useEffect} from 'react';
import {PDFDocument, StandardFonts, rgb} from 'pdf-lib';
import Button from "react-bootstrap/Button";
import {validationCheck} from "../../commom/ValidateFormData";
import coreLogo from "../../assets/CoreLinenLogo.png";
import {addRecipientsIfFieldHasValue} from "../../commom/CommonFunction";
import {getDateInMonth} from "../../commom/DateFormat";

const SamPdfGenerator = ({
                          userName, dateValue, sam, surveyGroupBySite, topIssues, gmEmail, rdoEmail,
                          emailSubject, submitHandler, sendPDFToServer, emailAddress, noteToManagement}) => {

    // Function to split string by width
    const splitStringByWidth = (str, fontSize, maxWidth, font) => {
        const words = str.trim().replace(/\n/g, ' ').split(' ').filter(word => word !== '');
        const lines = [];
        let currentLine = '';

        words.forEach(word => {
            const testLine = currentLine + (currentLine ? ' ' : '') + word;
            const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);
            if (testLineWidth <= maxWidth) {
                currentLine = testLine;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        if (currentLine) {
            lines.push(currentLine);
        }

        return lines;
    };

    const prepareEmailLines = (headerOfPdf, receiverName, contentWidth, fontSize, font) => {
        const line = `I hope this email finds you well. Attached, please find the daily summary for ${sam}. If you have any questions or need further information, feel free to reach out.`;
        const splitLineField = splitStringByWidth(line, fontSize, contentWidth, font);

        let lines = [headerOfPdf, "", ...splitLineField, ""];

        if (topIssues) {
            const splitTopIssue = splitStringByWidth(topIssues, fontSize, contentWidth, font);
            lines.push("Top Issue :", ...splitTopIssue, "");
        }

        if (noteToManagement) {
            const splitNoteToManagement = splitStringByWidth(noteToManagement, fontSize, contentWidth, font);
            lines.push("Note To Management :", ...splitNoteToManagement, "");
        }

        lines.push("Enjoy your day,", userName, "");

        return lines;
    };

    const drawCenteredText = (page, text, font, fontSize, yPosition, margin, width, lineHeight) => {
        if(text){
            // Replace the matched characters with an empty string
            text = text.replace(/\n/g, ' ')
        }
        const textWidth = font.widthOfTextAtSize(text, fontSize);
        page.drawText(text, {
            x: margin + (width / 2) - (textWidth / 2),
            y: yPosition - lineHeight,
            size: fontSize,
            font: font,
            color: rgb(0, 0.53, 0.71)
        });
        return yPosition - lineHeight;
    };

    const drawHeader = (header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, fontSize) => {
        header.forEach((header, colIndex) => {
            const x = margin + colIndex * columnWidth;
            const cellWidth = header.cellSpan ? columnWidth * header.cellSpan : columnWidth;

            // Draw the rectangle for the header cell
            page.drawRectangle({
                x,
                y: yPosition - lineHeight,
                width: cellWidth,
                height: lineHeight,
                borderColor: rgb(0.502, 0.502, 0.502),
                borderWidth: 0.5,
                color: rgb(0.91, 0.93, 0.94),
            });

            // Calculate the vertical center position for the text
            const textHeight = font.heightAtSize(fontSize);
            const textY = yPosition - lineHeight + (lineHeight - textHeight) / 2;

            // Draw the text in the header cell
            page.drawText(header.header, {
                x: x + (cellWidth - font.widthOfTextAtSize(header.header, fontSize)) / 2,
                y: textY,
                size: fontSize,
                font,
                color: rgb(0, 0, 0),
            });
        });
        return yPosition - lineHeight;
    };

    const drawRow = (row, header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, fontSize) => {
        const cellHeights = header.map((header) => {
            const text = String(row[header.name] || '');
            const cellWidth = columnWidth - cellPadding * 2;
            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';
                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ')
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);
            return lines.length * lineHeight + cellPadding * 2;
        });

        const rowHeight = Math.max(...cellHeights);

        header.forEach((header, colIndex) => {
            const text = String(row[header.name] || '');
            const cellWidth = columnWidth - cellPadding * 2;
            const x = margin + colIndex * columnWidth;

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';
                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ')
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);

            lines.forEach((line, lineIndex) => {
                page.drawText(line.trim(), {
                    x: x + cellPadding,
                    y: yPosition - (lineIndex + 1) * lineHeight,
                    size: fontSize,
                    font,
                    color: rgb(0, 0, 0),
                });
            });

            page.drawRectangle({
                x,
                y: yPosition - rowHeight,
                width: columnWidth,
                height: rowHeight,
                borderColor: rgb(0.502, 0.502, 0.502),
                borderWidth: 0.5,
            });
        });

        return yPosition - rowHeight;
    };

    const getRowHeight = (row, header, columnWidth, cellPadding, lineHeight, margin, font, fontSize) => {
        const cellHeights = header.map((header) => {
            let text = String(row[header.name] || '');
            if(text){
                // Replace the matched characters with an empty string
                text = text.replace(/\n/g, ' ')
            }
            const cellWidth = columnWidth - cellPadding * 2;
            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                let testLine = lastLine + word + ' ';
                if(testLine){
                    // Replace the matched characters with an empty string
                    testLine = testLine.replace(/\n/g, ' ')
                }
                if (font.widthOfTextAtSize(testLine, fontSize) > cellWidth) {
                    acc.push(word + ' ');
                } else {
                    acc[acc.length - 1] = testLine;
                }
                return acc;
            }, ['']);
            return lines.length * lineHeight + cellPadding * 2;
        });

        return Math.max(...cellHeights);
    };

    const drawTableTitle = (page, text, margin, yPosition, width, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc) => {
        // Draw the background rectangle with the specified color
        page.drawRectangle({
            x: margin,
            y: yPosition - lineHeight,
            width: width,
            height: lineHeight,
            borderColor: rgb(0.502, 0.502, 0.502),
            borderWidth: .5,
            color: rgb(0.91, 0.93, 0.94),  // Background color #e9ecef
        });
        if(text){
            // Replace the matched characters with an empty string
            text = text.replace(/\n/g, ' ')
        }
        // Draw the text over the background rectangle
        page.drawText(text, {
            x: (pageWidth - font.widthOfTextAtSize(text, fontSize)) / 2,
            y: yPosition - lineHeight + cellPadding,
            size: fontSize,
            font,
            color: rgb(0, 0, 0),  // Text color
        });

        // Update yPosition for the next element
        yPosition -= lineHeight;
        return {page, yPosition};
    };

    const drawNoDataMessage = (page, margin, yPosition, width, lineHeight, font, tableFontSize, cellPadding, pageWidth) => {
        const noDataMessage = 'NO DATA AVAILABLE';

        // Draw the background rectangle with the specified color
        page.drawRectangle({
            x: margin,
            y: yPosition - lineHeight,
            width: width,
            height: lineHeight,
            borderColor: rgb(0.502, 0.502, 0.502),
            borderWidth: .5,
        });

        // Draw the "NO DATA AVAILABLE" message
        page.drawText(noDataMessage, {
            x: (pageWidth - font.widthOfTextAtSize(noDataMessage, tableFontSize)) / 2,
            y: yPosition - lineHeight + cellPadding,
            size: tableFontSize,
            font,
            color: rgb(0, 0, 0),  // Text color
        });

        // Update yPosition for the next element
        return yPosition - lineHeight;
    };

    const drawTableWithTitleAndHeader = (page, title, header, bodyData, yPosition, cellPadding,
                                         lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight,
                                         contentWidth, fontSize) => {
        const columnWidth = contentWidth / header.length;

        // Calculate the height required for the title, header, and one row
        const titleHeight = lineHeight; // Assuming title height is the same as line height
        const headerHeight = lineHeight;
        let rowHeight = 0;
        if (bodyData.length !== 0) {
            rowHeight = getRowHeight(bodyData[0], header, columnWidth, cellPadding, lineHeight, margin, font, tableFontSize);
        }

        // Check if there is enough space for the title, header, and one row, if not create a new page
        if (yPosition - (titleHeight + headerHeight + rowHeight) < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            yPosition = pageHeight - margin;
        }

        // Draw Table Title
        ({
            page,
            yPosition
        } = drawTableTitle(page, title, margin, yPosition, contentWidth, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc));

        // If no data, show "NO DATA AVAILABLE" message
        if (bodyData.length === 0) {
            yPosition = drawNoDataMessage(page, margin, yPosition, contentWidth, lineHeight, font, tableFontSize, cellPadding, pageWidth);
        } else {
            // Draw the table header
            yPosition = drawHeader(header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, boldFont, tableFontSize);

            // Draw table rows
            bodyData.forEach((row) => {
                const rowHeight = getRowHeight(row, header, columnWidth, cellPadding, lineHeight, margin, font, tableFontSize);

                // Check if there is enough space for the next row, if not create a new page with the title and header
                if (yPosition - rowHeight < margin) {
                    page = pdfDoc.addPage([pageWidth, pageHeight]);
                    yPosition = pageHeight - margin;

                    // Draw Table Title
                    ({
                        page,
                        yPosition
                    } = drawTableTitle(page, title, margin, yPosition, contentWidth, lineHeight, font, fontSize, cellPadding, pageWidth, pageHeight, pdfDoc));

                    // Draw the table header
                    yPosition = drawHeader(header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, boldFont, tableFontSize);
                }

                yPosition = drawRow(row, header, columnWidth, page, yPosition, cellPadding, lineHeight, margin, font, tableFontSize);
            });
        }
        yPosition -= margin;
        return {page, yPosition};
    };

    const handlePDFExport = async (sendPfdByEmail) => {
        let checkEmail = validationCheck("email", emailAddress);
        let senderEmail = addRecipientsIfFieldHasValue(gmEmail, rdoEmail, emailAddress);
        if((senderEmail && senderEmail.length > 0) || checkEmail) {
            submitHandler(false);
        } else if (!sendPfdByEmail) {
            submitHandler(false);
        }
        const companyImageBytes = await fetch(coreLogo).then((res) => res.arrayBuffer());
        let receiverName = "";
        let headerOfPdf = getDateInMonth(dateValue);
        let subjectOfEmail = emailSubject;

        // Create a new PDF document
        let pdfDoc = await PDFDocument.create();
        // Define A4 dimensions (595.28 x 841.89 points)
        let pageWidth = 595.28;
        let pageHeight = 841.89;
        const margin = 36; //  0.5 inches (36 points):
        let page = pdfDoc.addPage([pageWidth, pageHeight]);
        let width = pageWidth - 2 * margin;
        let height = pageHeight;
        const tableFontSize = 7;
        const fontSize = 12;
        const headerFontSize = 15;
        const lineHeight = 18;
        const cellPadding = 5;
        let yPosition = height - margin;
        // Load the standard font
        const font = await pdfDoc.embedStandardFont('Helvetica');
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const contentWidth = pageWidth - 2 * margin;

        // Company Logo
        const companyImage = await pdfDoc.embedPng(companyImageBytes);
        const companyImageSize = companyImage.scale(0.8);
        page.drawImage(companyImage, {
            x: margin + (width / 2) - (companyImageSize.width / 2),
            y: yPosition - companyImageSize.height,
            width: companyImageSize.width,
            height: companyImageSize.height
        });

        yPosition -= companyImageSize.height + lineHeight;

        // Usage for Sam Name
        yPosition = drawCenteredText(page, sam, font, headerFontSize, yPosition, margin, width, lineHeight);

        // Usage for Subject of Email
        yPosition = drawCenteredText(page, subjectOfEmail, font, headerFontSize, yPosition, margin, width, lineHeight);

        yPosition -= margin;

        const emailLines = prepareEmailLines(headerOfPdf, receiverName, width, fontSize, font);

        // Generate Email
        emailLines.forEach(line => {
            if (yPosition < margin + lineHeight) {
                page = pdfDoc.addPage([pageWidth, pageHeight]);
                yPosition = height - margin;
            }
            page.drawText(line, {
                x: margin,
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0)
            });
            yPosition -= lineHeight;
        });

        surveyGroupBySite.forEach(client => (
            client.activity.forEach(activityGroup => {
                let headerName = client.site + "( " +activityGroup.survey + " )";
                // Draw Table Title CLIENT STAT
                ({
                    page,
                    yPosition
                } = drawTableWithTitleAndHeader(page, headerName, activityGroup.header, activityGroup.surveyData, yPosition, cellPadding, lineHeight, margin, font, tableFontSize, boldFont, pdfDoc, pageWidth, pageHeight, contentWidth, fontSize));
            })
        ));


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        if (sendPfdByEmail) {
            // Send PDF to server for email
            sendPDFToServer(pdfBytes);
        } else {
            // Trigger download of the PDF
            const blob = new Blob([pdfBytes], {type: 'application/pdf'});
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'chart_document.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            window.localStorage.setItem('chartRef', "");
        }
    };

    /*const showImageScreen = () => {
        handleOpenModal();
    };

    useEffect(() => {
        if (isSendEmail) {
            handlePDFExport(true).then(() =>{
                handleClose();
            })
        }
    }, [isSendEmail]);*/

    return (
        <>
            <Button onClick={() => handlePDFExport(false)} className="mx-sm-1 buttonCss">
                Export as PDF
            </Button>
            <Button onClick={() => handlePDFExport(true)} className="mx-sm-1 buttonCss">
                Send Email With PDF
            </Button>
        </>
    );
};

export default SamPdfGenerator;