import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const IdnForm = (props) => {

    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <div className="form-group row">
                <label htmlFor="idnAffiliation" className="col-sm-3 col-form-label">
                    <span className="text-danger starCss">*</span>IDN
                </label>
                <div className="col-sm-9 py-sm-1">
                    <input type="text"
                           className={`form-control ${!props.inputValidation.idnAffiliation ? "errorDiv" : ""}`}
                           value={props.formValue.idnAffiliation} onChange={props.inputChangeHandler}
                           name="idnAffiliation" disabled={props.readOnly}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="active" className="col-sm-3 col-form-label">Active</label>
                <div className="col-sm-9 py-sm-1">
                    <input type="checkbox" className="form-check-input"
                           checked={props.formValue.active} id="active" onChange={props.inputChangeHandler} name="active"/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="isAdmin" className="col-sm-3 col-form-label">Admin</label>
                <div className="col-sm-9 py-sm-1">
                    <input type="checkbox" className="form-check-input" id="isAdmin"
                           checked={props.formValue.isAdmin} onChange={props.inputChangeHandler} name="isAdmin"/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="displayIdn" className="col-sm-3 col-form-label">Display Idn</label>
                <div className="col-sm-9 py-sm-1">
                    <input type="checkbox" className="form-check-input" id="displayIdn"
                           checked={props.formValue.displayIdn} onChange={props.inputChangeHandler} name="displayIdn"/>
                </div>
            </div>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit || props.formValue.idnAffiliation === "SuperAdmin IDN"}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss" disabled={props.formValue.idnAffiliation === "SuperAdmin IDN"}>Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default IdnForm;