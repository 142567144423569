import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const ItemCategoryForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <div className="form-group row mb-1">
                <label htmlFor="value" className="col-sm-3 col-form-label"><span className="text-danger starCss">*</span>{props.screen}</label>
                <div className="col-sm-9">
                    <input type="text" id="value" name="value"
                           onChange={props.handleChange}  className={`form-control ${!props.inputValidation.value ? "errorDiv" : ""}`}
                           value={props.formValue.value} disabled={props.readOnlyField}/>
                </div>
            </div>
            <div className="form-group row mb-1">
                <label htmlFor="isActive" className="col-sm-3 col-form-label">Active</label>
                <div className="col-sm-9">
                    <input type="checkbox" className="form-check-input" name="isActive" id="isActive"
                           onChange={props.handleChange} checked={props.formValue.isActive}/>
                </div>
            </div>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default ItemCategoryForm;