import React from "react";
import {Button, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";

const PlantQualityAuditForm = (props) => {
    return (
        <>
            <Row className="mb-2">
                <div className="form-group col">
                    <label htmlFor="plant" className="col-form-label"><span
                        className="text-danger starCss">*</span>Plant</label>
                    <div>
                        <select onChange={props.handleChange} name="plant"
                                className="form-select"
                                value={props.formValue.plant}>
                            <option value="select">Select</option>
                            {
                                props.plantData && props.plantData.length
                                && props.plantData.map((obj, index) => {
                                    return <option key={obj._id + index}
                                                   value={obj._id}>{obj._id}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="site" className="col-form-label"><span className="text-danger starCss">*</span>Site</label>
                    <div>
                        <select onChange={props.handleChange} name="site"
                                className="form-select"
                                value={props.formValue.site}>
                            <option value="select">Select</option>
                            {
                                props.siteList && props.siteList.length
                                && props.siteList.map((obj, index) => {
                                    return <option key={obj.site + index}
                                                   value={obj.site}>{obj.site}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </Row>

            {(props.config && props.config.length > 0) && <table className="fixedHeaderTable">
                <thead>
                <tr>
                    <th data-name="item" className="text-start itemWidth px-1">Item</th>
                    <th data-name="inspection" className="text-center">Inspection</th>
                    {props.imperfectionList.map((data) => {
                        return <th key={data.value} data-name={data.value}
                                   className="text-center">{data.value}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                {
                    (props.config && props.config.length) ?
                        (props.config).map((configObj, index) => {
                            const {itemCategory, inspected, imperfectionArray, uniqueKey} = configObj;
                            return (
                                <tr key={itemCategory}>
                                    <td className="text-start itemWidth px-1"
                                        key={index + itemCategory}>{itemCategory}</td>
                                    <td className="text-end cellMinWidth" key={uniqueKey}>
                                        <input onChange={props.inputChangeHandler} name={itemCategory} type="number"
                                               className="form-control" value={inspected} min="0" max="100"/>
                                    </td>
                                    {(imperfectionArray && imperfectionArray.length) &&
                                    (imperfectionArray.map((imperfectData) => {
                                        const {value, type, name} = imperfectData;
                                        return (
                                            <td className="text-end cellMinWidth" key={name}>
                                                <input onChange={props.inputChangeHandler} name={name}
                                                       type="text" className="form-control" value={value}/></td>
                                        )
                                    }))
                                    }
                                </tr>
                            )
                        }) :
                        <tr>
                            <td colSpan={props.imperfectionList.length + 2} className="text-center">NO ITEM AVAILABLE
                            </td>
                        </tr>
                }
                </tbody>
            </table>
            }
            {(props.config && props.config.length > 0) && <div className="row justify-content-end m-1">
                <Button type="button" onClick={props.submitHandler} disabled={props.disableSubmit}
                        className="formButton col-md-1 mx-sm-1 buttonCss">Save</Button>
            </div>}
        </>
    )
};

export default PlantQualityAuditForm;