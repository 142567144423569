import React from "react";
import {Link} from "react-router-dom";

const NavLinkComponent = (props) => {
    return (
        <div className={`navLeftPadding ${props.isProdEnv ? "navLink" : "navLinkDev"}`}
             name={props.parent + "," + props.subModule.subModuleTitle} onClick={props.handleSideBarClick}>
            <Link to={props.subModule.href}>
            {props.subModule.subModuleTitle}
            </Link>
        </div>
    )
};

export default NavLinkComponent