import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';

const ConfirmationModal = ({showModal, handleClose, submitForm, flag = true, messageValue = ""}) => {
    return (
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            {!flag &&
            <Modal.Body>
                <div>Once survey is submitted, nothing can be change. Link will be deactivated.</div>
                <div>Do you want to submit the survey?</div>
            </Modal.Body>}
            {flag &&
            <Modal.Body>
                <div>
                    {messageValue ?
                        messageValue :
                        "Data might be linked to another screen for displaying values. If it is deactivated, it will no longer be visible. Do you want to submit the data?"
                    }
                </div>
            </Modal.Body>}
            {!flag &&
            <Modal.Footer>
                <Button className="buttonCss" onClick={handleClose}>
                    Close
                </Button>
                <Button className="buttonCss" onClick={submitForm}>Ok</Button>
            </Modal.Footer>
            }
            {flag &&
            <Modal.Footer>
                <Button className="buttonCss" onClick={submitForm}>Yes</Button>
                <Button className="buttonCss" onClick={handleClose}>
                    No
                </Button>
            </Modal.Footer>
            }
        </Modal>
    );
};

export default ConfirmationModal;