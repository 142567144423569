import { createSlice } from '@reduxjs/toolkit'
const initialState = [];
export const ImageOfChartReducer = createSlice({
    name: "ImageOfChartReducer",
    initialState,
    reducers: {
        updateChartData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateChartData } = ImageOfChartReducer.actions;

export default ImageOfChartReducer.reducer;