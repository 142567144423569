import React from "react";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const PlantQAConfigForm = (props) => {
    return (
        <form onSubmit={props.submitHandler} className="formContainer w-100 p-3">
            <div className="form-group row mb-1">
                <label htmlFor="item" className="col-sm-4 col-form-label"><span className="text-danger starCss">*</span>Item Category</label>
                <div className="col">
                    <select value={props.formValue.item} onChange={props.handleChange} name="item"
                            className={`form-select ${!props.inputValidation.item ? "errorDiv" : ""}`}
                    disabled={props.readOnly}>
                        <option value="select">Select</option>
                        {
                            props.itemCategoryList && props.itemCategoryList.length
                            && props.itemCategoryList.map((obj, index) => {
                                return <option key={obj.value + index} value={obj.value}>{obj.value}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="form-group row mb-1">
                <label htmlFor="count" className="col-sm-4 col-form-label"><span className="text-danger starCss">*</span>Required Inspections</label>
                <div className="col">
                    <input type="number" className={`form-control ${!props.inputValidation.count ? "errorDiv" : ""}`}
                           name="count" onChange={props.handleChange} value={props.formValue.count}/>
                </div>
            </div>
            <div className="row mx-auto form">
                <Col>
                    <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default PlantQAConfigForm;