import React from "react";
import {useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";

function BodyContainer() {
    const showSideNav = useSelector((state) => state.header.showSideNav);

    return (
        <>
            <Row className="bodyContainer">
                {
                    showSideNav && <Col sm={2} className="sidebarCol">
                        <Sidebar/>
                    </Col>
                }
                <Col sm={!showSideNav ? 12 : 10} className="border-4 border-primary mainCol">
                    <Outlet />
                </Col>
            </Row>
        </>
    );
}

export default BodyContainer;