import {
    convertStringToNumber,
    searchArray,
    searchArrayWithActive,
    sortArrayOfObject,
    sortArrayOfObjectByNumber
} from "./CommonFunction";
import {getDateInMonth, getDateInUTCByMonth} from "./DateFormat";
import React from "react";

export const surveyReportsData = (response, siteData, surveyName) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            if (objectData.survey === surveyName) {
                let siteObject = searchArray(objectData.site, siteData, "site");

                let obj = {
                    "Plant": siteObject.plant,
                    "Plant HC": siteObject.plantHc,
                    "Site Id": siteObject.siteId,
                    "Site": siteObject.site,
                    "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                    "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                };

                if (objectData.surveyDetail && objectData.surveyDetail.length) {
                    objectData.surveyDetail.map(surveyObj => {
                        let surveyDetail = Object.assign({}, surveyObj);

                        if (surveyDetail && surveyDetail.fieldName && surveyDetail.value) {
                            if (surveyDetail.fieldName === "Date") {
                                obj[surveyDetail.fieldName] = getDateInUTCByMonth(surveyDetail.value);
                            } else {
                                obj[surveyDetail.fieldName] = surveyDetail.value;
                            }
                        }
                    });
                }

                reportData.push(obj);
            }
        });
    }

    return reportData;
};

export const scorecardReportData = (response, siteData) => {
    let reportData = [];
    if (response && response.length) {
        response.forEach(obj => {
            let objectData = Object.assign({}, obj);
            let siteObject = searchArray(objectData.site, siteData, "site");
            let data = {
                "Plant": siteObject.plant,
                "Plant HC": siteObject.plantHc,
                "Site Id": siteObject.siteId,
                "Site": siteObject.site,
                "Survey Date": objectData.dateOfSurvey,
                "Soil Clean%": objectData.soilCleanRatio,
                "Total Number of Deliveries": objectData.totalDeliveries,
                "Number of Late Deliveries": objectData.lateDeliveries,
                "On Time Delivery%": objectData.onTimeDeliveryPercent,
                "Fill Rate %": objectData.fillRate,
                "User Id": objectData.userId,
                "User Name": objectData.username,
                "Date Range": objectData.dateRange,
            };
            reportData.push(data);
        });
    }

    return reportData;
};

export const utilizationReportData = (response, siteData) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(obj => {
            let objectData = Object.assign({}, obj);
            let siteObject = searchArray(objectData.site, siteData, "site");
            let data = {
                "Plant": siteObject.plant,
                "Plant HC": siteObject.plantHc,
                "Site Id": siteObject.siteId,
                "Site": siteObject.site,
                "Survey Date": objectData.dateOfSurvey,
                "Adjusted Patient Days": objectData.patientDaysAdjusted,
                "Patient Days": objectData.patientDays,
                "Total Clean Pounds": objectData.totalCleanPounds,
                "Total Invoice Amount%": objectData.totalInvoice,
                "User Id": objectData.userId,
                "User Name": objectData.username,
                "Date Range": objectData.dateRange,
                "Pd Rqd.": !!siteObject.pdRqd,
                "Adp Rqd.": !!siteObject.apdRqd,
                "Clean Lbs.": !!siteObject.cleanLbs,
                "Inv$": !!siteObject.inv
            };
            reportData.push(data);
        });
    }

    return reportData;
};

export const masterReportData = (response) => {
    let reportData = [];

    response.forEach(siteObject => {
        let objectData = Object.assign({}, siteObject);
        let obj = {
            "Plant": objectData.plant,
            "Plant HC": objectData.plantHc,
            "Site Id": objectData.siteId,
            "Site": objectData.site,
            "Site Status": objectData.siteStatus,
            "CLS Division": objectData.division,
            "CLS Region": objectData.region,
            "Sam": objectData.sam,
            "Annual Volume": objectData.annualVolume,
            "Security Group": objectData.idnAffiliation,
            "Visit Frequency": objectData.visitFrequency,
            "CV": objectData.requiredCV,
            "CS": objectData.requiredCS,
            "EU": objectData.requiredEU,
            "QA": objectData.requiredQA,
            "PAR": objectData.requiredPAR,
            "LL": objectData.requiredLL,
            "LA Committee": objectData.LArequiredCommittee,
            "LCCommittee": objectData.LCrequiredCommittee,
            "QBRs": objectData.requiredQBRs,
            "Add On Sales": objectData.addOnSales,
            "DVP": objectData.dvp,
            "RM": objectData.rm,
            "GM": objectData.gm,
            "TraumaLevel": objectData.traumaLevel,
            "ContactName1": objectData.contactName1,
            "ContactEmail 1": objectData.contactEmail1,
            "Contact Phone 1": objectData.contactPhone1,
            "ContactName 2": objectData.contactName2,
            "ContactEmail 2": objectData.contactEmail2,
            "ContactPhone 2": objectData.contactPhone2,
            "ContactName 3": objectData.contactName3,
            "ContactEmail 3": objectData.contactEmail3,
            "ContactPhone 3": objectData.contactPhone3,
            "ContactName 4": objectData.contactName4,
            "ContactEmail 4": objectData.contactEmail4,
            "ContactPhone 4": objectData.contactPhone4,
            "ContactName 5": objectData.contactName5,
            "ContactEmail 5": objectData.contactEmail5,
            "ContactPhone 5": objectData.contactPhone5
        };

        reportData.push(obj);
    });

    return reportData;
};

export const plantQAEntriesReportData = (response, imperfectionList) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(objData => {
            let objectData = Object.assign({}, objData);

            let data = {
                "Plant": objectData.plant,
                "Plant HC": objectData.plantHc,
                "Username": objectData.username,
                "Item Type": objectData.itemCategory,
                "Count Inspected": objectData.inspected,
                "Date Entered": getDateInUTCByMonth(objectData.date)
            };

            imperfectionList.map((imperfectionObj, index) => {
                let searchItem = searchArray(imperfectionObj.value, objData.imperfectionArray, "imperfectTitle");

                data["Imperfection Type" + index] = searchItem ? searchItem.imperfectTitle : "";
                data["Count" + index] = searchItem ? convertStringToNumber(searchItem.value) : "";
            });
            reportData.push(data);
        });
    }

    return reportData;
};

export const plantQAConfigurationReportData = (response, itemList) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(objData => {
            let objectData = Object.assign({}, objData);

            let obj = {
                "Plant": objectData.name,
                "Plant HC": objectData.houseCode,
                "Date Configured": getDateInUTCByMonth(objectData.date),
            };

            itemList.forEach((item, index) => {
                let searchItem = searchArray(item.value, objData.inspections, "item");

                obj["Inspection" + index] = searchItem ? searchItem.item : "";
                obj["Count" + index] = searchItem ? searchItem.count : "";
            });
            reportData.push(obj);
        });
    }

    return reportData;
};

export const userReportData = (response) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(obj => {
            let objectData = Object.assign({}, obj);
            let data = {
                "User Id": objectData.uniqueId,
                "FirstName": objectData.firstName,
                "LastName": objectData.lastName,
                "Email": objectData.email,
                "Security Role": objectData.securityRole,
                "IDN System": objectData.idnAffiliation,
                "Surveys Group": objectData.surveysGroup,
                "Active": objectData.active,
                "ExternalUser": objectData.externalUser,
                "Default Screen": objectData.defaultScreen,
                "PhoneNo": objectData.phoneNo,
                "Comments": objectData.comments,
            };
            reportData.push(data);
        });
    }

    return reportData;
};

export const incompleteSurveyData = (response, surveyList) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);
            let surveyObject = searchArrayWithActive(objectData.survey, surveyList, "title", "sendEmail");
            let obj = {
                "button": surveyObject.sendEmail,
                "surveyId": objectData._id ? objectData._id : "",
                "Survey Type": objectData.survey,
                "Date": getDateInUTCByMonth(objectData.createdAt),
                "Contact": objectData.userContactName ? objectData.userContactName[0] : "",
                "Contact Email": objectData.clientEmail,
            };

            reportData.push(obj);
        });
    }

    return reportData;
};

export const completedSurveyData = (response, siteData, surveyName) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            let obj = {
                "Survey Type": objectData.survey,
                "Date": getDateInUTCByMonth(objectData.createdAt),
                "Contact": objectData.userContactName ? objectData.userContactName[0] : "",
                "Contact Email": objectData.clientEmail,
                "Completion Date": getDateInUTCByMonth(objectData.surveyDate)
            };

            objectData.surveyDetail.forEach((surveyDetailObj) => {
                if (surveyDetailObj.type !== "Date") {
                    obj[surveyDetailObj.fieldName] = surveyDetailObj.value;
                }
            });
            reportData.push(obj);
        });
    }

    return reportData;
};

export const surveyReportData = (response, configTitle) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            let obj = {
                "Plant": objectData.plant,
                "Site": objectData.site,
                "Date": getDateInMonth(objectData.surveyDate),
            };

            objectData.surveyDetail.forEach((surveyDetailObj) => {
                if (configTitle.includes(surveyDetailObj.fieldName)) {
                    if (surveyDetailObj.type === "Date") {
                        obj[surveyDetailObj.fieldName] = getDateInUTCByMonth(surveyDetailObj.value);
                    } else {
                        obj[surveyDetailObj.fieldName] = surveyDetailObj.value;
                    }
                }
            });
            reportData.push(obj);
        });
    }

    return reportData;
};

export const userLogsReportData = (response) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            let obj = {
                "User Id": objectData.body.id,
                "Access URL": "Login",
                "Date": getDateInUTCByMonth(objectData.date),
            };

            reportData.push(obj);
        });
    }

    return reportData;
};

export const errorLogsReportData = (response) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            let obj = {
                "Date": getDateInUTCByMonth(objectData.date),
                "Method": objectData.method,
                "Access URL": objectData.url,
                "Error": objectData.error,
                "Headers": objectData.headers,
            };
            reportData.push(obj);
        });
    }

    return reportData;
};

export const getSurveyReportHeader = (response, surveyName) => {
    let reportData = [
        {
            name: "Plant",
            header: "Plant"
        },
        {
            name: "Plant HC",
            header: "Plant HC"
        },
        {
            name: "Site Id",
            header: "Site Id"
        },
        {
            name: "Site",
            header: "Site"
        },
        {
            name: "Survey Date",
            header: "Survey Date"
        },
        {
            name: "Survey Status",
            header: "Survey Status"
        }
    ];

    if (response && response.length) {
        response.forEach(responseObj => {
            if (responseObj.title === surveyName) {
                let objectData = Object.assign({}, responseObj);

                objectData.questions.forEach((surveyDetailObj) => {
                    if (surveyDetailObj.type !== "Date" && surveyDetailObj.active) {

                        let obj = {
                            name: surveyDetailObj.fieldName,
                            header: surveyDetailObj.title
                        };
                        reportData.push(obj)
                    }
                });
            }
        });
    }

    return reportData;
};

export const getSurveyReportHeaderFromQuestions = (survey) => {
    let reportData = [
        {
            name: "Plant",
            header: "Plant"
        },
        {
            name: "Plant HC",
            header: "Plant HC"
        },
        {
            name: "Site Id",
            header: "Site Id"
        },
        {
            name: "Site",
            header: "Site"
        },
        {
            name: "Survey Date",
            header: "Survey Date"
        },
        {
            name: "Survey Status",
            header: "Survey Status"
        }
    ];

    if (survey && survey.questions && survey.questions.length) {
        survey.questions.forEach((surveyDetailObj) => {
            if (surveyDetailObj.type !== "Date" && surveyDetailObj.active) {

                let obj = {
                    name: surveyDetailObj.fieldName,
                    header: surveyDetailObj.title
                };
                reportData.push(obj)
            }
        });
    }

    return reportData;
};

export const getOnlySurveyReportHeader = (response, surveyName) => {
    let reportData = [
        {
            name: "Survey Date",
            header: "Survey Date"
        }
    ];

    if (response && response.length) {
        response.forEach(responseObj => {
            if (responseObj.title === surveyName) {
                let objectData = Object.assign({}, responseObj);

                let surveyQuestions = sortArrayOfObjectByNumber(objectData.questions, "order");

                surveyQuestions.forEach((surveyDetailObj) => {
                    if (surveyDetailObj.type !== "Date" && surveyDetailObj.active && surveyDetailObj.isRequire) {

                        let obj = {
                            name: surveyDetailObj.fieldName,
                            header: surveyDetailObj.title
                        };
                        reportData.push(obj)
                    }
                    if (surveyDetailObj.type !== "Date" && surveyDetailObj.active && (surveyDetailObj.fieldName == "Summary" || surveyDetailObj.fieldName == "Comments")) {

                        let obj = {
                            name: surveyDetailObj.fieldName,
                            header: surveyDetailObj.title
                        };
                        reportData.push(obj)
                    }
                });
            }
        });
    }

    return reportData;
};

export const getSurveyReportBody = (response, siteData, surveyName, key, sites, linenItem, linenImperfection) => {
    let reportData = [];

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            let flag = sites.includes(responseObj[key]);
            if (objectData.survey === surveyName && flag && objectData.status === 2) {

                if(linenItem && linenItem.length) {
                    let flag = false;
                    objectData.surveyDetail.forEach((surveyDetailObject) => {
                        if (surveyDetailObject.fieldName === "Item") {
                            flag = linenItem.includes(surveyDetailObject.value);
                        }
                    });

                    if (flag) {
                        let siteObject = searchArray(objectData.site, siteData, "site");
                        let obj = {
                            "Plant": siteObject.plant,
                            "Plant HC": siteObject.plantHc,
                            "Site Id": siteObject.siteId,
                            "Site": siteObject.site,
                            "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                            "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                        };

                        if (objectData.surveyDetail && objectData.surveyDetail.length) {
                            objectData.surveyDetail.map(surveyObj => {
                                let surveyDetail = Object.assign({}, surveyObj);

                                if (surveyDetail && surveyDetail.fieldName && surveyDetail.value) {
                                    if (surveyDetail.fieldName === "Pieces Inspected") {
                                        obj[surveyDetail.fieldName] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type !== "Number" && surveyDetail.type !== "Date") {
                                        obj[surveyDetail.fieldName] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type === "Number" && surveyDetail.fieldName !== "Pieces Inspected") {
                                        linenImperfection.forEach((linenImperfectionObj) => {
                                            if (surveyDetail.fieldName == linenImperfectionObj) {
                                                obj[surveyDetail.fieldName] = surveyDetail.value;                                            }
                                        });
                                    }
                                }
                            });
                        }



                        reportData.push(obj);
                    }
                } else {
                    let siteObject = searchArray(objectData.site, siteData, "site");
                    let obj = {
                        "Plant": siteObject.plant,
                        "Plant HC": siteObject.plantHc,
                        "Site Id": siteObject.siteId,
                        "Site": siteObject.site,
                        "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                        "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                    };

                    if (objectData.surveyDetail && objectData.surveyDetail.length) {
                        objectData.surveyDetail.map(surveyObj => {
                            let surveyDetail = Object.assign({}, surveyObj);

                            if (surveyDetail && surveyDetail.fieldName && surveyDetail.value) {
                                if (surveyDetail.type !== "Date") {
                                    obj[surveyDetail.fieldName] = surveyDetail.value;
                                }
                            }
                        });
                    }

                    reportData.push(obj);
                }
            }
        });
    }

    return reportData;
};

export const getOnSiteReport = (response, siteData, surveyName, survey) => {
    let reportData = [];

    let linenImperfection = [];
    let linenItem = [];
    let rowHeader = [];

    if (survey.questions && survey.questions.length) {
        (survey.questions).forEach((detail) => {
            if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected" && detail.active) {
                linenImperfection.push(detail.fieldName);
            }

            if (detail.fieldName === "Item" && detail.active) {
                detail.answers.forEach((answersObj) => {
                    linenItem.push(answersObj.displayValue);
                });

            }

            if (detail.active) {
                rowHeader.push(detail.fieldName);
            }
        });
    }

    let surveyHeader = getSurveyReportHeaderFromQuestions(survey);


    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            if (objectData.survey === surveyName && objectData.status === 2) {

                if(linenItem && linenItem.length) {
                    let linenItemFlag = false;
                    objectData.surveyDetail.forEach((surveyDetailObject) => {
                        if (surveyDetailObject.fieldName === "Item") {
                            linenItemFlag = linenItem.includes(surveyDetailObject.value);
                        }
                    });

                    if (linenItemFlag) {
                        let siteObject = searchArray(objectData.site, siteData, "site");
                        let obj = {
                            "Plant": siteObject.plant,
                            "Plant HC": siteObject.plantHc,
                            "Site Id": siteObject.siteId,
                            "Site": siteObject.site,
                            "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                            "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                        };

                        if (objectData.surveyDetail && objectData.surveyDetail.length) {
                            objectData.surveyDetail.map(surveyObj => {
                                let surveyDetail = Object.assign({}, surveyObj);
                                let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");


                                if (surveyDetail && surveyDetail.fieldName && surveyDetail.value && surveyFieldName) {
                                    if (surveyDetail.fieldName === "Pieces Inspected") {
                                        obj[surveyFieldName.header] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type !== "Number" && surveyDetail.type !== "Date") {
                                        obj[surveyFieldName.header] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type === "Number" && surveyDetail.fieldName !== "Pieces Inspected") {
                                        linenImperfection.forEach((linenImperfectionObj) => {
                                            if (surveyDetail.fieldName == linenImperfectionObj) {
                                                obj[surveyFieldName.header] = surveyDetail.value;                                            }
                                        });
                                    }
                                }
                            });
                        }

                        reportData.push(obj);
                    }
                }
            }
        });
    }

    return reportData;
};

export const getLinenQualityReport = (response, siteData, surveyName, survey) => {
    let reportData = [];
    /*let linenItem = [];
    let rowHeader = [];

    if (survey.questions && survey.questions.length) {
        (survey.questions).forEach((detail) => {
            if (detail.fieldName === "Department" && detail.active) {
                detail.answers.forEach((answersObj) => {
                    linenItem.push(answersObj.displayValue);
                });

            }

            if (detail.active) {
                rowHeader.push(detail.fieldName);
            }
        });
    }*/

    let surveyHeader = getSurveyReportHeaderFromQuestions(survey);

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);
            if (objectData.survey === surveyName && objectData.status === 2) {

                let siteObject = searchArray(objectData.site, siteData, "site");
                let obj = {
                    "Plant": siteObject.plant,
                    "Plant HC": siteObject.plantHc,
                    "Site Id": siteObject.siteId,
                    "Site": siteObject.site,
                    "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                    "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                };

                if (objectData.surveyDetail && objectData.surveyDetail.length) {
                    objectData.surveyDetail.map(surveyObj => {
                        let surveyDetail = Object.assign({}, surveyObj);
                        let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");


                        if (surveyDetail && surveyDetail.fieldName && surveyDetail.value && surveyFieldName) {
                            obj[surveyFieldName.header] = surveyDetail.value;
                        }
                    });
                }

                reportData.push(obj);
            }
        });
    }

    return reportData;
};

export const getClientStatReport = (response, siteData, surveyName, survey) => {
    let reportData = [];
    let linenItem = [];
    let rowHeader = [];

   /* if (survey.questions && survey.questions.length) {
        (survey.questions).forEach((detail) => {
            if (detail.fieldName === "Contact Title/Dept" && detail.active) {
                detail.answers.forEach((answersObj) => {
                    linenItem.push(answersObj.displayValue);
                });

            }

            if (detail.active) {
                rowHeader.push(detail.fieldName);
            }
        });
    }*/

    let surveyHeader = getSurveyReportHeaderFromQuestions(survey);


    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);
            if (objectData.survey === surveyName && objectData.status === 2) {
                let siteObject = searchArray(objectData.site, siteData, "site");
                let obj = {
                    "Plant": siteObject.plant,
                    "Plant HC": siteObject.plantHc,
                    "Site Id": siteObject.siteId,
                    "Site": siteObject.site,
                    "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                    "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                };

                if (objectData.surveyDetail && objectData.surveyDetail.length) {
                    objectData.surveyDetail.map(surveyObj => {
                        let surveyDetail = Object.assign({}, surveyObj);
                        let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");


                        if (surveyDetail && surveyDetail.fieldName && surveyDetail.value && surveyFieldName) {
                            obj[surveyFieldName.header] = surveyDetail.value;
                        }
                    });
                }

                reportData.push(obj);
            }
        });
    }

    return reportData;
};

export const getOnSiteTableData = (response, siteData, surveyName, survey) => {
    let reportData = [];

    let linenImperfection = [];
    let linenItem = [];
    let rowHeader = [];

    if (survey.questions && survey.questions.length) {
        (survey.questions).forEach((detail) => {
            if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected" && detail.active) {
                linenImperfection.push(detail.fieldName);
            }

            if (detail.fieldName === "Item" && detail.active) {
                detail.answers.forEach((answersObj) => {
                    linenItem.push(answersObj.displayValue);
                });

            }

            if (detail.active) {
                rowHeader.push(detail.fieldName);
            }
        });
    }

    let surveyHeader = getSurveyReportHeaderFromQuestions(survey);


    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);

            if (objectData.survey === surveyName && objectData.status === 2) {

                if(linenItem && linenItem.length) {
                    let linenItemFlag = false;
                    objectData.surveyDetail.forEach((surveyDetailObject) => {
                        if (surveyDetailObject.fieldName === "Item") {
                            linenItemFlag = linenItem.includes(surveyDetailObject.value);
                        }
                    });

                    if (linenItemFlag) {
                        let siteObject = searchArray(objectData.site, siteData, "site");
                        let obj = {
                            "Plant": siteObject.plant,
                            "Plant HC": siteObject.plantHc,
                            "Site Id": siteObject.siteId,
                            "Site": siteObject.site,
                            "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                            "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                        };

                        if (objectData.surveyDetail && objectData.surveyDetail.length) {
                            objectData.surveyDetail.map(surveyObj => {
                                let surveyDetail = Object.assign({}, surveyObj);
                                let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");


                                if (surveyDetail && surveyDetail.fieldName && surveyFieldName) {
                                    if (surveyDetail.fieldName === "Pieces Inspected") {
                                        obj[surveyFieldName.header] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type !== "Number" && surveyDetail.type !== "Date") {
                                        obj[surveyFieldName.header] = surveyDetail.value;
                                    }

                                    if (surveyDetail.type === "Number" && surveyDetail.fieldName !== "Pieces Inspected") {
                                        linenImperfection.forEach((linenImperfectionObj) => {
                                            if (surveyDetail.fieldName == linenImperfectionObj) {
                                                obj[surveyFieldName.name] = surveyDetail.value;                                            }
                                        });
                                    }
                                }
                            });
                        }

                        reportData.push(obj);
                    }
                }
            }
        });
    }

    return reportData;
};

export const getCommonTableData = (response, siteData, surveyName, survey) => {
    let reportData = [];
    let surveyHeader = getSurveyReportHeaderFromQuestions(survey);

    if (response && response.length) {
        response.forEach(responseObj => {
            let objectData = Object.assign({}, responseObj);
            if (objectData.survey === surveyName && objectData.status === 2) {

                let siteObject = searchArray(objectData.site, siteData, "site");
                let obj = {
                    "Plant": siteObject.plant,
                    "Plant HC": siteObject.plantHc,
                    "Site Id": siteObject.siteId,
                    "Site": siteObject.site,
                    "Survey Date": getDateInUTCByMonth(objectData.surveyDate),
                    "Survey Status": objectData.status === 2 ? "Completed" : "Not Completed"
                };

                if (objectData.surveyDetail && objectData.surveyDetail.length) {
                    objectData.surveyDetail.map(surveyObj => {
                        let surveyDetail = Object.assign({}, surveyObj);
                        let surveyFieldName = searchArray(surveyDetail.fieldName, surveyHeader, "name");


                        if (surveyDetail && surveyDetail.fieldName && surveyFieldName) {
                            obj[surveyFieldName.name] = surveyDetail.value;                         }
                    });
                }

                reportData.push(obj);
            }
        });
    }

    return reportData;
};