import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Modal from 'react-bootstrap/Modal';
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import PlantReportModalForm from "./PlantReportModalForm";
import Message from "../../commom/Message";
import Loader from "../../commom/LoaderComponent";
import {
    getAllArrayValue,
    getArrayValue,
    getReportName, getSelectedAllFlag,
    getSelectedPlantConfigArray, searchArrayWithActive, searchArrayWithActiveAndShow,
    updateMultiSelectSearch
} from "../../commom/CommonFunction";
import {exportSurveyData} from "../../commom/ExportToExcel";
import {
    getClientStatReport, getLinenQualityReport, getOnSiteReport,
    getSurveyReportBody,
    masterReportData,
    scorecardReportData,
    surveyReportsData,
    utilizationReportData
} from "../../commom/CommonReportFunction";
import {useOuterClick} from "../../hook/VisibilityHook";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {getDateInUTC} from "../../commom/DateFormat";

const PlantReportModal = ({ showPlantModal, handleClose, isFacilityView, samValue, surveysArray= [] }) => {
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [reportOptionList, setReportOptionList] = useState();
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [plantList, setPlantList] = useState("");
    const [siteList, setSiteList] = useState("");
    const [sitesList, setSitesList] = useState("");
    const [formValue, setFormValue] = useState({
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
        showSite: false,
        selectAllSite: false,
        siteCount: 0,
        showReportOption: false,
        selectAllReportOption: false,
        reportOptionCount: 0,
    });
    const [reportDropdown, setReportDropdown] = useState("");
    const innerRef = useOuterClick(() => {
        setFormValue({...formValue, showPlant: false});
    });
    const innerRefSite = useOuterClick(() => {
        setFormValue({...formValue, showSite: false});
    });
    const innerRefReportOption = useOuterClick(() => {
        setFormValue({...formValue, showReportOption: false,});
    });
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [inputValidation, setInputValidation] = useState({
        plantCount: true,
        reportOptionCount: true
    });
    const fieldValidation = {
        plantCount: "digit",
        reportOptionCount: "digit"
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const plantData = useSelector((state) => state.plantReducer);
    const siteData = useSelector((state) => state.siteReducer);
    const frequencyData = useSelector((state) => state.frequencyReducer);
    const surveyData = useSelector((state) => state.SurveyReducer);

    useEffect(() => {
        if(frequencyData && surveysArray && surveysArray.length > 0) {

            setReportDropdown(updateMultiSelectFilteredOption(frequencyData, "Report Option", surveysArray));
            setReportOptionList(updateMultiSelectFilteredOption(frequencyData, "Report Option", surveysArray));
        }
        setPlantList(updateMultiSelectOption(plantData, "plant"));
    }, [frequencyData, surveysArray]);

    const updateMultiSelectOption = (fetchedValue, label) => {
        return fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });
    };

    const updateMultiSelectFilteredOption = (fetchedValue, filterValue, surveyData) => {
        let filteredArray = [];
        let reportArray = [];
        let surveys = surveyData;
        let reports = [];
        fetchedValue.forEach((obj) => {
            let data = Object.assign({}, obj);

            if (data.type === filterValue) {
                reports.push(data.value);
            }
        });

        if (reports && reports.length) {
            reports.forEach((value) => {
                if (value == 'Activity Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Activities", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Activity Raw Data");
                    }
                } else if (value == 'Add On Sales Data') {
                    let survey = searchArrayWithActiveAndShow("Add On Sales", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Add On Sales Data");
                    }
                } else if (value == 'Client Sat Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Client Satisfaction", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Client Sat Raw Data");
                    }
                } else if (value == 'Linen Quality & Availability Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Quality & Availability", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Quality & Availability Raw Data");
                    }
                } else if (value == 'Linen Awareness Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Awareness", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Awareness Data");
                    }
                } else if (value == 'Linen Committee Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Committee", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Committee Data");
                    }
                } else if (value == 'Linen Loss Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Loss", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Loss Raw Data");
                    }
                } else if (value === 'Master Data Raw Data') {
                    reportArray.push("Facility Master Data");
                } else if (value == 'PAR Raw Data') {
                    let survey = searchArrayWithActiveAndShow("PAR", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("PAR Raw Data");
                    }
                } else if (value == 'Onsite Linen Inspection Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Onsite Linen Inspection", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Onsite Linen Inspection Raw Data");
                    }
                } else if (value == 'QBR Data') {
                    let survey = searchArrayWithActiveAndShow("QBR", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("QBR Data");
                    }
                } else if (value === 'Scorecard') {
                    //reportArray.push("Scorecard");
                } else if (value === 'Utilization') {
                    //reportArray.push("Utilization");
                } else if (value == 'Sub Contractor Survey Data') {
                    let survey = searchArrayWithActiveAndShow("Subcontractor End User", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Sub Contractor Survey Data");
                    }
                } else {
                    let surveyTitle = value.replace(/\s*Data\s*/, '');

                    let survey = searchArrayWithActiveAndShow(surveyTitle, surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push(value);
                    }
                }
            });
        }
        reportArray.forEach((obj) => {
            filteredArray.push({
                label: obj,
                value: false
            });
        });

        if(filteredArray.length) {
            filteredArray.sort(function (a, b) {
                return (a["label"]).localeCompare(b["label"])
            });

            return filteredArray;
        } else {
            return "";
        }
    };

    const getSites = (plantName) => {
        let sites = [];

        if (plantName && plantName.length) {
            siteData.forEach(obj => {
                let plantObj = Object.assign({}, obj);
                if (plantName.indexOf(plantObj.plant) !== -1) {
                    sites.push({
                        label: plantObj.site,
                        value: false
                    });
                }
            });
        }

        return sites;
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        setShowMessage(false);

        if (name === "reportOption") {
            setReportOptionList(newData);
            setFormValue({...formValue, selectAllReportOption: checked, reportOptionCount: count});

            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "reportOptionCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        } else if (name === "plant") {
            setPlantList(newData);
            let plantNames = getArrayValue(newData);
            let data = getSites(plantNames);
            setSiteList(data);
            setSitesList(data);
            setFormValue({
                ...formValue,
                selectAllPlant: checked,
                plantCount: count,
                selectAllSite: false,
                siteCount: 0
            });

            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "plantCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        } else if (name === "site") {
            setSiteList(newData);
            setFormValue({...formValue, selectAllSite: checked, siteCount: count});
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "reportOption") {
            let flag = formValue.showReportOption;

            if(!flag) {
                let data = updateMultiSelectSearch(reportDropdown, "label", "", reportOptionList);
                setReportOptionList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showReportOption: !flag, selectAllReportOption: selectedAll});
            } else {
                setFormValue({...formValue, showReportOption: !flag});
            }
        } else if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        } else if (name === "site") {
            let flag = formValue.showSite;

            if(!flag) {
                let data = updateMultiSelectSearch(sitesList, "label", "", siteList);
                setSiteList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showSite: !flag, selectAllSite: selectedAll});
            } else {
                setFormValue({...formValue, showSite: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "reportOption") {
            setFormValue({...formValue, selectAllReportOption: false});
            let data = updateMultiSelectSearch(reportDropdown, "label", value, reportOptionList);
            setReportOptionList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllReportOption: selectedAll});
        } else if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        } else if (name === "site") {
            let data = updateMultiSelectSearch(sitesList, "label", value, siteList);
            setSiteList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllSite: selectedAll});
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        let surveys = getReportName(reportOptionList);
        let sites;
        let selectedSiteDate;

        if (isFacilityView) {
            const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
            setFormValidation(checkValidate);
            setInputValidation(currentFormState);
            setDisableSubmit(true);
            if (!checkValidate) {
                return false;
            }
            selectedSiteDate = getArrayValue(siteList);
            if (surveys && !surveys.length) {
                setDisableSubmit(true);
                return false;
            }

            if (selectedSiteDate) {
                sites = selectedSiteDate;
            } else {
                sites = getAllArrayValue(siteList);

                if (!sites) {
                    setDisableSubmit(true);
                    return false;
                }
            }
        } else {
            const {checkValidate, currentFormState} = validateFormOnSave(formValue, {"reportOptionCount": true}, {"reportOptionCount": "digit"});
            setFormValidation(checkValidate);
            setInputValidation(currentFormState);
            setDisableSubmit(true);
            if (!checkValidate) {
                return false;
            }
            sites = samValue;
        }

        let siteNewData = [];
        siteData.forEach((siteObj) => {
            let data = sites.indexOf(siteObj.site);

            if (data !== -1) {
                siteNewData.push(siteObj);
            }
        });

        let dataExportByAttribute = {};

        if(surveys.indexOf("Client Satisfaction") !== -1) {
            surveys.push("Client Satisfaction - No distribution");
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getReportForLatestSurvey',
            requestConfig: {
                data: {
                    "sites": sites,
                    "survey": surveys,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        setShowMessage(true);

        surveys.forEach((value) => {
            let surveyObject = searchArrayWithActive(value, surveysArray, "title", "active");
            if (value === 'Activities') {
                dataExportByAttribute["ActivityRawData"] = getLinenQualityReport(response.data, siteData, "Activities", surveyObject);
            } else if (value === 'Add On Sales') {
                dataExportByAttribute["AddOnSalesData"] = getLinenQualityReport(response.data, siteData, "Add On Sales", surveyObject);
            } else if (value === 'Client Satisfaction') {
                let clientSatRawData = getClientStatReport(response.data, siteData, "Client Satisfaction", surveyObject);
                let clientSatRawDataWD = getLinenQualityReport(response.data, siteData, "Client Satisfaction - No distribution", surveyObject);
                dataExportByAttribute["ClientSatRawData"] = [...clientSatRawData, ...clientSatRawDataWD];
            } else if (value === 'Linen Quality & Availability') {
                dataExportByAttribute["LinenQuality&Availability"] = getLinenQualityReport(response.data, siteData, "Linen Quality & Availability", surveyObject);
            } else if (value === 'Linen Awareness') {
                dataExportByAttribute["LinenAwarenessData"] = getLinenQualityReport(response.data, siteData, "Linen Awareness", surveyObject);
            } else if (value === 'Linen Committee') {
                dataExportByAttribute["LinenCommitteeData"] = getLinenQualityReport(response.data, siteData, "Linen Committee", surveyObject);
            } else if (value === 'Linen Loss') {
                dataExportByAttribute["LinenLossRawData"] = getLinenQualityReport(response.data, siteData, "Linen Loss", surveyObject);
            } else if (value === 'Facility Master Data') {
                dataExportByAttribute["MasterDataRawData"] = masterReportData(siteNewData);
            } else if (value === 'PAR') {
                dataExportByAttribute["PARRawData"] = getLinenQualityReport(response.data, siteData, "PAR", surveyObject);
            } else if (value === "Onsite Linen Inspection") {
                dataExportByAttribute["OnsiteLinenInspectionRawData"] = getOnSiteReport(response.data, siteData, "Onsite Linen Inspection", surveyObject);
            } else if (value === 'QBR') {
                dataExportByAttribute["QBRData"] = getLinenQualityReport(response.data, siteData, "QBR", surveyObject);
            } else if (value === 'Subcontractor End User') {
                dataExportByAttribute["SubContractorSurveyData"] = getLinenQualityReport(response.data, siteData, "Subcontractor End User", surveyObject);
            } else if (value !== "Client Satisfaction - No distribution") {
                let keyTitle = value;

                if(value.length > 25) {
                    keyTitle = value.slice(0, 30);
                }
                dataExportByAttribute[keyTitle] = getLinenQualityReport(response.data, siteData, value, surveyObject);
            }
        });

        if (surveys.indexOf("Scorecard") !== -1) {
            const scorecard = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getScorecardPlantMapping',
                requestConfig: {
                    data: {
                        "site": sites,
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            dataExportByAttribute["Scorecard"] = scorecardReportData(scorecard.data, siteData);
        }

        if (surveys.indexOf("Utilization") !== -1) {
            const utilization = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getUtilizationPlantMapping',
                requestConfig: {
                    data: {
                        "site": sites,
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            dataExportByAttribute["Utilization"] = utilizationReportData(utilization.data, siteData);
        }

        exportSurveyData(dataExportByAttribute, "surveyData.xlsx");
        setDisableSubmit(true);
    };

    const closeModalBox = () => {
        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"plantCount": true, "reportOptionCount": true});
        setFormValue({
            showPlant: false,
            selectAllPlant: false,
            plantCount: 0,
            showSite: false,
            selectAllSite: false,
            siteCount: 0,
            showReportOption: false,
            selectAllReportOption: false,
            reportOptionCount: 0,
        });
        setReportOptionList(updateMultiSelectFilteredOption(frequencyData, "Report Option"));
        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setSiteList("");
        handleClose();
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={showPlantModal} onHide={closeModalBox} backdrop="static"
                   aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message="Exported Data Successfully"/>
                    <PlantReportModalForm reportOptionList={reportOptionList} plantList={plantList} siteList={siteList}
                                          formValue={formValue} submitHandler={submitHandler}
                                          handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                          handleSearch={handleSearch} innerRef={innerRef} innerRefSite={innerRefSite}
                                          innerRefReportOption={innerRefReportOption} disableSubmit={disableSubmit}
                                          isFacilityView={isFacilityView} inputValidation={inputValidation}
                                          response={response} error={error}
                                          loading={loading} showMessage={showMessage} formValidation={formValidation}/>

                </Modal.Body>
            </Modal>
        </>
    )
};

export default PlantReportModal;