import React from "react";
import Row from "react-bootstrap/Row";

function Message(props) {
    return (
        <>
            {props.showMessage && <Row className="messageHeaderRow">
                {props.error.message &&
                <div className="messageRow bg-danger">
                    {props.error.message ? props.error.message : "Something went wrong. Please try again."}
                </div>
                }
                {console.log(props)}
                {!props.error.message && props.response &&
                <div className="messageRow bg-success">
                    {props.message ? props.message : "Saved Successfully."}
                </div>
                }
            </Row>
            }
            {!props.formValidation &&
            <div className="formErrorDiv" role="alert">
                {props.formValidationMessage ? props.formValidationMessage :
                    "Please complete the * required field without using any of the following special characters: (){}[]."}
            </div>
            }
        </>
    );
}

export default Message;