import React from "react";
import {useSelector} from "react-redux";
import CustomTable from "../../commom/CustomTable";

function PlantQAConfigList(props) {
    const tableData = useSelector((state) => state.plantQAConfigReducer);

    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "item",
            header: "Item Category"
        },
        {
            name: "count",
            header: "Required Inspections",
            align: "center"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow}/>
    );
}

export default PlantQAConfigList;