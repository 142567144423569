import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducer/UserReducer';
import loginReducer from './reducer/LoginReducer';
import themeReducer from './reducer/ThemeReducer';
import sideBarReducer from "./reducer/SidebarReducer";
import roleReducer from "./reducer/RoleReducer";
import headerReducer from "./reducer/HeaderReducer";
import modalReducer from "./reducer/ModalReducer";
import frequencyReducer from "./reducer/FrequencyReducer";
import plantDropdownReducer from "./reducer/PlantDropdownReducer";
import plantReducer from "./reducer/PlantReducer";
import siteReducer from "./reducer/SiteReducer";
import idnReducer from "./reducer/IdnReducer";
import securityRoleReducer from "./reducer/SecurityRoleReducer";
import userManagementReducer from "./reducer/UserManagementReducer";
import surveyReducer from "./reducer/SurveyReducer";
import surveyQuestionReducer from "./reducer/SurveyQuestionReducer";
import surveyComputedReducer from "./reducer/SurveyComputedReducer";
import surveySelectionReducer from "./reducer/SurveySelectionReducer";
import surveySelectedQuestionReducer from "./reducer/SurveySelectedQuestionReducer";
import surveyDropdownReducer from "./reducer/SurveyDropdownReducer";
import surveyAnswerReducer from "./reducer/SurveyAnswerReducer";
import surveyGroupReducer from "./reducer/SurveyGroupReducer";
import plantQAConfigReducer from "./reducer/PlantQAConfigReducer";
import plantQualityAuditReducer from "./reducer/PlantQualityAuditReducer";
import dateRangeReducer from "./reducer/DateRangeReducer";
import plantNameReducer from "./reducer/PlantNameReducer";
import itemCategoryReducer from "./reducer/ItemCategoryReducer";
import plantQualityDashboardDataReducer from "./reducer/PlantQualityDashboardDataReducer";
import userRoleReducer from "./reducer/UserRoleReducer";
import scorecardReducer from "./reducer/ScorecardReducer";
import utilizationReducer from "./reducer/UtilizationReducer";
import facilitySiteReducer from "./reducer/FacilitySiteReducer";
import facilityPlantReducer from "./reducer/FacilityPlantReducer";
import showDateReducer from "./reducer/ShowDateReducer";
import tokenModalReducer from "./reducer/TokenModalReducer";
import hideMasterDropdownReducer from "./reducer/HideMasterDropdownReducer";
import reportOptionReducer from "./reducer/ReportOptionReducer";
import siteAllReducer from "./reducer/SiteAllReducer";
import dateAppliedReducer from "./reducer/DateAppliedReducer";
import customerStatReducer from "./reducer/CustomerStatReducer";
import defaultScreenReducer from "./reducer/DefaultScreenReducer";
import idealTimeOutReducer from "./reducer/IdealTimeOutReducer";
import imageOfChartReducer from "./reducer/ImageOfChartReducer";
import summaryOfDayScreenReducer from "./reducer/SummaryOfDayScreenReducer";
import samSummaryScreenReducer from "./reducer/SamSummaryScreenReducer";

export default configureStore({
    reducer: {
        loginState: loginReducer,
        userState: userReducer,
        theme: themeReducer,
        submodule: sideBarReducer,
        role: roleReducer,
        header: headerReducer,
        modalReducer: modalReducer,
        frequencyReducer: frequencyReducer,
        plantDropdownReducer: plantDropdownReducer,
        plantReducer: plantReducer,
        siteReducer: siteReducer,
        idnReducer: idnReducer,
        securityRoleReducer: securityRoleReducer,
        userManagementReducer: userManagementReducer,
        surveyReducer: surveyReducer,
        surveyQuestionReducer: surveyQuestionReducer,
        surveyComputedReducer : surveyComputedReducer,
        surveySelectionReducer: surveySelectionReducer,
        surveySelectedQuestionReducer: surveySelectedQuestionReducer,
        surveyDropdownReducer: surveyDropdownReducer,
        surveyAnswerReducer: surveyAnswerReducer,
        surveyGroupReducer: surveyGroupReducer,
        plantQAConfigReducer: plantQAConfigReducer,
        plantQualityAuditReducer: plantQualityAuditReducer,
        dateRangeReducer: dateRangeReducer,
        plantNameReducer: plantNameReducer,
        itemCategoryReducer: itemCategoryReducer,
        plantQualityDashboardDataReducer: plantQualityDashboardDataReducer,
        userRoleReducer: userRoleReducer,
        scorecardReducer: scorecardReducer,
        utilizationReducer: utilizationReducer,
        facilitySiteReducer: facilitySiteReducer,
        facilityPlantReducer: facilityPlantReducer,
        showDateReducer: showDateReducer,
        tokenModalReducer: tokenModalReducer,
        hideMasterDropdownReducer: hideMasterDropdownReducer,
        reportOptionReducer: reportOptionReducer,
        siteAllReducer: siteAllReducer,
        dateAppliedReducer: dateAppliedReducer,
        customerStatReducer: customerStatReducer,
        defaultScreenReducer: defaultScreenReducer,
        idealTimeOutReducer: idealTimeOutReducer,
        imageOfChartReducer: imageOfChartReducer,
        summaryOfDayScreenReducer: summaryOfDayScreenReducer,
        samSummaryScreenReducer: samSummaryScreenReducer
    }
})