import React, {useContext, useEffect, useRef, useState} from 'react';
import "./FacilitySurveyDashboard.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {Link, useNavigate} from "react-router-dom";
import {
    convertToUTC, getCurrentDateOnly,
    getDateByMonth,
    getDateInUTC, getFourWeeksBackUTC,
    getMonthEndDate,
    getWeekRangeForGivenDate,
    getWeekRanges
} from "../../commom/DateFormat";
import {setSurveyData} from "../../reducer/SurveyReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {Button, InputGroup} from "react-bootstrap";
import Loader from "../../commom/LoaderComponent";
import {
    convertStringToNumber,
    determineRange, formatNumberWithCommas, getArrayValue, getAvgOfNumberInIntFormat, getChartCount,
    getClientSatChartByWeek,
    getLinenQualityChartByDept, getLinenQualityChartByDeptTopBottom,
    getLinenQualityChartByWeek, getOnsiteLinenChartByItem, getSelectedAllFlag, getSelectedArray,
    getUserAccessToUrl,
    searchArray, searchArrayWithActive, sortArrayData,
    sortArrayOfObject, updateMultiSelectSearch
} from "../../commom/CommonFunction";
import FacilitySiteData from "./FacilitySiteData";
import Form from 'react-bootstrap/Form';
import {setSite} from "../../reducer/SiteReducer";
import {setAllSite} from "../../reducer/SiteAllReducer";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {list} from "../../reducer/FrequencyReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";
import ReportTableModal from "../reports/ReportTableModal";
import MultiColumnSelection from "../../commom/MultiColumnSelection";
import {handleCheckboxInputChange} from "../../commom/FormHandler";
import FacilityPlantData from "./FacilityPlantData";
import FacilityIdnData from "./FacilityIdnData";
import ChartFullScreenModal from "./ChartFullScreenModal";
import {useOuterClick} from "../../hook/VisibilityHook";
import MultiSelect from "../../commom/MultiSelect";
import {
    getSurveyReportBody,
    getSurveyReportHeader,
    masterReportData,
    surveyReportsData
} from "../../commom/CommonReportFunction";
import {exportSurveyData} from "../../commom/ExportToExcel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import PlantReportModal from "./PlantReportModal";

const FacilityScreen = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [surveyData, setSurveyDataArray] = useState("");
    const [surveyDataBySearch, setSurveyDataBySearch] = useState("");
    const navigate = useNavigate();
    const [showPlantModal, setShowPlantModal] = useState(false);
    const isDateChanged = useSelector((state) => state.dateAppliedReducer.isDateChanged);
    const [getAllSiteData, setAllSiteData] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState();
    const auth = useContext(AuthContext);

    const [sitesData, setSitesData] = useState();
    const [plantsData, setPlantsData] = useState();
    const [surveysData, setSurveysData] = useState();

    const [allsurveyList, setAllsurveyList] = useState();
    const [allsurveyLists, setAllsurveyLists] = useState();

    const [siteAllDataWithSurvey, setSiteAllDataWithSurvey] = useState("");
    const [sitesAllDataWithSurvey, setSitesAllDataWithSurvey] = useState("");
    const [siteDataWithSurvey, setSiteDataWithSurvey] = useState("");
    const [sitesDataWithSurvey, setSitesDataWithSurvey] = useState("");

    const [linenImperfection, setLinenImperfection] = useState();
    const [linenItem, setLinenItem] = useState("");

    const [showPlant, setShowPlant] = useState(false);
    const [showIdn, setShowIdn] = useState(false);
    const [showSiteWithoutData, setShowSiteWithoutData] = useState(false);

    const [showChartFullScreen, setShowChartFullScreen] = useState(false);
    const [chartFullScreenData, setChartFullScreenData] = useState({
        "keyValue": "",
        "keyDate": "",
        "value": ""
    });

    const [chartRowHeader, setChartRowHeader] = useState("");
    const [chartTableData, setChartTableData] = useState("");
    const [chartCount, setChartCount] = useState("");
    const [chartImperfection, setChartImperfection] = useState();
    const [chartFlaw, setChartFlaw] = useState();

    const [showChartModal, setShowChartModal] = useState(false);
    const [chartHeader, setChartHeader] = useState("");
    const [chartValue, setChartValue] = useState(1);

    const [plantAllDataWithSurvey, setPlantAllDataWithSurvey] = useState("");
    const [plantsAllDataWithSurvey, setPlantsAllDataWithSurvey] = useState("");
    const [plantDataWithSurvey, setPlantDataWithSurvey] = useState("");
    const [plantsDataWithSurvey, setPlantsDataWithSurvey] = useState("");

    const [idnAllDataWithSurvey, setIdnAllDataWithSurvey] = useState("");
    const [idnsAllDataWithSurvey, setIdnsAllDataWithSurvey] = useState("");
    const [idnDataWithSurvey, setIdnDataWithSurvey] = useState("");
    const [idnsDataWithSurvey, setIdnsDataWithSurvey] = useState("");

    const [sentimentDataWithSurvey, setSentimentDataWithSurvey] = useState("");
    const [sentimentsDataWithSurvey, setSentimentsDataWithSurvey] = useState("");

    const [idnList, setIdnList] = useState("");

    const [chartColumn, setChartColumn] = useState({
        chart1: true,
        chart2: true,
        chart3: true,
        chart4: true,
        chart5: true,
    });
    const [formValue, setFormValue] = useState({
        showDept: false,
        selectAllDept: false,
        deptCount: 0,
    });
    const [deptList, setDeptList] = useState("");
    const [deptLists, setDeptLists] = useState("");
    const [surveyDataExport, setSurveyDataExport] = useState("");

    const innerRef = useOuterClick(() => {
        setFormValue({...formValue, showDept: false});
    });

    useEffect(() => {
        dispatch(showDateInHeader(true));
        dispatch(setDateRange());

        getUserDetailWithSiteAndMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));

            let idnArray = [];
            if (response.idn && response.idn.length) {

                (response.idn).map((idnObj) => {
                    let idnObject = {
                        "idn": idnObj.idnAffiliation,
                        sites: [],
                        plants: []
                    };

                    idnObj.plants.forEach((idnPlantObj) => {
                        idnObject.plants.push(idnPlantObj.name);

                        idnPlantObj.subModule.forEach((subModuleObj) => {
                            if (subModuleObj.isActive) {
                                idnObject.sites.push(subModuleObj.name);
                            }
                        })
                    });

                    idnArray.push(idnObject);
                });
            }
            setIdnList(idnArray);
            setSitesData(response.sites);
            setPlantsData(response.plants);
            setSurveysData(response.surveys);
            dispatch(setSurveyData(response.surveys));
            setUserId(response._doc._id);
            dispatch(userDetail({"user": response._doc}));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
            dispatch(setAllSite(response.sites));
            dispatch(list(response.masterData));
            dispatch(setUserRole(response.securityRoles));
            setSurveyDataArray(response.surveys);
            let imperfectionList = [];
            let itemList = [];
            if (response.surveys && response.surveys.length) {
                let data = (response.surveys).filter((data) => data.title === "Onsite Linen Inspection");

                data.forEach((obj) => {
                    if (obj.questions && obj.questions.length) {
                        obj.questions.forEach((detail) => {
                            if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected") {
                                imperfectionList.push(detail.fieldName);
                            }

                            if (detail.fieldName === "Item") {

                                detail.answers.forEach((answersObj) => {
                                    itemList.push(answersObj.displayValue);
                                });

                            }
                        })
                    }
                });
                setLinenImperfection(imperfectionList);
                setLinenItem(itemList);
            }
            getSurveySentiment(response.sites, response.surveys);
        });
    }, []);

    useEffect(() => {
        if (isDateChanged && sitesData && sitesData.length && surveysData && surveysData.length && linenImperfection && linenImperfection.length) {
            getSurveyDataBySite(sitesData, surveysData);
        }
    }, [isDateChanged, linenImperfection, sentimentDataWithSurvey]);

    const getUserDetailWithSiteAndMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getPlantSurveyByDates = async (siteData, surveyData) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantSurveyByDates',
            requestConfig: {
                data: {
                    "site": siteData,
                    "survey": surveyData,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });

        return await response.data;
    };

    /*Sentiment*/

    const getSentimentSurveyByDates = async (siteData, surveyData, currentDate, fourWeeksBackDate) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantSurveyByDates',
            requestConfig: {
                data: {
                    "site": siteData,
                    "survey": surveyData,
                    "startDate": fourWeeksBackDate,
                    "endDate": currentDate,
                }
            }
        });

        return await response.data;
    };

    const getSurveySentiment = (siteData, surveyData) => {
        let surveyList = ["Linen Quality & Availability", "Client Satisfaction"];

        let surveys = [];
        surveyData.forEach((surveyDataObj) => {
            let data = {...surveyDataObj};
            if (surveyList.indexOf(data.title) !== -1) {
                surveys.push(data.title);
            }
        });

        let sites = [];
        siteData.forEach((siteDataObj) => {
            let data = {...siteDataObj};
            sites.push(data.site);
        });
        let currentDate = getCurrentDateOnly();
        let fourWeeksBackDate = getFourWeeksBackUTC(currentDate);

        getSentimentSurveyByDates(sites, surveys, currentDate, fourWeeksBackDate).then((fetchedValue) => {
            let linearSurveyStructure = getLinearSurveyStructureForSentiment(fetchedValue, currentDate, fourWeeksBackDate);
            setSentimentDataWithSurvey(linearSurveyStructure);
            setSentimentsDataWithSurvey(linearSurveyStructure);
        });
    };

    const getLinearSurveyStructureForSentiment = (fetchedValue, currentDate, fourWeeksBackDate) => {
        let surveyDataArray = [];
        let weekRange = getWeekRanges(fourWeeksBackDate, currentDate);

        fetchedValue.forEach((fetchedValueObj) => {
            let surveyObj = Object.assign({}, fetchedValueObj, {surveyDetail: []});

            fetchedValueObj.surveyDetail.map((detailObj) => {
                let detail = {...detailObj};

                if (fetchedValueObj.survey === "Linen Quality & Availability" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Quality") {
                        surveyObj["Quality"] = determineRange(detail.value);
                        if (surveyObj["Quality"]) {
                            surveyObj["QualityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Quantity") {
                        surveyObj["Availability"] = determineRange(detail.value);
                        if (surveyObj["Availability"]) {
                            surveyObj["QuantityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["QualityDate"] = detail.value;
                    }

                    if (detail.fieldName === "Department") {
                        surveyObj["QualityDept"] = detail.value;
                    }

                    if (detail.fieldName === "Visitation Date") {
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        surveyObj["QuantityDate"] = detail.value;
                        if (weekData) {
                            surveyObj["WeekValue"] = weekData["week"];
                            surveyObj["QuantityWeek"] = weekData["week"];
                            surveyObj["QuantityWeekRange"] = {start: weekData["start"], end: weekData["end"]};
                            surveyObj["QuantityWeekStart"] = convertToUTC(weekData["start"]);
                            surveyObj["QuantityWeekEnd"] = weekData["end"];
                        }
                    }
                } else if (fetchedValueObj.survey === "Client Satisfaction" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Overall Performance") {
                        surveyObj["ClientSat"] = determineRange(detail.value);
                        if (surveyObj["ClientSat"]) {
                            surveyObj["ClientSatCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["ClientSatDate"] = detail.value;
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        if (weekData) {
                            surveyObj["WeekValue"] = weekData["week"];
                            surveyObj["ClientSatWeek"] = weekData["week"];
                            surveyObj["ClientSatWeekRange"] = {start: weekData["start"], end: weekData["end"]};
                            surveyObj["ClientSatWeekStart"] = convertToUTC(weekData["start"]);
                            surveyObj["ClientSatWeekEnd"] = weekData["end"];
                        }
                    }

                    if (detail.fieldName === "Contact Title/Dept") {
                        surveyObj["ClientSatDept"] = detail.value;
                    }
                }
            });

            if (surveyObj.status === 2) {
                surveyDataArray.push(surveyObj);
            }
        });

        return surveyDataArray;
    };

    const getSentimentForIdn = (fetchedValue, key = "WeekValue", siteName, filterKey) => {
        let count = 0;
        let total = 0;

        if(fetchedValue && fetchedValue.length && fetchedValue.length > 0) {
            siteName.forEach(fetchedValueObj => {
                if (fetchedValueObj) {
                    let value = getSentimentByWeek(fetchedValue, "WeekValue", fetchedValueObj, filterKey);
                    total += parseFloat(value);
                    if (parseFloat(value) > 0) {
                        count += 1;
                    }
                }
            });
            let valueTotal = 0;
            if (count > 0) {
                valueTotal = total / count;
            } else {
                valueTotal = total;
            }
            return valueTotal
        } else {
            return ""
        }
    };

    const getSentimentByWeek = (fetchedValue, key = "WeekValue", siteName, filterKey) => {
        let siteWithChartArray = [];
        let siteLinearData = [];

        if(fetchedValue && fetchedValue.length && fetchedValue.length > 0) {
            fetchedValue.forEach(fetchedValueObj => {
                let objData = Object.assign({}, fetchedValueObj);
                if (siteName.indexOf(objData[filterKey]) !== -1 && objData[key]) {
                    siteLinearData.push(objData);
                }
            });

            let weekId = [];

            siteLinearData.forEach((siteLinearDataObj) => {
                let siteLinearDataObject = {...siteLinearDataObj};

                if (weekId.indexOf(siteLinearDataObject[key]) === -1) {
                    weekId.push(siteLinearDataObject[key]);

                    siteLinearDataObject["linenTotal"] = 0;
                    siteLinearDataObject["linenCount"] = 0;
                    siteLinearDataObject["linenQualityChartTotal"] = 0;
                    siteLinearDataObject["linenQualityChartCount"] = 0;
                    siteLinearDataObject["linenQuantityChartTotal"] = 0;
                    siteLinearDataObject["linenQuantityChartCount"] = 0;

                    siteLinearDataObject["clientSatChartTotal"] = 0;
                    siteLinearDataObject["clientSatChartCount"] = 0;

                    siteLinearDataObject["TotalByWeek"] = 0;
                    siteLinearDataObject["CountByWeek"] = 0;
                    siteLinearDataObject["AvgByWeek"] = 0;

                    if (siteLinearDataObject.QuantityWeek) {
                        siteLinearDataObject["linenQualityChartTotal"] = siteLinearDataObject.Quality;
                        siteLinearDataObject["linenQuantityChartTotal"] = siteLinearDataObject.Availability;
                        siteLinearDataObject["linenQualityChartCount"] = 1;
                        siteLinearDataObject["linenQuantityChartCount"] = 1;
                        siteLinearDataObject["linenTotal"] = siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                        siteLinearDataObject["linenCount"] = siteLinearDataObject["linenQualityChartCount"] + siteLinearDataObject["linenQuantityChartCount"];
                    }

                    if (siteLinearDataObject.ClientSatWeek) {
                        siteLinearDataObject["clientSatChartTotal"] = siteLinearDataObject.ClientSat;
                        siteLinearDataObject["clientSatChartCount"] = 1;
                    }

                    siteWithChartArray.push(siteLinearDataObject);
                } else {
                    let resultObject = searchArray(siteLinearDataObject[key], siteWithChartArray, key);
                    let date = "";
                    if (siteLinearDataObject.QuantityWeek) {
                        date = siteLinearDataObject.QuantityWeek;
                    }

                    if (date) {
                        resultObject["linenQualityChartTotal"] += siteLinearDataObject.Quality;
                        resultObject["linenQuantityChartTotal"] += siteLinearDataObject.Availability;
                        resultObject["linenQualityChartCount"] += 1;
                        resultObject["linenQuantityChartCount"] += 1;
                        resultObject["linenTotal"] += siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                        resultObject["linenCount"] = siteLinearDataObject["linenQualityChartCount"] + siteLinearDataObject["linenQuantityChartCount"];
                    }

                    let clientWeek = "";
                    if (siteLinearDataObject.ClientSatWeek) {
                        clientWeek = siteLinearDataObject.ClientSatWeek;
                    }

                    if (clientWeek) {
                        resultObject["clientSatChartTotal"] += siteLinearDataObject.ClientSat;
                        resultObject["clientSatChartCount"] += 1;
                    }
                }
            });

            return getSentimentByAllWeek(siteWithChartArray);
        } else {
            return ""
        }
    };

    const getSentimentByAllWeek = (siteWithSentimentChartArray) => {
        let total = 0;
        let count = 0;
        siteWithSentimentChartArray.map((siteLinearDataObj) => {
            let subCount = 0;
            let quality = getAvgOfNumberInIntFormat(siteLinearDataObj["linenQualityChartTotal"], siteLinearDataObj["linenQualityChartCount"]);
            let availability = getAvgOfNumberInIntFormat(siteLinearDataObj["linenQuantityChartTotal"], siteLinearDataObj["linenQuantityChartCount"]);
            let clientSat = getAvgOfNumberInIntFormat(siteLinearDataObj["clientSatChartTotal"], siteLinearDataObj["clientSatChartCount"]);

            if (quality > 0) {
                subCount += 1;
            }

            if (availability > 0) {
                subCount += 1;
            }

            if (clientSat > 0) {
                subCount += 1;
            }

            siteLinearDataObj["TotalByWeek"] = quality + availability + clientSat;
            siteLinearDataObj["CountByWeek"] = subCount;

            if (siteLinearDataObj["CountByWeek"] && siteLinearDataObj["CountByWeek"] > 0) {
                siteLinearDataObj["AvgByWeek"] = siteLinearDataObj["TotalByWeek"] / siteLinearDataObj["CountByWeek"];
            } else {
                siteLinearDataObj["AvgByWeek"] = siteLinearDataObj["TotalByWeek"];
            }
            total += siteLinearDataObj["AvgByWeek"];

            if (total > 0) {
                count += 1;
            }
        });

        if (count > 0) {
            total = total / count;
        }

        return total;
    };

    /*Sentiment*/

    /*Fetch for survey*/
    const getSurveyDataBySite = (siteData, surveyData) => {
        let surveyList = ["Linen Quality & Availability", "Client Satisfaction", "Onsite Linen Inspection"];

        let surveys = [];
        surveyData.forEach((surveyDataObj) => {
            let data = {...surveyDataObj};
            if (surveyList.indexOf(data.title) !== -1) {
                surveys.push(data.title);
            }
        });

        let sites = [];
        siteData.forEach((siteDataObj) => {
            let data = {...siteDataObj};
            sites.push(data.site);
        });

        getPlantSurveyByDates(sites, surveys).then((fetchedValue) => {
            let newData = fetchedValue.map(fetchedValueObj => fetchedValueObj);
            setAllsurveyList(newData);
            setAllsurveyLists(newData);

            let linearSurveyStructure = getLinearSurveyStructure(fetchedValue, [], true);
            linearSurveyStructure.sort(function (a, b) {
                return (a["site"]).localeCompare(b["site"])
            });

            let dataArrayOfSiteWithSurvey = getLinearSurveyStructureByWeek(linearSurveyStructure, "site");
            let dataWithAllSiteValue = getAllSiteWithChartData(dataArrayOfSiteWithSurvey);

            let dataArrayOfSiteWithSurveySort = sortArrayData(dataArrayOfSiteWithSurvey, "site");
            let dataWithAllSiteValueSort = sortArrayData(dataWithAllSiteValue, "site");

            setSiteAllDataWithSurvey(dataWithAllSiteValueSort);
            setSitesAllDataWithSurvey(dataWithAllSiteValueSort);

            setSiteDataWithSurvey(dataArrayOfSiteWithSurveySort);
            setSitesDataWithSurvey(dataArrayOfSiteWithSurveySort);

            let allSiteArray = getLinearStructureForAllSite(dataArrayOfSiteWithSurvey, siteData);

            let dataArrayOfPlantWithSurvey = getAllPlantWithChartData(linearSurveyStructure, "plant", dataArrayOfSiteWithSurvey);
            let dataWithAllPlantValue = getAllPlantWithChartData(linearSurveyStructure, "plant", dataWithAllSiteValue);

            let dataArrayOfPlantWithSurveySort = sortArrayData(dataArrayOfPlantWithSurvey, "plant");
            let dataWithAllPlantValueSort = sortArrayData(dataWithAllPlantValue, "plant");

            setPlantAllDataWithSurvey(dataWithAllPlantValueSort);
            setPlantsAllDataWithSurvey(dataWithAllPlantValueSort);

            setPlantDataWithSurvey(dataArrayOfPlantWithSurveySort);
            setPlantsDataWithSurvey(dataArrayOfPlantWithSurveySort);

            let dataArrayOfIdnWithSurvey = getAllIdnWithChartData(linearSurveyStructure, dataArrayOfSiteWithSurvey);
            let dataWithAllIdnValue = getAllIdnWithChartData(linearSurveyStructure, dataWithAllSiteValue);

            let dataArrayOfIdnWithSurveySort = sortArrayData(dataArrayOfIdnWithSurvey, "idn");
            let dataWithAllIdnValueSort = sortArrayData(dataWithAllIdnValue, "idn");

            setIdnAllDataWithSurvey(dataWithAllIdnValueSort);
            setIdnsAllDataWithSurvey(dataWithAllIdnValueSort);

            setIdnDataWithSurvey(dataArrayOfIdnWithSurveySort);
            setIdnsDataWithSurvey(dataArrayOfIdnWithSurveySort);
        });
    };
    /*Fetch for survey*/
    const getLinearStructureForAllSite = (fetchedValue, sitesArray) => {
        let surveyDataArray = [];

        sitesArray.forEach((sitesArrayObject) => {
            let sitesArrayObj = {...sitesArrayObject};
            let siteObject = searchArray(sitesArrayObj.site, "site", fetchedValue);
            if (siteObject) {
                surveyDataArray.push({...siteObject});
            } else {
                surveyDataArray.push(sitesArrayObj);
            }
        });

        return surveyDataArray;
    };

    const getLinearSurveyStructure = (fetchedValue, deptData, flag) => {
        let surveyDataArray = [];
        let weekRange = getWeekRanges(startDate, endDate);
        let deptArray = [];

        fetchedValue.forEach((fetchedValueObj) => {
            let surveyObj = Object.assign({}, fetchedValueObj, {surveyDetail: []});

            fetchedValueObj.surveyDetail.map((detailObj) => {
                let detail = {...detailObj};

                if (fetchedValueObj.survey === "Linen Quality & Availability" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Quality") {
                        surveyObj["Quality"] = determineRange(detail.value);
                        if (surveyObj["Quality"]) {
                            surveyObj["QualityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Quantity") {
                        surveyObj["Availability"] = determineRange(detail.value);
                        if (surveyObj["Availability"]) {
                            surveyObj["QuantityCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["QualityDate"] = detail.value;
                    }

                    if (detail.fieldName === "Department") {
                        if (flag) {
                            surveyObj["QualityDept"] = detail.value;

                            if (deptArray.indexOf(detail.value) === -1) {
                                deptArray.push(detail.value);
                            }
                        } else {
                            let isExist = searchArrayWithActive(detail.value, deptData, "label", "value");

                            if (isExist) {
                                surveyObj["QualityDept"] = detail.value;
                            }
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        surveyObj["QuantityDate"] = detail.value;
                        if (weekData) {
                            surveyObj["QuantityWeek"] = weekData["week"];
                            surveyObj["QuantityWeekRange"] = {start: weekData["start"], end: weekData["end"]};
                            surveyObj["QuantityWeekStart"] = convertToUTC(weekData["start"]);
                            surveyObj["QuantityWeekEnd"] = weekData["end"];
                        }
                    }
                } else if (fetchedValueObj.survey === "Client Satisfaction" && fetchedValueObj.status === 2) {
                    if (detail.fieldName === "Overall Performance") {
                        surveyObj["ClientSat"] = determineRange(detail.value);
                        if (surveyObj["ClientSat"]) {
                            surveyObj["ClientSatCount"] = 1;
                        }
                    }

                    if (detail.fieldName === "Visitation Date") {
                        surveyObj["ClientSatDate"] = detail.value;
                        let weekData = getWeekRangeForGivenDate(detail.value, weekRange);
                        if (weekData) {
                            surveyObj["ClientSatWeek"] = weekData["week"];
                            surveyObj["ClientSatWeekRange"] = {start: weekData["start"], end: weekData["end"]};
                            surveyObj["ClientSatWeekStart"] = convertToUTC(weekData["start"]);
                            surveyObj["ClientSatWeekEnd"] = weekData["end"];
                        }
                    }

                    if (detail.fieldName === "Contact Title/Dept") {
                        surveyObj["ClientSatDept"] = detail.value;
                    }
                } else if (fetchedValueObj.survey === "Onsite Linen Inspection" && fetchedValueObj.status === 2) {

                    let flag = false;
                    fetchedValueObj.surveyDetail.forEach((surveyDetailObject) => {
                        if (surveyDetailObject.fieldName === "Item") {
                            flag = linenItem.includes(surveyDetailObject.value);
                        }
                    });

                    if (flag) {
                        if (detail.fieldName === "Visitation Date") {
                            surveyObj["OnsiteLinenImperfection"] = 0;
                            surveyObj["OnsiteLinenDate"] = detail.value;
                        }
                        if (detail.fieldName === "Pieces Inspected") {
                            surveyObj["OnsiteLinenInspected"] = convertStringToNumber(detail.value);
                            if (surveyObj["OnsiteLinenInspected"]) {
                                surveyObj["OnsiteLinenCount"] = 1;
                            }
                        }

                        if (detail.type === "Number" && detail.fieldName !== "Pieces Inspected") {
                            let key = detail.title;
                            let imperfection = convertStringToNumber(detail.value);
                            if (imperfection) {
                                surveyObj["OnsiteLinenImperfection"] += imperfection;
                                surveyObj[key] = imperfection;
                            }
                        }

                        if (detail.fieldName === "Item") {
                            surveyObj["OnsiteLinenItem"] = detail.value;
                        }
                    }
                }
            });

            if (surveyObj.status === 2) {
                surveyDataArray.push(surveyObj);
            }
        });

        if (flag) {
            let deptUpdatedList = updateMultiSelectOption(deptArray);
            let sortDeptData = sortArrayOfObject(deptUpdatedList, "label");
            setDeptList(sortDeptData);
            setDeptLists(sortDeptData);
        }
        return surveyDataArray;
    };

    const getLinearSurveyStructureByWeek = (fetchedValue, key) => {
        let siteIds = [];
        let siteWithChartArray = [];
        let siteLinearData = fetchedValue.map(fetchedValueObj => {
            let objData = Object.assign({}, fetchedValueObj);
            return objData;
        });

        siteLinearData.forEach((siteLinearDataObj) => {
            let siteLinearDataObject = {...siteLinearDataObj};

            // Add unique object in plantArray with new key as "chart"
            if (siteIds.indexOf(siteLinearDataObject[key]) < 0) {
                siteIds.push(siteLinearDataObject[key]);

                siteLinearDataObject["linenChartWithQADate"] = [];
                siteLinearDataObject["linenChartWithDDate"] = [];
                siteLinearDataObject["clientSatChartWithOPDate"] = [];
                siteLinearDataObject["onsiteLinenChartWithIDate"] = [];

                siteLinearDataObject["linenQualityChart"] = [];
                siteLinearDataObject["linenQualityChartByWeek"] = [];
                siteLinearDataObject["linenQualityBarChart"] = [];

                siteLinearDataObject["linenQualityChartAvg"] = 0;
                siteLinearDataObject["linenQualityChartTotal"] = 0;
                siteLinearDataObject["linenQualityChartCount"] = 0;

                siteLinearDataObject["linenQuantityChartAvg"] = 0;
                siteLinearDataObject["linenQuantityChartTotal"] = 0;
                siteLinearDataObject["linenQuantityChartCount"] = 0;

                siteLinearDataObject["linenTotal"] = 0;
                siteLinearDataObject["linenAvg"] = 0;

                siteLinearDataObject["quantityChart"] = [];
                siteLinearDataObject["quantityChartAvg"] = 0;
                siteLinearDataObject["quantityChartTotal"] = 0;
                siteLinearDataObject["quantityChartCount"] = 0;

                siteLinearDataObject["clientSatChart"] = [];
                siteLinearDataObject["clientSatChartAvg"] = 0;
                siteLinearDataObject["clientSatChartTotal"] = 0;
                siteLinearDataObject["clientSatChartCount"] = 0;

                siteLinearDataObject["onsiteLinenChart"] = [];
                siteLinearDataObject["onsiteLinenChartAvg"] = 0;
                siteLinearDataObject["onsiteLinenChartTotal"] = 0;
                siteLinearDataObject["onsiteLinenChartCount"] = 0;
                siteLinearDataObject["onsiteLinenChartTotalImperfection"] = 0;


                siteLinearDataObject["linenQualityChartWithWeek"] = [];
                siteLinearDataObject["linenQualityChartWithDept"] = [];
                siteLinearDataObject["clientSatChartWithWeek"] = [];
                siteLinearDataObject["onsiteLinenChartWithItem"] = [];
                siteLinearDataObject["sentimentScore"] = getSentimentByWeek(sentimentDataWithSurvey, "WeekValue", [siteLinearDataObject[key]], key)

                if (siteLinearDataObject.QualityDate) {
                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    siteLinearDataObject["linenQualityChart"].push(qualityChart);
                    siteLinearDataObject["linenQualityChartTotal"] = siteLinearDataObject.Quality;
                    siteLinearDataObject["linenQuantityChartTotal"] = siteLinearDataObject.Availability;
                    siteLinearDataObject["linenQualityChartCount"] = 1;
                    siteLinearDataObject["linenQuantityChartCount"] = 1;
                    siteLinearDataObject["linenTotal"] = siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                    siteLinearDataObject["linenChartWithQADate"].push(rawData);
                }

                if (siteLinearDataObject.QualityDept) {
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    siteLinearDataObject["linenChartWithDDate"].push(rawData);
                    siteLinearDataObject["linenQualityBarChart"].push(quantityChart);
                }

                if (siteLinearDataObject.ClientSatDate) {
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    siteLinearDataObject["clientSatChartWithOPDate"].push(rawData);
                    siteLinearDataObject["clientSatChart"].push(clientChart);
                    siteLinearDataObject["clientSatChartTotal"] = siteLinearDataObject.ClientSat;
                    siteLinearDataObject["clientSatChartCount"] = 1;
                }

                if (siteLinearDataObject.OnsiteLinenDate) {
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });

                    siteLinearDataObject["onsiteLinenChartWithIDate"].push(rawData);
                    siteLinearDataObject["onsiteLinenChart"].push(onsiteLinenChart);
                    siteLinearDataObject["onsiteLinenChartTotal"] = siteLinearDataObj.OnsiteLinenInspected;
                    siteLinearDataObject["onsiteLinenChartTotalImperfection"] = siteLinearDataObj.OnsiteLinenImperfection;
                    siteLinearDataObject["onsiteLinenChartCount"] = 1;
                }

                siteWithChartArray.push(siteLinearDataObject);
            } else {
                let resultObject = searchArray(siteLinearDataObject[key], siteWithChartArray, key);
                let date = "";
                if (siteLinearDataObject.QuantityWeek) {
                    date = siteLinearDataObject.QuantityWeek;
                }

                if (date) {
                    let linenQualityChartObject = searchArray(date, resultObject["linenQualityChart"], "Week");

                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    if (linenQualityChartObject) {
                        linenQualityChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityChart"].push(qualityChart);
                    }

                    resultObject["linenChartWithQADate"].push(rawData);
                    resultObject["linenQualityChartTotal"] += siteLinearDataObject.Quality;
                    resultObject["linenQuantityChartTotal"] += siteLinearDataObject.Availability;
                    resultObject["linenQualityChartCount"] += 1;
                    resultObject["linenQuantityChartCount"] += 1;
                    resultObject["linenTotal"] += siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                }

                let linenDepartment = "";
                if (siteLinearDataObject.QualityDept) {
                    linenDepartment = siteLinearDataObject.QualityDept;
                }

                if (linenDepartment) {
                    let linenQualityBarChartObject = searchArray(linenDepartment, resultObject["linenQualityBarChart"], "Dept");
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (linenQualityBarChartObject) {
                        linenQualityBarChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityBarChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityBarChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityBarChart"].push(quantityChart);
                    }

                    resultObject["linenChartWithDDate"].push(rawData);
                }

                let clientWeek = "";
                if (siteLinearDataObject.ClientSatWeek) {
                    clientWeek = siteLinearDataObject.ClientSatWeek;
                }

                if (clientWeek) {
                    let clientSatChartObject = searchArray(clientWeek, resultObject["clientSatChart"], "Week");
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (clientSatChartObject) {
                        clientSatChartObject["Overall Performance"] += siteLinearDataObject.ClientSat;
                        clientSatChartObject.Count += 1;
                    } else {
                        resultObject["clientSatChart"].push(clientChart);
                    }

                    resultObject["clientSatChartWithOPDate"].push(rawData);
                    resultObject["clientSatChartTotal"] += siteLinearDataObject.ClientSat;
                    resultObject["clientSatChartCount"] += 1;
                }

                let itemForOnSite = "";
                if (siteLinearDataObject.OnsiteLinenItem) {
                    itemForOnSite = siteLinearDataObject.OnsiteLinenItem;
                }

                if (itemForOnSite) {
                    let onsiteLinenChartObject = searchArray(itemForOnSite, resultObject["onsiteLinenChart"], "Item");
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });

                    if (onsiteLinenChartObject) {
                        onsiteLinenChartObject.Inspected += siteLinearDataObject.OnsiteLinenInspected;
                        onsiteLinenChartObject.Flaw += siteLinearDataObject.OnsiteLinenImperfection;
                        onsiteLinenChartObject.Count += 1;

                        linenImperfection.forEach((linenImperfectionObj) => {
                            if (siteLinearDataObject[linenImperfectionObj] && onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] += siteLinearDataObject[linenImperfectionObj];
                            } else if (siteLinearDataObject[linenImperfectionObj] && !onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                            }
                        });
                    } else {


                        resultObject["onsiteLinenChart"].push(onsiteLinenChart);
                    }

                    resultObject["onsiteLinenChartWithIDate"].push(rawData);
                    resultObject["onsiteLinenChartTotal"] += siteLinearDataObj.OnsiteLinenInspected;
                    resultObject["onsiteLinenChartTotalImperfection"] += siteLinearDataObj.OnsiteLinenImperfection;
                    resultObject["onsiteLinenChartCount"] += 1;
                }
            }
        });

        return getChartByAllWeek(siteWithChartArray);
    };

    const getChartByAllWeek = (siteLinearData) => {
        siteLinearData.forEach((siteLinearDataObject) => {
            if (siteLinearDataObject["linenQualityChart"] && siteLinearDataObject["linenQualityChart"].length) {
                let total = siteLinearDataObject["linenQualityChartTotal"] + siteLinearDataObject["linenQuantityChartTotal"];
                let totalQuality = siteLinearDataObject["linenQualityChartTotal"]/siteLinearDataObject["linenQualityChartCount"];
                let totalQuantity = siteLinearDataObject["linenQuantityChartTotal"]/siteLinearDataObject["linenQuantityChartCount"];
                let avg = total/(siteLinearDataObject["linenQualityChartCount"]* 2);

                siteLinearDataObject["linenTotal"] = total;
                siteLinearDataObject["linenAvg"] = avg.toFixed(1);

                siteLinearDataObject["linenQualityChartAvg"] = siteLinearDataObject["linenQualityChartTotal"] / siteLinearDataObject["linenQualityChartCount"];
                siteLinearDataObject["linenQuantityChartAvg"] = siteLinearDataObject["linenQuantityChartTotal"] / siteLinearDataObject["linenQuantityChartCount"];

                siteLinearDataObject["linenQualityChartWithWeek"] = getLinenQualityChartByWeek(siteLinearDataObject["linenQualityChart"]);
            }

            if (siteLinearDataObject["linenQualityBarChart"] && siteLinearDataObject["linenQualityBarChart"].length) {
                let avg = siteLinearDataObject["quantityChartTotal"] / siteLinearDataObject["quantityChartCount"];
                siteLinearDataObject["quantityChartAvg"] = avg.toFixed(1);

                let sortArray = sortArrayOfObject(siteLinearDataObject["linenQualityBarChart"], "Dept");

                //siteLinearDataObject["linenQualityBarChart"] = getLinenQualityChartByDept(sortArray);
                siteLinearDataObject["linenQualityChartWithDept"] = getLinenQualityChartByDeptTopBottom(sortArray);
            }

            if (siteLinearDataObject["clientSatChart"] && siteLinearDataObject["clientSatChart"].length) {
                let avg = siteLinearDataObject["clientSatChartTotal"] / siteLinearDataObject["clientSatChartCount"];
                siteLinearDataObject["clientSatChartAvg"] = avg.toFixed(1);

                siteLinearDataObject["clientSatChartWithWeek"] = getClientSatChartByWeek(siteLinearDataObject["clientSatChart"]);
            }

            if (siteLinearDataObject["onsiteLinenChart"] && siteLinearDataObject["onsiteLinenChart"].length) {
                let total = siteLinearDataObject["onsiteLinenChartTotal"] ? siteLinearDataObject["onsiteLinenChartTotal"] : 1;
                let flaw = siteLinearDataObject["onsiteLinenChartTotalImperfection"];

                let avg = (100 * flaw) / total;
                siteLinearDataObject["onsiteLinenChartAvg"] = avg.toFixed(1);

                siteLinearDataObject["onsiteLinenChart"] = sortArrayOfObject(siteLinearDataObject["onsiteLinenChart"], "Item");

                siteLinearDataObject["onsiteLinenChartWithItem"] = siteLinearDataObject["onsiteLinenChart"];
            }
        });

        return siteLinearData;
    };

    const getAllSiteWithChartData = (dataOfSiteWithSurvey) => {
        let siteWithChartArray = [];

        sitesData.forEach((siteDataObj) => {
            let siteObj = searchArray(siteDataObj["site"], dataOfSiteWithSurvey, "site");

            if (siteObj) {
                siteWithChartArray.push({...siteObj});
            } else {
                let siteDataObject = Object.assign({}, siteDataObj);
                siteDataObject["linenQualityChart"] = [];
                siteDataObject["linenQualityChartByWeek"] = [];
                siteDataObject["linenQualityBarChart"] = [];

                siteDataObject["linenChartWithQADate"] = [];
                siteDataObject["linenChartWithDDate"] = [];
                siteDataObject["clientSatChartWithOPDate"] = [];
                siteDataObject["onsiteLinenChartWithIDate"] = [];

                siteDataObject["linenQualityChartAvg"] = 0;
                siteDataObject["linenQualityChartTotal"] = 0;
                siteDataObject["linenQualityChartCount"] = 0;

                siteDataObject["linenQuantityChartAvg"] = 0;
                siteDataObject["linenQuantityChartTotal"] = 0;
                siteDataObject["linenQuantityChartCount"] = 0;

                siteDataObject["linenTotal"] = 0;
                siteDataObject["linenAvg"] = 0;

                siteDataObject["quantityChart"] = [];
                siteDataObject["quantityChartAvg"] = 0;
                siteDataObject["quantityChartTotal"] = 0;
                siteDataObject["quantityChartCount"] = 0;

                siteDataObject["clientSatChart"] = [];
                siteDataObject["clientSatChartAvg"] = 0;
                siteDataObject["clientSatChartTotal"] = 0;
                siteDataObject["clientSatChartCount"] = 0;

                siteDataObject["onsiteLinenChart"] = [];
                siteDataObject["onsiteLinenChartAvg"] = 0;
                siteDataObject["onsiteLinenChartTotal"] = 0;
                siteDataObject["onsiteLinenChartCount"] = 0;
                siteDataObject["onsiteLinenChartTotalImperfection"] = 0;

                siteDataObject["linenQualityChartWithWeek"] = [];
                siteDataObject["linenQualityChartWithDept"] = [];
                siteDataObject["clientSatChartWithWeek"] = [];
                siteDataObject["onsiteLinenChartWithItem"] = [];
                siteWithChartArray.push({...siteDataObject});
            }

        });

        return siteWithChartArray;
    };

    /*Plant*/
    const getAllPlantWithChartData = (fetchedValue, key, siteWithSurvey) => {
        let plantIds = [];
        let plantWithChartArray = [];
        let siteLinearData = fetchedValue.map(fetchedValueObj => {
            let objData = Object.assign({}, fetchedValueObj);
            return objData;
        });

        siteLinearData.forEach((siteLinearDataObj) => {
            let siteLinearDataObject = {...siteLinearDataObj};

            // Add unique object in plantArray with new key as "chart"
            if (plantIds.indexOf(siteLinearDataObject[key]) < 0) {
                plantIds.push(siteLinearDataObject[key]);
                siteLinearDataObject["sites"] = [];
                siteLinearDataObject["show"] = false;

                siteLinearDataObject["linenChartWithQADate"] = [];
                siteLinearDataObject["linenChartWithDDate"] = [];
                siteLinearDataObject["clientSatChartWithOPDate"] = [];
                siteLinearDataObject["onsiteLinenChartWithIDate"] = [];

                siteLinearDataObject["linenQualityChart"] = [];
                siteLinearDataObject["linenQualityChartByWeek"] = [];
                siteLinearDataObject["linenQualityBarChart"] = [];

                siteLinearDataObject["linenQualityChartAvg"] = 0;
                siteLinearDataObject["linenQualityChartTotal"] = 0;
                siteLinearDataObject["linenQualityChartCount"] = 0;

                siteLinearDataObject["linenQuantityChartAvg"] = 0;
                siteLinearDataObject["linenQuantityChartTotal"] = 0;
                siteLinearDataObject["linenQuantityChartCount"] = 0;

                siteLinearDataObject["linenTotal"] = 0;
                siteLinearDataObject["linenAvg"] = 0;

                siteLinearDataObject["quantityChart"] = [];
                siteLinearDataObject["quantityChartAvg"] = 0;
                siteLinearDataObject["quantityChartTotal"] = 0;
                siteLinearDataObject["quantityChartCount"] = 0;

                siteLinearDataObject["clientSatChart"] = [];
                siteLinearDataObject["clientSatChartAvg"] = 0;
                siteLinearDataObject["clientSatChartTotal"] = 0;
                siteLinearDataObject["clientSatChartCount"] = 0;

                siteLinearDataObject["onsiteLinenChart"] = [];
                siteLinearDataObject["onsiteLinenChartAvg"] = 0;
                siteLinearDataObject["onsiteLinenChartTotal"] = 0;
                siteLinearDataObject["onsiteLinenChartCount"] = 0;
                siteLinearDataObject["onsiteLinenChartTotalImperfection"] = 0;

                siteLinearDataObject["linenQualityChartWithWeek"] = [];
                siteLinearDataObject["linenQualityChartWithDept"] = [];
                siteLinearDataObject["clientSatChartWithWeek"] = [];
                siteLinearDataObject["onsiteLinenChartWithItem"] = [];

                if (siteLinearDataObject.QualityDate) {
                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    siteLinearDataObject["linenChartWithQADate"].push(rawData);
                    siteLinearDataObject["linenQualityChart"].push(qualityChart);
                    siteLinearDataObject["linenQualityChartTotal"] = siteLinearDataObject.Quality;
                    siteLinearDataObject["linenQuantityChartTotal"] = siteLinearDataObject.Availability;
                    siteLinearDataObject["linenQualityChartCount"] = 1;
                    siteLinearDataObject["linenQuantityChartCount"] = 1;
                    siteLinearDataObject["linenTotal"] = siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                }

                if (siteLinearDataObject.QualityDept) {
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    siteLinearDataObject["linenChartWithDDate"].push(rawData);
                    siteLinearDataObject["linenQualityBarChart"].push(quantityChart);
                }

                if (siteLinearDataObject.ClientSatDate) {
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };
                    siteLinearDataObject["clientSatChartWithOPDate"].push(rawData);
                    siteLinearDataObject["clientSatChart"].push(clientChart);
                    siteLinearDataObject["clientSatChartTotal"] = siteLinearDataObject.ClientSat;
                    siteLinearDataObject["clientSatChartCount"] = 1;
                }

                if (siteLinearDataObject.OnsiteLinenDate) {
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });
                    siteLinearDataObject["onsiteLinenChartWithIDate"].push(rawData);
                    siteLinearDataObject["onsiteLinenChart"].push(onsiteLinenChart);
                    siteLinearDataObject["onsiteLinenChartTotal"] = siteLinearDataObj.OnsiteLinenInspected;
                    siteLinearDataObject["onsiteLinenChartTotalImperfection"] = siteLinearDataObj.OnsiteLinenImperfection;
                    siteLinearDataObject["onsiteLinenChartCount"] = 1;
                }
                siteLinearDataObject["sentimentScore"] = getSentimentByWeek(sentimentDataWithSurvey, "WeekValue", [siteLinearDataObject[key]], key);
                siteLinearDataObject["sites"] = getSelectedArray(siteLinearDataObject[key], key, siteWithSurvey);
                plantWithChartArray.push(siteLinearDataObject);
            } else {
                let resultObject = searchArray(siteLinearDataObject[key], plantWithChartArray, key);
                let date = "";
                if (siteLinearDataObject.QuantityWeek) {
                    date = siteLinearDataObject.QuantityWeek;
                }

                if (date) {
                    let linenQualityChartObject = searchArray(date, resultObject["linenQualityChart"], "Week");
                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    if (linenQualityChartObject) {
                        linenQualityChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityChart"].push(qualityChart);
                    }

                    resultObject["linenChartWithQADate"].push(rawData);
                    resultObject["linenQualityChartTotal"] += siteLinearDataObject.Quality;
                    resultObject["linenQuantityChartTotal"] += siteLinearDataObject.Availability;
                    resultObject["linenQualityChartCount"] += 1;
                    resultObject["linenQuantityChartCount"] += 1;
                    resultObject["linenTotal"] += siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                }

                let linenDepartment = "";
                if (siteLinearDataObject.QualityDept) {
                    linenDepartment = siteLinearDataObject.QualityDept;
                }

                if (linenDepartment) {
                    let linenQualityBarChartObject = searchArray(linenDepartment, resultObject["linenQualityBarChart"], "Dept");
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (linenQualityBarChartObject) {
                        linenQualityBarChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityBarChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityBarChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityBarChart"].push(quantityChart);
                    }
                    resultObject["linenChartWithDDate"].push(rawData);
                }

                let clientWeek = "";
                if (siteLinearDataObject.ClientSatWeek) {
                    clientWeek = siteLinearDataObject.ClientSatWeek;
                }

                if (clientWeek) {
                    let clientSatChartObject = searchArray(clientWeek, resultObject["clientSatChart"], "Week");
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (clientSatChartObject) {
                        clientSatChartObject["Overall Performance"] += siteLinearDataObject.ClientSat;
                        clientSatChartObject.Count += 1;
                    } else {
                        resultObject["clientSatChart"].push(clientChart);
                    }

                    resultObject["clientSatChartWithOPDate"].push(rawData);
                    resultObject["clientSatChartTotal"] += siteLinearDataObject.ClientSat;
                    resultObject["clientSatChartCount"] += 1;
                }

                let itemForOnSite = "";
                if (siteLinearDataObject.OnsiteLinenItem) {
                    itemForOnSite = siteLinearDataObject.OnsiteLinenItem;
                }

                if (itemForOnSite) {
                    let onsiteLinenChartObject = searchArray(itemForOnSite, resultObject["onsiteLinenChart"], "Item");
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });

                    if (onsiteLinenChartObject) {
                        onsiteLinenChartObject.Inspected += siteLinearDataObject.OnsiteLinenInspected;
                        onsiteLinenChartObject.Flaw += siteLinearDataObject.OnsiteLinenImperfection;
                        onsiteLinenChartObject.Count += 1;

                        linenImperfection.forEach((linenImperfectionObj) => {
                            if (siteLinearDataObject[linenImperfectionObj] && onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] += siteLinearDataObject[linenImperfectionObj];
                            } else if (siteLinearDataObject[linenImperfectionObj] && !onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                            }
                        });
                    } else {
                        resultObject["onsiteLinenChart"].push(onsiteLinenChart);
                    }

                    resultObject["onsiteLinenChartWithIDate"].push(rawData);
                    resultObject["onsiteLinenChartTotal"] += siteLinearDataObj.OnsiteLinenInspected;
                    resultObject["onsiteLinenChartTotalImperfection"] += siteLinearDataObj.OnsiteLinenImperfection;
                    resultObject["onsiteLinenChartCount"] += 1;
                }
            }
        });

        let chartForPlant = getChartByAllWeek(plantWithChartArray);
        return getAllPlantWithAllSite(chartForPlant, siteWithSurvey);
    };

    const getAllPlantWithAllSite = (fetchArray, siteWithSurvey) => {
        let siteWithChartArray = [];

        plantsData.forEach((siteDataObj) => {
            let siteObj = searchArray(siteDataObj["_id"], fetchArray, "plant");

            if (siteObj) {
                siteWithChartArray.push({...siteObj});
            } else {
                let siteDataObject = Object.assign({}, siteDataObj);
                siteDataObject["sites"] = [];
                siteDataObject["sites"] = getSelectedArray(siteDataObj["_id"], "plant", siteWithSurvey);
                siteDataObject["show"] = false;
                siteDataObject["plant"] = siteDataObj["_id"];

                siteDataObject["linenChartWithQADate"] = [];
                siteDataObject["linenChartWithDDate"] = [];
                siteDataObject["clientSatChartWithOPDate"] = [];
                siteDataObject["onsiteLinenChartWithIDate"] = [];

                siteDataObject["linenQualityChart"] = [];
                siteDataObject["linenQualityChartByWeek"] = [];
                siteDataObject["linenQualityBarChart"] = [];

                siteDataObject["linenQualityChartAvg"] = 0;
                siteDataObject["linenQualityChartTotal"] = 0;
                siteDataObject["linenQualityChartCount"] = 0;

                siteDataObject["linenQuantityChartAvg"] = 0;
                siteDataObject["linenQuantityChartTotal"] = 0;
                siteDataObject["linenQuantityChartCount"] = 0;

                siteDataObject["linenTotal"] = 0;
                siteDataObject["linenAvg"] = 0;

                siteDataObject["quantityChart"] = [];
                siteDataObject["quantityChartAvg"] = 0;
                siteDataObject["quantityChartTotal"] = 0;
                siteDataObject["quantityChartCount"] = 0;

                siteDataObject["clientSatChart"] = [];
                siteDataObject["clientSatChartAvg"] = 0;
                siteDataObject["clientSatChartTotal"] = 0;
                siteDataObject["clientSatChartCount"] = 0;

                siteDataObject["onsiteLinenChart"] = [];
                siteDataObject["onsiteLinenChartAvg"] = 0;
                siteDataObject["onsiteLinenChartTotal"] = 0;
                siteDataObject["onsiteLinenChartCount"] = 0;
                siteDataObject["onsiteLinenChartTotalImperfection"] = 0;

                siteDataObject["linenQualityChartWithWeek"] = [];
                siteDataObject["linenQualityChartWithDept"] = [];
                siteDataObject["clientSatChartWithWeek"] = [];
                siteDataObject["onsiteLinenChartWithItem"] = [];
                siteWithChartArray.push({...siteDataObject});
            }

        });

        return siteWithChartArray;
    };

    const showSiteDataForPlant = (plantId, isExpandClick) => {
        if (plantAllDataWithSurvey && plantAllDataWithSurvey.length) {
            let plantAllDataWithSurveyArray = plantAllDataWithSurvey.map((plantAllDataWithSurveyObj) => {
                if (plantId && plantAllDataWithSurveyObj.plant === plantId) {
                    let displayValue = !plantAllDataWithSurveyObj.show;
                    plantAllDataWithSurveyObj.show = displayValue;
                } else {
                    plantAllDataWithSurveyObj.show = isExpandClick;
                }
                return plantAllDataWithSurveyObj;
            });

            setPlantAllDataWithSurvey(plantAllDataWithSurveyArray);
            setPlantsAllDataWithSurvey(plantAllDataWithSurveyArray);
        }

        if (plantDataWithSurvey && plantDataWithSurvey.length) {
            let plantDataWithSurveyArray = plantDataWithSurvey.map((plantDataWithSurveyObj) => {
                if (plantId && plantDataWithSurveyObj.plant === plantId) {
                    let displayValue = !plantDataWithSurveyObj.show;
                    plantDataWithSurveyObj.show = displayValue;
                } else {
                    plantDataWithSurveyObj.show = isExpandClick;
                }
                return plantDataWithSurveyObj;
            });

            setPlantDataWithSurvey(plantDataWithSurveyArray);
            setPlantsDataWithSurvey(plantDataWithSurveyArray);
        }
    };
    /*Plant*/

    /*Idn*/
    const getAllIdnWithChartData = (fetchedValue, siteWithSurvey) => {
        let plantWithChartArray = [];

        let idnListArray = idnList.map(fetchedValueObj => {
            let objData = Object.assign({}, fetchedValueObj);
            return objData;
        });

        idnListArray.forEach((idnListObj) => {
            let siteLinearDataObject = {...idnListObj};

            let siteLinearData = [];
            let siteAllLinearData = [];

            fetchedValue.map(fetchedValueObj => {
                let objData = Object.assign({}, fetchedValueObj);
                let flag = (siteLinearDataObject.sites).indexOf(objData.site);
                if (flag !== -1) {
                    siteLinearData.push(objData);
                }
            });

            siteWithSurvey.map(fetchedValueObj => {
                let objData = Object.assign({}, fetchedValueObj);
                let flag = (siteLinearDataObject.sites).indexOf(objData.site);
                if (flag !== -1) {
                    siteAllLinearData.push(objData);
                }
            });

            if (siteLinearData && siteLinearData.length && siteLinearData.length > 0) {
                let data = getIdnWithChartData(siteLinearData, siteAllLinearData, idnListObj.idn, siteLinearDataObject.sites);
                plantWithChartArray.push(data[0]);
            } else {
                let newIdnData = {};

                newIdnData["show"] = false;
                newIdnData["sites"] = [];
                newIdnData["idn"] = idnListObj.idn;

                newIdnData["linenChartWithQADate"] = [];
                newIdnData["linenChartWithDDate"] = [];
                newIdnData["clientSatChartWithOPDate"] = [];
                newIdnData["onsiteLinenChartWithIDate"] = [];

                newIdnData["linenQualityChart"] = [];
                newIdnData["linenQualityChartByWeek"] = [];
                newIdnData["linenQualityBarChart"] = [];

                newIdnData["linenQualityChartAvg"] = 0;
                newIdnData["linenQualityChartTotal"] = 0;
                newIdnData["linenQualityChartCount"] = 0;

                newIdnData["linenQuantityChartAvg"] = 0;
                newIdnData["linenQuantityChartTotal"] = 0;
                newIdnData["linenQuantityChartCount"] = 0;

                newIdnData["linenTotal"] = 0;
                newIdnData["linenAvg"] = 0;

                newIdnData["quantityChart"] = [];
                newIdnData["quantityChartAvg"] = 0;
                newIdnData["quantityChartTotal"] = 0;
                newIdnData["quantityChartCount"] = 0;

                newIdnData["clientSatChart"] = [];
                newIdnData["clientSatChartAvg"] = 0;
                newIdnData["clientSatChartTotal"] = 0;
                newIdnData["clientSatChartCount"] = 0;

                newIdnData["onsiteLinenChart"] = [];
                newIdnData["onsiteLinenChartAvg"] = 0;
                newIdnData["onsiteLinenChartTotal"] = 0;
                newIdnData["onsiteLinenChartCount"] = 0;
                newIdnData["onsiteLinenChartTotalImperfection"] = 0;

                newIdnData["linenQualityChartWithWeek"] = [];
                newIdnData["linenQualityChartWithDept"] = [];
                newIdnData["clientSatChartWithWeek"] = [];
                newIdnData["onsiteLinenChartWithItem"] = [];
                newIdnData["sites"] = siteAllLinearData;
                plantWithChartArray.push(newIdnData);
            }
        });

        return plantWithChartArray;
    };

    const getIdnWithChartData = (fetchedValue, siteWithSurvey, idn, idnSiteArray) => {
        let plantWithChartArray = [];
        let siteLinearData = fetchedValue.map(fetchedValueObj => {
            let objData = Object.assign({}, fetchedValueObj);
            return objData;
        });

        siteLinearData.forEach((siteLinearDataObj, index) => {
            let siteLinearDataObject = {...siteLinearDataObj};

            // Add unique object in plantArray with new key as "chart"
            if (index === 0) {
                siteLinearDataObject["show"] = false;
                siteLinearDataObject["sites"] = [];
                siteLinearDataObject["idn"] = idn;

                siteLinearDataObject["linenChartWithQADate"] = [];
                siteLinearDataObject["linenChartWithDDate"] = [];
                siteLinearDataObject["clientSatChartWithOPDate"] = [];
                siteLinearDataObject["onsiteLinenChartWithIDate"] = [];

                siteLinearDataObject["linenQualityChart"] = [];
                siteLinearDataObject["linenQualityChartByWeek"] = [];
                siteLinearDataObject["linenQualityBarChart"] = [];

                siteLinearDataObject["linenQualityChartAvg"] = 0;
                siteLinearDataObject["linenQualityChartTotal"] = 0;
                siteLinearDataObject["linenQualityChartCount"] = 0;

                siteLinearDataObject["linenQuantityChartAvg"] = 0;
                siteLinearDataObject["linenQuantityChartTotal"] = 0;
                siteLinearDataObject["linenQuantityChartCount"] = 0;

                siteLinearDataObject["linenTotal"] = 0;
                siteLinearDataObject["linenAvg"] = 0;

                siteLinearDataObject["quantityChart"] = [];
                siteLinearDataObject["quantityChartAvg"] = 0;
                siteLinearDataObject["quantityChartTotal"] = 0;
                siteLinearDataObject["quantityChartCount"] = 0;

                siteLinearDataObject["clientSatChart"] = [];
                siteLinearDataObject["clientSatChartAvg"] = 0;
                siteLinearDataObject["clientSatChartTotal"] = 0;
                siteLinearDataObject["clientSatChartCount"] = 0;

                siteLinearDataObject["onsiteLinenChart"] = [];
                siteLinearDataObject["onsiteLinenChartAvg"] = 0;
                siteLinearDataObject["onsiteLinenChartTotal"] = 0;
                siteLinearDataObject["onsiteLinenChartCount"] = 0;
                siteLinearDataObject["onsiteLinenChartTotalImperfection"] = 0;

                siteLinearDataObject["linenQualityChartWithWeek"] = [];
                siteLinearDataObject["linenQualityChartWithDept"] = [];
                siteLinearDataObject["clientSatChartWithWeek"] = [];
                siteLinearDataObject["onsiteLinenChartWithItem"] = [];

                if (siteLinearDataObject.QualityDate) {
                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };
                    siteLinearDataObject["linenChartWithQADate"].push(rawData);
                    siteLinearDataObject["linenQualityChart"].push(qualityChart);
                    siteLinearDataObject["linenQualityChartTotal"] = siteLinearDataObject.Quality;
                    siteLinearDataObject["linenQuantityChartTotal"] = siteLinearDataObject.Availability;
                    siteLinearDataObject["linenQualityChartCount"] = 1;
                    siteLinearDataObject["linenQuantityChartCount"] = 1;
                    siteLinearDataObject["linenTotal"] = siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                }

                if (siteLinearDataObject.QualityDept) {
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };
                    siteLinearDataObject["linenChartWithDDate"].push(rawData);
                    siteLinearDataObject["linenQualityBarChart"].push(quantityChart);
                }

                if (siteLinearDataObject.ClientSatDate) {
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };
                    siteLinearDataObject["clientSatChartWithOPDate"].push(rawData);
                    siteLinearDataObject["clientSatChart"].push(clientChart);
                    siteLinearDataObject["clientSatChartTotal"] = siteLinearDataObject.ClientSat;
                    siteLinearDataObject["clientSatChartCount"] = 1;
                }

                if (siteLinearDataObject.OnsiteLinenDate) {
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });
                    siteLinearDataObject["onsiteLinenChartWithIDate"].push(rawData);
                    siteLinearDataObject["onsiteLinenChart"].push(onsiteLinenChart);
                    siteLinearDataObject["onsiteLinenChartTotal"] = siteLinearDataObj.OnsiteLinenInspected;
                    siteLinearDataObject["onsiteLinenChartTotalImperfection"] = siteLinearDataObj.OnsiteLinenImperfection;
                    siteLinearDataObject["onsiteLinenChartCount"] = 1;
                }

                siteLinearDataObject["sentimentScore"] = getSentimentForIdn(sentimentDataWithSurvey, "WeekValue", idnSiteArray, "site");
                siteLinearDataObject["sites"] = siteWithSurvey;
                siteLinearDataObject["idnSiteArray"] = idnSiteArray;
                plantWithChartArray.push(siteLinearDataObject);
            } else {
                let resultObject = plantWithChartArray[0];
                let date = "";
                if (siteLinearDataObject.QuantityWeek) {
                    date = siteLinearDataObject.QuantityWeek;
                }

                if (date) {
                    let linenQualityChartObject = searchArray(date, resultObject["linenQualityChart"], "Week");
                    let qualityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7,
                        "Week": siteLinearDataObject.QuantityWeek,
                        "WeekStart": siteLinearDataObject.QuantityWeekStart,
                        "WeekRange": siteLinearDataObject.QuantityWeekRange
                    };

                    if (linenQualityChartObject) {
                        linenQualityChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityChart"].push(qualityChart);
                    }
                    resultObject["linenChartWithQADate"].push(rawData);
                    resultObject["linenQualityChartTotal"] += siteLinearDataObject.Quality;
                    resultObject["linenQuantityChartTotal"] += siteLinearDataObject.Availability;
                    resultObject["linenQualityChartCount"] += 1;
                    resultObject["linenQuantityChartCount"] += 1;
                    resultObject["linenTotal"] += siteLinearDataObject.Quality + siteLinearDataObject.Availability;
                }

                let linenDepartment = "";
                if (siteLinearDataObject.QualityDept) {
                    linenDepartment = siteLinearDataObject.QualityDept;
                }

                if (linenDepartment) {
                    let linenQualityBarChartObject = searchArray(linenDepartment, resultObject["linenQualityBarChart"], "Dept");
                    let quantityChart = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.QualityDate,
                        "Dept": siteLinearDataObject.QualityDept,
                        "Quality": siteLinearDataObject.Quality,
                        "Availability": siteLinearDataObject.Availability,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (linenQualityBarChartObject) {
                        linenQualityBarChartObject.Quality += siteLinearDataObject.Quality;
                        linenQualityBarChartObject.Availability += siteLinearDataObject.Availability;
                        linenQualityBarChartObject.Count += 1;
                    } else {
                        resultObject["linenQualityBarChart"].push(rawData);
                    }
                    resultObject["linenChartWithDDate"].push(quantityChart);
                }

                let clientWeek = "";
                if (siteLinearDataObject.ClientSatWeek) {
                    clientWeek = siteLinearDataObject.ClientSatWeek;
                }

                if (clientWeek) {
                    let clientSatChartObject = searchArray(clientWeek, resultObject["clientSatChart"], "Week");
                    let clientChart = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.ClientSatDate,
                        "Dept": siteLinearDataObject.ClientSatDept,
                        "Week": siteLinearDataObject.ClientSatWeek,
                        "WeekStart": siteLinearDataObject.ClientSatWeekStart,
                        "WeekRange": siteLinearDataObject.ClientSatWeekRange,
                        "Overall Performance": siteLinearDataObject.ClientSat,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    if (clientSatChartObject) {
                        clientSatChartObject["Overall Performance"] += siteLinearDataObject.ClientSat;
                        clientSatChartObject.Count += 1;
                    } else {
                        resultObject["clientSatChart"].push(clientChart);
                    }
                    resultObject["clientSatChartWithOPDate"].push(rawData);
                    resultObject["clientSatChartTotal"] += siteLinearDataObject.ClientSat;
                    resultObject["clientSatChartCount"] += 1;
                }

                let itemForOnSite = "";
                if (siteLinearDataObject.OnsiteLinenItem) {
                    itemForOnSite = siteLinearDataObject.OnsiteLinenItem;
                }

                if (itemForOnSite) {
                    let onsiteLinenChartObject = searchArray(itemForOnSite, resultObject["onsiteLinenChart"], "Item");
                    let onsiteLinenChart = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    let rawData = {
                        "Date": siteLinearDataObject.OnsiteLinenDate,
                        "Item": siteLinearDataObject.OnsiteLinenItem,
                        "Inspected": siteLinearDataObj.OnsiteLinenInspected,
                        "Flaw": siteLinearDataObj.OnsiteLinenImperfection,
                        "Count": 1,
                        "ReferenceValue": 7
                    };

                    linenImperfection.forEach((linenImperfectionObj) => {
                        if (siteLinearDataObject[linenImperfectionObj]) {
                            onsiteLinenChart[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                        }
                    });

                    if (onsiteLinenChartObject) {
                        onsiteLinenChartObject.Inspected += siteLinearDataObject.OnsiteLinenInspected;
                        onsiteLinenChartObject.Flaw += siteLinearDataObject.OnsiteLinenImperfection;
                        onsiteLinenChartObject.Count += 1;

                        linenImperfection.forEach((linenImperfectionObj) => {
                            if (siteLinearDataObject[linenImperfectionObj] && onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] += siteLinearDataObject[linenImperfectionObj];
                            } else if (siteLinearDataObject[linenImperfectionObj] && !onsiteLinenChartObject[linenImperfectionObj]) {
                                onsiteLinenChartObject[linenImperfectionObj] = siteLinearDataObject[linenImperfectionObj];
                            }
                        });
                    } else {
                        resultObject["onsiteLinenChart"].push(onsiteLinenChart);
                    }
                    resultObject["onsiteLinenChartWithIDate"].push(rawData);
                    resultObject["onsiteLinenChartTotal"] += siteLinearDataObj.OnsiteLinenInspected;
                    resultObject["onsiteLinenChartTotalImperfection"] += siteLinearDataObj.OnsiteLinenImperfection;
                    resultObject["onsiteLinenChartCount"] += 1;
                }
            }
        });

        let chartForPlant = getChartByAllWeek(plantWithChartArray);
        return chartForPlant;
    };

    const showSiteDataForIdn = (idn, isExpandClick) => {
        if (idnAllDataWithSurvey && idnAllDataWithSurvey.length) {
            let plantAllDataWithSurveyArray = idnAllDataWithSurvey.map((plantAllDataWithSurveyObj) => {
                if (idn && plantAllDataWithSurveyObj.idn === idn) {
                    let displayValue = !plantAllDataWithSurveyObj.show;
                    plantAllDataWithSurveyObj.show = displayValue;
                } else {
                    plantAllDataWithSurveyObj.show = isExpandClick;
                }
                return plantAllDataWithSurveyObj;
            });

            setIdnAllDataWithSurvey(plantAllDataWithSurveyArray);
            setIdnsAllDataWithSurvey(plantAllDataWithSurveyArray);
        }

        if (idnDataWithSurvey && idnDataWithSurvey.length) {
            let plantDataWithSurveyArray = idnDataWithSurvey.map((plantDataWithSurveyObj) => {
                if (idn && plantDataWithSurveyObj.idn === idn) {
                    let displayValue = !plantDataWithSurveyObj.show;
                    plantDataWithSurveyObj.show = displayValue;
                } else {
                    plantDataWithSurveyObj.show = isExpandClick;
                }
                return plantDataWithSurveyObj;
            });

            setIdnDataWithSurvey(plantDataWithSurveyArray);
            setIdnsDataWithSurvey(plantDataWithSurveyArray);
        }
    };
    /*Idn*/

    /*Export Report Data*/
    const getChartDetail = (chartValue, reportData, key, sites, count = 0, inspect = 0, flaw = 0) => {
        let dataExportBySurvey = [];
        let rowHeader = [];

        if (chartValue === 4) {
            dataExportBySurvey = getSurveyReportBody(allsurveyLists, sitesData, "Client Satisfaction", key, sites);
            rowHeader = getSurveyReportHeader(surveyData, "Client Satisfaction");
            setChartHeader("Client Satisfaction");
            setChartValue(1)
        } else if (chartValue === 1 || chartValue === 2) {
            dataExportBySurvey = getSurveyReportBody(allsurveyLists, sitesData, "Linen Quality & Availability", key, sites);
            rowHeader = getSurveyReportHeader(surveyData, "Linen Quality & Availability");
            setChartHeader("Linen Quality & Availability");
            setChartValue(1)
        } else if (chartValue === 3) {
            dataExportBySurvey = getSurveyReportBody(allsurveyLists, sitesData, "Onsite Linen Inspection", key, sites, linenItem, linenImperfection);
            rowHeader = getSurveyReportHeader(surveyData, "Onsite Linen Inspection");
            setChartHeader("Onsite Imperfections");
            setChartValue(2)
        }

        setChartRowHeader(rowHeader);
        setChartTableData(dataExportBySurvey);
        setChartCount(count);
        setShowChartModal(true);
        if (inspect) {
            setChartImperfection(formatNumberWithCommas(inspect));
        } else {
            setChartImperfection(inspect);
        }
        if (flaw) {
            setChartFlaw(formatNumberWithCommas(flaw));
        } else {
            setChartFlaw(flaw);
        }

        let exportArray = {
            "SurveyData": dataExportBySurvey
        };
        setSurveyDataExport(exportArray)
    };

    const exportReportData = () => {
        exportSurveyData(surveyDataExport, "surveyData.xlsx");
    };
    /*Export Report Data*/

    const handleCloseChartModal = () => {
        setShowChartModal(false);
    };

    /*Search*/
    const changeInputHandler = (event) => {
        setSearchTerm(event.currentTarget.value);
        searchSiteData(event.currentTarget.value);
    };

    const searchSiteData = (searchValue) => {
        // Facility Search
        if (sitesAllDataWithSurvey && sitesAllDataWithSurvey.length) {
            let filteredData = [];

            sitesAllDataWithSurvey.forEach((searchObj) => {
                let value = {...searchObj};
                let valueInLowerCase = (value.site + "").toLowerCase();
                let search = (searchValue + "").toLowerCase();
                let data = valueInLowerCase.match(search);

                if (data) {
                    filteredData.push(value);
                }

            });

            setSiteAllDataWithSurvey(filteredData);
        }

        if (sitesDataWithSurvey && sitesDataWithSurvey.length) {
            let filteredData = [];

            sitesDataWithSurvey.forEach((searchObj) => {
                let value = {...searchObj};
                let valueInLowerCase = (value.site + "").toLowerCase();
                let search = (searchValue + "").toLowerCase();
                let data = valueInLowerCase.match(search);
                if (data) {
                    filteredData.push(value);
                }
            });

            setSiteDataWithSurvey(filteredData);
        }
    };
    /*Search*/

    /*Collapse*/
    const handleCollapse = () => {
        let collapseData = !isExpanded;
        setIsExpanded(collapseData);
        showSiteDataForPlant("", collapseData);
        showSiteDataForIdn("", collapseData);
    };
    /*Collapse*/

    const handleChartColChange = (event) => {
        const currentFormState = handleCheckboxInputChange(event, chartColumn);
        setChartColumn(currentFormState);
    };

    const handleChartColVisibility = (event) => {
        let isHideAll = event.currentTarget.getAttribute("name");
        let data = true;

        if (isHideAll === "hide") {
            data = false;
        }

        let updatedData = {
            chart1: data,
            chart2: data,
            chart3: data,
            chart4: data,
            chart5: data,
        };

        setChartColumn(updatedData);
    };

    const showPlantData = () => {
        let displayValue = showPlant;
        setShowPlant(!displayValue);

        if (!displayValue) {
            setShowIdn(false);
        }
    };

    const showIdnData = () => {
        let displayValue = showIdn;
        setShowIdn(!displayValue);

        if (!displayValue) {
            setShowPlant(false);
        }
    };

    const showFacilityWithoutData = () => {
        let displayValue = showSiteWithoutData;
        setShowSiteWithoutData(!displayValue);
    };

    const handleChartFullScreen = () => {
        let displayValue = showChartFullScreen;
        setShowChartFullScreen(!displayValue)
    };

    const handleClose = () => {
        setShowPlantModal(false);
    };

    const showModal = () => {
        setShowPlantModal(true);
    };

    const getChartFullScreenDetail = (value, keyValue, keyData) => {
        setChartFullScreenData({
            "keyValue": keyValue,
            "keyDate": keyData,
            "value": value
        });
        handleChartFullScreen();
    };

    /*Multi Select*/
    const updateMultiSelectOption = (fetchedValue) => {
        return fetchedValue.map((obj) => {
            return {
                label: obj,
                value: false
            };
        });
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        if (name === "dept") {
            setDeptList(newData);
            setFormValue({...formValue, selectAllDept: checked, deptCount: count});

            let flag = false;
            let updatedData = [];

            // Display default values when no departments are selected
            if (newData && newData.length) {
                newData.forEach((newDataObj) => {
                    if (newDataObj.value) {
                        flag = true;
                    }
                })
            }

            if (flag) {
                updatedData = newData;
            } else {
                newData.forEach((newDataObj) => {
                    let obj = {
                        label: newDataObj.label,
                        value: true
                    };

                    updatedData.push(obj);
                })
            }

            let linearSurveyStructure = getLinearSurveyStructure(allsurveyLists, updatedData, false);
            linearSurveyStructure.sort(function (a, b) {
                return (a["site"]).localeCompare(b["site"])
            });

            let dataArrayOfSiteWithSurvey = getLinearSurveyStructureByWeek(linearSurveyStructure, "site");
            let dataWithAllSiteValue = getAllSiteWithChartData(dataArrayOfSiteWithSurvey);

            let dataArrayOfSiteWithSurveySort = sortArrayData(dataArrayOfSiteWithSurvey, "site");
            let dataWithAllSiteValueSort = sortArrayData(dataWithAllSiteValue, "site");

            setSiteAllDataWithSurvey(dataWithAllSiteValueSort);
            setSitesAllDataWithSurvey(dataWithAllSiteValueSort);

            setSiteDataWithSurvey(dataArrayOfSiteWithSurveySort);
            setSitesDataWithSurvey(dataArrayOfSiteWithSurveySort);

            let dataArrayOfPlantWithSurvey = getAllPlantWithChartData(linearSurveyStructure, "plant", dataArrayOfSiteWithSurvey);
            let dataWithAllPlantValue = getAllPlantWithChartData(linearSurveyStructure, "plant", dataWithAllSiteValue);

            let dataArrayOfPlantWithSurveySort = sortArrayData(dataArrayOfPlantWithSurvey, "plant");
            let dataWithAllPlantValueSort = sortArrayData(dataWithAllPlantValue, "plant");

            setPlantAllDataWithSurvey(dataWithAllPlantValueSort);
            setPlantsAllDataWithSurvey(dataWithAllPlantValueSort);

            setPlantDataWithSurvey(dataArrayOfPlantWithSurveySort);
            setPlantsDataWithSurvey(dataArrayOfPlantWithSurveySort);

            let dataArrayOfIdnWithSurvey = getAllIdnWithChartData(linearSurveyStructure, dataArrayOfSiteWithSurvey);
            let dataWithAllIdnValue = getAllIdnWithChartData(linearSurveyStructure, dataWithAllSiteValue);

            let dataArrayOfIdnWithSurveySort = sortArrayData(dataArrayOfIdnWithSurvey, "idn");
            let dataWithAllIdnValueSort = sortArrayData(dataWithAllIdnValue, "idn");

            setIdnAllDataWithSurvey(dataWithAllIdnValueSort);
            setIdnsAllDataWithSurvey(dataWithAllIdnValueSort);

            setIdnDataWithSurvey(dataArrayOfIdnWithSurveySort);
            setIdnsDataWithSurvey(dataArrayOfIdnWithSurveySort);
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "dept") {
            let flag = formValue.showDept;

            if (!flag) {
                let data = updateMultiSelectSearch(deptLists, "label", "", deptList);
                setDeptList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showDept: !flag, selectAllDept: selectedAll});
            } else {
                setFormValue({...formValue, showDept: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "dept") {
            setFormValue({...formValue, selectAllDept: false});
            let data = updateMultiSelectSearch(deptLists, "label", value, deptList);
            setDeptList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllDept: selectedAll});
        }
    };
    /*Multi Select*/

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <ReportTableModal rowHeader={chartRowHeader} tableData={chartTableData} showReportTable={showChartModal}
                              handleClose={handleCloseChartModal} header={chartHeader}
                              exportReportData={exportReportData}
                              chartCount={chartCount} chartImperfection={chartImperfection} chartFlaw={chartFlaw}
                              chartValue={chartValue}/>
            <ChartFullScreenModal showModal={showChartFullScreen} handleClose={handleChartFullScreen}
                                  chartCount={chartCount} value={chartFullScreenData.value}
                                  keyValue={chartFullScreenData.keyValue}
                                  chartColumn={chartColumn} keyDate={chartFullScreenData.keyDate}
                                  getChartDetail={getChartDetail} exportReportData={exportReportData}/>
            <Button onClick={showPlantData} className={showPlant ? "buttonCss" : "buttonDisableCss"}>
                {!showPlant ? "Show Plant" : "Hide Plant"}
            </Button>
            <Button onClick={showIdnData} className={showIdn ? "buttonCss" : "buttonDisableCss"}>
                {!showIdn ? "Show IDN" : "Hide IDN"}
            </Button>
            <Button onClick={showFacilityWithoutData}
                    className={showSiteWithoutData ? "buttonCss" : "buttonDisableCss"}>
                {!showSiteWithoutData ? "Show All Facility" : "Hide Facility Without Data"}
            </Button>
            <Button className="buttonCss" onClick={handleCollapse}>{isExpanded ? "Collapse" : "Expand"}</Button>
            <Button className="buttonCss" onClick={showModal}>Export</Button>
            {/*<MultiColumnSelection chartColumn={chartColumn} handleChartColChange={handleChartColChange}
                                  handleChartColVisibility={handleChartColVisibility}/>*/}

            <div className="form-group row searchInput">
                <label className="col-form-label col-sm-1" htmlFor="dept">Department: </label>
                <div className="col-sm-2" ref={innerRef}>
                    <input type="text"
                           className="form-control"
                           onClick={handleClick} readOnly={true}
                           value={`${formValue.deptCount} selected`} name="dept"/>
                    {
                        formValue.showDept &&
                        <div className="selectDiv text-start px-sm-1">
                            <MultiSelect show={formValue.showDept} data={deptList}
                                         handleCheckboxChange={handleCheckboxChange}
                                         allSelected={formValue.selectAllDept} label="dept"
                                         handleSearch={handleSearch}/>
                        </div>
                    }
                </div>
                <div className="col-sm-9">
                    {!showIdn && !showPlant && <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            type="text"
                            value={searchTerm}
                            onChange={changeInputHandler}
                        />
                        <InputGroup.Text id="basic-addon2">
                            Space Separated Search For Multi Site Only On Site Visibility
                        </InputGroup.Text>
                    </InputGroup>}
                </div>
            </div>

            <div className="tableContainer">
                <table className="fixedHeaderTable">
                    <thead>
                    <tr>
                        <th className="colTitle">Title</th>
                        {chartColumn.chart1 && <th className="colChart">Linen Quality & Availability</th>}
                        {chartColumn.chart2 && <th className="colChart">Dept Linen Feedback</th>}
                        {chartColumn.chart3 && <th className="colChart">Onsite Imperfections</th>}
                        {chartColumn.chart4 && <th className="colChart">Client Sat Score</th>}
                        <th className="colSentiment" tooltip="">Sentiment
                            <OverlayTrigger
                                placement="left"
                                delay={{show: 250, hide: 400}}
                                overlay={<Tooltip id="button-tooltip-2">Last 4-week average of quality, availability,
                                    and overall performance score compared to baseline 4.</Tooltip>}
                            ><FontAwesomeIcon icon={faInfoCircle} className="mx-1 fs-7"/></OverlayTrigger></th>

                    </tr>
                    </thead>
                    <tbody>
                    {!showIdn && !showPlant &&
                    <FacilitySiteData sites={showSiteWithoutData ? siteAllDataWithSurvey : siteDataWithSurvey}
                                      linenImperfection={linenImperfection}
                                      getChartFullScreenDetail={getChartFullScreenDetail}
                                      getChartDetail={getChartDetail} chartColumn={chartColumn}/>
                    }
                    {showPlant &&
                    <FacilityPlantData plants={showSiteWithoutData ? plantAllDataWithSurvey : plantDataWithSurvey}
                                       linenImperfection={linenImperfection}
                                       getChartFullScreenDetail={getChartFullScreenDetail}
                                       getChartDetail={getChartDetail} chartColumn={chartColumn}
                                       showSiteDataForPlant={showSiteDataForPlant}/>
                    }
                    {showIdn &&
                    <FacilityIdnData plants={showSiteWithoutData ? idnAllDataWithSurvey : idnDataWithSurvey}
                                     linenImperfection={linenImperfection}
                                     getChartFullScreenDetail={getChartFullScreenDetail}
                                     getChartDetail={getChartDetail} chartColumn={chartColumn}
                                     showSiteDataForIdn={showSiteDataForIdn}/>
                    }
                    </tbody>
                </table>
            </div>
            {surveysData && surveysData.length > 0 &&
            <PlantReportModal showPlantModal={showPlantModal}
            handleClose={handleClose} isFacilityView={true} surveysArray={surveysData}/>}
        </Container>
    )
};

export default FacilityScreen;