import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const SurveyComputedReducer = createSlice({
    name: "surveyComputedData",
    initialState,
    reducers: {
        setSurveyComputedData: (state, action) => {
            state.length = 0;
            action.payload.map(obj => {
                state.push(obj);
            });
        }
    }
});
export const {setSurveyComputedData} = SurveyComputedReducer.actions;

export default SurveyComputedReducer.reducer;