import React from "react";
import coreLogo from "../../assets/CoreLinenLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Header from "./Header";
import {Link} from "react-router-dom";

function TopContainer() {
    const logoClicked = () => {
        window.localStorage.setItem('selectedNavigation',"");
    };

    return (
        <>
            <Row className="">
                <Col  sm={2} className="border-left loginImageBorder" onClick={logoClicked}>
                    <div className="sidebar-header ">
                        <Link to="/dashboard"><Image src={coreLogo} className="fluid rounded thumbnail companyLogo"/></Link>
                    </div>
                </Col>
                <Col sm={10}>
                    <Header/>
                </Col>
            </Row>
        </>
    );
}

export default TopContainer;