import React, {useEffect, useRef, useState} from 'react';
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import ToggleMicButton from "./ToggleMicButton";

export const SpeechToText = ({resetComment, fieldName, handleChange, required, inputValidation, name = "", inputValueData}) => {
    const [inputValue, setInputValue] = useState('');
    const [micIsOn, setMicIsOn] = useState(false);
    const textInput = useRef(null);
    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
        finalTranscript
    } = useSpeechRecognition();

    useEffect(() => {
        console.log(inputValueData)

        if (inputValueData) {
            console.log(inputValueData)
            setInputValue(inputValueData);
        }
    }, [inputValueData]);

    useEffect(() => {
        if (resetComment) {
            resetSpeech();
        }
    }, [resetComment]);

    function resetSpeech() {
        resetTranscript();
        setInputValue("");
    }

    useEffect(() => {
        updateTranscript();
    }, [finalTranscript]);

    const handleMicChange = (newState) => {
        setMicIsOn(newState);

        if (newState) {
            resetTranscript();
            textInput.current.focus();
            SpeechRecognition.startListening({continuous: true});
        } else {
            SpeechRecognition.abortListening();
        }
    };

    const getValueOnChange = (e) => {
        setInputValue(e.currentTarget.value);
        handleChange(e);
    };

    const updateTranscript = () => {
        if(fieldName == document.activeElement.name) {
            let inputData = inputValue;
            let speechObject = {
                "transcript":  inputData + " " + finalTranscript,
                "name": name ? name : fieldName,
            };

            handleChange(speechObject, "speechToText");
            setInputValue(speechObject.transcript);
            resetTranscript();
        }
    };

    const updateTranscriptOnBlur = (e) => {
        if(micIsOn) {
            let finalData = "";

            if (e.currentTarget.value && transcript) {
                finalData = e.currentTarget.value + " " + transcript;
            } else if (e.currentTarget.value && !transcript) {
                finalData = e.currentTarget.value;
            } else if (!e.currentTarget.value && transcript) {
                finalData = transcript;
            }

            let speechObject = {
                "transcript": finalData,
                "name": name ? name : fieldName,
            };

            handleChange(speechObject, "speechToText");
            setInputValue(speechObject.transcript);
            setMicIsOn(false);
            SpeechRecognition.abortListening();
        } else {
            setInputValue(e.currentTarget.value);
            handleChange(e);
        }

    };

    return (
        <>
            <label htmlFor={name ? name : fieldName} className="col-form-label">
                {required && <span className="text-danger starCss">*</span>}
                {fieldName}
                {browserSupportsSpeechRecognition &&
                <ToggleMicButton micIsOn={micIsOn} handleMicChange={handleMicChange} resetSpeech={resetSpeech}
                                 isMicrophoneAvailable={isMicrophoneAvailable}/>
                }
            </label>
            <div>
                {required ?
                    <textarea name={name ? name : fieldName}
                                      value={inputValue}
                                      onChange={getValueOnChange}
                                      ref={textInput}
                                      onBlur={updateTranscriptOnBlur}
                                      className={`form-control ${inputValidation ? "" : "errorDiv"}`}
                    />
                    :
                    <textarea name={name ? name : fieldName }
                              value={inputValue}
                              onChange={getValueOnChange}
                              ref={textInput}
                              onBlur={updateTranscriptOnBlur}
                              className="form-control"
                    />
                }

            </div>
        </>

    );
};