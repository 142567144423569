import React, {useContext, useEffect, useState} from "react";
import axios from '../../apis/AxiosInstance';
import useAxiosFunction from "../../hook/AxiosHook";
import {handleCheckboxInputChange, handleInputChange} from "../../commom/FormHandler";
import {
    addRecipientsIfFieldHasValue, getArrayData, getFileExtension, getMimeType,
    getSelectedAllFlag,
    getSelectedArray,
    getSelectedPlantConfigArray,
    getSelectedPlantMasterData, getSurveyDataGroupBySite,
    getUserAccessToUrl, specificArrayData, specificArrayDataWithActive, trimObjectValues,
    updateMultiSelectOption,
    updateMultiSelectSearch
} from "../../commom/CommonFunction";
import {
    getCurrentDateOnly,
    getDateInUTC
} from "../../commom/DateFormat";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AuthContext} from "../../context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./SamSummaryOfDay.css";
import Message from "../../commom/Message";
import {useOuterClick} from "../../hook/VisibilityHook";
import SummaryOfDayTable from "../summaryOfDay/SummaryOfDayTable";
import {validationCheck} from "../../commom/ValidateFormData";
import SamPdfGenerator from "./SamPdfGenerator";
import SamSummaryOfDayForm from "./SamSummaryOfDayForm";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/fontawesome-free-solid";

const SamSummaryOfDay = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const user = useSelector((state) => state.userState.user);
    const [formValue, setFormValue] = useState({
        sam: "",
        defaultDate: false,
        dateValue: getCurrentDateOnly(),
        gmEmail: "",
        rdoEmail: "",
        userName: "",
        emailAddress: "",
        topIssues: "",
        emailSubject: "SAM Daily Summary",
        contactName: "",
        noteToManagement: "",
        showGM: false,
        selectAllGM: false,
        gmCount: 0,
        showRDO: false,
        selectAllRDO: false,
        rdoCount: 0,
        userId: "",
        userEmail: "",
        images: []
    });

    const [siteList, setSiteList] = useState();
    const [plantList, setPlantList] = useState();
    const [sitesData, setSitesData] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const [samList, setSamList] = useState();
    const [rdoList, setRdoList] = useState();
    const [gmList, setGmList] = useState();
    const [rdoLists, setRdoLists] = useState();
    const [gmLists, setGmLists] = useState();
    const [surveyData, setSurveyData] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [surveyGroupBySite, setSurveyGroupBySite] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showEmailMessage, setShowEmailMessage] = useState(true);
    const [emailValidationMessage, setEmailValidationMessage] = useState("Please fill the email address.");
    const [screenMessage, setScreenMessage] = useState("Export Pdf successfully.");
    const [imagePreviews, setImagePreviews] = useState([]);
    const [showImageScreen, setShowImageScreen] = useState(false);
    const [isSendEmail, setIsSendEmail] = useState(false);

    const innerGMRef = useOuterClick(() => {
        setFormValue({...formValue, showGM: false});
    });
    const innerRDORef = useOuterClick(() => {
        setFormValue({...formValue, showRDO: false});
    });

    useEffect(() => {
        dispatch(setDateRange());
        dispatch(showDateInHeader(false));

        getUserDetail().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;

            if (!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            setPlantList(response.plants);
            setSiteList(response.sites);
            setSitesData(response.sites);
            setSurveyData(response.surveys);

            let samDataArray = [];
            if(response.sites && response.sites.length) {
                samDataArray = specificArrayData(response.sites, "sam");
            }

            if(response.masterData && response.masterData.length) {
                let rdoData = getSelectedPlantMasterData("RDO", "type", response.masterData, "email");
                let gmData = getSelectedPlantMasterData("GM", "type", response.masterData, "email");
                let samData  = getSelectedPlantConfigArray("Sam", "type", response.masterData, "value");
                if(response.securityRoles.title !== "Admin") {
                    let newValue = [];
                    samData.forEach(obj => {
                        let objectData = Object.assign({}, obj);

                        if (samDataArray.indexOf(objectData.value) !== -1) {
                            newValue.push(objectData);
                        }
                    });
                    setSamList(newValue);

                    setFormValue({
                        ...formValue,
                        sam: newValue[0].value,
                        userId: user._id,
                        userEmail: user.email,
                        userName: user.firstName + " " + user.lastName
                    });
                } else {
                    setSamList(samData);
                    setFormValue({
                        ...formValue,
                        sam: samData[0].value,
                        userId: user._id,
                        userEmail: user.email,
                        userName: user.firstName + " " + user.lastName
                    });
                }

                let rdoArray = updateMultiSelectOption(rdoData, "email");
                let gmArray = updateMultiSelectOption(gmData, "email");

                setRdoList(rdoArray);
                setGmList(gmArray);
                setRdoLists(rdoArray);
                setGmLists(gmArray);
            }
        });
    }, []);

    useEffect(() => {
        if (formValue.sam && formValue.dateValue && surveyData) {
            createReportData();
        }
    }, [formValue.dateValue, formValue.sam, surveyData]);

    const handleLogoutFunction = () => {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetail = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailWithSiteAndMasterData',
            requestConfig: {
                "data": {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        let emailValue = getArrayData(newData);
        if (name === "rdoEmail") {
            setRdoList(newData);
            setFormValue({...formValue, selectAllRDO: checked, rdoCount: count, rdoEmail: emailValue});
        }

        if (name === "gmEmail") {
            setGmList(newData);
            setFormValue({...formValue, selectAllGM: checked, gmCount: count, gmEmail: emailValue});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "rdoEmail") {
            let flag = formValue.showRDO;

            if(!flag) {
                let data = updateMultiSelectSearch(rdoLists, "label", "", rdoList);
                setRdoList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showRDO: !flag, selectAllRDO: selectedAll});
            } else {
                setFormValue({...formValue, showRDO: !flag});

            }
        }

        if (name === "gmEmail") {
            let flag = formValue.showGM;

            if(!flag) {
                let data = updateMultiSelectSearch(gmLists, "label", "", gmList);
                setGmList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showGM: !flag, selectAllGM: selectedAll});
            } else {
                setFormValue({...formValue, showGM: !flag});

            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "rdoEmail") {
            let data = updateMultiSelectSearch(rdoLists, "label", value, rdoList);
            setRdoList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllRDO: selectedAll});
        }

        if (name === "gmEmail") {
            let data = updateMultiSelectSearch(gmLists, "label", value, gmList);
            setGmList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllGM: selectedAll});
        }
    };

    const updateListSelection = (event) => {
        if (event.currentTarget) {
            let eventObject = {
                "currentTarget": {
                    "value": event.currentTarget.innerText,
                    "name": event.currentTarget.getAttribute("name"),
                }
            };
            handleChange(eventObject)
        }
    };

    const handleCheckboxLabelClick = (event) => {
        setShowMessage(false);
        setShowEmailMessage(true);

        const currentFormState = handleCheckboxInputChange(event, formValue);
        setFormValue(currentFormState);
    };

    const handleChange = (event, flag = "") => {
        setShowMessage(false);
        setShowEmailMessage(true);

        if (!flag) {
            if(event.target && event.target.name === "images") {
                const files = Array.from(event.target.files);

                // Filter the files to only allow JPG and PNG formats
                const filteredFiles = files.filter(file =>
                    file.type === 'image/jpeg' || file.type === 'image/png'
                );
                const previews = filteredFiles.map(file => URL.createObjectURL(file));

                setFormValue((prevValue) => ({
                    ...prevValue,
                    images: [...prevValue.images, ...filteredFiles],
                }));

                setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
            } else {
                const currentFormState = handleInputChange(event, formValue);
                setFormValue(currentFormState);
            }
        } else {
            let newObj = {
                "currentTarget": {
                    "name": event.name,
                    "value": event.transcript
                }
            };
            const currentFormState = handleInputChange(newObj, formValue);
            setFormValue(currentFormState);
        }
    };

    const handleDeleteFile = (index) => {
        const updatedImages = formValue.images.filter((_, i) => i !== index);
        const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
        setFormValue({
            ...formValue,
            images: updatedImages,
        });
        setImagePreviews(updatedPreviews);
    };

    const getReportData = async () => {
        let siteArray = getSelectedArray(formValue.sam, "sam", siteList);
        let siteNameArray = specificArrayData(siteArray, "site");
        let surveyNameArray = specificArrayDataWithActive(surveyData, "title", "active");
        setShowEmailMessage(true);
        let filteredArray = {
            "sites": siteNameArray,
            "surveys": surveyNameArray,
            "dateValue": getDateInUTC(formValue.dateValue),
        };

        return await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getSamReportData',
            requestConfig: {
                data: filteredArray
            }
        })
    };

    const createReportData = () => {
        getReportData().then(response => {
            let surveyArray = getSurveyDataGroupBySite(response.data, surveyData);
            setSurveyGroupBySite(surveyArray);
        });
    };

    const submitHandler = async () => {
        setShowEmailMessage(true);
        setShowMessage(true);

        let newEntry = trimObjectValues({...formValue});
        newEntry.userId = user._id;
        newEntry.userEmail = user.email;
        newEntry.userName = user.firstName + " " + user.lastName;
        newEntry.surveyData = surveyGroupBySite;
        // Convert selected image files to base64 format
        const imagesBase64 = await Promise.all((newEntry.images).map(async (image) => {
            const data = await convertToBase64(image);
            return {
                data,
                extension: getFileExtension(image.name),
                mimeType: getMimeType(image),
                name: image.name,
            };
        }));
        newEntry.images = imagesBase64;
        let ss = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateSamSummaryOfDay',
            requestConfig: {
                data: newEntry
            }
        });
        setScreenMessage("The PDF has been saved successfully and exported.");
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const sendPDFToServer = async (pdfBuffer) => {
        setShowMessage(false);

        let senderEmail = addRecipientsIfFieldHasValue(formValue.gmEmail, formValue.rdoEmail, formValue.emailAddress);
        let checkEmail = validationCheck("email", formValue.emailAddress);
        if((senderEmail && senderEmail.length > 0) || checkEmail) {
            setShowEmailMessage(true);
        } else {
            if(!checkEmail) {
                setEmailValidationMessage("Please fil the email address in correct format.");
            } else {
                setEmailValidationMessage("Please fill the email address.");
            }
            setShowEmailMessage(false);
            return false;
        }

        let newEntry = trimObjectValues({...formValue});
        newEntry["pdfBuffer"] = Array.from(pdfBuffer);
        newEntry["senderEmail"] = senderEmail;
        newEntry.userId = user._id;
        newEntry.userEmail = user.email;
        newEntry.userName = user.firstName + " " + user.lastName;

        // Convert selected image files to base64 format
        const imagesBase64 = await Promise.all((newEntry.images).map(async (image) => {
            const data = await convertToBase64(image);
            return {
                data,
                extension: getFileExtension(image.name),
                mimeType: getMimeType(image),
                name: image.name,
            };
        }));
        newEntry.images = imagesBase64;
        setShowMessage(true);
        try {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/sendSamPdfAttachmentByEmail',
                requestConfig: {
                    data: newEntry,
                },
            });

            if (response.data === "Successfully sent an email!") {
                setScreenMessage("The PDF has been saved successfully and sent via email.");
            } else {
                setScreenMessage("Failed to send PDF. Please try again.");
            }
            setIsSendEmail(false);
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading || showLoading}/>
            <Message showMessage={showMessage}
                     response={response} error={error} loading={loading}
                     formValidation={showEmailMessage} formValidationMessage={emailValidationMessage}
                     message={screenMessage}/>
            <div className="justify-content-md-center">
                <SamSummaryOfDayForm handleChange={handleChange} formValue={formValue} samList={samList}
                                  response={response} error={error} loading={loading}
                                  updateListSelection={updateListSelection} showEmailMessage={showEmailMessage}
                                  handleCheckboxLabelClick={handleCheckboxLabelClick} rdoList={rdoList} gmList={gmList}
                                  innerGMRef={innerGMRef} innerRDORef={innerRDORef} handleClick={handleClick}
                                  handleSearch={handleSearch} handleCheckboxChange={handleCheckboxChange} imagePreviews={imagePreviews}
                />
            </div>

            <div>
                {imagePreviews.map((preview, index) => (
                    <span key={index} className="image-preview-item p-sm-1">
                        <div className="image-container">
                            <img src={preview} alt={`Preview ${index}`} width="100" />
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="delete-icon"
                                onClick={() => handleDeleteFile(index)}
                            />
                        </div>
                    </span>
                ))}
            </div>
            <div className="d-flex justify-content-end my-1">
                <Link to="/samSummaryScreen"><Button className="buttonCss">Sam Daily Summary Listing</Button></Link>
                <SamPdfGenerator userName={user.firstName + " " + user.lastName} dateValue={formValue.dateValue}
                              emailSubject={formValue.emailSubject} topIssues={formValue.topIssues}
                                 noteToManagement={formValue.noteToManagement} surveyGroupBySite={surveyGroupBySite}
                                 rdoEmail={formValue.rdoEmail} gmEmail={formValue.gmEmail}
                                 sam={formValue.sam} emailAddress={formValue.emailAddress}
                                 submitHandler={submitHandler} sendPDFToServer={sendPDFToServer}/>
            </div>

            <div>
                {(surveyGroupBySite && surveyGroupBySite.length && surveyGroupBySite.length > 0) ?
                    (surveyGroupBySite.map((client, index) => (
                    <div key={client.site+ index}>
                        {client.activity.map(activityGroup => (
                            <Row className="justify-content-md-center marginUnset" key={client.site+ activityGroup.survey}>
                                <SummaryOfDayTable
                                    tableHeader={activityGroup.header}
                                    tableData={activityGroup.surveyData}
                                    headerName={`${client.site} ( ${activityGroup.survey} )`}
                                />
                            </Row>
                        ))}
                    </div>
                )))
                :
                    <div className="text-center colBorder">NO DATA AVAILABLE</div>
                }
            </div>
        </Container>
    );
};

export default SamSummaryOfDay;
