import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useSelector} from "react-redux";
import {searchArray, searchArrayWithActive, specificArrayData} from "../../commom/CommonFunction";
import {getDateInUTC} from "../../commom/DateFormat";
import {
    completedSurveyData, getCommonTableData,
    getLinenQualityReport,
    getOnSiteReport, getOnSiteTableData, getSurveyReportHeaderFromQuestions,
    incompleteSurveyData
} from "../../commom/CommonReportFunction";
import ExternalSurveyTableModal from "./ExternalSurveyTableModal";
import {exportSurveyData} from "../../commom/ExportToExcel";

const ExternalSurveyReport = ({incompleteReportClicked, completedReportClicked, surveyList, sitesData, surveys, closeExternalReport}) => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [rowHeader, setRowHeader] = useState("");
    const [tableData, setTableData] = useState("");
    const [reportHeader, setReportHeader] = useState(null);
    const [showReportTable, setShowReportTable] = useState(false);
    const [reportData, setReportData] = useState("");
    const [selectedSurvey, setSelectedSurvey] = useState();
    const [isCompletedSurvey, setIsCompletedSurvey] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (completedReportClicked) {
            setShowMessage(true);
            showCompleteSurvey();
        }

        if (incompleteReportClicked) {
            setShowMessage(true);
            showIncompleteSurvey();
        }
    }, [incompleteReportClicked, completedReportClicked]);

    const getIncompleteExternalSurvey = async () => {
        let siteTitles = specificArrayData(sitesData, "site");
        let surveys = specificArrayData(surveyList, "title");

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getIncompleteExternalSurvey',
            requestConfig: {
                data: {
                    "siteTitles": siteTitles,
                    "surveys": surveys,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        return await response.data;
    };

    const showIncompleteSurvey = () => {
        setIsCompletedSurvey(false);
        getIncompleteExternalSurvey().then((data) => {
            let rowHeader = [
                {
                    name: "button",
                    header: "Resend Email",
                    alignment: "left"
                },
                {
                    name: "Date",
                    header: "Date",
                    alignment: "left"
                },
                {
                    name: "Survey Type",
                    header: "Survey Type",
                    alignment: "left"
                },
                {
                    name: "Contact",
                    header: "Contact",
                    alignment: "left"
                },
                {
                    name: "Contact Email",
                    header: "Contact Email",
                    alignment: "left"
                }
            ];

            let reportData = incompleteSurveyData(data, surveyList);
            setRowHeader(rowHeader);
            setTableData(reportData);
            setReportHeader("Incomplete Survey List");
            setReportData(reportData);
            setShowReportTable(true);
            setShowMessage(false);
        });
    };

    const getCompleteExternalSurvey = async (surveys) => {
        let siteTitles = specificArrayData(sitesData, "site");

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getCompleteExternalSurvey',
            requestConfig: {
                data: {
                    "siteTitles": siteTitles,
                    "surveys": [surveys],
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });
        return await response.data;
    };

    const showCompleteSurvey = () => {
        setIsCompletedSurvey(true);
        showCompleteSurveyData(surveys);
        setSelectedSurvey(surveys);
    };

    const showCompleteSurveyData = (surveyTitle) => {
        getCompleteExternalSurvey(surveyTitle).then((response) => {
            let survey = searchArrayWithActive(surveyTitle, surveyList, "title", "active");
            let value = surveyTitle;
            let reportData;
            if (value === "Onsite Linen Inspection") {
                reportData = getOnSiteReport(response, sitesData, value, survey);
            }  else {
                reportData = getLinenQualityReport(response, sitesData, value, survey);
            }

            let tableArray;
            if (value === "Onsite Linen Inspection") {
                tableArray = getOnSiteTableData(response, sitesData, value, survey);
            }  else {
                tableArray = getCommonTableData(response, sitesData, value, survey);
            }

            let rowHeader = getSurveyReportHeaderFromQuestions(survey);
            setRowHeader(rowHeader);
            setTableData(tableArray);
            setReportHeader("Completed Survey List");
            setReportData(reportData);
            setShowReportTable(true);
            setShowMessage(false);
        });
    };

    const handleModalInputChange = (event) => {
        setSelectedSurvey(event.currentTarget.value);
        showCompleteSurveyData(event.currentTarget.value);
    };

    const handleModalClose = () => {
        setShowReportTable(false);
        closeExternalReport();
    };

    const exportReportData = () => {
        if (reportHeader === "Incomplete Survey List") {
            let data = {
                "IncompleteSurveyList": reportData
            };
            exportSurveyData(data, "IncompleteSurveyList.xlsx");
        } else if (reportHeader === "Completed Survey List") {
            let data = {
                "CompletedSurveyList": reportData
            };
            exportSurveyData(data, "CompletedSurveyList.xlsx")
        }
    };

    const selectedRow = (value) => {
        setShowMessage(false);

        reminderEmailToExternalUser(value).then(() => {
            setShowMessage(true);
        })
    };

    const reminderEmailToExternalUser = async (value) => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/reminderEmailToExternalUser',
            requestConfig: {
                data: {
                    "_id": value.surveyId,
                }
            }
        });
        return await response.data;
    };

    return (
        <ExternalSurveyTableModal rowHeader={rowHeader} tableData={tableData} showReportTable={showReportTable}
                                  handleClose={handleModalClose} header={reportHeader} surveyList={surveyList}
                                  exportReportData={exportReportData} handleModalInputChange={handleModalInputChange}
                                  isCompletedSurvey={isCompletedSurvey}
                                  selectedRow={selectedRow} selectedSurvey={selectedSurvey}
                                  showMessage={showMessage} response={response} error={error} loading={loading}/>
    )
};

export default ExternalSurveyReport;

