import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChartWithTopLabel from "../../commom/BarChartWithTopLabel";
import RadarChartWithLabel from "../../commom/RadarChartWithLabel";
import LineChartWithLabel from "../../commom/LineChartWithLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {formatNumberWithCommas} from "../../commom/CommonFunction";

const ChartFullScreenModal = ({chartColumn, handleClose, showModal, value, getChartDetail, keyValue, keyDate}) => {
    return (
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
               centered
               className="reportModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Enlarge Chart View</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    {chartColumn.chart1 &&
                    <Col xs={6} className="colBorder">
                        {chartColumn.chart1 && <div className="headerTitleCss">Linen Quality & Availability</div>}
                        <div className="sortChartHeader">
                                        <span className="subHeaderCss">
                                            Avg {value.linenAvg}</span>
                            <span className="subHeaderCss">
                                            Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                            <span className="subHeaderIconCss"
                                  onClick={() => getChartDetail(1, value.linenChartWithQADate, keyValue, keyDate, value.linenQualityChartCount)}>
                                            <FontAwesomeIcon icon={faInfoCircle} className="mx-1 fs-7"/></span>
                        </div>
                        <div className="colChartClass">
                            {(value.linenQualityChartWithWeek && value.linenQualityChartWithWeek.length > 0) &&
                            <LineChartWithLabel data={value.linenQualityChartWithWeek} label="Quality"
                                                name="WeekStart"
                                                flag={true}
                                                nextLabel="Availability"/>
                            }
                        </div>
                    </Col>
                    }
                    {chartColumn.chart2 &&
                    <Col xs={6} className="colBorder">
                        {chartColumn.chart2 && <div className="headerTitleCss">Dept Linen Feedback</div>}
                        <div className="sortChartHeader">
                            <span className="subHeaderCss">Avg {value.linenAvg}</span>
                            <span
                                className="subHeaderCss"> Count {formatNumberWithCommas(value.linenQualityChartCount)}</span>
                            <span className="subHeaderIconCss"
                                  onClick={() => getChartDetail(2, value.linenChartWithDDate, keyValue, keyDate, value.linenQualityChartCount)}><FontAwesomeIcon
                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                        </div>
                        <div className="colChartClass">
                            {(value.linenQualityChartWithDept && value.linenQualityChartWithDept.length > 0) &&
                            <BarChartWithTopLabel data={value.linenQualityChartWithDept} label="Quality"
                                                  name="Dept"
                                                  flag={true}
                                                  nextLabel="Availability"/>
                            }
                        </div>
                    </Col>
                    }

                    {chartColumn.chart3 &&
                    <Col xs={6} className="colBorder">
                        {chartColumn.chart3 && <div className="headerTitleCss">Onsite Imperfections</div>}
                        <div className="sortChartHeader">
                                        <span
                                            className="subHeaderCss">Pieces {formatNumberWithCommas(value.onsiteLinenChartTotal)}</span>
                            <span className="subHeaderCss">Imp% {value.onsiteLinenChartAvg}</span>
                            <span className="subHeaderIconCss"
                                  onClick={() => getChartDetail(3, value.onsiteLinenChartWithIDate, keyValue, keyDate, "", value.onsiteLinenChartTotal, value.onsiteLinenChartTotalImperfection)}><FontAwesomeIcon
                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                        </div>
                        <div className="colChartClass">
                            {(value.onsiteLinenChartWithItem && value.onsiteLinenChartWithItem.length > 0) &&
                            <RadarChartWithLabel data={value.onsiteLinenChartWithItem} label="Inspected"
                                                 name="Item"
                                                 flag={true}
                                                 nextLabel="Flaw"/>
                            }
                        </div>
                    </Col>
                    }

                    {chartColumn.chart4 &&
                    <Col xs={6} className="colBorder">
                        {chartColumn.chart4 && <div className="headerTitleCss">Client Sat Score</div>}
                        <div className="sortChartHeader">
                            <span className="subHeaderCss">Avg {value.clientSatChartAvg}</span>
                            <span
                                className="subHeaderCss"> Count {formatNumberWithCommas(value.clientSatChartCount)}</span>
                            <span className="subHeaderIconCss"
                                  onClick={() => getChartDetail(4, value.clientSatChartWithOPDate, keyValue, keyDate, value.clientSatChartCount)}><FontAwesomeIcon
                                icon={faInfoCircle} className="mx-1 fs-7"/></span>
                        </div>
                        <div className="colChartClass">
                            {(value.clientSatChartWithWeek && value.clientSatChartWithWeek.length > 0) &&
                            <BarChartWithTopLabel data={value.clientSatChartWithWeek}
                                                  label="Overall Performance" name="WeekStart"
                                                  flag={false}
                                                  nextLabel=""/>
                            }
                        </div>
                    </Col>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="buttonDisableCss" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChartFullScreenModal;