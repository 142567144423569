import React from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { applyTheme  } from '../../reducer/ThemeReducer';
import { darkTheme, lightTheme } from '../../constant/ThemeConstant';

const ThemeSelector = () => {
    const dispatch = useDispatch();

    const changeTheme = (theme) => {
        dispatch(applyTheme(theme));
    };

    return (
        <>
            <DropdownButton id="dropdown-basic-button" title="Select Theme" className="themeSelector">
                <Dropdown.Item onClick={() => changeTheme(darkTheme)} >Dark</Dropdown.Item>
                <Dropdown.Item onClick={() => changeTheme(lightTheme)} >Light</Dropdown.Item>
            </DropdownButton>
        </>
    );
}
export default ThemeSelector;