import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const PlantForm = (props) => {
    return (
        <form className="formContainer w-100 p-3">
            <Row>
                <div className="form-group col">
                    <label htmlFor="_id" className="col-form-label"><span className="text-danger starCss">*</span>Plant Id</label>
                    <div>
                        <input type="text" name="_id" onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation._id ? "errorDiv" : ""}`}
                               value={props.formValue._id} disabled={props.isReadonly}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="houseCode" className="col-form-label"><span className="text-danger starCss">*</span>House Code</label>
                    <div>
                        <input type="number" name="houseCode" onChange={props.handleChange}
                               className={`form-control ${!props.inputValidation.houseCode ? "errorDiv" : ""}`}
                               value={props.formValue.houseCode} disabled={props.isReadonly}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="region" className="col-form-label">Region</label>
                    <div>
                        <input type="text" className="form-control" name="region"
                               onChange={props.handleChange} value={props.formValue.region}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="division" className="col-form-label">Division</label>
                    <div>
                        <input type="text" className="form-control" name="division"
                               onChange={props.handleChange} value={props.formValue.division}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="president" className="col-form-label">President</label>
                    <div>
                        <input type="text" className="form-control" name="president"
                               onChange={props.handleChange} value={props.formValue.president}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="presidentEmail" className="col-form-label">President Email</label>
                    <div>
                        <input type="email" className="form-control" name="presidentEmail"
                               onChange={props.handleChange} value={props.formValue.presidentEmail}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="street" className="col-form-label">Street</label>
                    <div>
                        <input type="text" className="form-control" name="street"
                               onChange={props.handleChange} value={props.formValue.street}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="city" className="col-form-label">City</label>
                    <div>
                        <input type="text" className="form-control" name="city"
                               onChange={props.handleChange} value={props.formValue.city}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="state" className="col-form-label">State</label>
                    <div>
                        <input type="text" className="form-control" name="state"
                               onChange={props.handleChange} value={props.formValue.state}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="zip" className="col-form-label">Zip</label>
                    <div>
                        <input type="text" className="form-control" name="zip"
                               onChange={props.handleChange} value={props.formValue.zip}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="hasKPI" className="col-form-label">Has KPI</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasKPI" id="hasKPI"
                               onChange={props.handleChange} checked={props.formValue.hasKPI}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="hasPulse" className=" col-form-label">Has Pulse</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasPulse" id="hasPulse"
                               onChange={props.handleChange} checked={props.formValue.hasPulse}/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form-group col">
                    <label htmlFor="hasCLII" className="col-form-label">Has CLII</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasCLII" id="hasCLII"
                               onChange={props.handleChange} checked={props.formValue.hasCLII}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="isActive" className="col-form-label">Active</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="isActive" id="isActive"
                               onChange={props.handleChange} checked={props.formValue.isActive}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="hasPlantQA" className=" col-form-label">Has Plant QA</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasPlantQA" id="hasPlantQA"
                               onChange={props.handleChange} checked={props.formValue.hasPlantQA}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="hasLinenMaster" className="col-form-label">Has Linen Master</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasLinenMaster" id="hasLinenMaster"
                               onChange={props.handleChange} checked={props.formValue.hasLinenMaster}/>
                    </div>
                </div>
                <div className="form-group col">
                    <label htmlFor="hasMasterData" className="col-form-label">Has Master Data</label>
                    <div>
                        <input type="checkbox" className="form-check-input" name="hasMasterData" id="hasMasterData"
                               onChange={props.handleChange} checked={props.formValue.hasMasterData}/>
                    </div>
                </div>
            </Row>
            <Row>
                <Col>Day</Col>
                <Col>From</Col>
                <Col>To</Col>
                <Col>Day</Col>
                <Col>From</Col>
                <Col>To</Col>
            </Row>
            <Row>
                <Col>
                    <label htmlFor="monday" className=" col-form-label">Monday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.mondayFrom} name="mondayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.mondayTo} name="mondayTo"/>
                    </div>
                </Col>
                <Col>
                    <label htmlFor="tuesday" className=" col-form-label">Tuesday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.tuesdayFrom} name="tuesdayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.tuesdayTo} name="tuesdayTo"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label htmlFor="wednesday" className=" col-form-label">Wednesday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.wednesdayFrom} name="wednesdayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.wednesdayTo} name="wednesdayTo"/>
                    </div>
                </Col>
                <Col>
                    <label htmlFor="thrusday" className=" col-form-label">Thrusday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.thrusdayFrom} name="thrusdayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.thrusdayTo} name="thrusdayTo"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label htmlFor="friday" className=" col-form-label">Friday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.fridayFrom} name="fridayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.fridayTo} name="fridayTo"/>
                    </div>
                </Col>
                <Col>
                    <label htmlFor="saturday" className=" col-form-label">Saturday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.saturdayFrom} name="saturdayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.saturdayTo} name="saturdayTo"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label htmlFor="sunday" className=" col-form-label">Sunday</label>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.sundayFrom} name="sundayFrom"/>
                    </div>
                </Col>
                <Col>
                    <div className="d-inline-block">
                        <input type="time" onChange={props.handleChange}
                               value={props.formValue.sundayTo} name="sundayTo"/>
                    </div>
                </Col>
                <Col/>
                <Col/>
                <Col/>
            </Row>
            <div className="row mx-auto form">
                <Col>
                    <Button onClick={props.handleOpen} className="formButton buttonCss" disabled={props.disableSubmit}>{props.buttonTitle}</Button>
                </Col>
                <Col>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                </Col>
            </div>
        </form>
    )
};

export default PlantForm;