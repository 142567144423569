import React, {useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEnvelopeSquare} from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import "./CustomTable.css";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import {faCheck} from "@fortawesome/fontawesome-free-solid";
import {getSortKeyValue} from "./CommonFunction";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";

function CustomTable(props) {
    const [filtered, setFiltered] = useState();
    const [sorted, setSorted] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    const [sortedColumn, setSortedColumn] = useState("");

    useEffect(() => {
        if(props.tableData && props.tableData.length) {
            sortHeader();
        } else {
            setFiltered([]);
        }
    }, [props.tableData]);

    useEffect(() => {
        if(props.tableData && props.tableData.length && props.forceLoad) {
            setShowInactive(false);
            setSearchTerm("");
            getTableData("", sorted, false, "");
        }
    }, [props.forceLoad]);

    const changeInputHandler = (event) => {
        setSearchTerm(event.currentTarget.value);
        getTableData(event.currentTarget.value, sorted, showInactive, sortedColumn);
    };

    const getTableData = (searchValue, sortValue, showInactiveFlag, sortData) => {
        let newArray;
        let sortRowValue;

        if (searchValue) {
            newArray = props.tableData.filter(stateObj => {
                let value = stateObj[props.rowHeader[1].name];
                let valueInLowerCase = (value + "").toLowerCase();
                let search = (searchValue + "").toLowerCase();
                return valueInLowerCase.match(search)
            });
        } else {
            newArray = props.tableData.map(data => data);
        }

        let finalArray = [];
        let newInactiveArray = [];
        let newActiveArray = [];
        sortRowValue = props.rowHeader[1].name;
        let activeKey = getActiveKey();
        if(activeKey) {
            if (showInactiveFlag) {
                newArray.forEach(function (data) {
                    let obj = Object.assign({}, data);
                    if (obj[activeKey]) {
                        newActiveArray.push(obj);
                    }
                    if (!obj[activeKey]) {
                        newInactiveArray.push(obj);
                    }
                });
                finalArray = [...newActiveArray];
            } else {
                newArray.forEach(function (data) {
                    let obj = Object.assign({}, data);
                    if (obj[activeKey]) {
                        finalArray.push(obj);
                    }
                });
            }
        } else {
            finalArray = newArray.map(data => data);
        }

        let sortedArray = [];
        let inActiveSortedArray = [];
        if(sortData) {
            sortRowValue = sortData;
        } else {
            sortRowValue = props.rowHeader[1].name;
        }

        finalArray.forEach(function (data) {
            let obj = Object.assign({}, data);
            if(obj[sortRowValue] || obj[sortRowValue] == "") {
                sortedArray.push(obj);
            }
        });

        if (sortValue) {
            sortedArray.sort(function (a, b) {
                return (a[sortRowValue]).localeCompare(b[sortRowValue])
            });
        } else {
            sortedArray.sort(function (a, b) {
                return (b[sortRowValue]).localeCompare(a[sortRowValue])
            });
        }

        if(newInactiveArray && newInactiveArray.length > 0) {
            newInactiveArray.forEach(function (data) {
                let obj = Object.assign({}, data);
                if(obj[sortRowValue] || obj[sortRowValue] == "") {
                    inActiveSortedArray.push(obj);
                }
            });

            if (sortValue) {
                inActiveSortedArray.sort(function (a, b) {
                    return (a[sortRowValue]).localeCompare(b[sortRowValue])
                });
            } else {
                inActiveSortedArray.sort(function (a, b) {
                    return (b[sortRowValue]).localeCompare(a[sortRowValue])
                });
            }

            setFiltered([...sortedArray, ...inActiveSortedArray]);
        } else {
            setFiltered(sortedArray);
        }
    };

    const getActiveKey = () => {
        let sortKeyValue = "";
        if(props.tableData && props.tableData.length > 0 && !props.hideSearch) {
            sortKeyValue = getSortKeyValue(props.tableData[0]);
        }

        return sortKeyValue;
    };

    const sortHeader = (event) => {
        if (event) {
            let sortValue = event.currentTarget.attributes['data-name'].value;
            setSortedColumn(sortValue);
            let sortedOrder = sorted;
            setSorted(!sortedOrder);
            getTableData(searchTerm, !sortedOrder, showInactive, sortValue);
        } else {
            getTableData(searchTerm, sorted, showInactive, "");
        }
    };

    const showInactiveData = () => {
        let showInactiveFlag = !showInactive;
        setShowInactive(showInactiveFlag);
        getTableData(searchTerm, sorted, showInactiveFlag, sortedColumn);
    };

    return (
        <div className="customTableResponsive">
            {!props.hideSearch && <InputGroup className="mb-1">
                <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    type="text"
                    value={searchTerm}
                    onChange={changeInputHandler}
                />
                <InputGroup.Text id="basic-addon2" onClick={showInactiveData}>
                    {showInactive ? "Hide Inactive" : "Show Inactive"}
                </InputGroup.Text>
            </InputGroup>}

            <Table bordered hover responsive>
                <thead>
                <tr>
                    {
                        props.rowHeader.map((data, key) => {
                            if (data.header !== "Edit" && data.header !== "Active" && data.header !== "Index") {
                                return <th key={key + data.name} onClick={sortHeader} data-name={data.name}
                                           className="text-center">{data.header}</th>
                            } else {
                                return <th key={key + data.name} data-name={data.name}
                                           className="text-center">{data.header}</th>
                            }
                        })
                    }
                </tr>
                </thead>
                <tbody id="customTableBody">
                {
                    (filtered && filtered.length && filtered.length > 0) ?
                    (filtered).map((value, index) => {
                        return (
                            <tr key={value + index}>
                                {
                                    props.rowHeader.map((data, key) => {
                                        if (data.header !== "Edit" && data.header !== "Active" && data.header !== "Index" && data.header !== "Resend Email" && data.header !== "View") {
                                            return <td key={key + data.header}
                                                       className={(data.alignment === "center" ? 'text-center' : '')}>{value[data.name]}</td>
                                        } else if (data.header === "Edit") {
                                            return (
                                                <td key={key + data.header} onClick={() => props.selectedRow(value)}
                                                    className="text-center"><FontAwesomeIcon icon={faEdit}/></td>)
                                        } else if (data.header === "Resend Email") {
                                            return value[data.name] === true ?
                                                <td key={value.surveyId + index} onClick={() => props.selectedRow(value)} className="text-center">
                                                    <FontAwesomeIcon icon={faEnvelopeSquare} />
                                                </td>
                                                :
                                                <td key={value.surveyId + index}></td>;
                                        } else if (data.header === "View") {
                                            return (<td key={value._id + index} onClick={() => props.selectedRow(value)} className="text-center eyeIcon">
                                                <FontAwesomeIcon icon={faEye} className="cssCursor"/>
                                            </td>)
                                        } else if (data.header === "Index") {
                                            return (
                                                <td key={key + index} className="text-center">{index + 1}</td>)
                                        } else if (data.header === "Active") {
                                            return (
                                                <td key={key + data.header}
                                                    className="text-center">
                                                    <div className={`${(value[data.name] === true || value[data.name] === "Open") ? "enableCheckbox checkboxDiv" : "disableCheckbox checkboxDiv"}`}>
                                                        <FontAwesomeIcon icon={(value[data.name] === true || value[data.name] === "Open") ? faCheck : faRemove}/>
                                                    </div>
                                                </td>
                                            )
                                        }
                                    })
                                }
                            </tr>
                        )

                    })
                        :
                        <tr><td colSpan={props.rowHeader.length} className="text-center">NO DATA AVAILABLE</td></tr>
                }
                </tbody>
            </Table>
        </div>
    );
}

export default CustomTable;