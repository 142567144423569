import React from "react";
import Button from "react-bootstrap/Button";

const DefaultScreenSelectionForm = (props) => {
    return (
        <form onSubmit={props.submitHandler}>
            <div className="form-group row mb-1">
                <label htmlFor="defaultScreen" className="col-sm-4 col-form-label">
                    <span className="text-danger col starCss">*</span>Default Screen :</label>
                <div className="col">
                    <select value={props.formValue.defaultScreen} onChange={props.handleChange}
                            name="defaultScreen"
                            className={`form-select ${!props.inputValidation.defaultScreen ? "errorDiv" : ""}`}>
                        <option value="select">Select</option>
                        {
                            props.defaultScreenArray && props.defaultScreenArray.length
                            && props.defaultScreenArray.map((obj, index) => {
                                return <option key={obj + index}
                                               value={obj}>{obj}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button type="submit" className="formButton buttonCss" disabled={props.disableSubmit}>Submit</Button>
            </div>
        </form>
    )
};

export default DefaultScreenSelectionForm;