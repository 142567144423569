import React from "react";
import CustomTable from "../../commom/CustomTable";
import {useSelector} from "react-redux";

function SummaryOfDayScreenList(props) {
    const tableData = useSelector((state) => state.summaryOfDayScreenReducer);
    let rowHeader = [
        {
            name: "startDate",
            header: "StartDate"
        },
        {
            name: "endDate",
            header: "EndDate"
        },
        {
            name: "senderName",
            header: "Sender Name"
        },
        {
            name: "site",
            header: "Site"
        },
        {
            name: "receiverName",
            header: "Contact Name"
        },
        {
            name: "senderEmail",
            header: "Email"
        },
        {
            name: "siteContact",
            header: "Site Contact"
        },
        {
            name: "subjectOfEmail",
            header: "Email Subject"
        },
        {
            name: "comment",
            header: "Comment"
        },
        {
            name: "createdAt",
            header: "Created At"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} hideSearch={true}
                     selectedRow={props.selectedRow}/>
    )
}

export default SummaryOfDayScreenList;