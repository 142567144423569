import React from "react";
import {useSelector} from "react-redux";
import CustomTable from "../../commom/CustomTable";

function UtilizationList(props) {
    const tableData = useSelector((state) => state.utilizationReducer);
    let rowHeader = [
        {
            name: "key",
            header: "Index"
        },
        {
            name: "siteDate",
            header: "Site Date"
        },
        {
            name: "Edit",
            header: "Edit"
        },
    ];

    return (
        <CustomTable rowHeader={rowHeader} tableData={tableData} selectedRow={props.selectedRow}/>
    );
}

export default UtilizationList;