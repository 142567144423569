import { createSlice } from '@reduxjs/toolkit'

export const HideMasterDropdownReducer = createSlice({
    name: "HideMasterDropdownReducer",
    initialState: {
        showMasterDate: true,
    },
    reducers: {
        showMasterDateOption: (state, action) => {
            state.showMasterDate = action.payload;
        }
    }
});

export const { showMasterDateOption } = HideMasterDropdownReducer.actions;
export default HideMasterDropdownReducer.reducer;