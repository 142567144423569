import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import useAxiosFunction from "../../hook/AxiosHook";
import SurveyDetailForm from "../plantQASurvey/SurveyDetailForm";
import {getDateInUTCByMonth} from "../../commom/DateFormat";
import {useParams} from 'react-router-dom';
import ConfirmationModal from "../../commom/ConfirmationModal";
import {formInputChangeHandler, formResetHandler, resetValidation} from "../../commom/FormHandler";
import {validateFormInputOnSave} from "../../commom/ValidateFormData";
import {plantSurveyQuestionArray} from "../../commom/CommonFunction";
import Loader from "../../commom/LoaderComponent";

const ExternalClientSurvey = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const [surveyData, setSurveyData] = useState("");
    const [config, setConfig] = useState();
    const [isSurveyEditable, setSurveyEditable] = useState(false);
    const [message, setMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [statusFlag, setStatus] = useState(false);
    const [inputValidation, setInputValidation] = useState();
    const [formValidation, setFormValidation] = useState(true);
    let params = useParams();
    const [fieldValidation, setFieldValidation] = useState("");
    const [resetComment, setResetComment] = useState(false);

    useEffect(() => {
        getFormData();
    }, []);

    const getFormData = () => {
        const getSurveyByUniqueId = async () => {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getPlantSurveyByUniqueID',
                requestConfig: {
                    data: {"uniqueId": params.uniqueId}
                }
            });

            return await response.data;
        };

        getSurveyByUniqueId().then((fetchedValue) => {
            if (!fetchedValue) {
                setSurveyEditable(false);
                setMessage("We can't find the page you're looking for.");
                setStatus(false);
                return false;
            } else if (fetchedValue.status === 0 || fetchedValue.status === 1) {
                setSurveyEditable(true);
            } else if (fetchedValue.status === 2) {
                setSurveyEditable(false);
                setMessage("You have already taken this survey.");
                setStatus(true);
                return false;
            }

            const {configData, inputValidationData, fieldValidationData} = plantSurveyQuestionArray(fetchedValue, false);

            if (fetchedValue.status === 0 && configData && configData.length) {
                updateSurvey(configData, 1, fetchedValue._id).then((response) => {
                });
            }
            setInputValidation(inputValidationData);
            setSurveyData(fetchedValue);
            setConfig(configData);
            setFieldValidation(fieldValidationData);
        });
    };

    const updateSurvey = async (configData, status, id) => {
        let dateValue = "";
        configData.forEach((obj) => {
            let data = Object.assign({}, obj);

            if (data.type === "Date") {
                dateValue = data.value
            }
        });

        let newEntry = {
            surveyDetail: configData,
            status: status,
            uniqueId: params.uniqueId,
            _id: id,
            surveyDate: dateValue
        };

        return await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/updatePlantSurvey',
            requestConfig: {
                data: newEntry
            }
        });
    };

    const handleChange = (event, flag = "") => {
        setResetComment(false);
        if(!flag) {
            const configData = formInputChangeHandler(event, config);
            setConfig(configData);
        } else {
            let newObj = {
                "currentTarget" : {
                    "name": event.name,
                    "value":event.transcript
                }
            };
            const configData = formInputChangeHandler(newObj, config);
            setConfig(configData);
        }
    };

    const cancelHandler = () => {
        setResetComment(true);
        const configData = formResetHandler(config, false, "");
        setConfig(configData);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const {checkValidate, currentFormState} = validateFormInputOnSave(config, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if (!checkValidate) {
            return false;
        }
        setShowModal(true);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        updateSurvey(config, 2, surveyData._id).then((response) => {
            if (response.data) {
                cancelHandler();
                setSurveyEditable(false);
                setMessage("Thank you for taking this survey.");
                setStatus(true);
            }
        });

        handleClose();
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            {isSurveyEditable && surveyData &&
            <>
                <Row className="mx-auto text-center align-items-center row navbarHeader text-white">
                    <span className="mt-1">
                        <b>Date :</b> {getDateInUTCByMonth(surveyData.surveyDate)}<b> Survey :</b> {surveyData.survey}</span>
                    <span className="mb-sm-1"><b>Plant :</b> {surveyData.plant}
                        <b> Site :</b> {surveyData.site} </span>
                </Row>
                <SurveyDetailForm config={config} handleChange={handleChange}
                                  submitHandler={submitHandler} inputValidation={inputValidation}
                                  cancelHandler={cancelHandler} formValidation={formValidation} response={response}
                                  error={error} loading={loading} showMessage={false} resetComment={resetComment}/>
            </>
            }
            {showModal && <ConfirmationModal handleClose={handleClose} showModal={showModal} submitForm={submitForm} flag={false}/>}
            {
                !isSurveyEditable && !statusFlag && message && message.length &&
                <div className="surveyErrorHeaderClass">
                    <h3>Unauthorised Access!!</h3>
                    <span>{message}</span>
                </div>
            }
            {
                !isSurveyEditable && statusFlag && message && message.length &&
                <div className="surveyHeaderClass">
                    <div>
                    <h3>Thank You!!</h3>
                    <span>{message}</span>
                    </div>
                </div>
            }
        </Container>
    )
};

export default ExternalClientSurvey;