import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import NavLinkComponent from "./NavLink";
import {useDispatch, useSelector} from "react-redux";
import {updateSubmodule} from "../../reducer/SidebarReducer";

const NavButton = props => {
    const isProdEnv = useSelector((state) => state.loginState.isProductionEnv);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const onToggle = (subModules) => {
        dispatch(updateSubmodule({"subModules" : subModules}));
        setOpen(!open);
    };

    const handleSideBarClick = (event) => {
        if(event && event.currentTarget.getAttribute("name")) {
            let selectedNav = event.currentTarget.getAttribute("name");
            window.localStorage.setItem('selectedNavigation',selectedNav);
        }
    };

    return (
        <div key={props.index} className="navBorder">
            <Button
                onClick={() => {onToggle(props.data.subModule); }}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className={`buttonCSS btnActive w-100 ${isProdEnv ? "prodBackground" : "devBackground"}`}
            >
                {props.data.mainModuleTile}
            </Button>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <Nav defaultActiveKey="/home" className={`flex-column subNavContainer ${isProdEnv ? "prodBackground" : "devBackground"}`}>
                        {props.data.subModule && props.data.subModule.length &&
                        (props.data.subModule).map((value, index) => {
                            return (
                                <NavLinkComponent key={value.subModuleId + index} subModule={value} isProdEnv={isProdEnv}
                                                  handleSideBarClick={handleSideBarClick} parent={props.data.mainModuleTile}/>
                            )
                        })
                        }
                    </Nav>
                </div>
            </Collapse>
        </div>
    );
};

export default NavButton;