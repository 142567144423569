import React, {useContext, useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import useAxiosFunction from "../../hook/AxiosHook";
import {useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import {updateRole} from '../../reducer/RoleReducer';
import RoleForm from "./RoleForm";
import RoleList from "./RoleList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {setSecurityRoleData, updateSecurityRole} from "../../reducer/SecurityRoleReducer";
import TreeCheckbox from "../../commom/CustomCheckbox";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import {formValueSetup, handleInputChange, resetValidation} from "../../commom/FormHandler";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import Loader from "../../commom/LoaderComponent";
import Message from "../../commom/Message";
import {userDetail} from "../../reducer/UserReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {logIn, logOut} from "../../reducer/LoginReducer";
import {getUserAccessToUrl, trimObjectValues} from "../../commom/CommonFunction";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const Role = () => {
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();
    const [buttonTitle, setButtonTitle] = useState("ADD");
    const [showMessage, setShowMessage] = useState(false);
    const [readOnly, setReadonly] = useState(false);
    const [roleArray, setRoleArray] = useState();
    const [formValue, setFormValue] = useState({
        _id: "",
        title: "",
        active: false
    });
    const [inputValidation, setInputValidation] = useState({
        title: true
    });
    const fieldValidation = {
        title: "string",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showRoleMessage, setShowRoleMessage] = useState(false);

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(false));

        async function getData() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getRoles'
            });
            return await response.data;
        }

        getData().then((fetchedValue) => {
            let arrangedSecurityRole = [];

            arrangedSecurityRole.push(fetchedValue[1]);
            arrangedSecurityRole.push(fetchedValue[0]);
            arrangedSecurityRole.push(fetchedValue[2]);
            dispatch(updateRole({"role": arrangedSecurityRole}));
            setRoleArray(updateData(arrangedSecurityRole));
        });

        async function getSecurityRole() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'get',
                url: '/getSecurityRole'
            });

            return await response.data;
        }

        getSecurityRole().then((fetchedValue) => {
            dispatch(setSecurityRoleData(fetchedValue));
        });

        getUserDetailOnly().then(response => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }
            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
        });
    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getUserDetailOnly = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetailOnly',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const updateData = (fetchedValue) => {
        let roles = [];

        fetchedValue.forEach(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});
            roleValue.isActive = false;
            roleValue.open = false;
            roleValue.id = roleValue.moduleId;
            roleValue.name = roleValue.mainModuleTile;

            (obj.subModules).forEach((subVal, index) => {
                let subModule;

                if (subVal.subModules) {
                    subModule = Object.assign({}, subVal, {subModules: []});
                    subModule.isActive = false;
                    subModule.open = false;
                    subModule.id = subModule.subModuleId;
                    subModule.name = subModule.subModuleTitle;
                    roleValue.subModule.push(subModule);
                    let subSubModule;
                    (obj.subModules[index].subModules).forEach((subSubVal) => {
                        subSubModule = Object.assign({}, subSubVal);
                        subSubModule.isActive = false;
                        subSubModule.open = false;
                        subSubModule.id = subSubModule.subModuleId;
                        subSubModule.name = subSubModule.subModuleTitle;
                        roleValue.subModule[index].subModules.push(subSubModule);
                    })
                } else {
                    subModule = Object.assign({}, subVal);
                    subModule.isActive = false;
                    subModule.open = false;
                    subModule.id = subModule.subModuleId;
                    subModule.name = subModule.subModuleTitle;
                    roleValue.subModule.push(subModule);
                }
            });

            roles.push(roleValue);
        });

        return roles;
    };

    const onCheckAllCheckbox = (name) => {
        setDisableSubmit(false);
        let checkBoxName = name.split(',');
        let parent = (checkBoxName.length === 1);
        let child = (checkBoxName.length === 2);
        let subChild = (checkBoxName.length === 3);

        const newData = roleArray.map(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});

            if (roleValue.id === checkBoxName[0]) {
                if (parent) {
                    roleValue.isActive = !roleValue.isActive;
                }
                let isParentActive = false;
                (obj.subModule).forEach((subVal, index) => {
                    let subModule;

                    if (subVal.subModules) {
                        subModule = Object.assign({}, subVal, {subModules: []});

                        if (parent) {
                            subModule.isActive = roleValue.isActive;
                        } else if (subModule.subModuleId === checkBoxName[1]) {
                            subModule.isActive = !subModule.isActive;
                        }

                        if (subModule.isActive === true) {
                            isParentActive = true;
                        }

                        if (child && !subChild && !isParentActive) {
                            roleValue.isActive = false;
                        } else if (child && !subChild && isParentActive) {
                            roleValue.isActive = true;
                        }

                        roleValue.subModule.push(subModule);

                        let subSubModule;
                        let isChildActive = false;
                        (obj.subModule[index].subModules).forEach((subSubVal) => {
                            subSubModule = Object.assign({}, subSubVal);

                            if (parent) {
                                subSubModule.isActive = roleValue.isActive;
                            } else if (subModule.id === checkBoxName[1] && child) {
                                subSubModule.isActive = subModule.isActive;
                            } else if (subSubModule.id === checkBoxName[2]) {
                                subSubModule.isActive = !subSubModule.isActive;
                            }

                            if (subSubModule.isActive === true) {
                                isChildActive = true;
                            }

                            if (subChild && !isChildActive && !isParentActive) {
                                roleValue.isActive = false;
                                roleValue.subModule[index].isActive = false;
                            } else if (subChild && isChildActive) {
                                roleValue.isActive = true;
                                roleValue.subModule[index].isActive = true;
                            } else if (subChild && isParentActive) {
                                roleValue.isActive = true;
                            }

                            roleValue.subModule[index].subModules.push(subSubModule);
                        })
                    } else {
                        subModule = Object.assign({}, subVal);

                        if (parent) {
                            subModule.isActive = roleValue.isActive;
                        } else if (subModule.id === checkBoxName[1]) {
                            subModule.isActive = !subModule.isActive;
                        }

                        if (subModule.isActive === true) {
                            isParentActive = true;
                        }

                        if (child && !isParentActive) {
                            roleValue.isActive = false;
                        } else if (child && isParentActive) {
                            roleValue.isActive = true;
                        }

                        roleValue.subModule.push(subModule);
                    }
                });

                return roleValue
            } else {
                return obj;
            }
        });

        setRoleArray(newData);
        dispatch(updateRole({"role": newData}));
    };

    const toggleOpen = (event) => {
        let toggleValue = event.currentTarget.attributes['name'].value;
        const newData = [];
        roleArray.forEach(obj => {
            let roleValue = Object.assign({}, obj);

            if (roleValue.id === toggleValue) {
                roleValue.open = !roleValue.open;
            }

            newData.push(roleValue);
        });

        setRoleArray(newData);
    };

    const toggleExpand = (selectValue) => {
        let flag = selectValue;
        const newData = [];

        roleArray.forEach(obj => {
            let roleValue = Object.assign({}, obj);
            roleValue.open = flag;
            newData.push(roleValue);
        });

        setRoleArray(newData)
    };

    const inputChangeHandler = (event) => {
        setShowMessage(false);
        setDisableSubmit(false);
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.value && fieldValidation.hasOwnProperty(event.currentTarget.name)) {
            validateInputValue(event);
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const formInputValueSetup = (flag, value = "") => {
        const currentFormState = formValueSetup(flag, value, formValue);
        setFormValue(currentFormState);
    };

    const selectedRow = (value) => {
        setDisableSubmit(true);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setShowMessage(false);
        setButtonTitle("UPDATE");
        formInputValueSetup(true, value);

        async function getRole() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getSecurityRoleByTitle',
                requestConfig: {
                    role: {"title": value.title}
                }
            });
            return await response;
        }

        getRole().then((response) => {
            if(response.flag) {
                setReadonly(true);
            } else {
                setReadonly(false);
            }
            let fetchedValue = response.data;
            dispatch(updateRole({"role": fetchedValue.modules}));
            setRoleArray(fetchedValue.modules);
        });
    };

    const selectedAllCheckboxHandler = (selectValue) => {
        setRoleArray(updateActiveValueOfMenu(selectValue));
    };

    const updateActiveValueOfMenu = (activeValue) => {
        const newData = roleArray.map(obj => {
            let roleValue = Object.assign({}, obj, {subModule: []});
            roleValue.isActive = activeValue;
            (obj.subModule).forEach((subVal, index) => {
                let subModule;

                if (subVal.subModules) {
                    subModule = Object.assign({}, subVal, {subModules: []});
                    subModule.isActive = activeValue;
                    roleValue.subModule.push(subModule);
                    let subSubModule;
                    (obj.subModule[index].subModules).forEach((subSubVal) => {
                        subSubModule = Object.assign({}, subSubVal);
                        subSubModule.isActive = activeValue;

                        roleValue.subModule[index].subModules.push(subSubModule);
                    })
                } else {
                    subModule = Object.assign({}, subVal);
                    subModule.isActive = activeValue;
                    roleValue.subModule.push(subModule);
                }
            });

            return roleValue
        });

        return newData;
    };

    const cancelHandler = () => {
        setDisableSubmit(false);
        setReadonly(false);
        setShowMessage(false);
        setButtonTitle("ADD");
        formInputValueSetup(false);
        const currentFormState = resetValidation(inputValidation);
        setFormValidation(true);
        setInputValidation(currentFormState);
        setRoleArray(updateActiveValueOfMenu(false));
    };

    const submitRole = () => {
        let newData = [];
        let isSecurityRoleSelected = false;

        roleArray.forEach(obj => {
            let roleValue = Object.assign({}, obj, {subModules: []});
            delete roleValue['subModules'];
            newData.push(roleValue);

            if(obj.isActive) {
                isSecurityRoleSelected = true;
            }
        });

        return {newData, isSecurityRoleSelected};
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setDisableSubmit(true);
        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);

        if(!checkValidate) {
            return false;
        }

        let newData = trimObjectValues({...formValue});

        let roleData = submitRole();
        newData['modules'] = roleData.newData;
        if(roleData.isSecurityRoleSelected){
            setShowRoleMessage(false);
        } else {
            setShowRoleMessage(true);
            return false;
        }

        let response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/addUpdateRole',
            requestConfig: {
                data: newData
            }
        });

        if (response.data) {
            dispatch(updateSecurityRole(response.data));
            setButtonTitle("Update");

            if(formValue._id === "") {
                setFormValue({...formValue, "_id": response.data._id});
            }
        }
        setShowMessage(true);
    };

    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Row>
                <Col sm={4}><RoleList selectedRow={selectedRow}/></Col>
                <Col sm={4}>

                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation}/>
                             <RoleForm submitHandler={submitHandler} cancelHandler={cancelHandler} readOnly={readOnly}
                                      inputChangeHandler={inputChangeHandler} buttonTitle={buttonTitle}
                                      formValue={formValue} formValidation={formValidation} inputValidation={inputValidation}
                                      response={response} error={error} loading={loading} showMessage={showMessage} disableSubmit={disableSubmit}/></Col>
                <Col sm={4}>
                    <Form>
                        {
                            showRoleMessage &&
                            <div className="formErrorDiv">Please select atleast one menu option.</div>
                        }
                        <ul id="ulCheckbox">
                            <TreeCheckbox data={roleArray} onCheckAllCheckbox={onCheckAllCheckbox}
                                          toggleOpen={toggleOpen} toggleExpand={toggleExpand}
                                          selectedAllCheckboxHandler={selectedAllCheckboxHandler} hideSelectAll={false}/>
                        </ul>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
};

export default Role;